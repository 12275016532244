import React from "react";

import {
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import EmailAccountRegistrationController,{Props} from "./EmailAccountRegistrationController";



// Customizable Area Start



// Customizable Area End

export default class PrivacyPolicy extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={{ margin: '0px auto', maxWidth: '1200px' }}>
                <h1 style={{ textAlign: 'center' }}>Privacy and Policy</h1>

                <h3>Ensuring Privacy and Quality in Your CHSE Preparation with CHSE EXPRESS</h3>

                <p style={{ marginBottom: '30px' }}>
                    To enhance your CHSE preparation, the CHSE EXPRESS coaching portal offers high-quality
                    video lectures delivered by expert faculties from Odisha. We prioritize your privacy,
                    which is why we have established a privacy statement to safeguard all the information
                    collected during the registration process. To ensure the security of credit card information,
                    we employ a secure server for processing transactions while safeguarding login details.
                </p>

                <h3>What Information Does CHSE EXPRESS Require?</h3>

                <p style={{ marginBottom: '30px' }}>
                    Our web portal and app may request various contact details such as your email address, name,
                    and mobile number. We only request information that is essential to provide you with improved service.
                </p>

                <h3>Is Your Information Accessible to Others?</h3>

                <p style={{ marginBottom: '30px' }}>
                    Our web portal and app strictly prohibit any third-party access to your information. Therefore,
                    the information you share with us remains completely secure.
                </p>

                <h3>Is There a Cost for Accessing the Site?</h3>

                <p style={{ marginBottom: '50px' }}>
                    Accessing our website and downloading the app is entirely free. Payment is only required
                    when you intend to purchase or renew a course.
                </p>



                {/* terms and conditions */}

                <h1 style={{ textAlign: 'center' }}>Terms and Conditions</h1>

                <p style={{ marginBottom: '70px' }}>
                    Please carefully review the Terms and Conditions. By accessing the content on this website and
                    app, you are considered to have thoroughly read and understood these Terms and Conditions.
                    Furthermore, by accessing the CHSE EXPRESS website and app, I acknowledge my consent to be
                    legally bound by the Terms and Conditions outlined herein.
                </p>

                <p style={{ marginBottom: '70px' }}>
                    CHSE EXPRESS may use your information for surveys, research, and to enhance our services.
                    Our marketing and sales teams may contact you for updates on products and offers. If you've
                    registered, taken a CHSE EXPRESS course, and achieved a notable ranking in exams like
                    JEE/NEET/State Board, we may feature your name, photo, marks, rank, and course on our social
                    media and marketing materials.
                </p>

                <p style={{ marginBottom: '70px' }}>
                    For clarity in these Terms, 'we,' 'our,' and 'us' refer to CHSE EXPRESS, and 'you' refers to anyone
                    using the Website or acting on behalf of an entity. 'Content/material' includes text, images, or
                    any website material. Unless specified differently, 'Website' means www.chseexpress.com
                </p>

                <h3 style={{ textDecoration: 'underline' }}>a) We can grant or deny access as permitted by law.</h3>

                <p>
                    We have the authority to grant or deny access to data and accounts for users, including
                    all content and materials on this site, to the fullest extent permitted by applicable law.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>b) We may change terms without notice; check regularly.</h3>

                <p>
                    We retain the right to modify the service's terms and conditions without prior notice.
                    Users are advised to regularly review these terms. If any user disagrees with the terms
                    and conditions, they should refrain from visiting or registering on this site.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>c) We provide academic info about CHSE EXPRESS.</h3>

                <p>
                    The website and app provide general information about academic programs offered by
                    CHSE EXPRESS and various examinations conducted by them.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>d) Protect your data and equipment; site use at own risk.</h3>

                <p>
                    Users are solely responsible for adequately protecting and backing up their data and
                    equipment used in connection with the site. Any damage to your computer resulting
                    from site use or material downloads is at your own discretion and risk.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>e) All digital product (videos) purchases are non-refundable.</h3>

                <p>
                    Any purchases made for digital products (specifically videos) on CHSE EXPRESS are final
                    and cannot be refunded. Please ensure your selection carefully as refunds are not
                    available for these products.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>f) We own all website content; no guarantee of service.</h3>

                <p>
                    We own the rights to all content on the website & app and make no warranty that the
                    service will be uninterrupted, timely, secure, or error-free.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>g) Unauthorized use may result in claims or offenses.</h3>

                <p>
                    Unauthorized use of this website and app may lead to claims for damages and/or be
                    considered a criminal offense.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>h) We welcome suggestions but don't compensate for them.</h3>

                <p>
                    We welcome user suggestions for improving website content, material, and
                    information, but such suggestions will not be compensated.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>i) Content may change without notice; user views not endorsed.</h3>

                <p>
                    The content on this website is subject to change without notice and is provided for
                    general information only. We are not responsible for the authenticity of user views and
                    comments on the website.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>j) Links to other sites for info; we're not responsible for them.</h3>

                <p>
                    This website may include links to other websites for informational purposes. These links
                    do not imply our endorsement of the linked website(s), and we accept no responsibility
                    for their content.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>k) Provide accurate information; we may verify it.</h3>

                <p>
                    Users agree to provide accurate and truthful information. We reserve the right to verify
                    and validate user information at any time. If any user details are found to be untrue or
                    doubtful, we may reject registration and prohibit the user from using both www.CHSE
                    EXPRESS .com and app.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>l) No use of marks/logos without permission.</h3>

                <p>
                    Users are prohibited from using any marks or logos appearing on the site without
                    permission from us.
                </p>

                <h3 style={{ textDecoration: 'underline' }}>m) Disputes subject to Balasore, Odisha, India laws.</h3>

                <p style={{ marginBottom: '100px' }}>
                    Your use of this website and any disputes arising from it are subject to the laws of
                    Balasore, Odisha, India.
                </p>

                <h3>Website Conduct Summary: When using the website, you must follow these rules:</h3>

                <p>a) Follow all laws and regulations.</p>
                <p>b) Do not post harmful or offensive content.</p>
                <p>c) Avoid content that harasses or discriminates against others.</p>
                <p>d) Don't impersonate someone else or misrepresent yourself.</p>
                <p>e) Respect privacy and don't share personal info without consent.</p>
                <p>f) No false, misleading, or deceptive content.</p>
                <p>g) Respect intellectual property rights.</p>
                <p>h) No spam or unsolicited advertising.</p>
                <p>i) Do not copy or download content without permission.</p>
                <p>j) No promotion of illegal items or services.</p>
                <p>k) External links for informative purposes only.</p>
                <p>l) No malicious software or code.</p>
                <p>m) Be mindful of forum discussions.</p>
                <p>n) No excessive messaging or disruptions.</p>
                <p>o) Do not use fake email addresses or headers.</p>
                <p>p) No mention of coaching institutes or personal information sharing by students.</p>
                <p>Please adhere to these guidelines while using the website.</p>

            </div>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End

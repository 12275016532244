import React from "react";
// Customizable Area Start
import "./categoryAdmin.css";
import "./table.css";
import AdminLayout from "./AdminLayout.web";
import Table from "./Table.web";
import EditCourseComp from "./EditCourseComp.web";
import { Modal } from '@material-ui/core';
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

export default class ChildCategory extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomRows = (row: any) => {
    return (
      <>
        <td>
          <img
            src={row.attributes.mobile_icon}
            alt="App Icon"
            width="30"
            height="30"
          />
        </td>
        <td>
          <img
            src={row.attributes.web_icon}
            alt="Web Icon"
            width="30"
            height="30"
          />
        </td>
        <td>{row.attributes.name}</td>
        <td>{row.attributes.course_sub_category.name}</td>
        <td>{row.attributes.course_category.name}</td>
        <td>{row.attributes.total_courses || 550}</td>
        <td>{row.attributes.total_subjects || 22000}</td>
      </>
    );
  };

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <AdminLayout
        navigation={undefined}
        id={""}
        title={"Child Category"}
        display={"flex"}
        handleExport={this.exportCategory}
        addButtonWidth={"220px"}
        addPath={"AddChildCategory"}
      >
        <Table
          tableData={this.state.childCategoryData}
          navigation={undefined}
          id={""}
          tableHeaders={[
            "App Icon",
            "Web Icon",
            "Child Category",
            "Sub Category",
            "Category",
            "Total Courses",
            "Total Students",
          ]}
          customRows={this.renderCustomRows}
          tableFunction={this.getChildCategoryData}
          deleteCategory={this.delCategory}
          editCategory={this.handleEdit}
        />
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          className="modal"
        >
          <EditCourseComp navigation={undefined} id={""} buttonText="Update" bodyStyle={"bodyStyle"} topStyle={"topStyle"} bodyContainerStyle={"bodyContainerStyle"} modalHeading="Add Category" topItemStyle={"topItemStyle"}/>
        </Modal>
      </AdminLayout>
    );
  }
}

// Customizable Area Start
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Snackbar,
  FormControl,
  Tooltip,
  IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import InfoIcon from '@material-ui/icons/Info';
import {
  TwoColBox,
  ProfilePicInput,
  InputBox
} from "../../../components/src/GenericInputs";
import HeaderBannerController, { Props, configJSON } from "./HeaderBannerController.web";

// Customizable Area End

export default class AddHeaderBanner extends HeaderBannerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputView = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Title</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="bannerNameId"
                  variant="outlined"
                  fullWidth
                  onChange={this.handleBannerName}
                  value={this.state.bannerName}
                />
                <span data-test-id="bannerNameError" style={webStyles.errorText}>
                  {this.state.bannerNameError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Add Image</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                {this.state.bannerIconPreview ? (
                  <ProfilePicInput
                    onChange={e => this.handleIconChange(e)}
                    onRemove={() => this.handleDeleteIcon()}
                    data-test-id="iconTestId"
                    name="imgupload"
                    previewThumbnail={this.state.bannerIconPreview}
                  />
                ) : (
                  <Box className="file_upload_box">
                    <Typography className="app_icon_txt">Browse</Typography>
                    <input
                      data-test-id="iconTestId"
                      multiple
                      type="file"
                      accept="image/*"
                      name="imgupload"
                      id="webIconInput"
                      style={{ display: "none" }}
                      onChange={e => this.handleIconChange(e)}
                    />
                    <label htmlFor="webIconInput" className="lable_txt">
                      {configJSON.browseText}
                    </label>
                  </Box>
                )}
                <span data-test-id="bannerIconErr" style={webStyles.errorText}>
                  {this.state.bannerIconError}
                </span>
              </FormControl>
              <Tooltip title={configJSON.tooltipInfoMessageText} arrow>
                <IconButton>
                  <InfoIcon style={{ fontSize:"1rem" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </TwoColBox>
          <ButtonContainer>
            {this.state.isUpdate ? (
              <ResetButton
                data-test-id="resetButtonId"
                onClick={this.bannerNavigationCall}
              >
                {configJSON.cancelText}
              </ResetButton>
            ) : (
              <ResetButton
                data-test-id="resetButtonId"
                onClick={this.handleAddReset}
              >
                {configJSON.resetText}
              </ResetButton>
            )}
            <CreateButton
              data-test-id="submitButtonId"
              onClick={() => this.handleSubmitBanner()}
              disabled={this.state.isLoading}
            >
              {this.state.isUpdate ? configJSON.updateText : configJSON.createText}
            </CreateButton>
          </ButtonContainer>
        </Box>
      </div>
    );
  };

  notificationAlert = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.oncloseAlert}
        data-test-id="alertTestId"
      >
        <Alert data-test-id="alertMsgId" severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.isLoading}
        data-test-id="mainLayoutEvent"
        backData={this.bannerNavigationCall}
        title={this.state.isUpdate ? `Edit ${this.state.screenTitle}` : `Add ${this.state.screenTitle}`}
        isEdit
        isAdd
      >
        <main
          className="table_content"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderInputView()}
        </main>
        {this.notificationAlert()}
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px"
});

const ResetButton = styled(Button)({
  border: "1px solid RGB(63,100,116)",
  textTransform: "inherit",
  marginRight: "30px",
  padding: "8px 50px",
  width: "195px",
  height: "52px",
  borderRadius: "0px",
  color: "#1F6575",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  "@media (max-width:830px)": {
    padding: "6px 30px",
    fontSize: "11px"
  }
});

const CreateButton = styled(Button)({
  padding: "8px 50px",
  borderRadius: "0px",
  textTransform: "inherit",
  height: "52px",
  width: "195px",
  color: "#fff",
  fontFamily: "Inter",
  backgroundColor: "RGB(63,100,116)",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",

  "&:hover": {
    backgroundColor: "RGB(63,100,116)"
  },
  "@media (max-width:830px)": {
    padding: "6px 30px",
    fontSize: "11px"
  }
});

// Customizable Area End

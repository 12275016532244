Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EmailNotifications";
exports.labelBodyText = "EmailNotifications Body";

exports.btnExampleTitle = "CLICK ME";
exports.emailNotificationsEndpoint = "bx_block_email_notifications/email_notification_templates";
exports.getEmailNotificationsAPIMethod = "GET";
exports.putApiMethodType = "PUT";
exports.updateToggleMsg = "Email Notification Template successfully updated"
// Customizable Area End
import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Button,
  Modal,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { IoIosArrowDown } from "react-icons/io";
import { Pagination } from "@material-ui/lab";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
const drawerWidth = 250;
const closeDrawer = 0;
// Customizable Area End

import AdminSubCatgoryController, {
  Props,
  SubCategoryDataType
} from "./AdminSubCategoryController.web";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";

export default class AdminSubCatgory extends AdminSubCatgoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPagination = () => {
    return (
      <SubPaginationBox
        style={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "flex-end",
          alignItems: "center"
        }}
      >
        <Pagination
          data-test-id="pageChangebutton"
          count={this.state.pageCount}
          onChange={this.onPageChange}
          shape="rounded"
          boundaryCount={1}
          page={this.state.currentPageCount}
          siblingCount={0}
        />
      </SubPaginationBox>
    );
  };

  renderTableHeaderTitle = () => {
    return (
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center"
        }}
      >
        <input
          type="checkbox"
          data-test-id="headerCheckbox"
          checked={this.state.isHeaderChecked}
          onChange={this.handleSubCatHeaderCheckboxChange}
          style={webStyles.input}
        />
      </Box>
    );
  };

  renderTableHeader = (isCurrentSort: (name: string) => boolean) => {
    return (
      <>
        <th>
          <Box className="th_box" style={{ justifyContent: "center" }}>
            <Typography className="title_th" data-test-id="statusId">
              Status
            </Typography>
            <IoIosArrowDown
              className="filter_icon"
              onClick={() => this.handleSubCatSorting("Status")}
              style={{
                transform: isCurrentSort("Status") ? "none" : "rotate(180deg)"
              }}
            />
          </Box>
        </th>
        <th>
          <Box className="th_box" style={{ justifyContent: "center" }}>
            <Typography className="title_th">Action</Typography>
          </Box>
        </th>
      </>
    );
  };

  renderAdminSubCategoryTable = () => {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>{this.renderTableHeaderTitle()}</th>
              {[
                "S.No",
                "App Icon",
                "Web Icon",
                "Sub Category",
                "Main Category",
                "Total Courses",
                "Total Students"
              ].map((header, index) => (
                <th key={index.toString()}>
                  <Box
                    className="th_box"
                    style={
                      index === 0 || index === 1
                        ? { justifyContent: "center" }
                        : {}
                    }
                  >
                    <Typography className="title_th">{header}</Typography>
                    {["S.No", "Sub Category"].includes(header) && (
                      <IoIosArrowDown
                        data-test-id="headerShortIcone"
                        className={`filter_icon ${isCurrentSort(header) ? "active" : ""}`}
                        onClick={() => this.handleSubCatSorting(header)}
                        style={{
                          transform: isCurrentSort(header)
                            ? "none"
                            : "rotate(180deg)"
                        }}
                      />
                    )}
                  </Box>
                </th>
              ))}
              {this.renderTableHeader(isCurrentSort)}
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody>
            {this.state.adminSubCategoryData.data.map((item, index) =>
              this.renderAdminSubCategoryTableBody(item, index)
            )}
          </TableBody>
        </TableStyle>
        {this.renderPagination()};
      </Box>
    );
  };

  renderAdminSubCategoryTableBody = (
    item: SubCategoryDataType,
    index: number
  ) => {
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                test-id="rowCheckbox"
                type="checkbox"
                style={webStyles.input}
                checked={this.state.isChecked[index]}
                onChange={() => this.handleRowCheckboxChange(index)}
              />
            </Box>
          </td>
          <td style={{ textAlign: "left" }}>{item.id}</td>
          <td>
            <img
              src={item.attributes.mobile_icon}
              alt="img"
              className="desc_img"
            />
          </td>
          <td>
            <img
              src={item.attributes.web_icon}
              alt="img2"
              className="desc_img"
            />
          </td>
          <td style={{ textAlign: "left" }}>{item.attributes.name}</td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.course_category.name}
          </td>
          <td style={{ textAlign: "left" }}>{item.attributes.total_courses}</td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.student_count || 0}
          </td>
          <td>
            <SubCheckBtn className="switch">
              <input
                type="checkbox"
                data-test-id="statusTestId"
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
                checked={item.attributes.status}
              />
              <span className="slider round" />
            </SubCheckBtn>
          </td>
          <td style={{ borderRadius: "0px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.handleEditPropsSubCategory(item.id)}
                size={20}
                color="black"
              />
              <FaRegTrashAlt
                data-test-id={"deleteTestId"}
                className="desc_edit_icon"
                onClick={() => this.handleOpenDeleteSubCategoryModal(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderSubCategoryDeleteModal = () => {
    return (
      <>
        <SubDeleteModal
          open={this.state.openDeleteSubCategoryModal}
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="deleteModalId"
          onClose={this.handleCloseDeleteSubCategoryModal}
        >
          {this.renderModalContent()}
        </SubDeleteModal>
      </>
    );
  };

  renderModalContent = () => {
    return (
      <SubModalComponent>
        <DeleteTitleText component={"p"}>
          Do you want to delete this sub category?
        </DeleteTitleText>
        <ButtonBox>
          <SubDelButton
            data-test-id="deleteButtonId"
            onClick={() =>
              this.handleDeleteSubCategory(this.state.deleteSubCatId)
            }
          >
            Delete
          </SubDelButton>
          <SubCanButton
            data-test-id="CloseDeleteButtonId"
            onClick={() => this.handleCloseDeleteSubCategoryModal()}
          >
            Cancel
          </SubCanButton>
        </ButtonBox>
      </SubModalComponent>
    );
  };

  notificationAlertSubCategory = () => {
    return (
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={this.state.isAlert}
        autoHideDuration={3000}
        data-test-id="alertTestId"
        onClose={this.oncloseAlert}
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.loader}
        handleSearch={(e: any) => this.handleSubCategorySearch(e)}
        title="Sub Category"
        data-test-id="mainLayoutEvent"
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.handleAddSubCategoryNavigation(),
            text: "Add Sub Category",
            testId: "addSubCatButtonId"
          }
        ]}
      >
        {this.notificationAlertSubCategory()}
        {this.renderSubCategoryDeleteModal()}
        <main className="table_data" style={{ background: "white" }}>
          {this.renderAdminSubCategoryTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  hideDrawer: {
    width: `calc(100% - ${closeDrawer}px)`
  },
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "10px"
  },
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  tableActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tableBodyMsg: {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh",
    width: "100%",
    marginTop: "50px",
    border: "1px solid red"
  },
  currentPage: {
    backgroundColor: "#176876",
    color: "white",
    borderRadius: "5px",
    padding: "0px 5px"
  },
  input: {
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    border: "3px solid black"
  },
  modalContent: {
    width: "90%",
    maxWidth: "700px",
    backgroundColor: "#fff",
    borderRadius: "5px"
  },
  tableFooter: {
    display: "flex",
    alignSelf: "flex-end",
    width: "320px",
    marginRight: "40px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    width: "70%",
    justifyContent: "space-between"
  },
  pageWrapper: {
    width: "70%",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex"
  }
};

const SubPaginationBox = styled(Box)({
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#fff",
    backgroundColor: "#206575"
  },
  "& .viewall_txt": {
    fontSize: "0.75rem",
    cursor: "pointer",
    color: "#206575"
  }
});

const SubDelButton = styled("button")({
  color: "white",
  fontSize: "16px",
  fontWeight: 500,
  width: "145px",
  height: "45px",
  backgroundColor: "#DA1831",
  border: "0"
});

const SubDeleteModal = styled(Modal)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  alignItems: "center"
});

const SubModalComponent = styled(Box)({
  width: "340px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 0px 3px px transparent",
  outline: "none",
  margin: "20px",
  height: "120px",
  padding: "10px 50px",
  borderRadius: "5px",
  backgroundColor: "#ffffff"
});

const SubCanButton = styled("button")({
  width: "145px",
  backgroundColor: "white",
  fontSize: "16px",
  height: "40px",
  border: "1px solid #206575",
  color: "#206575"
});

const DeleteTitleText = styled(Box)({
  width: "100%",
  height: "37px",
  fontWeight: 600,
  fontSize: "16px"
});

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
});

const SubCheckBtn = styled("label")({
  "&.switch": {
    width: "36px",
    height: "19px",
    position: "relative",
    display: "inline-block",
    marginTop: "5px",
    "& input": {
      width: 0,
      height: 0,
      opacity: 0
    }
  },
  "& .slider": {
    position: "absolute",
    cursor: "pointer",
    backgroundColor: "#ccc",
    WebkitTransition: ".4s",
    top: 0,
    left: 0,
    transition: ".4s",
    borderRadius: "20px",
    right: 0,
    bottom: "-1px"
  },
  "& .slider:before": {
    width: "18px",
    left: "1px",
    bottom: "1px",
    backgroundColor: "white",
    WebkitTransition: ".4s",
    position: "absolute",
    content: '""',
    height: "18px",
    transition: ".4s",
    borderRadius: "50%"
  },
  "& input:checked + .slider": {
    backgroundColor: "#708328"
  },
  "& input:checked + .slider:before": {
    left: "-10px"
  }
});
// Customizable Area End

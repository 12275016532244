import React from "react";

// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import { PiPen } from "react-icons/pi";
// Customizable Area End

import PushNotificationsController, {
  Props,
  NotificationsData
} from "./PushNotificationsController.web";

export default class TermsAndConditions extends PushNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: NotificationsData, index: number) => {
    return (
      <>
        <tr>
          <td style={{ textAlign: "left", width: 25 }}>{item.id}</td>
          <td style={{ textAlign: "left", width: 450, lineBreak: "auto" }}>
            {item.attributes.headers}
          </td>
          <td style={{ textAlign: "left", width: 200 }}>
            {item.attributes.contents}
          </td>
          <td style={{ textAlign: "center", width: 200 }}>
            {item.attributes.push_notification_template_type}
          </td>
          <td style={{ textAlign: "left", width: 100 }}>
            <StatusToggle className="switch">
              <input
                type="checkbox"
                data-test-id="statusTestId"
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
                checked={item.attributes.status}
              />
              <span className="slider round" />
            </StatusToggle>
          </td>
          <td
            style={{
              borderRadius: "0px 10px 10px 0px",
              textAlign: "center",
              width: 25
            }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.navigationEditPushNotifications(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              {[
                "ID",
                "Headings",
                "Contents",
                "Notification Type",
                "Status",
                "Action"
              ].map((header, index) => {
                return (
                  <th>
                    <Box
                      className="th_box"
                      style={
                        index === 3
                          ? { justifyContent: "center" }
                          : { justifyContent: "left" }
                      }
                    >
                      <Typography className="title_th">{header}</Typography>
                    </Box>
                  </th>
                );
              })}
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody>
            {this.state.notificationsList &&
              this.state.notificationsList.length > 0 &&
              this.state.notificationsList.map((notification, index) =>
                this.renderTableBody(notification, index)
              )}
          </TableBody>
        </TableStyle>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.isLoading}
        newUI
        isAdd
        title="Push Notifications"
        data-test-id="mainLayoutEvent"
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          isOpen={this.state.isAlert}
          msg={this.state.alertMsg}
          onClose={this.onCloseAlert}
          dataTestId="alertTestId"
        />
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "8px"
  },
  iconSize: {
    fontSize: "13px"
  }
};
const PaginationBox = styled(Box)({
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff"
  },
  "& .viewall_txt": {
    fontSize: "14px",
    cursor: "pointer",
    color: "#206575"
  }
});
const StatusToggle = styled("label")({
  "&.switch": {
    width: "36px",
    height: "19px",
    position: "relative",
    display: "inline-block",
    marginTop: "5px",
    "& input": {
      width: 0,
      height: 0,
      opacity: 0
    }
  },
  "& .slider": {
    position: "absolute",
    cursor: "pointer",
    backgroundColor: "#ccc",
    WebkitTransition: ".4s",
    top: 0,
    left: 0,
    transition: ".4s",
    borderRadius: "20px",
    right: 0,
    bottom: "-1px"
  },
  "& .slider:before": {
    width: "18px",
    left: "1px",
    bottom: "1px",
    backgroundColor: "white",
    WebkitTransition: ".4s",
    position: "absolute",
    content: '""',
    height: "18px",
    transition: ".4s",
    borderRadius: "50%"
  },
  "& input:checked + .slider": {
    backgroundColor: "#708328"
  },
  "& input:checked + .slider:before": {
    left: "-10px"
  }
});
// Customizable Area End

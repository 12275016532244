import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button,
    Modal,
    Snackbar,
    TextField,
    NativeSelect
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Pagination } from "@material-ui/lab";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const drawerWidth = 250;
const closeDrawer = 0;
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";
import {
  ProfilePicInput
} from "../../../components/src/GenericInputs";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
// Customizable Area End

import AdminInstitutesController, {
    Props,
} from "./AdminInstitutesController.web";

export default class AdminInstitutes extends AdminInstitutesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderTableBodyInstitue = (item: any, index: any) => {
        return (
            <TableBody>
                <tr>
                    <td>
                        <Box className="desc_checkbox">
                            <input
                                data-test-id={"rowCheckboxTestId"}
                                type="checkbox"
                                checked={this.state.isChecked[index]}
                                onChange={() => this.handleInstitutesRowCheckboxChange(index)}
                                style={instituteDrawerStyle.input}
                            />
                        </Box>
                    </td>
                    <td className="tr_desc">{item.id}</td>
                    <td>
                        <img
                            src={item.attributes.institute_logo}
                            alt="img"
                            className="desc_img"
                        />
                    </td>
                    <td>{item.attributes.name?.length > 17 ? `${item.attributes.name.slice(0, 17)}...` : item.attributes.name || "----"}</td>
                    <td>{item.attributes.address?.length > 33 ? `${item.attributes.address.slice(0, 33)}...` : item.attributes.address || "----"}</td>
                    <td>{item.attributes.district_details.name || "----"}</td>
                    <td>{item.attributes.state_details.name || "----"}</td>
                    <td>
                        {<InstituteCheckStatusBtn className="switch">
                            <input data-test-id={'statusCheckTestId'} type="checkbox" onChange={() => this.handleInstituteStatus(item.id)} checked={item.attributes.status} />
                            <span className="slider round"></span>
                        </InstituteCheckStatusBtn>}
                    </td>
                    <td>
                        <Box className="desc_edit_icon_box">
                            <PiPen
                                data-test-id={'editIconTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={20}
                                onClick={() => this.handleShowInstitutes(item.id)}
                            />
                            <FaRegTrashAlt
                                data-test-id={'deleteIconeTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={17}
                                onClick={() => this.handleOpenDeleteInstituteModal(item.id)}
                            />
                        </Box>
                    </td>
                </tr>
                <Box className="table_divider" style={instituteDrawerStyle.drawerTableDivider} />
            </TableBody>
        )
    }

    getSortTestId = (header: string) => {
        const headerMap: { [key: string]: string } = {
        "Institute Name": "sort_name",
        "S.No": "sort_id",
        Status: "sort_status",
        };

        return headerMap[header] || "sort";
    };

    renderTableInstitute = () => {
        const isCurrentSort = (name: string) =>
            this.state.currentSortState.field === name &&
            this.state.currentSortState.order === "asc";
        return (
            <Box>
                <TableStyle>
                    <thead>
                        <tr>
                            <th>
                                <Box
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        data-test-id="headerCheckboxTestId"
                                        style={instituteDrawerStyle.input}
                                        checked={this.state.isHeaderChecked}
                                        onChange={this.handleInstitutesHeaderCheckboxChange}
                                    />
                                </Box>
                            </th>
                            {[
                                "S.No",
                                "Inst. Logo",
                                "Institute Name",
                                "Institute Address",
                                "District",
                                "State",
                                "Status",
                                "Action"
                            ].map((header, index) => {
                                let renderIcon = false;
                                if (["S.No", "Institute Name", "Status"].includes(header)) {
                                    renderIcon = true;
                                }
                                return renderTableRecord(
                                    () => isCurrentSort(header),
                                    header,
                                    renderIcon,
                                    index,
                                    () => this.handleSort(header),
                                    this.getSortTestId(header),
                                    "center"
                                );
                            })
                            }
                        </tr>
                    </thead>
                    <span style={instituteDrawerStyle.drawerDividerLine}></span>
                    {this.state.adminInstitutesData?.data?.map((item: any, index: any) => this.renderTableBodyInstitue(item, index))}
                </TableStyle>
                <InstitutePaginationBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <Pagination
                        data-test-id="pageChangebutton"
                        count={this.state.pageCount}
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.currentPageCount}
                        siblingCount={0}
                        onChange={this.onInstitutesPageChange}
                    />
                </InstitutePaginationBox>
            </Box>
        )
    }

    renderDeleteInstituteModal = () => {
        return (
            <>
                <InstituteDeleteModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="InstituteDeleteModalId"
                    open={this.state.openDeleteInstituteModal}
                    onClose={this.handleCloseDeleteInstituteModal}
                >
                    <InstituteModalView>
                        <InstituteDeleteText component={"p"}>
                            Do you want to delete this institute?
                        </InstituteDeleteText>
                        <InstituteModalButton>
                            <InstituteDeleteButton data-test-id="deleteBtnTestId"
                                onClick={() => this.handleDeleteInstitute(this.state.deleteInstituteId)}
                            >Delete</InstituteDeleteButton>
                            <InstituteCancelButton data-test-id="cancelBtnTestId"
                                onClick={() => this.handleCloseDeleteInstituteModal()}
                            >Cancel</InstituteCancelButton>
                        </InstituteModalButton>
                    </InstituteModalView>
                </InstituteDeleteModal>
            </>
        )
    }

    renderAddInstituteModal = () => {
        const logoName = this.state.instituteLogoName;
        const logoNameText = logoName === "" ? "Upload square image icon" : logoName.length > 40 ? `${logoName.substring(0, 40)}...` : logoName;

        return (
            <>
                <AddInstituteModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="AddInstituteModalId"
                    open={this.state.openAddInstituteModal}
                    onClose={this.handleCloseDeleteInstituteModal}
                >
                    <AddInstituteModalView>
                        {this.notificationAlertMessageInstitute()}
                        <AddInstituteModalBox>
                            <ModalHeadingText >Add Institute</ModalHeadingText>
                            <RxCross2 onClick={() => this.handleCloseAddInstituteModal()} style={{ height: "25px", width: "25px" }} />
                        </AddInstituteModalBox>
                        <InstituteFieldBox>
                            <InstituteFieldControlBox>
                                <Typography className="input_txt">Institute Name*</Typography>
                                <InstituteInputBox
                                    variant="outlined" fullWidth
                                    data-test-id="instituteInputTestId"
                                    onChange={this.handleInstituteName}
                                    value={this.state.instituteName}
                                />
                            </InstituteFieldControlBox>
                        </InstituteFieldBox>
                        <InstituteFieldBox>
                            <InstituteFieldControlBox>
                                <Typography className="input_txt">Address*</Typography>
                                <InstituteAddressInputBox
                                    variant="outlined" fullWidth
                                    data-test-id="addressInputTestId"
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    onChange={this.handleInstituteAddress}
                                    value={this.state.instituteAddress}
                                />
                            </InstituteFieldControlBox>
                        </InstituteFieldBox>
                        <InstituteFieldBox>
                            {this.renderStateSelect()}
                        </InstituteFieldBox>
                        <InstituteFieldBox>
                            {this.renderDistrictSelect()}
                        </InstituteFieldBox>
                        <InstituteFieldBox>
                            <InstituteFieldControlBox>
                                <Typography className="input_txt">Upload Logo*</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {this.state.instituteLogoThumbnail ? (
                                        <ProfilePicInput
                                            onChange={this.handleInstituteLogo}
                                            onRemove={this.handleDeleteFile}
                                            data-test-id="seletInstituteLogoId"
                                            name="subCatIconImgUpload"
                                            previewThumbnail={this.state.instituteLogoThumbnail}
                                        />
                                    ) : (
                                        <Box className="file_upload_box">
                                            <Typography className="app_icon_txt">
                                                Upload square image icon
                                            </Typography>
                                            <input
                                                data-test-id="seletInstituteLogoId"
                                                multiple
                                                type="file"
                                                accept="image/*"
                                                name="subCatIconImgUpload"
                                                id="subCatIconInput"
                                                style={{ display: "none" }}
                                                onChange={this.handleInstituteLogo}
                                            />
                                            <label
                                                htmlFor="subCatIconInput"
                                                className="lable_txt"
                                            >
                                                Browse
                                            </label>
                                        </Box>
                                    )}
                                    <span style={webStyles.errorText}>
                                        {this.state.instituteLogoError}
                                    </span>
                                </div>
                            </InstituteFieldControlBox>
                        </InstituteFieldBox>
                        <InstituteFieldBox>
                            <InstituteFieldControlBox>
                                <Typography className="input_txt">status</Typography>
                                <InstituteCheckBoxButton className="switch">
                                    <input type="checkbox"
                                        data-test-id="checkboxId"
                                        onChange={this.handleInstituteStatusCheck} checked={this.state.instituteStatus}
                                    />
                                    <span className="slider round"></span>
                                </InstituteCheckBoxButton>
                            </InstituteFieldControlBox>
                        </InstituteFieldBox>
                        <ButtonBox>
                            {this.state.isUpdate ?
                                <Button variant="contained" className="reset_btn"
                                    onClick={() => this.handleCloseAddInstituteModal()}
                                >
                                    Cancel
                                </Button> :
                                <Button variant="contained" className="reset_btn"
                                    onClick={this.handleFeildReset}
                                >
                                    Reset
                                </Button>}
                            {this.state.isUpdate ?
                                <Button
                                    data-test-id="updateButtonTestId"
                                    onClick={this.handleEditInstitute}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    Update
                                </Button> :
                                <Button
                                    data-test-id="createButtonTestId"
                                    onClick={this.handlePostInstitute}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    Add Institute
                                </Button>}
                        </ButtonBox>
                    </AddInstituteModalView>
                </AddInstituteModal>
            </>
        )
    }

    renderDistrictSelect = () => {
        return (
            <InstituteFieldControlBox>
                <Typography className="input_txt">District*</Typography>
                <SelectBox
                    id="demo-customized-select-native"
                    disableUnderline
                    IconComponent={FaChevronDown}
                    data-test-id="districtSelectTestId"
                    onChange={(event) => this.handleDistrictSelectChange(event.target.value)}
                    value={this.state.districtId}
                >
                    <option aria-label="None" value="" />
                    {this.state.districtListData?.map((district: { attributes:{id: string, name: string }}) => {
                        return (
                            <option key={district.attributes.id} value={district.attributes.id}>
                                {district.attributes.name}
                            </option>
                        );
                    })}
                </SelectBox>
            </InstituteFieldControlBox>
        )
    }

    renderStateSelect = () => {
        return (
            <InstituteFieldControlBox>
                <Typography className="input_txt">State*</Typography>
                <SelectBox
                    id="demo-customized-select-native"
                    disableUnderline
                    IconComponent={FaChevronDown}
                    data-test-id="stateSelectTestId"
                    onChange={(e) => this.handleStateSelectChange(e.target.value)}
                    value={this.state.stateId}
                >
                    <option aria-label="None" value="" />
                    {this.state.stateListData?.map((state: any) => {
                        return (
                            <option key={state.value} value={state.value}>
                                {state.lable}
                            </option>
                        );
                    })}
                </SelectBox>
            </InstituteFieldControlBox>
        )
    }

    notificationAlertMessageInstitute = () => {
        return (
            <Snackbar
                autoHideDuration={3000}
                open={this.state.isAlert}
                onClose={this.onCloseAlert}
                anchorOrigin={{ horizontal: 'center', vertical: "top" }}
            >
                <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
            </Snackbar>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainLayout
                isLoading={this.state.loader}
                handleSearch={(e: any) => this.handleInstitutesSearch(e)}
                title="Institute"
                data-test-id="mainLayoutEvent"
                actionButtons={[
                    {
                        icon: <AddCircleOutlineIcon />,
                        iconType: "jsxElement",
                        onClick: () => this.handleOpenAddInstituteModal(),
                        text: "Create Institute",
                        testId: "addInstituteButtonId"
                    }
                ]}
            >
                {this.notificationAlertMessageInstitute()}
                {this.renderDeleteInstituteModal()}
                {this.renderAddInstituteModal()}
                <main className="table_data" style={{background: "white"}}>
                    {this.renderTableInstitute()}
                </main>
            </MainLayout>   
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const instituteDrawerStyle = {
    hideChildCatDrawer: { width: `calc(100% - ${closeDrawer}px)` },
    drawerTableDivider: { height: "8px" },
    showChildCatDrawer: { width: `calc(100% - ${drawerWidth}px)` },
    input: {
        height: "20px",
        width: "20px",
        border: "3px solid black",
        display: "flex",
        background: "none",
        justifyContent: "center",
        alignItems: "center"
    },
    drawerDividerLine: {
        display: "inline-block",
        height: "20px"
    },
};

const InstitutePaginationBox = styled(Box)({
    "& .MuiPaginationItem-page.Mui-selected": {
        color: "#fff",
        backgroundColor: "#206575"
    },
    "& .viewall_txt": {
        fontSize: "14px",
        color: "#206575",
        cursor: "pointer"
    }
});

const InstituteCheckStatusBtn = styled("label")({
    "& .slider": {
        position: "absolute",
        cursor: "pointer",
        right: 0,
        top: 0,
        bottom: "-1px",
        left: 0,
        backgroundColor: "#ccc",
        WebkitTransition: ".4s",
        transition: ".4s",
        borderRadius: "20px"
    },
    "&.switch": {
        position: "relative",
        display: "inline-block",
        marginTop: "5px",
        width: "36px",
        height: "19px",
        "& input": {
            width: 0,
            height: 0,
            opacity: 0
        },
    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        position: "absolute",
        content: '""',
        left: "1px",
        height: "18px",
        width: "18px",
        WebkitTransition: ".4s",
        bottom: "1px",
        borderRadius: "50%",
        backgroundColor: "white",
        transition: ".4s"
    },
    "& input:checked + .slider:before": { left: "-10px" }
});

const InstituteDeleteModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const AddInstituteModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const InstituteModalView = styled(Box)({
    height: "120px",
    width: "340px",
    borderRadius: '5px',
    padding: "10px 50px",
    outline: "none",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column"
});

const AddInstituteModalView = styled(Box)({
    height: "545px",
    width: "651px",
    borderRadius: '18px',
    padding: "10px 30px",
    outline: "none",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column"
});

const SelectBox = styled(NativeSelect)({
    "&.MuiInputBase-root": {
        border: "1px solid lightgray",
        width: "490px",
        borderRadius: "4px"
    },
    "& .MuiNativeSelect-icon": {
        top: "calc(50% - 7px);",
        right: "6px",
    },
    "& .MuiInputBase-input": { padding: "10px 0px 10px 8px" }
});

const InstituteModalButton = styled(Box)({
    justifyContent: "space-between",
    display: "flex"
});

const InstituteCancelButton = styled("button")({
    height: "40px",
    width: "145px",
    color: "#206575",
    border: "1px solid #206575",
    fontSize: "16px",
    backgroundColor: "white"
});

const InstituteDeleteButton = styled("button")({
    height: "45px",
    width: "145px",
    border: "0",
    backgroundColor: "#DA1831",
    fontSize: "16px",
    color: "white",
    fontWeight: 500
});

const InstituteDeleteText = styled(Box)({
    height: "37px",
    width: "100%",
    fontSize: "16px",
    fontWeight: 600
});

const InstituteFieldBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    "@media (max-width:1330px)": {
        flexDirection: "column",
        alignItems: "start",
    },
    justifyContent: "flex-start"
});

const InstituteFieldControlBox = styled(Box)({
    marginRight: '93px',
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
    "& .input_txt": {
        width: "160px",
        textTransform: "capitalize",
        fontFamily: "Inter",
        fontStyle: "normal",
        color: "#192252",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    "& .file_upload_box": {
        width: "490px",
        height: "44px",
        color: "#fff",
        border: "1px solid black",
        justifyContent: "space-between",
        display: "flex",
        borderRadius: "4px",
        alignItems: "center",
        borderColor: "#D4D4D4",
        "& .lable_txt": {
            height: "90%",
            alignItems: "center",
            backgroundColor: "#3F6474",
            display: "flex",
            marginRight: "2px",
            borderRadius: "4px",
            justifyContent: "center",
            padding: "0px 30px",
            fontSize: "12px",
        },
        "& .app_icon_txt": {
            color: "#A9A9A9",
            wordSpacing: "-2px",
            fontSize: "13px",
            marginLeft: "10px",
        },
    },
    "@media (max-width:875px)": {
        flexDirection: "column",
        alignItems: "start",
        "& .input_txt": {
            marginBottom: "8px",
            width: "100%",
        }
    },
});

const InstituteInputBox = styled(TextField)({
    "& .MuiOutlinedInput-input": { padding: "0px 14px" },
    width: "490px",
    "& .MuiInputBase-input": { height: "44px" },
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
});

const InstituteAddressInputBox = styled(TextField)({
    width: "490px",
    "& .MuiInputBase-input": { height: "86px" },
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
});

const InstituteCheckBoxButton = styled("label")({
    "& .slider": {
        transition: ".4s",
        borderRadius: "20px",
        position: "absolute",
        bottom: "-1px",
        top: 0,
        cursor: "pointer",
        right: 0,
        WebkitTransition: ".4s",
        backgroundColor: "#ccc",
        left: 0,
    },
    "& input:checked + .slider:before": { left: "-10px" },
    "&.switch": {
        width: "36px",
        marginTop: "5px",
        position: "relative",
        "& input": {
            height: 0,
            opacity: 0,
            width: 0,
        },
        display: "inline-block",
        height: "19px",
    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        position: "absolute",
        width: "18px",
        WebkitTransition: ".4s",
        content: '""',
        left: "1px",
        backgroundColor: "white",
        transition: ".4s",
        borderRadius: "50%",
        bottom: "1px",
        height: "18px"
    },
});

const ButtonBox = styled(Box)({
    width: "100%",
    justifyContent: "center",
    display: "flex",
    margin: "25px 0",
    alignItems: "center",
    "& .create_btn": {
        backgroundColor: "#3f6474",
        borderRadius: "0",
        color: "#fff",
        textTransform: "capitalize",
        fontSize: "14px",
        boxShadow: "none",
        width: "179px",
        margin: "0px 10px",
        fontFamily: "Inter",
        fontWeight: 700,
        height: "40px",
        lineHeight: "22px",
        fontStyle: "normal",
    },
    "& .reset_btn": {
        backgroundColor: "#fff",
        textTransform: "capitalize",
        borderRadius: "0",
        border: "1px solid #3F6474",
        fontSize: "14px",
        width: "108px",
        padding: "10px 60px",
        color: "#3f6474",
        margin: "0px 10px",
        fontFamily: "Inter",
        height: "40px",
        fontStyle: "normal",
        lineHeight: "22px",
        boxShadow: "none",
        fontWeight: 700,
    },
    "@media (max-width:460px)": {
        "& .create_btn": {
            padding: '5px 48px',
            height: '40px',
        },
        "& .reset_btn": {
            padding: '5px 50px',
            height: '40px',
        },
    },
    "@media (max-width:400px)": {
        "& .create_btn": { width: '100%' },
        flexDirection: 'column',
        "& .reset_btn": { marginBottom: '10px', width: '100%' }
    },
});

const AddInstituteModalBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 22
});

const ModalHeadingText = styled(Typography)({
    fontWeight: 700,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "18px",
    color: "#000",
    lineHeight: "26px",
    letterSpacing: "-0.12px"
});
// Customizable Area End
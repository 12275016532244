import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { customAlert, getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { ChangeEvent } from "react";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export interface ResponseErrors {
    errors: {
      token: string;
    }[];
  }
export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    apiBody?: object;
    body?: object;
    token?: string;
    type?: string;
}

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

export interface InvalidResponseType {
    errors: Array<string>;
}

export interface StateListData {
    id: string | number;
    type: string;
    attributes: {
        id: number;
        name: string;
        profile_country: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
        };
        created_on: string;
        updated_on: string;
    };
}

interface StatesListDataPayloadType {
    data: StateListData[];
    meta: {
        page: string;
        total_states: number;
    };
    error?: string | undefined;
}

interface CountryData {
    lable: string;
    value: number;
}

interface ErrorResponse {
    data: {
        attributes: { name: string };
        name: string[];
    };
}

interface ShowStateData {
    data: {
        attributes: {
            id: string;
            name: string;
            profile_country: { id: string; }
        }, name: string;
    }
}

export const defaultStateValues: Pick<S, "userToken" | "loader" | "isAlert" | "alertMsg" | "alertType" | "isDrawer" | "takeAction" | "anchorEl" | "currentPageCount" | "perPage"> = {
    userToken: "",
    loader: false,
    isAlert: false,
    alertMsg: "",
    alertType: "success",
    isDrawer: true,
    takeAction: "",
    anchorEl: null,
    currentPageCount: 1,
    perPage: elementsPerPage,
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userToken: string;
    loader: boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    isDrawer: boolean;
    takeAction: string;
    anchorEl: HTMLButtonElement | null;
    currentPageCount: number;
    perPage: number;
    adminStateListData: StatesListDataPayloadType;
    pageCount: number;
    deleteId: string | number;
    currentSortState: { order: string; field: string };
    columnSort: string;
    selectCountry: string | unknown;
    countryData: CountryData[];
    stateName: string;
    isUpdate: boolean;
    stateId: string | number;
    openDeleteModal: boolean;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminStateListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStatelistApiCallId: string = "";
    deleteStateApiCallId: string = "";
    getCountrylistApiCallId: string = "";
    postAddStateApiCallId: string = "";
    showStateApiCallId: string = "";
    updateStateApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationIdMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            ...defaultStateValues,
            adminStateListData: {
                data: [],
                meta: {
                    page: "",
                    total_states: 0
                }
            },
            pageCount: 0,
            deleteId: "",
            currentSortState: { order: "desc", field: "id_sort" },
            columnSort: "",
            selectCountry: "",
            countryData: [],
            stateName: "",
            isUpdate: false,
            stateId: "",
            openDeleteModal: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isStatesValidResponse(responseJson)) {
                if(!responseJson.errors) {
                    this.apiSuccessCallBacks(apiRequestCallId, responseJson);
                } else{
                    this.parseErrors(responseJson)
                }
            };
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
            if (propsData) {
                this.handleShowState(propsData.stateId)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.getUserToken();
    }

    getUserToken = async () => {
        const token = await getStorageData('loginToken');
        this.setState({ userToken: token });
        await this.getStateListDataFunction(this.state.currentPageCount, this.state.perPage, this.state.columnSort);
        await this.getCountryListDataFunction();
    };

    adminStateListApiCall = (apiCallData: APIPayloadType) => {
        let { endPoint, body, method, contentType, type } = apiCallData;
        const header = {
            "Content-Type": contentType,
            "token": this.state.userToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    isStatesValidResponse = (responseJson: ValidResponseType) => {
        return responseJson;
    };

    apiSuccessCallBacks = async (apiRequestCallId: string, responseJson: StatesListDataPayloadType & ShowStateData & Array<CountryData> & StateListData & ErrorResponse) => {
        if (apiRequestCallId === this.getStatelistApiCallId) {
            this.handleGetStatesListResponse(responseJson);
        }
        if (apiRequestCallId === this.deleteStateApiCallId) {
            this.handleDeleteStateResponse(responseJson);
        }
        if (apiRequestCallId === this.getCountrylistApiCallId) {
            this.handleGetCountryListResponse(responseJson);
        }
        if (apiRequestCallId === this.postAddStateApiCallId) {
            this.handlePostStateResponse(responseJson);
        }
        if (apiRequestCallId === this.showStateApiCallId) {
            this.handleShowAdminStateResponse(responseJson);
        }
        if (apiRequestCallId === this.updateStateApiCallId) {
            this.handleUpdateAdminStateResponse(responseJson);
        }
    };

    parseErrors = async (responseJson: ResponseErrors) => {
        if (Object.keys(responseJson.errors[0])[0] === "token") {
          this.navigationLoginScreen();
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      };
    
    handleUpdateAdminStateResponse = async (response: ErrorResponse) => {
        const { data } = response;

        if (data?.attributes?.name.length > 0) {
            const alertMsg = configJSON.updateSuccessMsg;
            const alertType: Color = configJSON.successMsg;

            this.handleFieldReset();
            await this.getStateListDataFunction(this.state.currentPageCount, this.state.perPage, this.state.columnSort);

            this.setState({
                isAlert: true,
                alertMsg,
                alertType
            }, () => {
                this.navigationStateListScreen();
            });
        } else if (data?.name[0] === configJSON.postStateErrorMsg) {
            const errorMsg = data?.name[0] || configJSON.deleteErrorMsg;
            const errorType = configJSON.errorMsg;

            const newState = {
                loader: false,
                isAlert: true,
                alertMsg: errorMsg,
                alertType: errorType,
            };
            this.setState(newState);
        }
    };

    handleShowAdminStateResponse = async (response: StatesListDataPayloadType & ShowStateData) => {
        const { data } = response;
        const attributes = data?.attributes || {};

        this.setState({
            isUpdate: true,
            loader: false,
            selectCountry: attributes.profile_country?.id || this.state.selectCountry,
            stateName: attributes.name || this.state.stateName,
            stateId: attributes.id
        });
    };

    handlePostStateResponse = async (response: ErrorResponse) => {
        const { data } = response;

        if (data?.attributes?.name.length > 0) {
            this.handleFieldReset();
            this.navigationStateListScreen();
        } else if (data?.name[0] === configJSON.postStateErrorMsg) {
            const errorMsg = data?.name[0] || configJSON.deleteErrorMsg;
            const errorType = configJSON.errorMsg;

            const newState = {
                loader: false,
                isAlert: true,
                alertMsg: errorMsg,
                alertType: errorType,
            };
            this.setState(newState);
        }
    };

    handleGetCountryListResponse = async (response: Array<CountryData>) => {
        this.setState({
            countryData: response,
            loader: false,
        });
    };

    handleDeleteStateResponse = (response: { error?: string }) => {
        const successMsg = configJSON.deleteSuccessMsg;
        const successType = configJSON.successMsg;
        const errorMsg = response.error || configJSON.deleteErrorMsg;
        const errorType = configJSON.errorMsg;

        if (response.error) {
            this.setState({
                loader: false,
                isAlert: true,
                alertMsg: errorMsg,
                alertType: errorType,
                openDeleteModal: false
            });
        } else {
            this.setState({
                loader: false,
                isAlert: true,
                alertMsg: successMsg,
                alertType: successType,
                openDeleteModal: false
            }, () => this.getStateListDataFunction(this.state.currentPageCount, this.state.perPage, this.state.columnSort));
        }
    };

    handleGetStatesListResponse = (response: StatesListDataPayloadType) => {
        const pageCount = Math.ceil(response.meta.total_states / this.state.perPage);
        this.setState({
            adminStateListData: response,
            loader: false,
            pageCount: pageCount
        });
    };

    onCloseAlert = () => {
        this.setState({ isAlert: false });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleCountrySelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ selectCountry: event.target.value as string });

    };

    handleCloseDeleteModal = () => {
        this.setState({
            openDeleteModal: false,
            stateId: ""
        });
    };

    handleOpenModal = (deleteId: number | string) => {
        this.setState({
            openDeleteModal: true,
            stateId: deleteId
        });
    };

    handleStateName = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;
        if (inputValue === '' || this.validateStateInput(inputValue)) {
            this.setState({
                stateName: inputValue,
                isAlert: false,
            });
        } else {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.validationErrorMsg,
                alertType: configJSON.errorMsg
            });
        }
    };

    validateStateInput(input: string) {
        const regex = /^(?!\s*$)(?!^[^a-zA-Z]*$)(?!^[\W_]*$).{1,30}$/;
        return regex.test(input);
    }

    handleRowColor(indexNum: number) {
        if (indexNum % 2 === 0) {
            return false;
        }
        return true;
    };

    onStatesPageChange = (event: ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ currentPageCount: newPageCount }, () => {
            this.getStateListDataFunction(newPageCount, this.state.perPage, this.state.columnSort);
        });
    };

    handleSort(field: string) {
        const sortParams: Record<string, string> = {
            id: "id_sort",
            name: "name_sort",
        };
        let fieldSort = "";
        const sortOrder = this.state.currentSortState.order === "asc" ? "desc" : "asc";

        if (sortParams.hasOwnProperty(field)) {
            fieldSort = `&${sortParams[field]}=${sortOrder}`;
        }
        this.toggleSortState(field, fieldSort);
        this.getStateListDataFunction(this.state.currentPageCount, this.state.perPage, fieldSort);
    }

    toggleSortState = (field: string, columnSort: string) => {
        this.setState({
            columnSort,
            currentSortState: {
                field,
                order: this.state.currentSortState.order === "asc" ? "desc" : "asc"
            }
        });
    };

    handlePostState = async () => {
        const { stateName, selectCountry } = this.state;
        const blankField = this.getBlankField(stateName, Number(selectCountry));

        if (blankField) {
            this.setState({
                isAlert: true,
                alertMsg: `${blankField}${configJSON.errorMsgBlank}`,
                alertType: configJSON.errorMsg
            });
        } else {
            let body = {
                name: this.state.stateName.trim(),
                profile_country_id: Number(this.state.selectCountry)
            }
            this.postAddStateApiCallId = this.adminStateListApiCall({
                contentType: configJSON.contenttypeApiValidateMobileNo,
                method: configJSON.callTypeApiValidateMobileNo,
                endPoint: configJSON.endPointApiPostState,
                body: body
            });
        };
    };

    handleUpdateState = async () => {
        const { stateName, selectCountry } = this.state;
        const blankField = this.getBlankField(stateName, Number(selectCountry));

        if (blankField) {
            this.setState({
                isAlert: true,
                alertMsg: `${blankField}${configJSON.errorMsgBlank}`,
                alertType: configJSON.errorMsg
            });
        } else {
            let body = {
                name: this.state.stateName.trim(),
                profile_country_id: Number(this.state.selectCountry)
            }
            const endPoint = `${configJSON.endPointApiGetState}${this.state.stateId}`;
            this.updateStateApiCallId = this.adminStateListApiCall({
                contentType: configJSON.contentTypeApiUpdateUser,
                method: configJSON.apiUpdateUserType,
                endPoint,
                body: body
            });
        };
    };

    getBlankField = (stateName: string, countryId: number) => {
        if (!countryId) return configJSON.counterNameText;
        if (!stateName) return configJSON.stateNameText;
        return null;
    };

    handleShowState = async (selectedStateId: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.endPointApiGetState}/${selectedStateId}`;
        this.showStateApiCallId = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    getCountryListDataFunction = async () => {
        this.setState({ loader: true });
        const endPoint = configJSON.endPointApiGetCountry;
        this.getCountrylistApiCallId = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    getStateListDataFunction = async (pageNo: number, perPage: number, sorting: string) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.endPointApiGetState}?page=${pageNo}&per_page=${perPage}${sorting || ""}`;
        this.getStatelistApiCallId = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    handleDeleteState = async () => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.deleteStateAPIEndPoint}/${this.state.stateId}`;
        this.deleteStateApiCallId = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.httpDeleteType,
            endPoint
        });
    };

    handleFieldReset = () => {
        this.setState({ selectCountry: "", stateName: "" });
    };

    navigationAddStateScreen = () => {
        const addStateScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAddAdminStateMessage)
        );
        addStateScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(addStateScreenMsg);
    };

    navigationStateListScreen = () => {
        const stateListScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAdminStateListMessage)
        );
        stateListScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(stateListScreenMsg);
    };

    navigationEditStateScreen = (selectedStateId: string | number) => {
        const editStateScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAddAdminStateMessage)
        );
        editStateScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        editStateScreenMsg.addData(getName(MessageEnum.NavigationIdMessage), { stateId: selectedStateId });
        this.send(editStateScreenMsg);
    };
    
    navigationLoginScreen = () => {
        alert(configJSON.sessionExpireMsg)
        const messageLogin: Message = new Message(
          getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messageLogin.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(messageLogin);
      };
    // Customizable Area End
}
import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Snackbar,
  FormControl
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import {
  TwoColBox,
  InputBox,
  StyledSelect,
  ProfilePicInput
} from "../../../components/src/GenericInputs";
import { FaChevronDown } from "react-icons/fa";
import {
  handleEditFileChange,
  handleDeleteFile
} from "./AdminCategoryController.web";
const drawerWidth = 250;
const closeDrawer = 0;
// Customizable Area End

import AdminSubCatgoryController, {
  Props,
  SubCategoryDataType,
  S
} from "./AdminSubCategoryController.web";

export default class AdminSubCatgory extends AdminSubCatgoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.setStateValues = this.setStateValues.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  setStateValues(value: unknown) {
    this.setState(value as Pick<S, keyof S>);
  }

  addSubNotificationAlert = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        data-test-id="alertTestId"
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.oncloseAlert}
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };

  renderSelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Main Category</Typography>
        <FormControl variant="outlined" fullWidth className="box__input">
          <StyledSelect
            id="demo-customized-select-native"
            disableUnderline
            IconComponent={FaChevronDown}
            onChange={e => this.handleSelectChange(e.target.value)}
            value={this.state.courseCategoryId}
            data-test-id="categorySelectTestId"
          >
            <option aria-label="None" value="" disabled />
            {this.state.courseCategoryData &&
              this.state.courseCategoryData.map(
                (category: SubCategoryDataType) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.attributes.name}
                    </option>
                  );
                }
              )}
          </StyledSelect>
          <span style={webStyles.errorText}>
            {this.state.courseCategoryError}
          </span>
        </FormControl>
      </Box>
    );
  };

  renderInputView = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            {this.renderSelect()}
            <Box className="child__box">
              <Typography className="box__label">Sub category</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  variant="outlined"
                  fullWidth
                  data-test-id="courseSubCategoryNameInput"
                  onChange={this.handleSubCategoryName}
                  value={this.state.courseSubCategoryName}
                />
                <span style={webStyles.errorText}>
                  {this.state.courseSubCategoryError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Web icon</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                {this.state.webIconPreviewThumbnail ? (
                  <ProfilePicInput
                    onChange={e =>
                      handleEditFileChange(e, "webIcon", this.setStateValues)
                    }
                    onRemove={() =>
                      handleDeleteFile("webIcon", this.setStateValues)
                    }
                    data-test-id="webIconId"
                    name="webIconImgUpload"
                    previewThumbnail={this.state.webIconPreviewThumbnail}
                  />
                ) : (
                  <Box className="file_upload_box">
                    <Typography className="app_icon_txt">
                      Upload square image icon
                    </Typography>
                    <input
                      data-test-id="webIconId"
                      multiple
                      type="file"
                      accept="image/*"
                      name="webIconImgUpload"
                      id="webIconInput"
                      style={{ display: "none" }}
                      onChange={e =>
                        handleEditFileChange(e, "webIcon", this.setStateValues)
                      }
                    />
                    <label htmlFor="webIconInput" className="lable_txt">
                      Browse
                    </label>
                  </Box>
                )}
                <span style={webStyles.errorText}>
                  {this.state.webIconError}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">App icon</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                {this.state.iconSubCatPreviewThumbnail ? (
                  <ProfilePicInput
                    onChange={e =>
                      handleEditFileChange(e, "iconSubCat", this.setStateValues)
                    }
                    onRemove={() =>
                      handleDeleteFile("iconSubCat", this.setStateValues)
                    }
                    data-test-id="subCatIconId"
                    name="subCatIconImgUpload"
                    previewThumbnail={this.state.iconSubCatPreviewThumbnail}
                  />
                ) : (
                  <Box className="file_upload_box">
                    <Typography className="app_icon_txt">
                      Upload square image icon
                    </Typography>
                    <input
                      data-test-id="subCatIconId"
                      multiple
                      type="file"
                      accept="image/*"
                      name="subCatIconImgUpload"
                      id="subCatIconInput"
                      style={{ display: "none" }}
                      onChange={e =>
                        handleEditFileChange(
                          e,
                          "iconSubCat",
                          this.setStateValues
                        )
                      }
                    />
                    <label htmlFor="subCatIconInput" className="lable_txt">
                      Browse
                    </label>
                  </Box>
                )}
                <span style={webStyles.errorText}>
                  {this.state.iconSubCatError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Status</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <AddCheckBtn className="switch">
                  <input
                    type="checkbox"
                    onChange={this.handleClickCheckBox}
                    checked={this.state.courseSubCategoryStatus}
                  />
                  <span className="slider round" />
                </AddCheckBtn>
              </FormControl>
            </Box>
          </TwoColBox>
          <BtnBox>
            {this.state.isUpdate ? (
              <Button
                variant="contained"
                className="reset_btn"
                onClick={this.handleSubCategoryNavigation}
              >
                Cancel
              </Button>
            ) : (
              <Button
                variant="contained"
                className="reset_btn"
                onClick={this.handleAddReset}
              >
                Reset
              </Button>
            )}
            {this.state.isUpdate ? (
              <Button
                onClick={this.handleEditSubCategory}
                variant="contained"
                className="create_btn"
                data-test-id="updateButtonTestId"
                disabled={this.state.loader}
              >
                Update
              </Button>
            ) : (
              <Button
                data-test-id="createButtonTestId"
                onClick={this.handlePostSubCategory}
                variant="contained"
                className="create_btn"
                disabled={this.state.loader}
              >
                Create
              </Button>
            )}
          </BtnBox>
        </Box>
      </div>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.loader}
        data-test-id="mainLayoutEvent"
        backData={this.handleSubCategoryNavigation}
        title={this.state.isUpdate ? "Edit Sub Category" : "Add Sub Category"}
        isEdit
        isAdd
      >
        {this.addSubNotificationAlert()}
        <main className="table_content" style={{ background: "white" }}>
          {this.renderInputView()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AddCheckBtn = styled("label")({
  "& input:checked + .slider:before": {
    left: "-10px"
  },
  "& .slider": {
    transition: ".4s",
    borderRadius: "20px",
    top: 0,
    position: "absolute",
    cursor: "pointer",
    bottom: "-1px",
    left: 0,
    right: 0,
    backgroundColor: "#ccc",
    WebkitTransition: ".4s"
  },
  "& .slider:before": {
    WebkitTransition: ".4s",
    position: "absolute",
    content: '""',
    width: "18px",
    left: "1px",
    backgroundColor: "white",
    transition: ".4s",
    borderRadius: "50%",
    height: "18px",
    bottom: "1px"
  },
  "&.switch": {
    marginTop: "5px",
    width: "36px",
    position: "relative",
    "& input": {
      opacity: 0,
      height: 0,
      width: 0
    },
    display: "inline-block",
    height: "19px"
  },
  "& input:checked + .slider": {
    backgroundColor: "#708328"
  }
});

const BtnBox = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  margin: "25px 0",
  "& .reset_btn": {
    borderRadius: "0",
    backgroundColor: "#fff",
    border: "1px solid #3F6474",
    textTransform: "capitalize",
    padding: "10px 60px",
    fontSize: "16px",
    color: "#3f6474",
    width: "195px",
    height: "52px",
    margin: "0px 10px",
    boxShadow: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  "& .create_btn": {
    borderRadius: "0",
    backgroundColor: "#3f6474",
    textTransform: "capitalize",
    padding: "10px 58px",
    fontSize: "16px",
    color: "#fff",
    margin: "0px 10px",
    boxShadow: "none",
    width: "195px",
    height: "52px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  "@media (max-width:460px)": {
    "& .reset_btn": {
      padding: "5px 50px",
      height: "40px"
    },
    "& .create_btn": {
      padding: "5px 48px",
      height: "40px"
    }
  },
  "@media (max-width:400px)": {
    flexDirection: "column",
    "& .reset_btn": {
      marginBottom: "10px",
      width: "100%"
    },
    "& .create_btn": {
      width: "100%"
    }
  }
});
// Customizable Area End

import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { initialValues } from "./CouponcodegeneratorController.web";
import moment from "moment";
import { removeStorageData } from "framework/src/Utilities";
import {
  apiCall,
  removeDuplicatesForSubmit,
} from "./VouchercodegeneratorController";
const fetch = require("node-fetch");
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface Response {
  data: { id: string; type: string; attributes: Attributes };
  meta: { message: string };
}

export interface Attributes {
  id: number;
  discount_code: string;
  discount_name: string;
  discount_type: string;
  discount: string;
  status: boolean;
  minimum_purchase: string;
  created_at: string;
  updated_at: string;
  expiry_date: string;
  applicable_purchase: string;
}

interface S {
  // Customizable Area Start
  isChecked: boolean[];
  isLoading: boolean;
  isHeaderChecked: boolean;
  userToken: any;
  pageNo: number;
  perPage: number;
  totalPages: number;
  openDeleteModal: boolean;
  deleteId: string | number;
  searchText: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isModalOpen: boolean;
  discountFormDataInitial?: FormValues;
  discountFormData: FormValues;
  isUpdate: boolean;
  discountTypeList: any[];
  discountsList: any[];
  discountDataId: any;
  currentSortState: { order: string; field: string };
  columnSort: string;
  // Customizable Area End
}

interface FormValues {
  discount_name: string;
  discount_code: string;
  discount_type: string;
  minimum_purchase: string;
  discount: string;
  expiry_date: string;
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FlatDiscountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDiscountsApiCallId: string = "";
  deleteDiscountAPICallId: string = "";
  addDiscountApiCallId: string = "";
  editDiscountApiCallId: string = "";
  showDiscountApiCallId: string = "";
  toggleDiscountStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
    ];

    this.state = {
      ...initialValues,
      discountFormData: {
        discount_name: "",
        discount_code: "",
        discount_type: "amount",
        discount: "",
        expiry_date: "",
        minimum_purchase: "",
      },
      discountFormDataInitial: undefined,
      discountsList: [],
      discountTypeList: [
        { id: "amount", name: "Amount" },
        { id: "percentage", name: "Percentage" },
      ],
      discountDataId: [],
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getDiscountsDataList();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleReceive(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleReceive(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getDiscountsApiCallId:
            this.handleGetDiscountsResponse(responseJson);
            break;
          case this.deleteDiscountAPICallId:
            this.deleteDiscountApiResponse(responseJson);
            break;
          case this.editDiscountApiCallId:
            this.handleAddDiscountApiResponse(responseJson);
            break;
          case this.addDiscountApiCallId:
            this.handleAddDiscountApiResponse(responseJson);
            break;
          case this.showDiscountApiCallId:
            this.handleGetDiscountListResponse(responseJson);
            break;
          case this.toggleDiscountStatusApiCallId:
            this.handleToggleOptionsResponse(responseJson);
            break;
        }
      } else {
        parseErrors(responseJson, this.parseApiErrorResponse, this.send, this.props);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let discountData = message.getData(
        getName(MessageEnum.NavigationIdMessage)
      );
      if (discountData) {
        this.handleShowDiscount(discountData.ScreenTest);
      }
    }
  }

  handleShowDiscount = (id: string | number) => {
    this.setState({ isLoading: true });
    this.showDiscountApiCallId = apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeCouponGet,
      endPoint: configJSON.discountGetApiEndpoint + id,
      token: this.state.userToken,
    });
  };

  handleGetDiscountListResponse(resJson: Response) {
    const { data } = resJson;
    const { attributes } = data;

    const values = {
      discount: attributes.discount,
      discount_type: attributes.discount_type,
      expiry_date: moment(attributes.expiry_date).format("YYYY-MM-DD"),
      minimum_purchase: attributes.minimum_purchase,
      discount_code: attributes.discount_code,
      discount_name: attributes.discount_name,
    };

    this.setState({
      discountDataId: data.id,
      isUpdate: true,
      discountFormData: values,
      discountFormDataInitial: values,
      isLoading: false,
    });
  }

      handleSort(field: string) {
    const sortParams: Record<string, string> = {
      "S.No": "id_sort",
      "Flat Discount": "name_sort",
      Status: "status_sort",
      "Expiry Date": "expiry_date_sort"
    };
    let fieldSort = "";
    const sortOrder = this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      fieldSort = `&${sortParams[field]}=${sortOrder}`;
    }
    this.toggleSortState(field, fieldSort);
    this.getDiscountsDataList(fieldSort);
  }

  toggleSortState = (field: string, columnSort: string) => {
    this.setState({
      columnSort,
      currentSortState: {
        field,
        order: this.state.currentSortState.order === "asc" ? "desc" : "asc"
      }
    });
  };


  oncloseAlert = () => {
    this.setState({
      isAlert: false,
    });
  };

  handleResetData() {
    this.setState({
      discountFormData: {
        discount: "",
        discount_type: "",
        expiry_date: "",
        minimum_purchase: "",
        discount_code: "",
        discount_name: "",
      },
    });
  }
  handleGetDiscountsResponse(resJson: any) {
    if (resJson.error === "Record not found.") {
      this.setState({
        isAlert: true,
        alertMsg: resJson.error,
        alertType: "info",
        isLoading: false,
        totalPages: 0,
        discountsList: [],
        isChecked: [],
      });
    } else if (
      resJson?.errors &&
      resJson.errors.length > 0 &&
      resJson.errors[0]?.token == configJSON.tokenExpiredMessage
    ) {
      this.discountNavigate();
    } else {
      let count = resJson.meta.total_flat_discount / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        discountsList: resJson.data,
        isChecked: Array(
          this.state.discountsList ? this.state.discountsList.length : 0
        ).fill(false),
        totalPages: count,
        isLoading: false,
      });
    }
  }

  handleAddDiscountApiResponse(responseJson: any) {
    if (responseJson && responseJson.error) {
      const object = Object.keys(responseJson.error as any[])[0];
      const objectMsg = Object.values(responseJson.error as any[])[0][0];
      const stateFinal: any =
        objectMsg === "has already been taken"
          ? { isModalOpen: true, isLoading: false }
          : {
              isAlert: true,
              alertMsg: object + " " + objectMsg || "Error during petition",
              alertType: "error",
              isLoading: false,
            };
      this.setState(stateFinal);
    } else {
      this.discountNavigate();
    }
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  handleGoBack() {
    this.discountNavigate();
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getDiscountsDataList(this.state.columnSort);
    });
  };
  handleOpenModal = (id: string) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id,
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: "",
    });
  };
  handleEditPropsDiscount = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddFlatDiscountMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };
  deleteDiscountApiResponse(responseJson: any) {
    this.setState({ openDeleteModal: false }, () =>
      this.getDiscountsDataList(this.state.columnSort)
    );
  }
  handleHeaderCheckboxChange = () => {
    this.setState((prevState) => ({
      isHeaderChecked: !prevState.isHeaderChecked,
      isChecked: Array(this.state.discountsList.length).fill(
        !prevState.isHeaderChecked
      ),
    }));
  };
  handleRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      return {
        isChecked,
        isHeaderChecked: isChecked.every((value) => value),
      };
    });
  };

  handleDeleteDiscount(id: any) {
    this.setState({ isLoading: true });
    this.deleteDiscountAPICallId = apiCall({
      method: configJSON.deleteCouponAPiMethod,
      endPoint: configJSON.discountsListApiEndpoint + `/${id}`,
      token: this.state.userToken,
    });
  }
  handleDiscountSearch(e: any) {
    let searchVal = e.target.value;
    this.setState({ searchText: searchVal, pageNo: 1 }, () => {
      this.getDiscountsDataList(this.state.columnSort);
    });
  }
  getDiscountsDataList(sorting?:string) {
    const searchParamsString = this.state.searchText
      ? `&search_params=${this.state.searchText}&${sorting}`
      : `${sorting}`;
    this.setState({ isLoading: true });
    this.getDiscountsApiCallId = apiCall({
      method: configJSON.apiMethodTypeCouponGet,
      endPoint:
        configJSON.discountsListApiEndpoint +
        `?page=${this.state.pageNo}&per_page=${this.state.perPage}${searchParamsString}`,
      token: this.state.userToken,
    });
  }

  handleCourseInputChange(e: any, isNumber = false) {
    let { name, value } = e.target;
    if (isNumber) {
      if (!/^(?=.{0,10}$)[0-9]*\.?[0-9]*$/.test(value)) return;
    }
    this.setState({
      discountFormData: {
        ...this.state.discountFormData,
        [name]: value,
      },
    });
  }
  handleAddDiscount() {
    this.setState({ isLoading: true });
    const {
      discount,
      discount_type,
      expiry_date,
      minimum_purchase,
      discount_code,
      discount_name,
    } = this.state.discountFormData;

    if (
      !discount ||
      !expiry_date ||
      !minimum_purchase ||
      !discount_code ||
      !discount_name
    ) {
      this.setState({
        isAlert: true,
        alertMsg: `Please fill in all fields`,
        alertType: "error",
        isLoading: false,
      });
      return;
    }

    const header = new fetch.Headers();
    header.append("token", this.state.userToken);

    const body = {
      data: {
        discount_name,
        discount_code,
        discount_type,
        discount,
        expiry_date,
        minimum_purchase,
      },
    };

    this.addDiscountApiCallId = apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.createCouponAPiMethod,
      endPoint: configJSON.discountsListApiEndpoint,
      body,
      token: this.state.userToken,
    });
  }

  handleEditDiscount() {
    this.setState({ isLoading: true });
    const { applicable_purchase, ...formValues }: any =
      this.state.discountFormData;
    const { discount, expiry_date, discount_code, discount_name } =
      this.state.discountFormData;
    const initialValues: any = this.state.discountFormDataInitial;

    if (
      !discount ||
      !discount_name ||
      !discount_code ||
      !expiry_date ||
      !initialValues
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Please fill in all fields",
        alertType: "error",
        isLoading: false,
      });
      return;
    }

    const body = removeDuplicatesForSubmit<FormValues>(
      formValues,
      initialValues
    );

    const header = new fetch.Headers();
    header.append("token", this.state.userToken);

    this.editDiscountApiCallId = apiCall({
      body,
      contentType: configJSON.validationApiContentType,
      method: configJSON.putCouponApiMethod,
      endPoint: configJSON.discountGetApiEndpoint + this.state.discountDataId,
      token: this.state.userToken,
    });
  }

  addDiscountNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddFlatDiscountMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  discountNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationFlatDiscountMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleToggleOptionsResponse = (responseJson: any) => {
    this.getDiscountsDataList(this.state.columnSort);
    this.setState({
      isAlert: true,
      alertMsg:
        responseJson?.meta?.message || "Discount status change successfully",
      alertType: "success",
      isLoading: false,
    });
  };

  handleToggleStatus = (id: string, status: boolean, index: number) => {
    let clone = [...this.state.discountsList];
    clone[index].attributes.status = !status;

    this.setState({
      discountsList: clone,
    });

    this.setState({ isLoading: true });
    this.toggleDiscountStatusApiCallId = apiCall({
      body: { data: { status: !status } },
      contentType: configJSON.validationApiContentType,
      method: configJSON.putCouponApiMethod,
      endPoint: configJSON.discountGetApiEndpoint + id,
      token: this.state.userToken,
    });
  };
  // Customizable Area End
}
// Customizable Area Start
export const parseErrors = async (responseJson: ResponseErrors, parseApiErrorResponse: any, send: (message: Message) => void, props: any) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      navigationLoginScreen(props, send);
    } else {
      parseApiErrorResponse(responseJson);
    }
  };

export const handleLogoutUser = async () => {
  await removeStorageData("emailOTPToken");
  await removeStorageData("loginEmail");
  await removeStorageData("loginToken");
  await removeStorageData("profilePic");
  await removeStorageData("profileUsername");
  await removeStorageData("userRole");
}

export const navigationLoginScreen = (props: any, send: (message: Message) => void) => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      props
    );
    handleLogoutUser();
    send(messageLogin);
  };
// Customizable Area End

import React from "react";
// Customizable Area Start
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { Box, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import CouponcodegeneratorControllerWeb, {
  Props as PropsWeb,
  DataApiResponse
} from "./CouponcodegeneratorController.web";
import {
  DeleteModal,
  ModalView,
  CancelButton,
  ModalButton,
  DeleteButton,
  DeleteText,
  PaginationBox,
  webStyles
} from "./Vouchercodegenerator.web";
import {
  CheckBtn,
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { GenerateColumnTitles } from "./FlatDiscount.web";
const drawerWidth = 250;
const closeDrawer = 0;

// Customizable Area End

export default class CouponCodegenerator extends CouponcodegeneratorControllerWeb {
  constructor(props: PropsWeb) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.onSort = this.onSort.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  onSort(field: string) {
    this.handleSort(field);
  }

  truncateString = (str: string) => {
    if (str.length > 20) {
      return str.slice(0, 20) + "...";
    } else {
      return str;
    }
  };

  tableBody = () => {
    return (
      <>
        {this.renderTableHead()}
        <span style={webStyles.lineDivide} />
        {this.renderTableBody()}
      </>
    );
  };

  renderTableBody = () => {
    return (
      <TableBody>
        {this.state.couponsList.map((item: any, index: any) =>
          this.renderTableBodyRows(item, index)
        )}
      </TableBody>
    );
  };

  checkboxHeader = () => {
    return (
      <th>
        <Box style={webStyles.checkboxMain}>
          <input
            type="checkbox"
            test-id="headerCheckbox"
            checked={this.state.isHeaderChecked}
            onChange={this.handleHeaderCheckboxChange}
            style={webStyles.input}
          />
        </Box>
      </th>
    );
  };

  renderTableHead = () => {
    return (
      <thead>
        <tr>
          {this.checkboxHeader()}
          <GenerateColumnTitles
            columns={[
              "S.No",
              "Coupons",
              "Coupon Code",
              "Discount Type",
              "Discount",
              "Associate Course",
              "Applicable purchase",
              "Expiry Date",
              "Status",
              "Action"
            ]}
            onSort={this.onSort}
            data-test-id="sorterTestId"
            currentSortState={this.state.currentSortState}
          />
        </tr>
      </thead>
    );
  };

  renderCouponsTable = () => {
    return (
      <Box>
        <TableStyle>{this.tableBody()}</TableStyle>
        {this.renderPagination()}
      </Box>
    );
  };

  renderPagination = () => {
    return (
      <PaginationBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Pagination
          data-test-id="pageChangebutton"
          count={this.state.totalPages}
          shape="rounded"
          boundaryCount={1}
          page={this.state.pageNo}
          siblingCount={0}
          onChange={this.handlePageChange}
        />
      </PaginationBox>
    );
  };

  renderTableBodyRows = (item: DataApiResponse, index: any) => {
    const hasCourses = !!(
      item.attributes.coupon_code_course &&
      item.attributes.coupon_code_course.length
    );
    const expiryDate = moment(item.attributes.expiry_date).format(
      "DD, MMM YYYY"
    );
    const coursesList = () => {
      if (!hasCourses) {
        return "";
      }
      let courses = item.attributes.coupon_code_course
        .map(val => {
          const course = this.state.associatedCourses.find(
            v => v.id == val.course_id
          );
          if (!course) {
            return "";
          }
          return course.name;
        })
        .join(", ");
      return this.truncateString(courses);
    };
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                data-test-id="rowCheckbox"
                type="checkbox"
                checked={this.state.isChecked[index]}
                onChange={() => this.handleRowCheckboxChange(index)}
                style={webStyles.input}
              />
            </Box>
          </td>
          <td>{item.id}</td>
          <td>{item.attributes.coupon_name}</td>
          <td>{item.attributes.coupon_code}</td>
          <td style={{ textTransform: "capitalize" }}>
            {item.attributes.discount_type}
          </td>
          <td>
            {item.attributes.discount_type === "amount" && "₹"}
            {item.attributes.discount}
            {item.attributes.discount_type !== "amount" && "%"}
          </td>
          <td>{coursesList()}</td>
          <td>{item.attributes["Applicable Purchase"]}</td>
          <td>{expiryDate}</td>
          <td>
            {
              <CheckBtn className="switch">
                <input
                  type="checkbox"
                  data-test-id="toggleStatus"
                  onClick={() =>
                    this.handleToggleStatus(
                      item.id,
                      item.attributes.status,
                      index
                    )
                  }
                  checked={item.attributes.status}
                />
                <span className="slider round" />
              </CheckBtn>
            }
          </td>
          <td style={{ borderRadius: "0px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleEditPropsCoupon(item.id)}
              />
              <FaRegTrashAlt
                data-test-id={"deleteIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={17}
                onClick={() => this.handleOpenModal(item.id)}
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivide} />
      </>
    );
  };

  renderDeleteCouponModal = () => {
    return (
      <>
        <DeleteModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="deleteModalTestId"
          open={this.state.openDeleteModal}
          onClose={this.handleCloseDeleteModal}
        >
          <ModalView>
            <DeleteText component={"p"}>
              Do you want to delete this coupon?
            </DeleteText>
            <ModalButton>
              <DeleteButton
                data-test-id="deleteBtnTestId"
                onClick={() => this.handleDeleteCoupon(this.state.deleteId)}
              >
                Delete
              </DeleteButton>
              <CancelButton
                data-test-id="cancelDeleteModal"
                onClick={() => this.handleCloseDeleteModal()}
              >
                Cancel
              </CancelButton>
            </ModalButton>
          </ModalView>
        </DeleteModal>
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleCouponsSearch(e)}
        isLoading={this.state.isLoading}
        title={"Coupon Code"}
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.addCouponNavigate(),
            text: "Add Coupon",
            testId: "addCouponTestId"
          }
        ]}
        isStudent={false}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        {this.renderDeleteCouponModal()}
        <main className="table_data">{this.renderCouponsTable()}</main>
      </MainLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End

import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  CardContent,
  Chip,
  styled,
  Divider,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  CourseData,
  CourseOrder,
} from '../../blocks/catalogue/src/MyCourseController.web';

const styles = {
    root: {
      height: 284,
      boxShadow: 'unset',
      borderRadius: 12,
    },
    media: {
      height: 140,
      borderRadius: 12,
    },
  },
  stylesLocal: Record<string, object> = {
    listContainer: {
      display: 'inline-flex',
      justifyContent: 'start'
    },
    media: {
      width: '30%'
    },
  };

interface CourseCardProps extends WithStyles<typeof styles> {
  item: CourseOrder;
  index: number;
  isList: boolean;
}

class CourseCard extends React.Component<CourseCardProps> {
  getRemainingDays(initialDate: Date, year: number): number {
    const today = new Date();

    // Add 1 year to the initial date
    const oneYearLater = new Date(initialDate);
    oneYearLater.setFullYear(initialDate.getFullYear() + year);

    // Calculate the difference in time (milliseconds)
    const timeDifference = oneYearLater.getTime() - today.getTime();

    // Convert milliseconds to days
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Ensure the remaining days is non-negative
    return remainingDays > 0 ? remainingDays : 0;
  }

  render() {
    const { classes, item, isList } = this.props;

    return (
      <Card className={classes.root} style={isList ? { height: 184 } : {}}>
        <CardActionArea
          style={{
            ...(isList ? { ...stylesLocal.listContainer } : {}),
            position: 'relative',
            borderRadius: 12,
          }}
        >
          <CardMedia
            className={classes.media}
            style={isList ? { width: '30%' } : {}}
            image={item.course_data.data.attributes.course_thumbnail}
            title="Thumbnail image"
          />
          <CustomChipImage
            style={isList ? { right: '80%' } : {}}
            label={`${this.getRemainingDays(
              new Date(item.place_order_date),
              item.no_of_year
            )} Days`}
          />
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              ...(isList ? { width: '100%' } : {}),
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ margin: 0, height: 64, fontWeight: 'bold' }}
            >
              {item.course_data.data.attributes.course_name}
            </Typography>
            <CustomProgress variant="determinate" value={0} />
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h4"
                component="h5"
                style={{ fontSize: 10, color: '#FF5900', fontWeight: 'bold' }}
              >
                {0} of {item.course_data.data.attributes.total_content} Chapters
              </Typography>
              <Divider orientation="vertical" flexItem />
              <div style={{ display: 'inline-flex', gap: 3 }}>
                {0 === 0 ? (
                  <CustomChip label="Start Course" />
                ) : (
                  <span style={{ color: '#3B7A89' }}>Write a review</span>
                )}
                <CustomChip label="Purchased" />
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

const CustomProgress = styled(LinearProgress)(() => ({
  borderRadius: 20,
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#EEEEEE'
  },
  '& > div': {
    backgroundColor: '#FE8155'
  },
  '&.MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#FE8155'
  },
}));

const CustomChip = styled(Chip)(() => ({
  fontSize: 10,
  background: '#FF5900',
  color: 'white',
  height: 20,
  fontWeight: 'bold',
  fontFamily: 'Inter',
  borderRadius: 4,
  '& .MuiChip-label': {
    paddingLeft: 6,
    paddingRight: 6,
  },
}));

const CustomChipImage = styled(Chip)(() => ({
  fontSize: 10,
  background: '#36AF0C',
  fontWeight: 'bold',
  color: 'white',
  height: 20,
  position: 'absolute',
  fontFamily: 'Inter',
  right: 10,
  top: 110,
  '& .MuiChip-label': {
    paddingLeft: 6,
    paddingRight: 6,
  },
}));

export default withStyles(styles)(CourseCard);

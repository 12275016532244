// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Wishlist";
exports.labelBodyText = "Wishlist Body";
exports.deleteAPiMethod = "DELETE"
exports.btnExampleTitle = "CLICK ME";
exports.urlGetWishlist = "bx_block_item/items";
exports.getWishlist = "bx_block_wishlist/wishlists";
exports.toggleWishlist = "bx_block_wishlist/toggle_wishlist"

exports.getAllWishlistApiEndpoint = "bx_block_wishlist/all_wishlist";
exports.addToChartApiEndpoint = "bx_block_shopping_cart/add_to_cart";
exports.wishlistApiEndpoint = "bx_block_wishlist/add_wishlist";
exports.removeWishlistApiEndpoint = "bx_block_wishlist/remove_course_from_wishlist";

exports.validity1Year = "1 Year Validity";
exports.validity2Year = "2 Year Validity";
exports.courseValidityTitle = "Choose your Course Validity";
// Customizable Area End

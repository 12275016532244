import React from "react";
// Customizable Area Start
import {
    Button,
    withStyles
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { Shape1, Shape2, Shape3, Shape4, Shape5, Shape, graduation, telegram, whatsup, insta, facebook, Group3, copy } from "./assets";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";


export class CourseSpecification extends CatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    copyToClipboard = () => {
        const value = window.location.href;

        navigator.clipboard.writeText(value)
            .then(() => alert('Copied to clipboard!'))
            .catch(err => console.error('Failed to copy:', err));
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        let value: any = window.location.href;
        return (
            //Merge Engine DefaultContainer
            <div style={{ maxWidth: '430px', backgroundColor: 'white', padding: '14px', borderRadius: '15px', boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <div style={{ position: 'relative' }}>
                        <img src={Group3} className={classes.courseImage} />
                    </div>
                    <div className={classes.priceContainer}>
                        <div>
                            <span style={{ color: 'black', fontWeight: 'bold', fontSize: '30px' }}>&#8377;</span> <span style={{ fontSize: '38px', color: 'lightgreen', fontWeight: 'bolder' }}>499.00/-</span>
                        </div>
                        <div>
                            <span style={{ color: '#BDBCBC' }}>&#8377;</span> <span style={{ fontSize: '20px', color: '#BDBCBC', fontWeight: 'bolder' }}>699.00/-</span>
                            <p style={{ background: '#F1F1F1', fontSize: '12px', padding: '5px 10px' }}>
                                COURSE VALIDITY: 01 YEARS
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonsContainer}>
                    <div>
                        <Button variant="contained" className={classes.buttoncontained}>BUY NOW </Button>
                    </div>
                    <div>
                        <Button variant="outlined" className={classes.buttonOutlined}><AddIcon style={{ marginRight: '10px', fontSize: '10px' }} />ADD TO CART</Button>
                    </div>
                </div>

                <div style={{ maxWidth: '400px', marginBottom: '20px auto' }}>
                    <div className={classes.listContainer}>
                        <p className={classes.listsHeading}>
                            <span style={{ marginRight: '8px' }}><img src={Shape} /></span>
                            Instructor</p>
                        <p>Vivek Kumar Sir</p>
                    </div>

                    <div className={classes.listContainer}>
                        <p className={classes.listsHeading}>
                            <span style={{ marginRight: '8px' }}><img src={Shape1} /></span>
                            Chapters</p>
                        <p>03 Chapters</p>
                    </div>

                    <div className={classes.listContainer}>
                        <p className={classes.listsHeading}>
                            <span style={{ marginRight: '8px' }}><img src={Shape2} /></span>
                            Lecture</p>
                        <p>12 Lectures</p>
                    </div>
                    <div className={classes.listContainer}>
                        <p className={classes.listsHeading}>
                            <span style={{ marginRight: '8px' }}><img src={Shape3} /></span>
                            Duration</p>
                        <p>2 Hours, 36 Minutes</p>
                    </div>
                    <div className={classes.listContainer}>
                        <p className={classes.listsHeading}>
                            <span style={{ marginRight: '8px' }}><img src={graduation} /></span>
                            Enrolled</p>
                        <p>2000 Students</p>
                    </div>
                    <div className={classes.listContainer}>
                        <p className={classes.listsHeading}>
                            <span style={{ marginRight: '8px' }}><img src={Shape4} /></span>
                            Languages</p>
                        <p>English, Odia</p>
                    </div>
                    <div className={classes.listContainer}>
                        <p className={classes.listsHeading}>
                            <span style={{ marginRight: '8px' }}><img src={Shape5} /></span>
                            Share on</p>
                        <p>
                            <img src={facebook} style={{ marginRight: '8px' }} />
                            <img src={insta} style={{ marginRight: '8px' }} />
                            <img src={whatsup} style={{ marginRight: '8px' }} />
                            <img src={telegram} />
                        </p>
                    </div>
                </div>

                <div>
                    <div style={{ position: 'relative', marginTop: '15px' }}>
                        <input type="email" className={classes.subscribeInput} placeholder={value} />
                        <button className={classes.subscribeButton} onClick={this.copyToClipboard}>
                            <img src={copy} style={{ marginRight: '10px' }} />
                            Copy</button>
                    </div>
                </div>
            </div>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
    buttoncontained: {
        width: '395px',
        background: "linear-gradient(133deg, #FF5C01 -26.6%, #FFA302 118.85%)",
        color: 'white',
        borderRadius: '30px',
        fontSize: '24px',
        marginBottom: '19px'
    },

    buttonOutlined: {
        width: '395px',
        color: '#FD6F01',
        fontSize: '24px',
        border: '2px solid #FD6F01',
        borderRadius: '30px'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #BEBEBE'
    },
    courseImage: {
        width: '413px',
        height: '242px',
        borderRadius: '15px'
    },
    listsHeading: {
        fontSize: '21px',
        fontWeight: 'bold'
    },
    priceContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: '10px',
    },
    subscribeInput: {
        width: '415px',
        height: '58px',
        flexShrink: '0',
        borderRadius: '16px',
        background: "#EEE",
        boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.09)',
        outline: 'none',
        border: 'none',
        "& ::placeholder": {
            color: '#818181',
            fontSize: '15.633px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            letterSpacing: '-0.019px',
            paddingLeft: '5px'
        }
    },
    subscribeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        border: 'none',
        width: '132px',
        height: '38px',
        flexShrink: '0',
        borderRadius: '7px',
        background: "linear-gradient(133deg, #3CC9E1 -26.6%, #03859B 118.85%) ",
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center'
    },
});


export default withStyles(styles)((CourseSpecification));
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

export interface AdminCategoryResponseType {
  data: Array<CategoryDataType>;
  meta: {
    page: string;
    total_course_categories: number;
    total_categories: number;
    course_categories: string;
  };
  errors: { token: string }[];
  error: string;
}

export interface CategoryDataType {
  id: string;
  type: string;
  attributes: {
    name: string;
    status: boolean;
    total_courses: number;
    icon_cat: string;
    web_icon: string;
    class_content: string;
    student_count: number;
  };
}

export interface InvalidResponseType {
  errors: Array<string>;
}

const categoryRegex =
  /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,30}$/;

export const makeApiCall = (data: APIPayloadType) => {
  let { contentType, method, endPoint, body, type, token } = data;
  const header = {
    "Content-Type": contentType,
    token: token
  };
  let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      !type ? JSON.stringify(body) : body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

export const handleEditFileChange = (
  event: any,
  fieldName: string,
  setState: (values: unknown) => void
) => {
  const files = event.target?.files;

  if (files && files.length > 0) {
    const file = files[0];
    const fileName = file.name;
    const fileSize = file.size;

    const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFormats.includes(file.type)) {
      setState({
        [`${fieldName}Error`]:
          "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
        [fieldName]: null,
        [`${fieldName}Name`]: "",
        [`${fieldName}PreviewThumbnail`]: ""
      });
    } else if (fileSize > configJSON.maxFileSize) {
      setState({
        [`${fieldName}Error`]:
          "File size exceeds 2MB. Please choose a smaller file.",
        [fieldName]: null,
        [`${fieldName}Name`]: "",
        [`${fieldName}PreviewThumbnail`]: ""
      });
    } else {
      const imageUrl = URL.createObjectURL(file);
      setState({
        [fieldName]: file,
        [`${fieldName}Name`]: fileName,
        [`${fieldName}Error`]: "",
        [`${fieldName}PreviewThumbnail`]: imageUrl
      });
    }
  } else {
    setState({
      [fieldName]: null,
      [`${fieldName}Name`]: "",
      [`${fieldName}Error`]: "No file is selected",
      [`${fieldName}PreviewThumbnail`]: ""
    });
  }
};

export const handleDeleteFile = (
  fieldName: string,
  setState: (value: unknown) => void
) => {
  setState({
    [fieldName]: null,
    [`${fieldName}Name`]: "",
    [`${fieldName}Error`]: "",
    [`${fieldName}PreviewThumbnail`]: ""
  });
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  adminCategoryData: AdminCategoryResponseType;
  isDrawer: boolean;
  setfullWidth: boolean;
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  loader: boolean;
  categoryName: string;
  categoryNameError: string;
  iconCat: any;
  webIcon: any;
  webIconName: string;
  iconCatName: string;
  webIconError: string;
  iconCatError: string;
  webIconPreviewThumbnail: string;
  iconCatPreviewThumbnail: string;
  courseStatus: any;
  openDeleteModal: boolean;
  deleteId: string | number;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isUpdate: boolean;
  categoryId: string | number;
  searchedCatText: string;
  perPage: number;
  isHeaderChecked: boolean;
  isChecked: boolean[];
  currentSortState: { order: string; field: string };
  columnSort: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminCategoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminCategoryListApiCallId: string = "";
  postAddCategoryApiCallId: string = "";
  deleteCategoryApiCallId: string = "";
  editCategoryApiCallId: string = "";
  showCategoryApiCallId: string = "";
  toggleStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      adminCategoryData: {
        data: [],
        meta: {
          page: "",
          total_course_categories: 0,
          total_categories: 0,
          course_categories: ""
        },
        errors: [{ token: "" }],
        error: ""
      },
      isDrawer: true,
      setfullWidth: true,
      currentPageCount: 1,
      pageCount: 0,
      userToken: "",
      loader: true,
      categoryName: "",
      categoryNameError: "",
      iconCat: "",
      webIcon: "",
      courseStatus: true,
      openDeleteModal: false,
      deleteId: "",
      iconCatName: "",
      webIconName: "",
      webIconError: "",
      iconCatError: "",
      webIconPreviewThumbnail: "",
      iconCatPreviewThumbnail: "",
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      isUpdate: false,
      categoryId: "",
      searchedCatText: "",
      perPage: elementsPerPage,
      isHeaderChecked: false,
      isChecked: [],
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (this.isValidResponse(responseJson)) {
        if(!responseJson.errors) {
          this.apiSuccessCallBacks(apiRequestCallId, responseJson);
        } else {
          this.parseErrors(responseJson)
        }
      }
      // Customizable Area End
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (data) {
        this.handleShowCategory(data.ScreenTest);
      }
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    await this.handleFilterCategory(
      1,
      this.state.perPage,
      this.state.searchedCatText,
      this.state.columnSort
    );
  }
  parseErrors = async (responseJson: {
    errors: {
      token: string;
    }[];
  }) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: AdminCategoryResponseType
  ) => {
    if (apiRequestCallId === this.getAdminCategoryListApiCallId) {
      this.handleGetAdminCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.postAddCategoryApiCallId) {
      this.handleAddAdminCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.deleteCategoryApiCallId) {
      this.handleDeleteAdminCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.editCategoryApiCallId) {
      this.handleEditAdminCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.showCategoryApiCallId) {
      this.handleShowAdminCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.toggleStatusApiCallId) {
      this.handleToggleStatusResponse(responseJson);
    }
  };

  handleGetAdminCategoryListResponse = async (
    response: AdminCategoryResponseType
  ) => {
    const { meta } = response;
    if (meta?.course_categories === configJSON.catRecordNotFoundMessage) {
      this.setState({
        isAlert: true,
        alertMsg: meta.course_categories,
        alertType: "info",
        loader: false,
        pageCount: 0,
        adminCategoryData: {
          data: [],
          meta: {
            page: "",
            total_course_categories: 0,
            total_categories: 0,
            course_categories: ""
          },
          errors: [{ token: "" }],
          error: ""
        }
      });
    } else if (response && response?.errors) {
      if (response?.errors[0]?.token == configJSON.tokenExpiredMessage) {
        this.navigationLoginScreen();
      }
    } else {
      this.setState({
        adminCategoryData: response,
        pageCount: Math.ceil(
          (meta.total_course_categories || meta.total_categories) /
            this.state.perPage
        ),
        loader: false
      });
    }
  };

  handleDeleteAdminCategoryListResponse = (
    response: AdminCategoryResponseType
  ) => {
    if (response.error) {
      this.setState({
        openDeleteModal: false,
        deleteId: "",
        loader: false,
        isAlert: true,
        alertMsg: response.error,
        alertType: "error"
      });
    } else {
      this.setState(
        {
          openDeleteModal: false,
          deleteId: "",
          loader: false,
          isAlert: true,
          alertMsg: "Main Category Deleted Successfully",
          alertType: "success"
        },
        () => {
          this.handleFilterCategory(
            1,
            this.state.perPage,
            this.state.searchedCatText,
            this.state.columnSort
          );
        }
      );
    }
  };

  handleToggleStatusResponse = (responseJson: any) => {
    if (
      responseJson?.meta?.message ==
      "Course Category status changed successfully"
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Course Main Category status changed successfully",
        alertType: "success"
      });
      this.handleFilterCategory(
        this.state.currentPageCount,
        this.state.perPage,
        this.state.searchedCatText,
        this.state.columnSort
      );
    }
  };

  handleEditAdminCategoryListResponse = (responseJson: any) => {
    const { data } = responseJson;
    let alertMsg, alertType: any;
    if (data.icon_cat || data.web_icon) {
      alertMsg = data.icon_cat ? data.icon_cat[0] : data.web_icon[0];
      alertType = "error";
    } else {
      alertMsg = "Main Category Edited Successfully";
      alertType = "success";
      this.handleAddReset();
      this.AdminCategoryNavigationCall();
    }
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
  };

  handleShowAdminCategoryListResponse = (response: any) => {
    const { attributes } = response.data;
    this.setState({
      iconCatName: attributes.icon_cat,
      webIconName: attributes.web_icon,
      categoryName: attributes.name,
      courseStatus: attributes.status,
      isUpdate: true,
      categoryId: response.data.id,
      iconCatPreviewThumbnail: attributes.icon_cat,
      webIconPreviewThumbnail: attributes.web_icon
    });
  };

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  handleToggleStatus(id: string, status: boolean, index: number) {
    if (
      !this.state.adminCategoryData.data ||
      !this.state.adminCategoryData.data.length
    ) {
      return;
    }
    let clone = [...this.state.adminCategoryData.data];
    clone[index].attributes.status = !status;

    this.setState((prevState) => ({
      adminCategoryData: { ...prevState.adminCategoryData, data: clone }
    }));

    this.toggleStatusApiCallId = makeApiCall({
      method: configJSON.httpPutType,
      endPoint: `${configJSON.categoryStatusUpdateApiEndpoint}/${id}`,
      body: { change_status: !status },
      contentType: configJSON.categoryApiContentType,
      token: this.state.userToken
    });
  }

  toggleDrawer = () => {
    this.setState({
      isDrawer: !this.state.isDrawer,
      setfullWidth: !this.state.setfullWidth
    });
  };

  onPageChange = (event: any, value: any) => {
    this.setState(
      {
        currentPageCount: Number(value)
      },
      () => {
        this.handleFilterCategory(
          this.state.currentPageCount,
          this.state.perPage,
          this.state.searchedCatText,
          this.state.columnSort
        );
      }
    );
  };

  handleAddReset = () => {
    this.setState({
      categoryName: "",
      iconCat: "",
      webIcon: "",
      iconCatName: "",
      webIconName: "",
      courseStatus: true,
      iconCatError: "",
      categoryNameError: "",
      webIconError: "",
      iconCatPreviewThumbnail: "",
      webIconPreviewThumbnail: ""
    });
  };

  AdminCategoryNavigationCall = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAdminCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  checkCategoryFormValidations(updatedValues: any): boolean {
    const validationFields = [
      {
        field: "categoryName",
        errorKey: "categoryNameError",
        errorMessage: "Main Category Name is Required",
        regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,30}$/
      },
      {
        field: "iconCatName",
        errorKey: "iconCatError",
        errorMessage: "App Icon is Required"
      },
      {
        field: "webIconName",
        errorKey: "webIconError",
        errorMessage: "Web Icon is Required"
      }
    ];

    let hasError = false;

    validationFields.forEach(({ field, errorKey, errorMessage, regex }) => {
      if (
        !updatedValues[field] ||
        updatedValues[field]?.length === 0 ||
        (regex && !regex.test(updatedValues[field].trim()))
      ) {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: errorMessage
        }));
        hasError = true;
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: ""
        }));
      }
    });

    return hasError;
  }

  handlePostCategory = async () => {
    this.setState({ loader: true });
    const {
      categoryName,
      iconCat,
      webIcon,
      iconCatName,
      webIconName,
      courseStatus
    } = this.state;
    const formValues = { categoryName, iconCatName, webIconName };
    if (!this.checkCategoryFormValidations(formValues)) {
      const formdata = new FormData();
      formdata.append("course_category[name]", categoryName);
      formdata.append("course_category[icon_cat]", iconCat, iconCatName);
      formdata.append("course_category[web_icon]", webIcon, webIconName);
      formdata.append("course_category[status]", courseStatus);
      this.postAddCategoryApiCallId = makeApiCall({
        method: configJSON.httpPostType,
        endPoint: configJSON.categoryAPIEndPoint,
        body: formdata,
        type: "formdata",
        token: this.state.userToken
      });
    } else {
      this.setState({ loader: true });
    }
  };

  handleAddAdminCategoryListResponse = (response: any) => {
    const { data } = response;
    let alertMsg, alertType: any;
    if (data.icon_cat || data.web_icon) {
      alertMsg = data.icon_cat ? data.icon_cat[0] : data.web_icon[0];
      alertType = "error";
    } else {
      alertMsg = "Main Category Created Successfully";
      alertType = "success";
      this.handleAddReset();
      this.AdminCategoryNavigationCall();
    }
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
  };

  handleCategoryName = (event: { target: { value: string } }) => {
    const value = event.target.value.trimStart();

    if (!categoryRegex.test(value)) {
      this.setState({
        categoryName: value,
        categoryNameError: "Please enter a valid Main Category name"
      });
    } else {
      this.setState({
        categoryName: value,
        categoryNameError: ""
      });
    }
  };

  handleClickCheckbox = (event: { target: { checked: boolean } }) => {
    this.setState({
      courseStatus: event.target.checked
    });
  };

  handleDeleteCategory = async (id: string | number) => {
    this.setState({ loader: true });
    this.deleteCategoryApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpDeleteType,
      endPoint: `${configJSON.categoryAPIEndPoint}/${id}`,
      token: this.state.userToken
    });
  };

  handleEditCategory = async () => {
    this.setState({ loader: true });
    const {
      categoryName,
      iconCat,
      webIcon,
      iconCatName,
      webIconName,
      categoryId,
      courseStatus
    } = this.state;
    const formValues = {
      categoryName,
      webIconName,
      iconCatName
    };
    if (!this.checkCategoryFormValidations(formValues)) {
      const formdata = new FormData();
      formdata.append("course_category[name]", categoryName);
      formdata.append("course_category[status]", courseStatus);
      if (iconCat) {
        formdata.append("course_category[icon_cat]", iconCat, iconCatName);
      }
      if (webIcon) {
        formdata.append("course_category[web_icon]", webIcon, webIconName);
      }
      this.editCategoryApiCallId = makeApiCall({
        method: configJSON.httpPutType,
        endPoint: `${configJSON.categoryAPIEndPoint}/${categoryId}`,
        body: formdata,
        type: "formdata",
        token: this.state.userToken
      });
    } else {
      this.setState({ loader: false });
    }
  };

  handleShowCategory = async (id: string | number) => {
    this.showCategoryApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.categoryAPIEndPoint}/${id}`,
      token: this.state.userToken
    });
  };

  handleFilterCategory = async (
    pageNo: number,
    per_page: number,
    searchedCatString: string,
    sort: string
  ) => {
    this.setState({ loader: true });
    this.getAdminCategoryListApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${
        configJSON.categoryAPIEndPoint
      }/search?page=${pageNo}&per_page=${per_page}&category_search_params=${searchedCatString}&${
        sort || ""
      }`,
      token: this.state.userToken
    });
  };

  handleCategorySearch = (e: { target: { value: string } }) => {
    const searchedSubCatVal = e.target.value;
    this.setState(
      { searchedCatText: searchedSubCatVal, currentPageCount: 1 },
      () => {
        this.handleFilterCategory(
          this.state.currentPageCount,
          this.state.perPage,
          searchedSubCatVal,
          this.state.columnSort
        );
      }
    );
  };

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: ""
    });
  };

  handleOpenModal = (id: string) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id
    });
  };

  handleHeaderCheckboxChange = () => {
    this.setState((prevState) => {
      const isHeaderChecked = !prevState.isHeaderChecked;
      const isChecked = Array(this.state.adminCategoryData.data.length).fill(
        isHeaderChecked
      );

      return {
        isHeaderChecked,
        isChecked
      };
    });
  };

  handleRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];

      const isHeaderChecked = isChecked.every((value) => value);

      return {
        isChecked,
        isHeaderChecked
      };
    });
  };

  handleSorting(field: string) {
    const sortParams: Record<string, string> = {
      "S.No": "id_sort",
      "Main Category Name": "category_name_sort",
      Status: "status_sort"
    };
    let columnSort = "";
    const sortOrder =
      this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      columnSort = `${sortParams[field]}=${sortOrder}`;
    }
    this.setState({
      currentSortState: { order: sortOrder, field: field },
      columnSort
    });
    this.handleCategorySort(columnSort);
  }

  handleCategorySort = async (fieldSort: string) => {
    this.setState({ loader: true });
    const endPoint = `${configJSON.categoryAPIEndPoint}/search?page=${this.state.currentPageCount}&per_page=${this.state.perPage}&${fieldSort}`;
    this.getAdminCategoryListApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint,
      token: this.state.userToken
    });
  };

  handleAddCategory = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleEditPropsCategory = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}

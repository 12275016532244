import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { makeApiCall } from "./AdminCategoryController.web";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

const subCategoryRegex =
  /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,30}$/;

interface ValidationFieldsType {
  field: string;
  errorKey: string;
  errorMessage: string;
  regex?: RegExp;
}

type GenericObject = {
  [key: string]: string;
};

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

export interface AdminSubCategoryResponseType {
  errors: { token: string }[];
  data: Array<SubCategoryDataType>;
  meta: {
    page: string;
    total_course_sub_categories: number;
    course_sub_categories: string;
  };
  error: string;
}

export interface SubCategoryDataType {
  id: string;
  type: string;
  attributes: {
    name: string;
    status: boolean;
    total_courses: number;
    icon_cat: string;
    web_icon: string;
    class_content: string;
    course_category: {
      name: string;
    };
    student_count: number;
    mobile_icon: string;
  };
}

export interface InvalidResponseType {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  adminSubCategoryData: AdminSubCategoryResponseType;
  isDrawer: boolean;
  setfullWidth: boolean;
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  loader: boolean;
  categoryName: string;
  iconSubCat: any;
  webIcon: any;
  openDeleteSubCategoryModal: boolean;
  deleteSubCatId: string | number;
  iconSubCatName: string;
  webIconName: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isUpdate: boolean;
  categoryId: string | number;
  courseCategoryId: string;
  courseCategoryError: string;
  courseSubCategoryName: string;
  courseSubCategoryError: string;
  courseSubCategoryStatus: any;
  perPage: number;
  courseCategoryData: any;
  searchedSubCatText: string;
  isHeaderChecked: boolean;
  isChecked: boolean[];
  currentSortState: { order: string; field: string };
  webIconError: string;
  iconSubCatError: string;
  webIconPreviewThumbnail: string;
  iconSubCatPreviewThumbnail: string;
  columnSort: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminSubCategoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminSubCategoryListApiCallId: string = "";
  postAddSubCategoryApiCallId: string = "";
  deleteSubCategoryApiCallId: string = "";
  editSubCategoryApiCallId: string = "";
  showSubCategoryApiCallId: string = "";
  getAdminCourseCategoryListApiCallId: string = "";
  toggleStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      adminSubCategoryData: {
        data: [],
        meta: {
          page: "",
          total_course_sub_categories: 0,
          course_sub_categories: ""
        },
        errors: [{ token: "" }],
        error: ""
      },
      isDrawer: true,
      setfullWidth: true,
      currentPageCount: 1,
      pageCount: 0,
      userToken: "",
      loader: true,
      categoryName: "",
      iconSubCat: "",
      webIcon: "",
      openDeleteSubCategoryModal: false,
      deleteSubCatId: "",
      iconSubCatName: "",
      webIconName: "",
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      isUpdate: false,
      categoryId: "",
      courseCategoryId: "",
      courseCategoryError: "",
      courseSubCategoryName: "",
      courseSubCategoryError: "",
      courseSubCategoryStatus: true,
      perPage: elementsPerPage,
      courseCategoryData: [],
      searchedSubCatText: "",
      webIconError: "",
      iconSubCatError: "",
      webIconPreviewThumbnail: "",
      iconSubCatPreviewThumbnail: "",
      isHeaderChecked: false,
      isChecked: [],
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isSubValidResponse(responseJson)) {
          this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let SubData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (SubData) {
        this.handleShowSubCategory(SubData.ScreenTest);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    await this.handleSubCategorySort(
      this.state.currentPageCount,
      this.state.perPage,
      this.state.searchedSubCatText,
      this.state.columnSort
    );
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getCourseCategoryDataFunction();
  };
  parseErrors = async (responseJson: {
    errors: {
      token: string;
    }[];
  }) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  isSubValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: AdminSubCategoryResponseType
  ) => {
    if (apiRequestCallId === this.getAdminSubCategoryListApiCallId) {
      this.handleGetAdminSubCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.postAddSubCategoryApiCallId) {
      this.handleAddAdminSubCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.deleteSubCategoryApiCallId) {
      this.handleDeleteAdminSubCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.editSubCategoryApiCallId) {
      this.handleEditAdminSuCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.showSubCategoryApiCallId) {
      this.handleShowAdminSubCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.getAdminCourseCategoryListApiCallId) {
      this.handleGetAdminCourseCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.toggleStatusApiCallId) {
      this.handleToggleStatusResponse(responseJson);
    }
  };

  handleToggleStatusResponse = (responseJson: any) => {
    if (
      responseJson?.meta?.message ==
      "Course Sub Category status changed successfully"
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Course Sub Category status changed successfully",
        alertType: "success"
      });
      this.handleSubCategorySort(
        this.state.currentPageCount,
        this.state.perPage,
        this.state.searchedSubCatText,
        this.state.columnSort
      );
    }
  };

  handleToggleStatus(id: string, status: boolean, index: number) {
    if (
      !this.state.adminSubCategoryData.data ||
      !this.state.adminSubCategoryData.data.length
    ) {
      return;
    }
    let clone = [...this.state.adminSubCategoryData.data];
    clone[index].attributes.status = !status;

    this.setState((prevState) => ({
      adminSubCategoryData: {
        ...prevState.adminSubCategoryData,
        data: clone
      }
    }));

    this.toggleStatusApiCallId = makeApiCall({
      method: configJSON.httpPutType,
      endPoint: `${configJSON.subCategoryStatusUpdateApiEndpoint}/${id}`,
      body: { change_status: !status },
      contentType: configJSON.categoryApiContentType,
      token: this.state.userToken
    });
  }

  handleAddAdminSubCategoryListResponse = (response: any) => {
    const { data } = response;
    let alertMsg, alertType: any;
    if (data.mobile_icon || data.web_icon) {
      alertMsg = data.mobile_icon ? data.mobile_icon[0] : data.web_icon[0];
      alertType = "error";
    } else {
      alertMsg = "Sub Category Created Successfully";
      alertType = "success";
      this.handleAddReset();
      this.handleSubCategoryNavigation();
    }
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
  };

  handleGetAdminSubCategoryListResponse = (
    response: AdminSubCategoryResponseType
  ) => {
    const { meta } = response;
    if (meta?.course_sub_categories === configJSON.catRecordNotFoundMessage) {
      this.setState({
        isAlert: true,
        alertMsg: configJSON.catRecordNotFoundMessage,
        alertType: "info",
        loader: false,
        adminSubCategoryData: {
          data: [],
          meta: {
            page: "",
            total_course_sub_categories: 0,
            course_sub_categories: ""
          },
          errors: [{ token: "" }],
          error: ""
        },
        pageCount: 0
      });
    } else if (response && response?.errors) {
       if (Object.keys(response.errors[0])[0] === "token") {
        this.navigationLoginScreen();
      }
    } else {
      const pageCount = Math.ceil(
        meta.total_course_sub_categories / this.state.perPage
      );
      this.setState({
        adminSubCategoryData: response,
        loader: false,
        pageCount: pageCount
      });
    }
  };

  handleDeleteAdminSubCategoryListResponse = (
    response: AdminSubCategoryResponseType
  ) => {
    if (response.error) {
      this.setState({
        openDeleteSubCategoryModal: false,
        deleteSubCatId: "",
        loader: false,
        isAlert: true,
        alertMsg: response.error,
        alertType: "error"
      });
    } else {
      this.setState(
        {
          openDeleteSubCategoryModal: false,
          deleteSubCatId: "",
          loader: false,
          isAlert: true,
          alertMsg: "Sub Category Deleted Successfully",
          alertType: "success"
        },
        () =>
          this.handleSubCategorySort(
            this.state.currentPageCount,
            this.state.perPage,
            this.state.searchedSubCatText,
            this.state.columnSort
          )
      );
    }
  };

  handleGetAdminCourseCategoryListResponse = (
    response: AdminSubCategoryResponseType
  ) => {
    if(!response.errors) {
      this.setState({
        courseCategoryData: response.data
      });
    } else {
      this.parseErrors(response)
    }
    
  };

  handleEditAdminSuCategoryListResponse = (responseJson: any) => {
    const { data } = responseJson;
    let alertMsg, alertType: any;
    if (data.mobile_icon || data.web_icon) {
      alertMsg = data.mobile_icon ? data.mobile_icon[0] : data.web_icon[0];
      alertType = "error";
    } else {
      alertMsg = "Sub Category Edited Successfully";
      alertType = "success";
      this.handleAddReset();
      this.handleSubCategoryNavigation();
    }
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
  };

  handleShowAdminSubCategoryListResponse = (response: any) => {
    const { attributes } = response.data;
    this.setState({
      iconSubCatName: attributes.mobile_icon,
      webIconName: attributes.web_icon,
      courseSubCategoryName: attributes.name,
      courseSubCategoryStatus: attributes.status,
      isUpdate: true,
      categoryId: response.data.id,
      courseCategoryId: attributes.course_category_id,
      webIconPreviewThumbnail: attributes.web_icon,
      iconSubCatPreviewThumbnail: attributes.mobile_icon
    });
  };

  handleShowSubCategory = async (id: string | number) => {
    this.showSubCategoryApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.subCategoryAPIEndPoint}/${id}`,
      token: this.state.userToken
    });
  };

  getCourseCategoryDataFunction = async () => {
    this.setState({ loader: true });
    this.getAdminCourseCategoryListApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.courseCategoryAPIListEndPoint,
      token: this.state.userToken
    });
  };

  checkSubCategoryFormValidations(updatedValues: GenericObject): boolean {
    const validationFields: ValidationFieldsType[] = [
      {
        field: "courseSubCategoryName",
        errorKey: "courseSubCategoryError",
        errorMessage: "Sub Category is Required",
        regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,30}$/
      },
      {
        field: "courseCategoryId",
        errorKey: "courseCategoryError",
        errorMessage: "Main Category Name is Required"
      },
      {
        field: "iconSubCatName",
        errorKey: "iconSubCatError",
        errorMessage: "App Icon is Required"
      },
      {
        field: "webIconName",
        errorKey: "webIconError",
        errorMessage: "Web Icon is Required"
      }
    ];

    return this.checkValidations(validationFields, updatedValues);
  }

  checkValidations(
    validationFields: ValidationFieldsType[],
    updatedValues: GenericObject
  ) {
    let hasError = false;

    validationFields.forEach(({ field, errorKey, errorMessage, regex }) => {
      if (
        !updatedValues[field] ||
        (updatedValues[field] && updatedValues[field].length === 0) ||
        (regex && !regex.test(updatedValues[field].trim()))
      ) {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: errorMessage
        }));
        hasError = true;
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: ""
        }));
      }
    });

    return hasError;
  }

  handlePostSubCategory = async () => {
    this.setState({ loader: true });
    const {
      webIcon,
      iconSubCat,
      courseCategoryId,
      iconSubCatName,
      courseSubCategoryName,
      courseSubCategoryStatus,
      webIconName
    } = this.state;
    const formValues = {
      courseSubCategoryName,
      courseCategoryId,
      webIconName,
      iconSubCatName
    };

    if (!this.checkSubCategoryFormValidations(formValues)) {
      const formdata = new FormData();
      formdata.append(
        "course_sub_category[course_category_id]",
        courseCategoryId
      );
      formdata.append("course_sub_category[name]", courseSubCategoryName);
      formdata.append("course_sub_category[status]", courseSubCategoryStatus);
      formdata.append(
        "course_sub_category[mobile_icon]",
        iconSubCat,
        iconSubCatName
      );
      formdata.append("course_sub_category[web_icon]", webIcon, webIconName);
      this.postAddSubCategoryApiCallId = makeApiCall({
        method: configJSON.httpPostType,
        endPoint: configJSON.subCategoryAPIEndPoint,
        body: formdata,
        type: "formdata",
      token: this.state.userToken
      });
    } else {
      this.setState({ loader: false });
    }
  };

  handleDeleteSubCategory = async (id: string | number) => {
    this.setState({ loader: true });
    this.deleteSubCategoryApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpDeleteType,
      endPoint: `${configJSON.subCategoryAPIEndPoint}/${id}`,
      token: this.state.userToken
    });
  };

  handleEditSubCategory = async () => {
    this.setState({ loader: true });
    const {
      courseCategoryId,
      courseSubCategoryName,
      courseSubCategoryStatus,
      webIcon,
      webIconName,
      categoryId,
      iconSubCat,
      iconSubCatName
    } = this.state;
    const formValues = {
      courseSubCategoryName,
      courseCategoryId,
      webIconName,
      iconSubCatName
    };
    if (!this.checkSubCategoryFormValidations(formValues)) {
      const formdata = new FormData();
      formdata.append(
        "course_sub_category[course_category_id]",
        courseCategoryId
      );
      formdata.append("course_sub_category[name]", courseSubCategoryName);
      formdata.append("course_sub_category[status]", courseSubCategoryStatus);
      if (iconSubCat) {
        formdata.append(
          "course_sub_category[mobile_icon]",
          iconSubCat,
          iconSubCatName
        );
      }
      if (webIcon) {
        formdata.append("course_sub_category[web_icon]", webIcon, webIconName);
      }
      this.editSubCategoryApiCallId = makeApiCall({
        method: configJSON.httpPutType,
        endPoint: `${configJSON.subCategoryAPIEndPoint}/${categoryId}`,
        body: formdata,
        type: "formdata",
      token: this.state.userToken
      });
    } else {
      this.setState({ loader: false });
    }
  };

  handleSubCategorySearch = (e: { target: { value: string } }) => {
    const searchedSubCatVal = e.target.value;
    this.setState(
      { searchedSubCatText: searchedSubCatVal, currentPageCount: 1 },
      () => {
        this.handleSubCategorySort(
          this.state.currentPageCount,
          this.state.perPage,
          searchedSubCatVal,
          this.state.columnSort
        );
      }
    );
  };

  handleSubCategorySort = async (
    pageNo: number,
    per_page: number,
    search: string,
    SubCatfieldSort: string
  ) => {
    this.setState({ loader: true });
    const endPoint = `${
      configJSON.subCategoryAPIEndPoint
    }/search?page=${pageNo}&per_page=${per_page}&sub_category_search_params=${
      search || ""
    }&${SubCatfieldSort || ""}`;
    this.getAdminSubCategoryListApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint,
      token: this.state.userToken
    });
  };

  handleSubCatSorting(SubCatField: string) {
    const sortParams: Record<string, string> = {
      "S.No": "id_sort",
      "Sub Category": "sub_category_name_sort",
      Status: "status_sort"
    };
    let columnSort = "";
    const sortOrder =
      this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(SubCatField)) {
      columnSort = `${sortParams[SubCatField]}=${sortOrder}`;
    }
    this.setState({
      currentSortState: { order: sortOrder, field: SubCatField },
      columnSort
    });
    this.handleSubCategorySort(
      this.state.currentPageCount,
      this.state.perPage,
      this.state.searchedSubCatText,
      columnSort
    );
  }

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  toggleDrawer = () => {
    this.setState({
      isDrawer: !this.state.isDrawer,
      setfullWidth: !this.state.setfullWidth
    });
  };

  onPageChange = (event: any, value: any) => {
    this.setState(
      {
        currentPageCount: Number(value)
      },
      () => {
        this.handleSubCategorySort(
          this.state.currentPageCount,
          this.state.perPage,
          this.state.searchedSubCatText,
          this.state.columnSort
        );
      }
    );
  };

  handleAddReset = () => {
    this.setState({
      iconSubCat: "",
      webIcon: "",
      iconSubCatName: "",
      webIconName: "",
      webIconPreviewThumbnail: "",
      iconSubCatPreviewThumbnail: "",
      courseCategoryError: "",
      courseSubCategoryError: "",
      courseSubCategoryStatus: true,
      courseSubCategoryName: "",
      webIconError: "",
      iconSubCatError: "",
      courseCategoryId: ""
    });
  };

  handleSubCategoryName = (event: { target: { value: string } }) => {
    const value = event.target.value.trimStart();

    if (!subCategoryRegex.test(value)) {
      this.setState({
        courseSubCategoryName: value,
        courseSubCategoryError: "Please enter a valid Sub Category name"
      });
    } else {
      this.setState({
        courseSubCategoryName: value,
        courseSubCategoryError: ""
      });
    }
  };

  handleClickCheckBox = (event: { target: { checked: boolean } }) => {
    this.setState({
      courseSubCategoryStatus: event.target.checked
    });
  };

  handleCloseDeleteSubCategoryModal = () => {
    this.setState({
      openDeleteSubCategoryModal: false,
      deleteSubCatId: ""
    });
  };

  handleOpenDeleteSubCategoryModal = (id: string) => {
    this.setState({
      openDeleteSubCategoryModal: true,
      deleteSubCatId: id
    });
  };

  handleSelectChange = (id: string) => {
    this.setState({
      courseCategoryId: id,
      courseCategoryError: ""
    });
  };

  handleSubCatHeaderCheckboxChange = () => {
    this.setState((previousState) => {
      const isHeaderChecked = !previousState.isHeaderChecked;
      const isChecked = Array(this.state.adminSubCategoryData.data.length).fill(
        isHeaderChecked
      );

      return {
        isHeaderChecked,
        isChecked
      };
    });
  };

  handleRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];

      const isHeaderChecked = isChecked.every((value) => value);

      return {
        isChecked,
        isHeaderChecked
      };
    });
  };

  handleAddSubCategoryNavigation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminSubCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleEditPropsSubCategory = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminSubCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };

  handleSubCategoryNavigation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAdminSubCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}

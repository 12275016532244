import React from "react";
// Customizable Area Start
import {
    withStyles,
    Accordion, AccordionSummary, Button, Typography, Grid
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import LockIcon from '@material-ui/icons/Lock';

let accordionData = [
    {
        title: 'Accordion 1',
        class: 'Class 1',
        time: 'Time 1',
        lectures: [
            'This is lecture one of solid state',
            'This is lecture two of solid state',
            'This is lecture three of solid state',
            'This is lecture four of solid state',
        ],
        lecturetime: ['00:45 mins', '00:45 mins', '00:45 mins', '00:45 mins'],
    },
    {
        title: 'Accordion 2',
        class: 'Class 2',
        time: 'Time 2',
        lectures: [
            'This is lecture one of solid state',
            'This is lecture two of solid state',
            'This is lecture three of solid state',
            'This is lecture four of solid state',
        ],
        lecturetime: ['00:45 mins', '00:45 mins', '00:45 mins', '00:45 mins'],
    },
    {
        title: 'Accordion 3',
        class: 'Class 3',
        time: 'Time 3',
        lectures: [
            'This is lecture one of solid state',
            'This is lecture two of solid state',
            'This is lecture three of solid state',
            'This is lecture four of solid state',
        ],
        lecturetime: ['00:45 mins', '00:45 mins', '00:45 mins', '00:45 mins'],
    },
];
let tabs = [
    { id: 1, name: 'OVERVIEW' },
    { id: 2, name: 'COURSE' },
    { id: 3, name: 'INSTRUCTOR' },
    { id: 4, name: 'REVIEW' }
]

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";
import { teaching } from "./assets";


export class CourseContent extends CatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    toggleAccordion = (index: any) => {
        const newExpanded = [...this.state.expanded];
        newExpanded[index] = !newExpanded[index];
        this.setState({ expanded: newExpanded });
    };

    // Customizable Area End

    render() {
        const { classes } = this.props;


        return (
            //Merge Engine DefaultContainer
            <div>
                <div style={{ maxWidth: '60vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    {tabs.map((item, index) => (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div key={index} style={{
                                    width: '270px',
                                    height: '64px',
                                    background: this.state.selectedTab === item.id ? 'linear-gradient(180deg, #FFED65 0%, #FCB910 100%)' : 'rgb(227, 227, 227)',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '0px 0px 15px 15px',
                                    marginBottom: '8px'
                                }}
                                    onClick={() => this.setState({ selectedTab: item.id })}
                                >{item.name}
                                </div>
                                {this.state.selectedTab === item.id &&
                                    <div style={{ width: '270px', background: "linear-gradient(180deg, #FFED65 0%, #FCB910 100%)", height: '7px', borderRadius: '10px' }}></div>}

                            </div>
                        </>
                    ))}

                </div>
                <div>
                    <h1 className={classes.contentHeading}>
                        Course Content
                    </h1>

                    <div style={{ maxWidth: '60vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className={classes.detailsText}>
                            3 Chapters . 12 Lectures . 40 Hours, 33 minutes Total Length{' '}
                        </p>
                        <div>
                            <Button className={classes.stylebutton}>
                                expand all <ArrowRightAltIcon />
                            </Button>
                        </div>

                    </div>

                    <div style={{ maxWidth: '60vw' }}>
                        {accordionData.map((item, index) => (
                            <Accordion key={index} expanded={this.state.expanded[index]} onChange={() => this.toggleAccordion(index)} className={classes.AccourdianStyle} square={false}>
                                <AccordionSummary className={classes.accordionSummary}>
                                    {this.state.expanded[index] ? (
                                        <RemoveIcon className={classes.icon} />
                                    ) : (
                                        <AddIcon className={classes.icon} />
                                    )}
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            <Typography className={classes.accText}>{item.title}</Typography>
                                        </Grid>
                                        <Grid item className={classes.secondaryContent}>
                                            <span className={classes.accText} style={{ marginRight: '30px' }}>{item.class}</span>
                                            <span className={classes.accText} style={{ marginRight: '30px' }}>{item.time}</span>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <div className={classes.contentdiv}>
                                    <div>
                                        <img src={teaching} alt="img" style={{ width: '255px', height: '136px', marginRight: '20px' }} />
                                    </div>
                                    <div className={classes.accordianText}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex Ut enim ad minim...
                                    </div>
                                </div>

                                {[1, 2, 3, 4].map((item) => (
                                    <div key={item} style={{ borderBottom: '1px solid gray', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ background: 'gray', padding: '5px', justifyContent: 'center', alignItems: 'center', width: 'content-fit', borderRadius: '50%', marginRight: '20px' }}><LockIcon /></div>
                                            <div className={classes.faq}>Lecture 01: This is the a lecture of solid state</div>
                                        </div>

                                        <div>
                                            <div className={classes.faq}>00:45 MINS</div>
                                        </div>
                                    </div>
                                ))}
                            </Accordion>
                        ))}
                    </div>
                </div>
            </div>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
    accordionSummary: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(to right top, #439ead, #3e97a6, #38909e, #338997, #2d8290)',
        borderRadius: '5px',
        marginTop: '20px',
        maxWidth: '100%'
    },
    icon: {
        fontSize: 20,
        color: 'white',
        marginRight: '10px'
    },
    secondaryContent: {
        textAlign: 'right',
    },
    spaceBetweenSpans: {
        marginRight: '30px',
    },
    stylebutton: {
        border: '1px solid #1721CD',
        borderRadius: '300px',
        color: '#1721CD'
    },
    contentdiv: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '30px 20px'
    },
    lectureItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        borderBottom: '1px solid black',
    },
    accordianText: {
        color: '#212121',
        fontFamily: 'ProximaNova',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        letterSpacing: '-0.027px',
    },
    faq: {
        color: '#212121',
        fontFamily: 'ProximaNova',
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        letterSpacing: '-0.031px',
    },
    accText: {
        color: '#FFF',
        fontFamily: 'ProximaNova',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
    },
    contentHeading: {
        color: '#858585',
        fontFamily: 'ProximaNova',
        fontSize: '37.633px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        letterSpacing: '-0.046px',
    },
    detailsText: {
        color: '#000',
        fontFamily: 'ProximaNova',
        fontSize: '25.633px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        letterSpacing: '-0.031px',
    }
});


export default withStyles(styles)((CourseContent));
// Customizable Area End

import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  FormControl,
  Button,
  MenuItem
} from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
const drawerWidth = 250;
const closeDrawer = 0;
import { DuplicatedModal } from "./Vouchercodegenerator.web";
import Loader from "../../../components/src/Loader.web";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Customizable Area End

import CouponController, { Props } from "./CouponcodegeneratorController.web";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  DateInput,
  InputBox,
  MultiSelect,
  OneColBox,
  StyledSelect,
  TwoColBox
} from "../../../components/src/GenericInputs";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

export default class AddCouponcodeGenerator extends CouponController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddCouponForm() {
    const { couponFormData } = this.state;
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Coupon Name*</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="couponNameTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="coupon_name"
                  fullWidth
                  value={couponFormData.coupon_name}
                  onChange={e => {
                    this.handleCouponInputChange(e);
                  }}
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Coupon Code*</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="couponCodeTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="coupon_code"
                  fullWidth
                  value={couponFormData.coupon_code}
                  onChange={e => {
                    this.handleCouponInputChange(e);
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Discount Type</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <StyledSelect
                  fullWidth={true}
                  onChange={e => {
                    this.handleCouponInputChange(e);
                  }}
                  disableUnderline
                  name="discount_type"
                  value={couponFormData.discount_type}
                  data-test-id="discountTypeTestId"
                  IconComponent={IoIosArrowDown}
                >
                  {this.state.discountTypeList &&
                    this.state.discountTypeList.length > 0 &&
                    this.state.discountTypeList.map((item: any) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                </StyledSelect>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">
                Discount{" "}
                {this.state.couponFormData.discount_type === "amount"
                  ? "(₹)"
                  : "(%)"}
                *
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="discountTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="discount"
                  fullWidth
                  inputProps={{
                    inputProps: {
                      min: 0
                    }
                  }}
                  value={couponFormData.discount}
                  onChange={e => {
                    this.handleCouponInputChange(e, "number");
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box multiselect">
              <Box className="box__label" style={{ alignItems: "flex-start" }}>
                Associate Course
                <IconButton
                  color="primary"
                  data-test-id="infoButtonTestId"
                  size="small"
                  onClick={() => this.onCourseInfoClick()}
                  style={{ fontSize: "0.75rem" }}
                  aria-label="add to shopping cart"
                >
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <MultiSelect
                  fullWidth={true}
                  onChange={(e: any) => {
                    this.handleCouponInputChange(e);
                  }}
                  multiple
                  name="course_id"
                  className="multiple__input"
                  value={couponFormData.course_id}
                  data-test-id="associateCourseTestId"
                  IconComponent={IoIosArrowDown}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    keepMounted: true,
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        overflow: "auto"
                      }
                    }
                  }}
                >
                  {this.state.associatedCourses &&
                    this.state.associatedCourses.length > 0 &&
                    this.state.associatedCourses.map(item => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        style={{
                          maxWidth: 330,
                          display: "flex",
                          flexFlow: "wrap",
                          whiteSpace: "break-spaces"
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </MultiSelect>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label" style={{ alignItems: "flex-start" }}>
                Expiry Date*
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <DateInput
                  id="datetime-local"
                  name="expiry_date"
                  value={this.state.couponFormData.expiry_date}
                  data-test-id="expiryDateTestId"
                  onChange={e => {
                    this.handleCouponInputChange(e, "date");
                  }}
                  inputProps={{
                    inputProps: {
                      min: new Date().toJSON().slice(0, 10)
                    }
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <OneColBox>
            <Box className="box__label">Applicable Purchase (₹)</Box>
            <FormControl variant="outlined" fullWidth className="box__input">
              <InputBox
                data-test-id="applicablePurchaseTestId"
                id="outlined-basic"
                variant="outlined"
                name="applicable_on"
                inputProps={{
                  inputProps: {
                    min: 0
                  }
                }}
                fullWidth
                value={couponFormData.applicable_on}
                onChange={e => {
                  this.handleCouponInputChange(e, "number");
                }}
              />
            </FormControl>
          </OneColBox>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              columnGap: "10px",
              marginBottom: "20px"
            }}
          >
            <Button
              style={webStyles.resetBtn}
              variant="outlined"
              data-test-id="cancelButtonTestId"
              onClick={() => this.handleGoBack()}
            >
              Cancel
            </Button>
            {this.state.isUpdate ? (
              <Button
                data-test-id="updateButtonTestId"
                style={webStyles.createBtn}
                disabled={this.state.isLoading}
                onClick={() => this.handleEditCoupon()}
              >
                Update
              </Button>
            ) : (
              <Button
                data-test-id="createButtonTestId"
                style={webStyles.createBtn}
                disabled={this.state.isLoading}
                onClick={() => this.handleAddCoupon()}
              >
                Create
              </Button>
            )}
          </div>
        </Box>
      </div>
    );
  }

  closemodal() {
    this.oncloseAlert();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <MainLayout
          isAdd={true}
          backData={() => this.handleGoBack()}
          title={"Add Coupon Code"}
        >
          <NotificationAlertMessage
            alertType={this.state.alertType}
            msg={this.state.alertMsg}
            onClose={this.closemodal}
            isOpen={this.state.isAlert}
          />
          <Loader loading={this.state.isLoading} />
          <DuplicatedModal
            handleCloseModal={() =>
              this.setState({ isModalOpen: false, alertMsg: "" })
            }
            isModalOpen={this.state.isModalOpen}
            message={this.state.alertMsg}
          />
          <main className="table_content">{this.renderAddCouponForm()}</main>
        </MainLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const webStyles: Record<string, CSSProperties> = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  resetBtn: {
    width: "150px",
    borderRadius: "3px",
    padding: "15px 0",
    fontSize: "0.875rem",
    fontWeight: 600,
    marginTop: "20px",
    backgroundColor: "#fcfcfc",
    border: "1px solid #81999f",
    color: "#81999f",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
    alignItems: "centerd"
  },
  createBtn: {
    width: "150px",
    borderRadius: "3px",
    padding: " 15px 0",
    border: "none",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: 600,
    backgroundColor: " #206575",
    marginTop: " 20px",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
    alignItems: "centerd"
  },
  main: {
    display: "flex",
    columnGap: "15px",
    borderRadius: "5px",
    border: "1px solid #9F9F9F",
    background: "#FFF",
    padding: "10px",
    minWidth: "691px",
    minHeight: "110px"
  },
  left: {},
  rightMain: {
    width: "100%"
  },
  right1: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "0.938rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: " -0.022px",
    marginBottom: "2px"
  },
  right2: {
    color: "#8B8B8B",
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.022px",
    marginBottom: "21px"
  },
  right3: {
    display: "flex",
    justifyContent: "space-between"
  },
  right3Left: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.022px"
  },
  right3Right: {
    display: "flex",
    columnGap: "5px"
  },
  deleteAddLearn: {
    width: "44px",
    height: "44px",
    borderRadius: "5px",
    border: "1px solid #FF5C00",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  resetAddMain: {
    display: "flex",
    justifyContent: "center",
    columnGap: "15px",
    marginBottom: "20px"
  },
  categoryImgName: {
    display: "flex",
    justifyContent: "center"
  },
  checkboxMain: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  modalTitleHeadText: {
    color: "var(--Basic-Black, #000)",
    fontFamily: "Inter",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px"
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "32px"
  },
  bundleCourseText: {
    display: "flex",
    marginLeft: "40px",
    fontSize: "1.438rem",
    color: "black"
  },
  lineDivide: {
    height: "20px",
    display: "inline-block"
  },
  addMoreText: {
    color: "#1F6575",
    fontFamily: "Inter",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "17.5px"
  },
  tableDivide: {
    height: "10px"
  },
  addMoreDeleteIcon: { display: "flex", columnGap: "10px" },
  addMoreLearn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "130px",
    cursor: "default",
    fontSize: "0.875rem",
    columnGap: "10px",
    borderRadius: "3.977px",
    border: " 0.795px solid #1F6575",
    height: "50px"
  },
  addMoreReset: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "108px",
    border: "1px solid #1F6575",
    background: "var(--Basic-White, #FFF)"
  },
  addCourseTextModalBtn: {
    width: "180px",
    background: "#1F6575"
  },
  addCourseTextModal: {
    color: "var(--Basic-White, #FFF)",
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fullNameText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  imgNameMain: { display: "flex", marginLeft: "15px", columnGap: "10px" },
  editDeleteIcon: { background: "none", border: "none", cursor: "pointer" },
  tableActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  bundleCourseMainLayout: {
    width: "97%",
    margin: "0 auto",
    maxWidth: "1024px"
  },
  adminLayout: { display: "flex", flexDirection: "column", gap: "2rem" },
  adminLabelText: {
    color: "#192252",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center"
  },
  formErrorText: { fontSize: "0.625rem", color: "red" },
  inputField: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    paddingBottom: "20px"
  },
  fileLabel: {
    padding: "10px",
    alignItems: "center",
    border: "2px dashed #ccc",
    cursor: "pointer",
    width: "98%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  }
};

// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "pushnotifications";
exports.labelBodyText = "pushnotifications Body";
exports.putApiMethodType = "PUT";

exports.btnExampleTitle = "CLICK ME";
exports.tokenExpiredMessage = "Token has Expired";
exports.tokenInvalidMessage = "Invalid token";

exports.getNotificationsEndPoint = "push_notifications/push_notifications";
exports.getNotificationsAPIMethod = "GET";
exports.pushNotificationsEndpoint =
  "bx_block_push_notifications/push_notification_templates";
exports.successSubmitMessage =
  "Push Notification Template successfully updated";
// Customizable Area End

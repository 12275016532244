import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { removeStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { parseErrors } from "../../couponcodegenerator/src/FlatDiscountController.web";

interface EducationCategoryData {
  data: EducationCategoryAttr[];
}

export interface EducationCategoryAttr {
    id: string;
    type: string;
    attributes: {
        name: string;
        web_icon: string;
    },
}
interface AchievementsData {
    data: AchievementsAttr
}

interface AchievementsAttr {
  id: number;
  total_followers: number;
  certified_teachers: number;
  online_courses: number;
  student_enrolled:number;
}

interface InstructorData {
  data: Instructor[]
}
interface InstructorAttributes {
  full_name: string;
  details: string;
  profile_icon: string;
  leacture_in: string;
}

interface Instructor {
  id: string;
  type: string;
  attributes: InstructorAttributes;
}

interface StudentReviewDataRes {
  data : StudentReviewData[];
}
interface StudentReviewData {
    id: string;
    type: string;
    attributes: StudentReviewAttr
}

interface StudentReviewAttr {
  id: string;
  reviewable_id: string;
  rating: number;
  content: string
  status: boolean;
  visible_on_landing: boolean;
  user_name: {
      id: number
      full_phone_number: string;
      email: string;
      full_name: string
  },
  user_image: string;
}
export interface BannerListResponseType {
  data: BannerListData[];
}

export interface BannerResponseType {
  data: BannerListData;
}

export interface BannerListData {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  banner_title: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  banner_image: string;
}

export interface WishlistResponse {
  meta?: {
    message: string;
  }
  message?: string;
}
export interface AddCartResponse {
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  newLaunchCourses: any;
  bundleCourses:any;
  alertMsg: string;
  isAlert: boolean;
  alertType: Color;
  trendingCourses: any;
  trendingCoursePageNo:any;
  terndingCoursePerPage:any;
  trendingCourseLoading: boolean,
  trendingCourseLastPage: number | undefined,
  launchCoursePageNo:any;
  launchCoursePerPage:any;
  launchCourseLoading: boolean,
  launchCourseLastPage: number | undefined,
  bundleCoursePageNo:any;
  bundleCoursePerPage:any;
  bundleCourseLoading: boolean,
  bundleCourseLastPage: number | undefined,
  listMaxPerPage: number;
  launchCourseId:number;
  isHover:boolean;
  ourAchievements: AchievementsAttr;
  ourInstruction: Instructor[];
  currentIndex: number;
  userToken: string;
  studentReview: StudentReviewData[];
  WebTopBanner: BannerListData[];
  value: number;
  activeSubject: number;
  loadingMainCategory: boolean;
  loadingSubCategory: boolean;
  loadingChildSubject: boolean;
  educationMainCategory : EducationCategoryAttr[];
  educationSubCategory: EducationCategoryAttr[];
  educationChildSubject: EducationCategoryAttr[];
  selectedCategoryId: string;
  selectedSubCategoryId: string;
  CourseValidityDialog: boolean;
  courseValidityDetails: Record<string, any>;
  courseIndex: number;
  courseListname: string;
  selectedValidity: number;
  selectedEducationCategories: {
    class: string;
    stream: string;
    subject: string;
    classId: string;
    streamId: string;
    subjectId: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      userToken: "",
      newLaunchCourses: [],
      trendingCourses: [],
      bundleCourses:[],
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      listMaxPerPage: 12,
      launchCoursePageNo:1,
      launchCoursePerPage:4,
      launchCourseLoading: false,
      launchCourseLastPage: undefined,
      trendingCoursePageNo:1,
      terndingCoursePerPage:4,
      trendingCourseLoading: false,
      trendingCourseLastPage: undefined,
      bundleCoursePageNo:1,
      bundleCoursePerPage:3,
      bundleCourseLoading: false,
      bundleCourseLastPage: undefined,
      launchCourseId:0,
      isHover:false,
      ourAchievements: {
        id: 1,
        total_followers: 1,
        certified_teachers: 1,
        online_courses: 1,
        student_enrolled: 1,
      },
      ourInstruction: [],
      currentIndex: 0,
      studentReview: [],
      WebTopBanner: [],
      value: 0,
      activeSubject: 0,
      educationMainCategory: [],
      loadingMainCategory: false,
      loadingSubCategory: false,
      loadingChildSubject: false,
      educationSubCategory: [],
      educationChildSubject: [],
      selectedCategoryId: "",
      selectedSubCategoryId: "",
      CourseValidityDialog: false,
      courseValidityDetails: {},
      courseListname: "newLaunchCourses",
      courseIndex: 0,
      selectedValidity: 1,
      selectedEducationCategories: {
        class: "",
        stream: "",
        subject: "",
        classId: "",
        streamId: "",
        subjectId: ""
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getNewLaunchedCourseWebApiCallId:
            this.handleNewlyLaunchedCourses(responseJson)
            break;
          
          case this.getTrendingCourseWebApiCallId:
            this.handleTrendingCourses(responseJson);
            break;
          
            case this.getBundleCoursesWebApiCallId:
              this.handleBundleCourses(responseJson)
              break;
            case this.addToChartApiCallId:
              this.handleChartStatusResponse(responseJson);
              break;
            case this.toggleWishlistApiCallId:
              this.handleWishlistStatusResponse(responseJson);
            break;
          case this.logoutUserApiCallId:
            this.handleLogoutUserResponse();
            break;
          case this.getOurAchievementWebApiCallId:
            this.handleOurAchievementApiResponse(responseJson as AchievementsData)
            break;
          case this.getOurInstructionWebApiCallId:
              this.handleOurInstructoreApiResponse(responseJson as InstructorData)
            break;
          case this.getStudentReviewApiCallId:
            this.studentReviewResponseData(responseJson as StudentReviewDataRes)
            break;
          case this.getWebTopBannerApiCallId:
            this.webTopBannerApiResponseData(responseJson as BannerListResponseType);
            break;
          case this.getEducationMainCategoryApiCallId:
            this.educationMainCategoryApiResponseData(responseJson as EducationCategoryData);
           break;
          case this.getEducationSubCategoryApiCallId:
            this.educationSubCategoryApiResponseData(responseJson as EducationCategoryData)
            break;
          case this.getEducationChildCategorySubjectApiCallId: 
           this.educationChildCategoryApiResponseData(responseJson as EducationCategoryData)
           break
        }
      } else {
        parseErrors(responseJson, this.parseApiErrorResponse, this.send, this.props);
      }
     
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  oncloseAlert() {
    this.setState({
      isAlert: false,
    });
  }

  stringToBoolean(value: string): boolean {
    return value.toLowerCase() === "true";
  }

  disabledNextButtons() {
    return {
      launchedCourses: this.state.launchCoursePageNo === this.state.launchCourseLastPage || this.state.launchCourseLoading,
      trendingCourses: this.state.trendingCoursePageNo === this.state.trendingCourseLastPage || this.state.trendingCourseLoading,
      bundleCourses: this.state.bundleCoursePageNo === this.state.bundleCourseLastPage || this.state.bundleCourseLoading
    }
  }; 
  
  disabledBackButtons() {
    return {
      launchedCourses: this.state.launchCoursePageNo === 1 || this.state.bundleCourseLoading,
      trendingCourses: this.state.trendingCoursePageNo === 1 || this.state.bundleCourseLoading,
      bundleCourses: this.state.bundleCoursePageNo === 1 || this.state.bundleCourseLoading
    }
  };

  handleWishlistStatusResponse(response: WishlistResponse) {
    if (response && response.meta && response.meta.message) {
      this.setState({
        alertMsg: response.meta.message,
        isAlert: true,
        alertType: "success"
      });
    } else if (response && response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "info"
      });
    }
  }

  handleWishlistStatus(isAdd: boolean, courseId: string, listName: "newLaunchCourses" | "trendingCourses" | "bundleCourses") {
    if (!this.state.userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your wishlist.",
        alertType: "info",
        isAlert: true
      });
      return;
    }

    const headers = {
      "Content-Type": !isAdd ? configJSON.productApiContentType : undefined,
      "token": this.state.userToken
    };

    const body = {
      wishlist: {
        course_id: courseId
      }
    };
    const formdata = new FormData();
    formdata.append("course_id", courseId);

    let clone = [...this.state[listName as keyof S]];
    const index = clone.findIndex((e)=> e.id === courseId);
    clone[index].attributes.is_wishlist = `${!isAdd}`;

    const stateToSet: unknown = { [listName as keyof S]: clone };

    this.setState(stateToSet as Pick<S, keyof S>);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.toggleWishlistApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      !isAdd ? configJSON.wishlistApiEndpoint : configJSON.removeWishlistApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      !isAdd ? JSON.stringify(body) : formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      !isAdd ? configJSON.exampleAPiMethod : configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleChartStatusResponse(response: AddCartResponse) {
    if (response && response.message) {
      await this.updateCartCount()
      this.setState({
        CourseValidityDialog: false,
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
    }
  }
  updateCartCount = async () => {
    try {
      let cartCount = await getStorageData('cartCount'); 
  
      if (cartCount) {
        cartCount = parseInt(cartCount) + 1; 
      } else {
        cartCount = 1;
      }
  
      await setStorageData('cartCount', cartCount);
      const event = new CustomEvent('cartCountChange', { detail: { count: cartCount } });
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error updating cart count:', error);
    }
  }
  handleChartStatus(isAdd: boolean, courseId: string, type: string, listName: string,no_of_year: number = 1 ) {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.userToken
    };
    const body = {
      course_id: courseId,
      no_of_year,
      text: type
    };

    let clone = [...this.state[listName as keyof S]];
    const index = clone.findIndex((e)=> e.id === courseId);
    clone[index].attributes.is_cart = true;

    const stateToSet: unknown = { [listName as keyof S]: clone };

    this.setState(stateToSet as Pick<S, keyof S>);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToChartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToChartApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleNewlyLaunchedCourses(responseJson: any) {
    if (!responseJson.data) {
      this.setState({ launchCoursePageNo: this.state.launchCoursePageNo - 1, launchCourseLoading: false, launchCourseLastPage: this.state.launchCoursePageNo });
    } else {
      const arrayLength = responseJson.data.length;
      this.setState({ newLaunchCourses: [...this.state.newLaunchCourses, ...responseJson.data], launchCourseLoading: false, launchCourseLastPage: arrayLength < 12 ? this.state.launchCoursePageNo + this.getLastPageIndex(arrayLength) : undefined });
    }
  }

  getNewLaunchedCourses(pageNo:number,perPage:number) {
    this.setState({ launchCourseLoading: true });
    const header: Record<string, string> = {
      "Content-Type": configJSON.productApiContentType,
    };
    if (this.state.userToken)
      header.token = this.state.userToken;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNewLaunchedCourseWebApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNewLaunchedCourseWebApiEndPoint + `/?page=${pageNo}&per_page=${perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleCoursesPagination = <T, >(array: T[], pageNumber: number, itemsPerPage: number) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  };

  handleLauchedCoursePage = (direction: "next" | "prev") => {
    const currentPage = this.state.launchCoursePageNo + (direction === "next" ? 1 : -1);
    this.setState({ launchCoursePageNo: currentPage }, () => {
      const totalPages = Math.ceil(this.state.newLaunchCourses.length / 4);
      const isLastPage = currentPage > totalPages;
      const totalPagesApi = Math.ceil((isLastPage ? totalPages + 1 : totalPages) / 3);
      if (isLastPage)
        this.getNewLaunchedCourses(totalPagesApi,this.state.listMaxPerPage);
    });
  }
  
  handleTrendingCourses(responseJson: any) {
    if (!responseJson.data) {
      this.setState({ trendingCoursePageNo: this.state.trendingCoursePageNo - 1, trendingCourseLoading: false, trendingCourseLastPage: this.state.trendingCoursePageNo });
    } else {
      const arrayLength = responseJson.data.length;
      this.setState({ trendingCourses: [...this.state.trendingCourses, ...responseJson.data], trendingCourseLoading: false, trendingCourseLastPage: arrayLength < 12 ? this.state.trendingCoursePageNo + this.getLastPageIndex(arrayLength) : undefined })
    }
  }

  getTrendingCourses(pageNo:number,perPage:number) {
    this.setState({ trendingCourseLoading: true });
    const header: Record<string, string> = {
      "Content-Type": configJSON.productApiContentType,
    };
    if (this.state.userToken)
      header.token = this.state.userToken;
   
    const requestMessageTrending = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrendingCourseWebApiCallId = requestMessageTrending.messageId;
    requestMessageTrending.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTrendingCoursesWebApiEndPoint + `/?page=${pageNo}&per_page=${perPage}`
    );

    requestMessageTrending.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageTrending.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessageTrending.id, requestMessageTrending);

  }

  handleTrendingCoursePage = (direction: "next" | "prev") => {
    const currentPage = this.state.trendingCoursePageNo + (direction === "next" ? 1 : -1);
    this.setState({ trendingCoursePageNo: currentPage }, () => {
      const totalPages = Math.ceil(this.state.trendingCourses.length / 4);
      const isLastPage = currentPage > totalPages;
      const totalPagesApi = Math.ceil((isLastPage ? totalPages + 1 : totalPages) / 3);
      if (isLastPage)
        this.getTrendingCourses(totalPagesApi, this.state.listMaxPerPage);
    });
  }

  handleBundleCoursePage = (direction: "next" | "prev") => {
    const currentPage = this.state.bundleCoursePageNo + (direction === "next" ? 1 : -1);
    this.setState({ bundleCoursePageNo: currentPage }, () => {
      const totalPages = Math.ceil(this.state.bundleCourses.length / 3);
      const isLastPage = currentPage > totalPages;
      const totalPagesApi = Math.ceil((isLastPage ? totalPages + 1 : totalPages) / 2);
      if (isLastPage)
        this.getBundleCourses(totalPagesApi,this.state.listMaxPerPage);
    });
  }

  handleBundleCourses(responseJson: any) {
    if (!responseJson.data) {
      this.setState({ bundleCoursePageNo: this.state.bundleCoursePageNo - 1, bundleCourseLoading: false, bundleCourseLastPage: this.state.bundleCoursePageNo });
    } else {
      const arrayLength = responseJson.data.length;
      this.setState({ bundleCourses: [...this.state.bundleCourses, ...responseJson.data], bundleCourseLoading: false, bundleCourseLastPage: arrayLength < 12 ? this.state.bundleCoursePageNo + this.getLastPageIndex(arrayLength, 3) : undefined })
    }
  }

  getLastPageIndex(arrayLength: number, elementsPerPage = 4) {
    const totalPages = Math.ceil(arrayLength / elementsPerPage);
    return totalPages;
  }

  getBundleCourses(pageNo:number,perPage:number) {
    this.setState({ bundleCourseLoading: true });
    const header: Record<string, string> = {
      "Content-Type": configJSON.productApiContentType,
    };
    if (this.state.userToken)
      header.token = this.state.userToken;
    const requestMessageBundleCourses = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBundleCoursesWebApiCallId = requestMessageBundleCourses.messageId;
    requestMessageBundleCourses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bundleCoursesWebApiEndPoint+ `/?page=${pageNo}&per_page=${perPage}`
    );

    requestMessageBundleCourses.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageBundleCourses.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessageBundleCourses.id, requestMessageBundleCourses);

  }

  handleViewCourseList=( screenName: string )=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), { screenName });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  logoutApiCall = (token: string) => {
    const header = {
      'Content-Type': configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logoutUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNewLaunchedCourseWebApiCallId: any;
  getTrendingCourseWebApiCallId: any;
  getBundleCoursesWebApiCallId:any;
  logoutUserApiCallId: string = "";
  addToChartApiCallId: string = "";
  toggleWishlistApiCallId: string = "";
  getOurAchievementWebApiCallId: string = "";
  getOurInstructionWebApiCallId: string = "";
  getStudentReviewApiCallId: string = "";
  getWebTopBannerApiCallId: string = "";
  getEducationMainCategoryApiCallId: string = "";
  getEducationSubCategoryApiCallId: string = "";
  getEducationChildCategorySubjectApiCallId: string = "";
  handleLogoutUserResponse() {
    removeStorageData("emailOTPToken");
    removeStorageData("loginEmail");
    removeStorageData("loginToken");
    removeStorageData("profilePic");
    removeStorageData("profileUsername");
    removeStorageData("userRole");
    window.location.reload();
  }

  async checkUserLoggedIn() {
    const queryParams = new URLSearchParams(window.location.search);
    const isLogout = queryParams.get("logout_user");
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token || "" });

    if (token && isLogout) {
      this.logoutApiCall(token);
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.checkUserLoggedIn();
    this.getWebTopBannerData()
    this.getTrendingCourses(this.state.trendingCoursePageNo,this.state.listMaxPerPage);
    this.getNewLaunchedCourses(this.state.launchCoursePageNo,this.state.listMaxPerPage);
    this.getBundleCourses(this.state.bundleCoursePageNo,this.state.listMaxPerPage);
    this.getOurAchievement();
    this.getOurInstruction();
    this.getStudentReviewData();
    this.getEducationCategory();
  }

  getOurAchievement() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOurAchievementWebApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOurAchievementWebApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOurInstruction() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOurInstructionWebApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOurInstructionWebApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOurAchievementApiResponse = (responseJson: AchievementsData) => {
    if(responseJson.data) {
      this.setState({ourAchievements: responseJson.data});
    }
  }

  handleOurInstructoreApiResponse = (responseJson: InstructorData) => {
    if(responseJson.data) {
      this.setState({ourInstruction: responseJson.data});
    }
  }
  handleSlideChange = (current: number, next: number) => {
    this.setState({ currentIndex: next });
  };

  getVideoId = (url:string) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get('v');
  };

  handleImageClick = (videoLink:string) => {
    window.open(videoLink, '_blank');
  };
  getStudentReviewData() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentReviewApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentReviewApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  studentReviewResponseData = (responseJson: StudentReviewDataRes) => {
    if(responseJson.data) {
      this.setState({ studentReview: responseJson.data})
    }
  }

  getWebTopBannerData() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWebTopBannerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWebTopBannerApiEndPoint + '?banner_type=top_banner'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  webTopBannerApiResponseData = (responseJson: BannerListResponseType) => {
    if(responseJson.data) {
      this.setState({ WebTopBanner: responseJson.data })
    }
  }

  getEducationCategory() {
    this.setState({loadingMainCategory: true, loadingSubCategory: true, loadingChildSubject: true})
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEducationMainCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationCategoryApiEndPoint + "?page=1&per_page=20"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  educationMainCategoryApiResponseData = (responseJson: EducationCategoryData) => { 
    if( responseJson.data) {
      this.setState({ educationMainCategory: responseJson.data}, () => {
        this.setState({ selectedCategoryId: this.state.educationMainCategory[0]?.id ,activeSubject: 0, loadingMainCategory: false})
        this.getEducationSubCategory(this.state.educationMainCategory[0]?.id,this.state.educationMainCategory[0]?.attributes.name)
       })
    }
  }
  getEducationSubCategory(id: string, name: string) {
    this.setState({ selectedCategoryId: id, loadingSubCategory: true, loadingChildSubject: true});
    this.setState({ selectedEducationCategories: {...this.state.selectedEducationCategories, class: name, classId: id}})
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEducationSubCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationSubCategoryApiEndPoint + `?course_category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  educationSubCategoryApiResponseData = (responseJson: EducationCategoryData) => { 
    if( responseJson.data) {
      this.setState({ educationSubCategory: responseJson.data}, () => {
        this.setState({ selectedSubCategoryId: this.state.educationSubCategory[0].id,activeSubject: 0, loadingSubCategory: false})
        this.getEducationChildCategorySubject(this.state.selectedCategoryId, this.state.educationSubCategory[0].id,this.state.educationSubCategory[0].attributes.name)
      })
    }
  }

  getEducationChildCategorySubject(categoryId: string, subCategoryId: string, name:string) {
    this.setState({loadingChildSubject: true})
    this.setState({ selectedEducationCategories: {...this.state.selectedEducationCategories, stream: name, streamId: subCategoryId }})
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEducationChildCategorySubjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SubCategoryAPiEndPoint + `?page=1&per_page=20&course_category_id=${categoryId}&course_sub_category_id=${subCategoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  educationChildCategoryApiResponseData = (responseJson: EducationCategoryData) => { 
    if( responseJson.data) {
      this.setState({ educationChildSubject: responseJson.data, loadingChildSubject: false})
    }
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue, selectedCategoryId: this.state.educationMainCategory[newValue].id });
  };
  handleSubjectClick = (index: number,id: string, name: string) => {
    this.setState({ activeSubject: index, selectedSubCategoryId: id });
    this.getEducationChildCategorySubject(this.state.selectedCategoryId, id, name)
  };

  isConditionalRending = <T, U>(value: boolean, condition1: T, condition2: U): T | U => {
    return value ? condition1 : condition2
  }

  truncateText = (text: string = "", length: number = 12) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  getWidth = (length: number) => {
    if (length > 5) {
      return '100%';
    } else if (length >= 4) {
      return '48%';
    } else if (length === 3) {
      return '46%';
    } else {
      return '52%';
    }
  };
  
  navigationShoppingCart = () => {
    const shoppingCartScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationShoppingCartMessage)
    );
    shoppingCartScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(shoppingCartScreenMsg);
  };
  handleOpenCourseValidityPopup = (isAdd: boolean,course: any,list: "newLaunchCourses" | "trendingCourses" | "bundleCourses", index: number) => {
    if (!this.state.userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your cart.",
        alertType: "info",
        isAlert: true
      });
      return;
    }
    if (isAdd) {
      this.setState({ alertMsg: "Redirect to cart", isAlert: true, alertType: "info" });
      this.navigationShoppingCart()
      return;
    }
    this.setState({courseListname: list,courseIndex: index, courseValidityDetails: course}, () => {
      this.setState({ CourseValidityDialog: true})
    })
  }
  onClose = () => {
    this.setState({selectedValidity: 1, CourseValidityDialog: false})
  }

  handleSelectValidity = (validity: 1 | 2) => {
    this.setState({ selectedValidity: validity });
  };

  handleNavigateEducationSubject = (id: string,name: string) => {
    this.setState({ selectedEducationCategories: { ...this.state.selectedEducationCategories, subject: name, subjectId: id } }, () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), this.state.selectedEducationCategories);
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    })
  }
  // Customizable Area End
}

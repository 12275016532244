import React from "react";
// Customizable Area Start
import "./categoryAdmin.css";
import "./editCourseComp.css";
import AdminLayout from  "./AdminLayout.web";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";
import EditCourseComp from "./EditCourseComp.web";

export default class AddSubCategory extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <AdminLayout navigation={undefined} id={""} headingText={"Add"} title={"Sub Category"} display={"none"} backPath ={"SubCategoryAdmin"}>
        <EditCourseComp navigation={undefined} id={""} addFunction={this.addSubCategory} subCategory={"Sub Category"} title={"Sub Category"} />
      </AdminLayout>
    );
  }
}

// Customizable Area Start
// Customizable Area End

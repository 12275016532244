import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
    getName
} from '../../../framework/src/Messages/MessageEnum';

export default class BannerAdapter {
    send: (message: Message) => void;

    constructor() {
        const blockId = uuidv4();
        this.send = message => runEngine.sendMessage(blockId, message);
        runEngine.attachBuildingBlock(this as IBlock, [
            getName(MessageEnum.NavigationBannerMessage),
            getName(MessageEnum.NavigationHeaderBannerMessage),
            getName(MessageEnum.NavigationWebHeaderBannerMessage),
            getName(MessageEnum.NavigationMobileHeaderBannerMessage)
        ]);
    }

    convert = (from: Message): Message => {
        const to = new Message(getName(MessageEnum.NavigationMessage));

        if (from.id === "NavigationHeaderBannerMessage") {
            to.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'HeaderBanner'
            );
        } else if(from.id === "NavigationWebHeaderBannerMessage") {
            to.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'WebHeaderBanner'
            );
        } else if (from.id === "NavigationMobileHeaderBannerMessage") {
            to.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'MobileFooterBanner'
            );
        } else {
            to.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'Banner'
            );
        }

        to.addData(
            getName(MessageEnum.NavigationPropsMessage),
            from.getData(getName(MessageEnum.NavigationPropsMessage))
        );

        const raiseMessage = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
          );
      
          raiseMessage.addData(
            getName(MessageEnum.NavigationIdMessage),
            from.getData(getName(MessageEnum.NavigationIdMessage))
          );
      
          to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
          return to;
    };

    receive(from: string, message: Message): void {
        this.send(this.convert(message));
    }
}
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfRazorpayIntegration64";
exports.labelBodyText = "CfRazorpayIntegration64 Body";

exports.btnExampleTitle = "CLICK ME";

exports.URLforRazorPay = "/bx_block_cfrazorpayintegration64/orders";
exports.capturePaymentApiEndpoint = "/bx_block_cfrazorpayintegration64/capture_payment";
exports.placeOrderEndPointMethod = "POST";
exports.paymentSuccessText = "Payment Successful";
exports.yeahText = "Yeah!";
exports.goToCourseBtnText = "Go TO MY COURSES";
exports.goToHomeBtnText = "Go TO HOME";
exports.paymentFailedText = "Payment Failed";
exports.tryAgainText = "Try Again";
// Customizable Area End
import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
const columnsHeaders = [
  { label: "Order ID", width: 50 },
  { label: "Order Date & Time", width: 70 },
  { label: "Payment Status", width: 50 },
  { label: "User ID", width: 50 },
  { label: "Full Name", width: 50 },
  { label: "Email", width: 50 },
  { label: "Phone", width: 90 },
  { label: "Course ID", width: 60 },
  { label: "Purchased Course", width: 200 },
  { label: "Transaction ID", width: 70 },
  { label: "Billing Address", width: 80 },
  { label: "Original Price", width: 50 },
  { label: "Discount Price", width: 50 },
  { label: "Total Amt Paid", width: 50 },
];

import OrderManagementController, {
  Props,
  OrderList,
} from "./OrderManagementController.web";
import { PaginationBox, webStyles } from "./RazorpayTransactions.web";
// Customizable Area End

export default class OrderManagement extends OrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: OrderList) => {
    return (
      <>
        <tr>
          <td
            className="tr_desc"
            style={{
              textAlign: "left",
              borderRadius: "10px 0px 0px 10px",
            }}
          >
            {item.order_id}
          </td>
          <td style={{ textAlign: "left" }}>{item.order_date_and_time}</td>
          <td style={{ textAlign: "left" }}>{item.payment_status}</td>
          <td style={{ textAlign: "left" }}>{item.account_id}</td>
          <td style={{ textAlign: "left" }}>{item.full_name}</td>
          <td style={{ textAlign: "left" }}>{item.email}</td>
          <td style={{ textAlign: "left" }}>{item.full_phone_number}</td>
          <td style={{ textAlign: "left" }}>{item.course_id}</td>
          <td style={{ textAlign: "left", padding: "10px 10px" }}>
            {item.course_name}
          </td>
          <td style={{ textAlign: "left" }}>{item.transaction_id}</td>
          <td style={{ textAlign: "left" }}>{item.address}</td>
          <td style={{ textAlign: "left" }}>{item.original_price}</td>
          <td style={{ textAlign: "left" }}>{item.discount_price}</td>
          <td style={{ textAlign: "left" }}>{item.total_amount_paid}</td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderColumnHeaders() {
    return (
      <>
        {columnsHeaders.map((header, index) => {
          return (
            <th key={index.toString()}>
              <Box
                className="th_box"
                style={{
                  justifyContent: "left",
                  width: header.width,
                  textAlign: "left",
                }}
              >
                <Typography className="title_th">{header.label}</Typography>
              </Box>
            </th>
          );
        })}
      </>
    );
  }

  renderTable = () => {
    return (
      <Box>
        <div style={{ overflow: "auto" }}>
          <TableStyle>
            <thead>
              <tr>{this.renderColumnHeaders()}</tr>
            </thead>
            <span style={webStyles.dividerLine} />
            <TableBody>
              {this.state.ordersList.map((item) => this.renderTableBody(item))}
            </TableBody>
          </TableStyle>
        </div>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.pageCount}
            shape="rounded"
            boundaryCount={1}
            page={this.state.currentPageCount}
            siblingCount={0}
            onChange={this.onPageChange}
          />
        </PaginationBox>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.isLoading}
        newUI
        isAdd
        title="Order Management"
        data-test-id="mainLayoutEvent"
      >
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  FormControl
} from "@material-ui/core";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import { TwoColBox, InputBox } from "../../../components/src/GenericInputs";
// Customizable Area End

import AddEmailNotificationsController, {
  Props
} from "./AddEmailNotificationsController.web";

export default class AddEmailNotifications extends AddEmailNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.isLoading}
        data-test-id="mainLayoutEvent"
        backData={this.navigationEmailNotifications}
        title={"Edit Email Notification"}
        isEdit
        isAdd
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          isOpen={this.state.isAlert}
          msg={this.state.alertMsg}
          onClose={this.onCloseAlert}
          dataTestId="alertTestId"
        />
            <main
                className="table_content"
                style={{ background: "rgb(252, 252, 252)" }}
            >
                <div style={webStyles.bundleCourseMainLayout}>
                    <Box sx={webStyles.adminLayout}>
                      <TwoColBox>
                            <Box className="child__box">
                                <Typography className="box__label">Notification Type</Typography>
                                <FormControl variant="outlined" fullWidth className="box__input">
                                    <InputBox
                                        data-test-id="notificationTypeTestId"
                                        name="email_notification_template_type"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={this.state.email_notification_template_type}
                                    />
                                </FormControl>
                            </Box>
                        </TwoColBox>
                        <TwoColBox>
                            <Box className="child__box">
                                <Typography className="box__label">Headings</Typography>
                                <FormControl variant="outlined" fullWidth className="box__input">
                                    <InputBox
                                        data-test-id="headersTestId"
                                        name="headers"
                                        variant="outlined"
                                        fullWidth
                                        onChange={e => this.handleInputChange(e)}
                                        value={this.state.headers}
                                        style={{ width: "500px"}}
                                    />
                                    <span data-test-id="headersErrTestId" style={webStyles.errorText}>
                                        {this.state.headersError}
                                    </span>
                                </FormControl>
                            </Box>
                        </TwoColBox>
                        <ButtonContainer>
                            <ResetButton
                                data-test-id="resetButtonId"
                                onClick={() => this.handleAddReset()}
                            >
                                Reset
                            </ResetButton>
                            <CreateButton
                                onClick={() => this.handleSubmitEmailNotification()}
                                data-test-id="createButtonId"
                                disabled={this.state.isLoading}
                            >
                                Update
                            </CreateButton>
                        </ButtonContainer>
                    </Box>
                </div>
            </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px"
});

const ResetButton = styled(Button)({
  border: "1px solid RGB(63,100,116)",
  textTransform: "inherit",
  marginRight: "30px",
  padding: "8px 50px",
  width: "195px",
  height: "52px",
  borderRadius: "0px",
  color: "#1F6575",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  "@media (max-width:830px)": {
    padding: "6px 30px",
    fontSize: "11px"
  }
});

const CreateButton = styled(Button)({
  padding: "8px 50px",
  borderRadius: "0px",
  textTransform: "inherit",
  height: "52px",
  width: "195px",
  color: "#fff",
  fontFamily: "Inter",
  backgroundColor: "RGB(63,100,116)",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",

  "&:hover": {
    backgroundColor: "RGB(63,100,116)"
  },
  "@media (max-width:830px)": {
    padding: "6px 30px",
    fontSize: "11px"
  }
});

const AddCheckBtn = styled("label")({
  "&.switch": {
    position: "relative",
    display: "inline-block",
    marginTop: "5px",
    "& input": {
      opacity: 0,
      width: 0,
      height: 0
    },
    width: "36px",
    height: "19px"
  },
  "& .slider": {
    position: "absolute",
    cursor: "pointer",
    bottom: "-1px",
    backgroundColor: "#ccc",
    WebkitTransition: ".4s",
    transition: ".4s",
    borderRadius: "20px",
    top: 0,
    left: 0,
    right: 0
  },
  "& .slider:before": {
    position: "absolute",
    content: '""',
    backgroundColor: "white",
    height: "18px",
    width: "18px",
    WebkitTransition: ".4s",
    transition: ".4s",
    borderRadius: "50%",
    left: "1px",
    bottom: "1px"
  },
  "& input:checked + .slider": {
    backgroundColor: "#708328"
  },
  "& input:checked + .slider:before": {
    left: "-10px"
  }
});

// Customizable Area End

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Dialog
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import CfRazorpayIntegration64Controller, {
  Props,
  configJSON,
} from "./CfRazorpayIntegration64Controller";

export default class CfRazorpayIntegration64 extends CfRazorpayIntegration64Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog open={this.state.isPaymentSuccess}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{ gap: 16, width: 400, padding: 50 }}>
            <DoneIcon
              style={{
                color: "#fff",
                background: "#00BF11",
                width: '2rem',
                height: '2rem',
                fontSize: 60,
                borderRadius: '50%'
              }} />
            <Typography
              variant="h3"
              style={{
                color: '#00BF11',
                fontFamily: 'Inter'
              }}>
              {configJSON.yeahText}
            </Typography>
            <Typography
              data-test-id="payment_successful"
              variant="h4"
              style={{ fontFamily: 'Inter' }}>
              {configJSON.paymentSuccessText}
            </Typography>
            <Button
              data-test-id="go_to_courses"
              variant="outlined"
              style={webStyle.buttonStyle}
              onClick={this.handleNavigateToCourses}>
              {configJSON.goToCourseBtnText}
            </Button>
            <Button
              data-test-id="go_to_home"
              variant="outlined"
              style={webStyle.goToHomeBtn}
              onClick={() => this.handleNavigateToHome("Home")}>
              {configJSON.goToHomeBtnText}
            </Button>
          </Box>
        </Dialog>
        <Dialog open={this.state.isPaymentFailed}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{ gap: 16, width: 400, padding: 50 }}>
            <CloseTwoToneIcon
              style={{
                color: "#fff",
                fontFamily: 'Inter',
                background: "#FE502E",
                width: '2rem',
                height: '2rem',
                fontSize: 60,
                borderRadius: '50%'
              }} />
            <Typography
              variant="h4"
              style={{
                color: '#FE502E',
                fontFamily: 'Inter'
              }}>
              {configJSON.paymentFailedText}
            </Typography>
            <Button
              variant="outlined"
              style={webStyle.buttonStyle}
              onClick={() => this.handleNavigateToHome("ShoppingCartOrders")}>
              {configJSON.tryAgainText}
            </Button>
            <Button
              variant="outlined"
              style={webStyle.goToHomeBtn}
              onClick={() => this.handleNavigateToHome("Home")}>
              {configJSON.goToHomeBtnText}
            </Button>
          </Box>
        </Dialog>
        
        <Button
          data-test-id={"checkoutButton"}
          className={'checkoutButton'}
          variant="contained"
          disabled={this.props.order_id === ""}
          onClick={this.getOrderDatials}
          fullWidth
        >
          {"CHECKOUT"}
          <ArrowForwardIcon style={{ marginLeft: 12 }} />
        </Button>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  checkoutButton: {
    cursor: 'pointer',
    marginTop: 24,
    backgroundColor: '#ff6f00',
    fontWeight: 600,
    fontSize: 23.327,
    letterSpacing: 0,
    color: '#fff',
    borderRadius: 24,
    fontFamily: 'Inter',
    '&:hover': {
      backgroundColor: '#ff6f00',
    },
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "24px",
    border: "none",
    fontFamily: 'Inter',
    backgroundColor: '#FE502E',
    color: '#fff'
  },
  goToHomeBtn: {
    width: '100%',
    fontFamily: 'Inter',
    height: '45px',
    borderColor: "#FE502E",
    color: "#FE502E"
  }
};
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface AboutUsAttributes {
  description: string;
  created_on: string;
  updated_on: string;
}

export interface AboutUs {
  data: AboutUsData;
}

export interface AboutUsData {
  id: string;
  type: string;
  attributes: AboutUsAttributes;
}

interface ErrorResponse {
  errors: Array<{ token: string }>;
}

interface AboutUsListResponse {
  data: AboutUsData;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  loader: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  aboutUs: AboutUsData | undefined;
  isUpdate: boolean;
  aboutUsId: string | number;
  description: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AboutUsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAboutUsListApiCallId: string = "";
  showAboutUsApiCallId: string = "";
  submitAboutUsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: "",
      loader: false,
      isAlert: false,
      alertMsg: "",
      alertType: configJSON.successMsg,
      isUpdate: false,
      aboutUs: undefined,
      aboutUsId: "",
      description: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        if(!responseJson?.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
        } else {
          this.parseErrors(responseJson, apiRequestCallId)
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (propsData) {
        this.handleShowAboutUs(propsData.aboutUsId);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    await this.getAboutUsList();
  };

  aboutUsApiCall = (apiCallData: APIPayloadType) => {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: AboutUs | AboutUsListResponse | ErrorResponse
  ) => {
    switch (apiRequestCallId) {
      case this.getAboutUsListApiCallId:
          this.handleGetAboutUsResponse(responseJson as AboutUsListResponse);
        break;
      case this.showAboutUsApiCallId:
        this.handleShowAboutUsResponse(responseJson as AboutUs);
        break;
      case this.submitAboutUsApiCallId:
        this.handleUpdateAboutUsResponse(responseJson as ErrorResponse);
        break;
    }
  };
  parseErrors = async (responseJson: {
    errors: {
      token: string;
    }[];
  }, apiCallId: string) => {
    if(apiCallId === this.submitAboutUsApiCallId) {
      this.handleUpdateAboutUsResponse(responseJson as ErrorResponse);
    } else if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  
  handleUpdateAboutUsResponse = async (response: ErrorResponse) => {
    if (
      response.errors &&
      response.errors.length > 0 &&
      Object.keys(response.errors[0])[0] === "token"
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Invalid token",
        alertType: configJSON.alertTypeError
      });
      this.navigationLoginScreen()
    } else {
      const alertMsg = configJSON.aboutUsUpdateSuccessMsg;
      const alertType: Color = configJSON.alertTypeSuccess;

      this.handleFieldReset();
      this.setState(
        {
          isAlert: true,
          alertMsg,
          alertType
        },
        () => {
          this.navigationAboutUs();
        }
      );
    }
  };

  handleShowAboutUsResponse = (response: AboutUs) => {
    const {
      data: {
        id,
        attributes: { description }
      }
    } = response;

    this.setState({
      loader: false,
      description: description,
      isUpdate: true,
      aboutUsId: id
    });
  };

  handleGetAboutUsResponse = (response: AboutUsListResponse) => {
    this.setState({
      aboutUs: response.data,
      loader: false
    });
  };

  handleDescriptionChange = (value: string) => {
    this.setState({
      description: value
    });
  };

  handleFieldReset = () => {
    this.setState({ description: "" });
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getBlankField = (description: string) => {
    if (!description) {
      return configJSON.descriptionText;
    }
    return null;
  };

  handleUpdateAboutUs = async () => {
    const { description } = this.state;
    const blankField = this.getBlankField(description);

    if (blankField) {
      this.setState({
        isAlert: true,
        alertMsg: `${blankField}${configJSON.errorMsgBlankField}`,
        alertType: configJSON.alertTypeError
      });
    } else {
      const body = {
        about: {
          description: this.state.description
        }
      };
      this.submitAboutUsApiCallId = this.aboutUsApiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.examplePutAPiMethod,
        endPoint: `${configJSON.getAboutUsBasicApiEndpoint}${
          this.state.aboutUsId
        }`,
        body: body
      });
    }
  };

  handleShowAboutUs = async (aboutUsId: string | number) => {
    this.setState({ loader: true });
    const endPoint = `${configJSON.getAboutUsBasicApiEndpoint}${aboutUsId}`;
    this.showAboutUsApiCallId = this.aboutUsApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  getAboutUsList = async () => {
    this.setState({ loader: true });
    const endPoint = configJSON.getAboutUsApiEndpoint;
    this.getAboutUsListApiCallId = this.aboutUsApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  navigationEditAboutUs = (aboutUsId: string | number) => {
    const editAboutUsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAddAboutUsMessage)
    );
    editAboutUsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    editAboutUsScreenMsg.addData(getName(MessageEnum.NavigationIdMessage), {
      aboutUsId: aboutUsId
    });
    this.send(editAboutUsScreenMsg);
  };

  navigationAboutUs = () => {
    const aboutUsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAboutUsMessage)
    );
    aboutUsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(aboutUsScreenMsg);
  };
  // Customizable Area End
}

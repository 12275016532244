import React from "react";
// Customizable Area Start
import {
    Button,
    Typography,
    withStyles
} from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import { OvalBlue, YellowCircles } from "./assets";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";
import CourseSpecificationWeb from "./CourseSpecification.web";
import RelatedCourseWeb from "./RelatedCourse.web";
import CourseContentWeb from "./CourseContent.web";
import FooterWeb from "./Footer.web";
import CoursesSlider from "./CoursesSlider.web";


export class CourseDetails extends CatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            //Merge Engine DefaultContainer
            <div>
                <div>
                    <div>
                        {/* <HeaderWeb navigation={undefined} id=""/> */}
                    </div>

                    <div className={classes.specificationContainer}>
                        <div>
                            <div style={{ maxWidth: '90%', margin: '0px auto' }}>
                                <Typography variant="h1" className={classes.header}>Solid state of Chemistry from
                                    CHSE Syllabus</Typography>
                                <Typography variant="body1" className={classes.descriptText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ...</Typography>
                                <div className={classes.courseSpecification}>
                                    <div><Button
                                        variant="contained"
                                        className={classes.buttonMeth}>
                                        MATHEMATICS
                                    </Button></div>
                                    <div style={{ color: 'white', fontSize: '20px' }}>Created by <span style={{ color: '#FCB910' }}>CHSH Express</span></div>
                                    <div className={classes.textWithIcon}>
                                        <div>
                                            <StarIcon style={{ color: '#FFD735', marginRight: '20px' }} />
                                        </div>
                                        <div>
                                            <span style={{ fontSize: '20px', color: 'white' }}>4.8</span>
                                        </div>
                                    </div>
                                    <div className={classes.textWithIcon}><AccessAlarmOutlinedIcon style={{ color: 'white', marginRight: '20px' }} /><span style={{ color: 'white', fontSize: '20px' }}>72 Hours</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={classes.contentStyle}>
                        <div >
                            <img src={OvalBlue} className={classes.blueImage} />
                            <CourseContentWeb navigation={undefined} id="" />
                        </div>
                        <img src={YellowCircles} className={classes.yellowImage} />
                        <div style={{ position: 'absolute', top: '160px', right: '100px' }}>
                            <div>
                                <CourseSpecificationWeb navigation={undefined} id="" />
                            </div>
                            <div style={{ marginTop: '20px', zIndex: 2 }}>
                                <RelatedCourseWeb navigation={undefined} id="" />
                            </div>
                        </div>
                    </div>

                </div>


                <div style={{ marginTop: '1000px' }}>
                    <CoursesSlider navigation={undefined} id=""/>
                    <FooterWeb {...this.props}/>
                </div>

            </div>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
    buttonMeth: {
        background: '#FF5C01',
        color: 'white',
        fontSize: '17px',
        fontWeight: 'bold',
        borderRadius: '30px',
        padding: '10px 20px'
    },
    courseSpecification: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '60vw',
        paddingBottom: '27px'
    },
    specificationContainer: {
        backgroundImage: "linear-gradient(136deg, #4B8D9C 0%, #194D59 98.15%)",
    },
    header: {
        color: 'white',
        fontSize: '58px',
        fontFamily: 'ProximaNova',
        fontWeight: 'bolder',
        padding: '20px 0px',
        maxWidth: '60vw'
    },
    descriptText: {
        color: 'white',
        fontFamily: "ProximaNova",
        fontSize: '26px',
        maxWidth: '60vw',
        marginBottom: '20px'
    },
    textWithIcon: {
        display: 'flex',
        alignItems: 'center'
    },
    contentStyle: {
        maxWidth: '90%',
        margin: '0px auto',
        display: 'flex',
        justifyContent: 'space-between',
    },
    blueImage: {
        width: '859px',
        height: '859px',
        position: 'absolute',
        left: '0px',
        zIndex: '-2',
        top:'680px'
    },
    yellowImage: {
        width: '1078px',
        height: '1078px',
        position: 'absolute',
        right: '0px',
        top: '750px',
        zIndex: '-3',
    }

});


export default withStyles(styles)((CourseDetails));
// Customizable Area End

// Customizable Area Start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Theme, Box ,Container, Grid, Typography, IconButton} from '@material-ui/core';
import {
  chseExpressLogo,
  googlePlayIcon,
  facebookIcon,
  addressIcon,
  phoneIcon,
  emailIcon,
  instagramIcon,
  youtubeIcon,
  chseExpressLogo2,
} from '../../blocks/landingpage/src/assets';
import FooterController,{ Props } from './FooterController.web';

const styles = (theme: Theme) => ({
  footer: {
    padding: theme.spacing(6, 3),
    backgroundColor: '#ECECEC',
    fontFamily: 'Inter',
  },
  logo: {
    height: 'auto',
    cursor: "pointer"
  },
  icon: {
    marginRight: theme.spacing(2),
    verticalAlign: 'middle',
    cursor: "pointer"
  },
  button: {
    marginTop: theme.spacing(2),
    cursor: "pointer"
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    cursor: "pointer"
  },
  footerHeading: {
    fontFamily: 'Inter',
    color: '#212121',
    fontWeight: 600,
    fontSize: 25.6,
    lineHeight: '61px',
    letterSpacing: '-0.03px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '40px',
    },
  },
  footersubHeading: {
    cursor: "pointer",
    color: '#212121',
    fontFamily: 'Inter',
    fontSize: '22.63px',
    fontWeight: 400,
    letterSpacing: '-0.027744004502892494px',
    textAlign: 'left' as const,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column' as const,
      alignItems: 'flex-start',
    },
  },
  addressContainer: {
    display: 'flex',
    alignItems: 'start',
    minHeight: 100,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
    },
  },
  topContainer: {
    height: 90,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  logosContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '48%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  googlePlayIcon: {
    width: 257,
    height: 77,
    [theme.breakpoints.down('xs')]: {
      width: 200,
      height: 'auto',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    width: 269,
    height: 98,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto',
      justifyContent: 'center',
    },
  },
});
// Customizable Area End

class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { email, address, mobile } = this.state;
    return (
      <footer className={classes.footer}>
        <Container maxWidth="xl">
          <Grid container spacing={4} justify="space-between" alignItems="center" className={classes.topContainer}>
            <Grid item className={classes.logosContainer}>
              <Box className={classes.logoContainer}>
                <img src={chseExpressLogo2} alt="CHSE Express Logo" className={classes.logo} style={{ backgroundColor: '00FFFFFF', width: 100, height: 98 }} />
                <img src={chseExpressLogo} alt="CHSE Express Logo" className={classes.logo} style={{ backgroundColor: '00FFFFFF', width: 160, height: 94 }} />
              </Box>
              <Box>
                <img src={googlePlayIcon} alt="Get it on Google Play" className={classes.googlePlayIcon} />
              </Box>
            </Grid>
            <Grid item className={classes.socialIcons}>
              <IconButton color="inherit">
                <img src={facebookIcon} height="50px" alt="Facebook" />
              </IconButton>
              <IconButton color="inherit">
                <img src={instagramIcon} height="50px" alt="Instagram" />
              </IconButton>
              <IconButton color="inherit">
                <img src={youtubeIcon} height="50px" alt="YouTube" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={8} style={{ padding: '50px 20px' }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" className={classes.footerHeading} gutterBottom>
                Contact
              </Typography>
              <Box display="flex" flexDirection="column" gridGap={24}>
                <Box className={classes.addressContainer}>
                  <img src={addressIcon} alt="address" className={classes.icon} />
                  <Typography variant="body2" className={classes.footersubHeading} style={{
                    lineHeight: '32px'
                  }}>
                    {address}
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.footersubHeading}>
                  <img src={phoneIcon} alt="phone" className={classes.icon} /> {mobile}
                </Typography>
                <Typography variant="body2" className={classes.footersubHeading}  onClick={() => this.handleEmailClick(email)}>
                  <img src={emailIcon} alt="email" className={classes.icon} /> {email}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" className={classes.footerHeading} gutterBottom>
                Support
              </Typography>
              <Typography variant="body2" className={classes.footersubHeading}>
                Frequently Asked Questions
              </Typography>
              <br />
              <Typography variant="body2" className={classes.footersubHeading}>
                Help Center
              </Typography>
              <br />
              <Typography variant="body2" className={classes.footersubHeading}>
                Refund Policy
              </Typography>
              <br />
              <Typography variant="body2" className={classes.footersubHeading}>
                Customer Support
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" className={classes.footerHeading} gutterBottom>
                Information
              </Typography>
              <Typography variant="body2" className={classes.footersubHeading}>
                Courses
              </Typography>
              <br />
              <Typography variant="body2" className={classes.footersubHeading} onClick={() => this.handleEmailClick(email)}>
                Write to Us
              </Typography>
              <br />
              <Typography variant="body2" className={classes.footersubHeading}>
                Privacy
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </footer>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(Footer);
// Customizable Area End

import { Route, Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach((param) => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal,
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
};

// ProtectedRoute Component
const ProtectedRoute = ({
  component: Component,
  allowedRoles,
  userRole,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAdmin = userRole === "Super Admin",
          isUnauth =
            !userRole || !["Super Admin", "Student"].includes(userRole),
          location = rest.path;
        const unauthLogic =
            isUnauth &&
            location !== "/" &&
            !allowedRoles.includes("") &&
            (allowedRoles.includes("Super Admin") ||
              allowedRoles.includes("Student")),
          adminLogic =
            isAdmin &&
            location !== "/Dashboard" &&
            (allowedRoles.includes("Student") || !allowedRoles.length),
          studentLogic =
            !isAdmin &&
            location !== "/" &&
            allowedRoles.includes("Super Admin");
        if (unauthLogic) {
          return <Redirect to="/" />;
        }
        if (adminLogic) {
          return <Redirect to="/Dashboard" />;
        }
        if (studentLogic) {
          return <Redirect to="/" />;
        }
        return (
          <Wrapper
            element={<Component />}
            {...props}
            routeMap={rest.routeMap}
          />
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  userRole: PropTypes.string.isRequired,
  routeMap: PropTypes.object,
};

// WebRoutesGenerator Component
const WebRoutesGenerator = ({ routeMap, userRole }) => {
  const generateUniqueId = () => {
    return (
      "id-" +
      Math.random()
        .toString(36)
        .slice(2, 11)
    );
  };
  return Object.keys(routeMap).map((route) => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    const key = generateUniqueId();
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={key}
          path={currentRoute.path}
          component={(props) => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <ProtectedRoute
          key={key}
          path={currentRoute.path}
          exact={currentRoute.exact}
          component={Component}
          allowedRoles={currentRoute.allowedRoles || []} // Role-based access control
          userRole={userRole}
          routeMap={routeMap}
        />
      );
    }
  });
};
WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired, // User role should be passed down
};

export default WebRoutesGenerator;

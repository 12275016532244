// Customizable Area Start
import React from "react";
import {

  Container
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import "./Stepper.css";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    step: {
      "& .MuiStepLabel-label.MuiStepLabel-active": {
        fontWeight: 'bolder'
      },
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: '#B8D0F1',
        border: '3px solid blue',
        borderRadius: '50%'
      },
      '& .MuiStepConnector-line': {
        borderColor: 'gray',
      },
      '& .MuiStepConnector-root': {
        paddingLeft: '8px', // Adjust the left padding to center the line
        paddingRight: '8px', // Adjust the right padding to center the line
        margin: '0 auto', // Center the line horizontally
        paddingTop: '30px'
      },
      "& .MuiStepIcon-root": {
        color: '#B8D0F1',
        zIndex: '11'
      },

    },
    stepHights: {
      "& .MuiStepper-alternativeLabel": {
        width: '830px',
        marginLeft: '-75px'
      }
    },
    roostyle: {
      color: '#B8D0F1',
      border: '2px solid blue',
      borderStyle: 'dotted',
      borderRadius: '50%',
      fontSize: '50px',
    },

    completedstate: {
      color: '#B8D0F1',
      border: '3px solid blue',
      borderRadius: '50%'
    },
    activeLebal: {
      fontWeight: "bolder"
    },
    stepperContainer: {

      marginBottom: '20px'
    }

  }),
);


// Customizable Area End



export const Steps = (props: any) => {

  const classes = useStyles();


  const steps = [
    "Basic Details",
    "Mobile number",
    "Email Id",
];


  return (

    <Container maxWidth="lg">
      {props.activeStep > 2 ? '' :
        <div>

          <div className={classes.stepperContainer}>
            <Stepper alternativeLabel activeStep={props.activeStep} className={classes.stepHights}>
              {steps.map((label) => (
                <Step key={label} className={classes.step} >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.roostyle,
                        completed: classes.completedstate,
                        active: classes.activeLebal
                      }
                    }}
                  >
                    
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>}
    </Container >
  );
};


import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { initialValues } from "./CouponcodegeneratorController.web";
import { apiCall } from "./VouchercodegeneratorController";
import { parseErrors } from "./FlatDiscountController.web";

const fetch = require("node-fetch");
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Response {
  data: Attributes;
  meta: { message: string };
  message?: string;
}

interface Attributes {
  id: number;
  referral_limit: string;
  referrer_amount: string;
  referrer_friend_amount: string;
  status: boolean;
  term_and_condition: string;
  created_at: Date;
  updated_at: Date;
  referral_validity_day: string;
}

interface FormValues {
  referral_limit: string;
  referrer_amount: string;
  referrer_friend_amount: string;
  term_and_condition: string | number;
  referral_validity_day: string;
}

interface S {
  // Customizable Area Start
  userToken: any;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  formDataInitial?: FormValues;
  formData: FormValues;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ReferralController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getReferralApiCallId: string = "";
  updateReferralApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      ...initialValues,
      formData: {
        referral_limit: "",
        referrer_friend_amount: "",
        referrer_amount: "",
        term_and_condition: "",
        referral_validity_day: ""
      },
      formDataInitial: undefined
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getReferral();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getReferralApiCallId:
            this.handleGetReferralResponse(responseJson);
            break;
          case this.updateReferralApiCallId:
            this.handleUpdateReferralResponse(responseJson);
            break;
        }
      } else {
        parseErrors(
          responseJson,
          this.parseApiErrorResponse,
          this.send,
          this.props
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getReferral() {
    this.setState({ isLoading: true });
    this.getReferralApiCallId = apiCall({
      endPoint: configJSON.getReferralApiEndpoint,
      method: configJSON.apiMethodTypeCouponGet,
      token: this.state.userToken,
      contentType: configJSON.validationApiContentType
    });
  }

  oncloseAlert() {
    this.setState({
      isAlert: false
    });
  }

  handleGetReferralResponse(responseJson: Response) {
    this.setValuesResponse(responseJson);
  }

  setValuesResponse(responseJson: Response, showMessage = false) {
    const { data, message } = responseJson;

    const formData = {
      referral_limit: data.referral_limit,
      referral_validity_day: data.referral_validity_day,
      referrer_amount: data.referrer_amount,
      referrer_friend_amount: data.referrer_friend_amount,
      term_and_condition: data.term_and_condition
    };

    this.setState({
      formData,
      formDataInitial: formData,
      isLoading: false,
      ...(showMessage
        ? ({ isAlert: true, alertMsg: message, alertType: "success" } as any)
        : {})
    });
  }

  updateReferral() {
    const {
      referral_limit,
      referral_validity_day,
      referrer_amount,
      referrer_friend_amount,
      term_and_condition
    } = this.state.formData;

    if (
      !referral_limit ||
      !referral_validity_day ||
      !referrer_amount ||
      !referrer_friend_amount
    ) {
      this.setState({
        isAlert: true,
        alertMsg: `Please fill in all fields`,
        alertType: "error"
      });
      return;
    }

    const body = {
      data: {
        referral_limit,
        referral_validity_day,
        referrer_amount,
        referrer_friend_amount,
        term_and_condition
      }
    };

    this.setState({ isLoading: true });
    this.updateReferralApiCallId = apiCall({
      endPoint: configJSON.updateReferralApiEndpoint,
      method: configJSON.putCouponApiMethod,
      token: this.state.userToken,
      body,
      contentType: configJSON.validationApiContentType
    });
  }

  handleUpdateReferralResponse(responseJson: Response) {
    this.setValuesResponse(responseJson, true);
  }

  handleInputChange(e: any, isNumber = false, isDecimal = false) {
    let { name, value } = e.target;
    if (isNumber) {
      const regex = isDecimal
        ? new RegExp(/^(?=.{0,10}$)[0-9]*\.?[0-9]*$/)
        : new RegExp("^\\d+$");
      if (!regex.test(value)) {
        return;
      }
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  }

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

import React from "react";

// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("../../blocks/landingpage/src/config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface FooterResponse {
  data: FooterData;
  errors: any[];
  message: string;
}

export interface FooterData {
  address: string;
  full_phone_number: string;
  email: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  address: string;
  mobile: string;
  email: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FooterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFooterListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      isLoading: false,
      userToken: "",
      address: "",
      mobile: "",
      email: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getFooterData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getFooterListApiCallId:
          this.handleGetFooterResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleGetFooterResponse(resJson: FooterResponse) {
    if (
      resJson.errors &&
      resJson.errors.length > 0 &&
      resJson.errors[0].token === configJSON.tokenExpiredMessage
    ) {
      this.navigationLoginScreen();
    } else {
      this.setState({
        email: resJson.data.email,
        address: resJson.data.address,
        mobile: resJson.data.full_phone_number.slice(2),
        isLoading: false
      });
    }
  }

  getFooterData() {
    this.setState({ isLoading: true });
    this.getFooterListApiCallId = this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getFooterInfoApiEndPoint
    });
  }

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  handleEmailClick = (email:string) => {
    const url = `mailto:${email}`;
    const a = document.createElement("a");
    a.href = url;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); 
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

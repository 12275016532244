import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Color } from "@material-ui/lab/Alert";
import { getStorageData } from "framework/src/Utilities";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

export interface Meta {
  page: string;
  total_email_notification_template: number;
  message: string;
}

interface NotificationsListResponse extends ErrorResponse {
  data: NotificationsData[];
  meta: Meta;
}

export interface Attributes {
  id: number;
  notificationId: string;
  created_at: Date;
  updated_at: Date;
  email_notification_template_type: string;
  contents: string;
  headers: string;
  functions: string;
  status: boolean;
}

interface ResponseSubmit extends ErrorResponse {
  data: NotificationsData;
  meta: {
    message: string;
  };
}

export interface NotificationsData {
  id: string;
  type: string;
  attributes: Attributes;
}
interface ErrorResponse {
  errors: Array<{ token: string }>;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  notificationsList: NotificationsData[];
  isLoading: boolean;
  notificationId: string;
  token: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  headers: string;
  headersError: string;
  functions: string;
  functionsError: string;
  contents: string;
  contentsError: string;
  perPage: number;
  pageCount: number;
  currentPageCount: number;
  email_notification_template_type: string;
  email_notification_template_typeError: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmailNotificationsApiCallId: string = "";
  toggleStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationIdMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      perPage: elementsPerPage,
      pageCount: 0,
      currentPageCount: 1,
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      notificationsList: [],
      isLoading: true,
      contentsError: "",
      token: "",
      headers: "",
      headersError: "",
      functions: "",
      functionsError: "",
      notificationId: "",
      contents: "",
      email_notification_template_type: "",
      email_notification_template_typeError: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId,responseJson)
      } else {
        this.setState({ isLoading : false})
        this.parseErrors(responseJson);
      }
    } 
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const token = await getStorageData("loginToken");
    this.setState({ token: token });
    this.handleGetEmailNotifications(
      this.state.currentPageCount,
      this.state.perPage
    );
  }

  parseErrors = async (responseJson: ErrorResponse) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson:
      | NotificationsListResponse
      | ErrorResponse
      | ResponseSubmit
  ) => {
    switch (apiRequestCallId) {
      case this.getEmailNotificationsApiCallId:
        this.handleGetEmailNotificationsResponse(
          responseJson as NotificationsListResponse
        );
        break;
      case this.toggleStatusApiCallId:
        this.handleToggleStatusResponse(responseJson as ResponseSubmit);
        break;
    }
  };

  handleApiRequest(apiCallData: APIPayloadType) {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  }

  handleGetEmailNotifications = (pageNo: number, perPage: number) => {
    this.setState({ isLoading: true });
    this.getEmailNotificationsApiCallId = this.handleApiRequest({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint:
        configJSON.emailNotificationsEndpoint +
        `?page=${pageNo}&per_page=${perPage}`
    });
  };

  handleGetEmailNotificationsResponse = (response: NotificationsListResponse) => {
    if (response && response.data) {
      const pageCount = Math.ceil(
        response.meta.total_email_notification_template / this.state.perPage
      );
      this.setState({
        isLoading: false,
        notificationsList: response.data,
        pageCount: pageCount
      });
    } else {
      this.setState({
        isLoading: false,
        notificationsList: [],
        pageCount: 0
      });
    }
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  onCourseChaptersPageChange = (event: any, value: number) => {
    const newPageCount = Number(value);
    this.setState({ currentPageCount: newPageCount }, () => {
      this.handleGetEmailNotifications(newPageCount, this.state.perPage);
    });
  };

  handleToggleStatus(id: string, status: boolean, index: number) {
    let clone = [...this.state.notificationsList];
    clone[index].attributes.status = !status;

    this.setState({
      notificationsList: clone
    });

    this.toggleStatusApiCallId = this.handleApiRequest({
      method: configJSON.putApiMethodType,
      endPoint: `${configJSON.emailNotificationsEndpoint}/${id}`,
      body: { data : { status: !status } },
      contentType: configJSON.exampleApiContentType,
      token: this.state.token
    });
  }

  handleToggleStatusResponse = (responseJson: ResponseSubmit) => {
    if (responseJson.meta.message === configJSON.updateToggleMsg) {
      this.setState({
        isAlert: true,
        alertMsg: responseJson.meta.message,
        alertType: "success"
      });
      this.handleGetEmailNotifications(
        this.state.currentPageCount,
        this.state.perPage
      );
    }
  };

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  navigationEditEmailNotifications = (emailNotificationId: string | number) => {
    const editEmailNotificationsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAddEmailNotificationsMessage)
    );
    editEmailNotificationsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    editEmailNotificationsScreenMsg.addData(
      getName(MessageEnum.NavigationIdMessage),
      {
        notificationId: emailNotificationId
      }
    );
    this.send(editEmailNotificationsScreenMsg);
  };
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import "./categoryAdmin.css";
import "./table.css";
import AdminLayout from "./AdminLayout.web";
import { Modal } from '@material-ui/core';
import Table from "./Table.web";
import EditCourseComp from "./EditCourseComp.web";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";


export default class SubCategoryAdmin extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomRows = (row: any) => {
    return (
      <>
        <td>
              <img
                src={row.attributes.mobile_icon}
                alt="App Icon"
                width="30"
                height="30"
              />
            </td>
            <td>
              <img
                src={row.attributes.web_icon}
                alt="Web Icon"
                width="30"
                height="30"
              />
            </td>
            <td>{row.attributes.name}</td>
            <td>{row.attributes.course_category.name}</td>
            <td>{row.attributes.total_courses || 550}</td>
            <td>{row.attributes.total_subjects || 22000}</td>
      </>
    );
  };
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <AdminLayout
        navigation={undefined}
        id={""}
        handleExport={this.exportCategory}
        addButtonWidth={"190px"}
        addPath={"AddSubCategory"}
        title={"Sub Category"}
        display={"flex"}
      >
        <Table
          tableData={this.state.subCategoryData}
          navigation={undefined}
          id={""}
          tableHeaders={[
            "App Icon",
            "Web Icon",
            "Sub Category",
            "Category",
            "Total Courses",
            "Total Students",
          ]}
          customRows={this.renderCustomRows}
          tableFunction={this.getSubCategoryData}
          editPath={"AddSubCategory"}
          deleteCategory={this.delCategory}
          editCategory={this.handleEdit}
        />
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          className="modal"
        >
          <EditCourseComp
            navigation={undefined}
            id={""}
            buttonText="Update"
            bodyStyle={"bodyStyle"}
            topStyle={"topStyle"}
            bodyContainerStyle={"bodyContainerStyle"}
            modalHeading="Add Category"
            topItemStyle={"topItemStyle"}
            subCategory="SubCategory"
          />
        </Modal>
      </AdminLayout>
    );
  }
}

// Customizable Area Start
// Customizable Area End

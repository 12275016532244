import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
 styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { facebookLogo, twitterLogo, instagramLogo,gradient,bgTitle,headerTitle,mail,wishlist,chseLogo } from "./assets";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import { PiPen } from "react-icons/pi";
import {NotificationsData} from "./EmailNotificationsController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import EmailNotificationsController, {
    Props,
  configJSON,
} from "./EmailNotificationsController";

export default class EmailNotifications extends EmailNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: NotificationsData, index: number) => {
    return (
      <>
        <tr data-test-id="notification-row">
          <td style={{ textAlign: "left", width: 25 }}>{item.id}</td>
          <td style={{ textAlign: "left", width: "500px", lineBreak: "auto" }}>
            {item.attributes.headers}
          </td>
          <td style={{ textAlign: "left", width: 100 }}>
            <StatusToggle className="switch">
              <input
                type="checkbox"
                data-test-id="statusTestId"
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
                checked={item.attributes.status}
              />
              <span className="slider round" />
            </StatusToggle>
          </td>
          <td
            style={{
              borderRadius: "0px 10px 10px 0px",
              textAlign: "center",
              width: 25
            }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.navigationEditEmailNotifications(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderEmailImages() {
    return <>
      <img src={facebookLogo} alt="Facebook Logo" hidden={true} />
      <img src={twitterLogo} alt="Twitter Logo" hidden={true} />
      <img src={instagramLogo} alt="Instagram Logo" hidden={true} />
      <img src={gradient} alt="Gradient" hidden={true} />
      <img src={bgTitle} alt="Background Title" hidden={true} />
      <img src={headerTitle} alt="Header Title" hidden={true} />
      <img src={mail} alt="Mail" hidden={true} />
      <img src={wishlist} alt="Wishlist" hidden={true} />
      <img src={chseLogo} alt="CHSE Logo" hidden={true} />
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
      isLoading={this.state.isLoading}
      newUI
      isAdd
      title="Email Notifications"
      data-test-id="mainLayoutEvent"
    >
      {this.renderEmailImages()}
      <NotificationAlertMessage
        alertType={this.state.alertType}
        isOpen={this.state.isAlert}
        msg={this.state.alertMsg}
        onClose={this.onCloseAlert}
        dataTestId="alertTestId"
      />
      <main
        className="table_data"
        style={{ background: "rgb(252, 252, 252)" }}
      >
       <Box>
        <TableStyle>
          <thead>
            <tr>
              {[
                "ID",
                "Headings",
                "Status",
                "Action"
              ].map((header, index) => {
                return (
                  <th>
                    <Box
                      className="th_box"
                      style={
                        index === 3
                          ? { justifyContent: "center" }
                          : { justifyContent: "left" }
                      }
                    >
                      <Typography className="title_th">{header}</Typography>
                    </Box>
                  </th>
                );
              })}
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody>
            {this.state.notificationsList &&
              this.state.notificationsList.length > 0 &&
              this.state.notificationsList.map((notification, index) =>
                this.renderTableBody(notification, index)
              )}
          </TableBody>
        </TableStyle>
      </Box>
      </main>
    </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "8px"
  },
  iconSize: {
    fontSize: "13px"
  }
};
const PaginationBox = styled(Box)({
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff"
  },
  "& .viewall_txt": {
    fontSize: "14px",
    cursor: "pointer",
    color: "#206575"
  }
});
const StatusToggle = styled("label")({
  "&.switch": {
    width: "36px",
    height: "19px",
    position: "relative",
    display: "inline-block",
    marginTop: "5px",
    "& input": {
      width: 0,
      height: 0,
      opacity: 0
    }
  },
  "& .slider": {
    position: "absolute",
    cursor: "pointer",
    backgroundColor: "#ccc",
    WebkitTransition: ".4s",
    top: 0,
    left: 0,
    transition: ".4s",
    borderRadius: "20px",
    right: 0,
    bottom: "-1px"
  },
  "& .slider:before": {
    width: "18px",
    left: "1px",
    bottom: "1px",
    backgroundColor: "white",
    WebkitTransition: ".4s",
    position: "absolute",
    content: '""',
    height: "18px",
    transition: ".4s",
    borderRadius: "50%"
  },
  "& input:checked + .slider": {
    backgroundColor: "#708328"
  },
  "& input:checked + .slider:before": {
    left: "-10px"
  }
});
// Customizable Area End

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Img = require("../assets/Img.png");
export const dropdown = require("../assets/dropdown.png");
export const background = require("../assets/background.png");
export const Chse_logo_PNG = require("../assets/Chse_logo_PNG.png");
export const Google = require("../assets/Google.png");
export const Success = require("../assets/success.png");
export const Logotext = require("../assets/Logotext.png");
export const IndianFlag = require("../assets/india.svg");
export const celender = require("../assets/celender.svg")
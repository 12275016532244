import React from "react";

// Customizable Area Start
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { Box, Typography, styled, Modal } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { Pagination } from "@material-ui/lab";
import {
  CheckBtn,
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";
const drawerWidth = 250;
const colNames = [
  "S.No",
  "Categories",
  "Sub Categories",
  "Bundle Course Name",
  "Price",
  "Created On",
  "Total Students",
  "Status",
  "Action"
];

// Customizable Area End

import BundleCourseController, { Props } from "./BundleCourseController";

export default class BundleCourse extends BundleCourseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  renderTableBody = (item: any, index: any) => {
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                data-test-id="rowCheckbox"
                type="checkbox"
                checked={this.state.isChecked[index]}
                onChange={() => this.handleRowCheckboxChange(index)}
                style={webStyles.input}
              />
            </Box>
          </td>
          <td>{item.id}</td>
          <td>
            <div style={webStyles.categoryImgName}>
              <img
                src={item.attributes.bundle_course_thumbnail}
                alt="img"
                style={{
                  width: 41,
                  height: 41,
                  borderRadius: 10,
                  margin: "auto 0"
                }}
              />
              <span style={{ margin: "auto 0" }}>
                {item.attributes.course_category.name}
              </span>
            </div>
          </td>
          <td>{item.attributes.bundle_name}</td>
          <td>{item.attributes.bundle_name}</td>
          <td>{item.attributes.first_year_price}</td>
          <td>{item.attributes.created_on}</td>
          <td>{item.attributes.total_student || 0}</td>
          <td>
            <CheckBtn className="switch">
              <input
                type="checkbox"
                data-test-id="statusTestId"
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
                checked={item.attributes.status}
              />
              <span className="slider round" />
            </CheckBtn>
          </td>
          <td style={{ borderRadius: "0px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleEditPropsBundleCourse(item.id)}
              />
              <FaRegTrashAlt
                data-test-id={"deleteModalTest"}
                className="desc_edit_icon"
                color={"black"}
                size={17}
                onClick={() => this.handleOpenModal(item.id)}
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivide} />
      </>
    );
  };

  getSortTestId = (header: string) => {
    const headerMap: { [key: string]: string } = {
      "Bundle Course Name": "sort_name",
      "S.No": "sort_id",
      Status: "sort_status"
    };

    return headerMap[header] || "sort";
  };

  renderTable = () => {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box style={webStyles.checkboxMain}>
                  <input
                    type="checkbox"
                    test-id="headerCheckbox"
                    checked={this.state.isHeaderChecked}
                    onChange={this.handleHeaderCheckboxChange}
                    style={webStyles.input}
                  />
                </Box>
              </th>
              {colNames.map((header, index) => {
                let renderIcon = false;
                if (["S.No", "Bundle Course Name", "Status"].includes(header)) {
                  renderIcon = true;
                }
                return renderTableRecord(
                  () => isCurrentSort(header),
                  header,
                  renderIcon,
                  index,
                  () => this.handleSort(header),
                  this.getSortTestId(header),
                  index !== 1 ? "center" : "left"
                );
              })}
            </tr>
          </thead>
          <span style={webStyles.lineDivide} />
          <TableBody>
            {this.state.bundleCourseData.map((item: any, index: any) =>
              this.renderTableBody(item, index)
            )}
          </TableBody>
        </TableStyle>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.totalPages}
            shape="rounded"
            boundaryCount={1}
            page={this.state.pageNo}
            siblingCount={0}
            onChange={this.handlePageChange}
          />
        </PaginationBox>
      </Box>
    );
  };

  renderDeleteModal = () => {
    return (
      <>
        <CourseDeleteModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="deleteModalId"
          open={this.state.openDeleteModal}
          onClose={this.handleCloseDeleteModal}
        >
          <StyledModalComponent>
            <DeleteTitleText component={"p"}>
              Do you want to delete this Bundle Course?
            </DeleteTitleText>
            <StyledButtonBox>
              <DelButton
                data-test-id="closemodalid"
                onClick={() =>
                  this.handleDeleteBundleCourse(this.state.deleteId)
                }
              >
                Delete
              </DelButton>
              <StyledCancelBtn onClick={() => this.handleCloseDeleteModal()}>
                Cancel
              </StyledCancelBtn>
            </StyledButtonBox>
          </StyledModalComponent>
        </CourseDeleteModal>
      </>
    );
  };

  // Customizable Area End

  render() {
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleBundleCourseSearch(e)}
        isLoading={this.state.isLoading}
        title={"Bundle Courses"}
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.addBundleCourseNavigate(),
            text: "Create New Bundle",
            testId: "exportButtonId"
          }
        ]}
        isStudent={false}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
          dataTestId="oncloseAlert"
        />
        {this.renderDeleteModal()}
        <main className="table_data">{this.renderTable()}</main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PaginationBox = styled(Box)({
  "& .viewall_txt": {
    color: "#206575",
    fontSize: "0.75rem",
    cursor: "pointer"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff"
  }
});

const webStyles = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  categoryImgName: {
    display: "inline-flex",
    gap: "1rem",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  checkboxMain: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  lineDivide: {
    height: "20px",
    display: "inline-block"
  },
  tableDivide: {
    height: "10px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const StyledModalComponent = styled(Box)({
  outline: "none",
  width: "340px",
  height: "120px",
  boxShadow: "0px 0px 3px px transparent",
  padding: "10px 50px",
  borderRadius: "5px",
  backgroundColor: "#ffffff",
  flexDirection: "column",
  margin: "20px",
  display: "flex"
});
const CourseDeleteModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%"
});

const StyledButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
});

const StyledCancelBtn = styled("button")({
  width: "145px",
  height: "40px",
  border: "1px solid #206575",
  color: "#206575",
  backgroundColor: "white",
  fontSize: "0.875rem"
});

const DelButton = styled("button")({
  width: "145px",
  height: "45px",
  backgroundColor: "#DA1831",
  border: "0",
  color: "white",
  fontSize: "0.875rem",
  fontWeight: 500
});

const DeleteTitleText = styled(Box)({
  width: "100%",
  height: "37px",
  fontWeight: 600,
  fontSize: "0.875rem"
});
// Customizable Area End

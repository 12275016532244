import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ReactNode } from "react";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  children?: ReactNode;
  display?: any;
  title?: string;
  headingText?: string;
  tableData?: any;
  handleExport?: any;
  handleAdd?: any;
  tableHeaders?: any;
  customRows?: any;
  buttonText?: string;
  tableFunction?: any;
  addButtonWidth?: any;
  addPath?: any;
  editPath?: any;
  row?: any;
  bodyStyle?: any;
  topStyle?: any;
  bodyContainerStyle?: any;
  modalHeading?: string;
  topItemStyle?: any;
  addFunction?: any;
  subCategory?: string;
  childCategory?: any;
  handleSearch?: any;
  searchValue?: any;
  editResetFunction?: any;
  addResetFunction?: any;
  editFunction?: any;
  deleteCategory?: any;
  editCategory?: any;
  handleBack?: any; 
  editActive?: any;
  fromDate?: any;
  toDate?: any;
  handleFromDate?: any;
  handleToDate?: any;
  backPath?: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  categories: any;
  tabs: any[];
  activeTab: number;
  courses: any;
  hoveredIndex: number | null;
  tabView: boolean;
  listView: boolean;
  userToken: any;
  apiHeader: any;
  loading: boolean;
  pageNo: any;
  per_page: any;
  items?: any;
  expanded?: any;
  currentPage: any;
  selectedTab: any;
  openMenu: boolean;
  categoryData: any;
  categoryName: string;
  icon_cat: any;
  web_icon: any;
  mobile_icon: any;
  courseStatus: boolean;
  cat_per_page: any;
  icon_cat_name: string;
  web_icon_name: string;
  mobile_icon_name: string;
  subCategoryData: any;
  childCategoryData: any;
  modalOpen: boolean;
  row: any;
  isChecked: boolean[];
  isHeaderChecked: boolean;
  tableLoader: boolean;
  rowDataError: any;
  edit: boolean;
  editPageData: any;
  subCategoryName: any;
  courseCategory: any;
  childCategoryColor: any;
  childCategoryName: any;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      items: [1, 2, 3, 4, 5, 6, 7, 8],
      expanded: [false, false, false],
      currentPage: 0,
      selectedTab: 2,
      categories: [
        { name: "Category", value: 0 },
        { name: "M1", value: 1 },
        { name: "Class 5", value: 2 },
        { name: "Class 6", value: 3 },
        { name: "Class 7", value: 4 },
        { name: "Class 8", value: 5 },
        { name: "Class 9", value: 6 },
        { name: "Class 10", value: 7 },
        { name: "Class 11", value: 8 },
        { name: "Class 12", value: 9 },
        { name: "Class 6TH", value: 10 },
      ],
      tabs: [
        { id: 1, name: "All Courses" },
        { id: 2, name: "Physics" },
        { id: 3, name: "Chemistry" },
        { id: 4, name: "Mathematics" },
        { id: 5, name: "Biology" },
      ],
      activeTab: 0,
      courses: [],
      hoveredIndex: -1,
      tabView: true,
      listView: false,
      userToken: "",
      apiHeader: {},
      loading: false,
      pageNo: 1,
      per_page: 10,
      openMenu: false,
      categoryData: [],
      categoryName: "",
      icon_cat: null,
      web_icon: null,
      mobile_icon: null,
      icon_cat_name: "",
      web_icon_name: "",
      mobile_icon_name: "",
      courseStatus: false,
      cat_per_page: 5,
      subCategoryData: [],
      childCategoryData: [],
      modalOpen: false,
      row: {},
      isChecked: [],
      isHeaderChecked: false,
      tableLoader: false,
      rowDataError: null,
      edit: false,
      editPageData: [],
      subCategoryName: "",
      courseCategory: "",
      childCategoryColor: "",
      childCategoryName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    window.localStorage.removeItem("editData");
    await this.getUserToken();
    this.getApiHeader();
    this.getCoursesData(this.state.pageNo, this.state.per_page);
    this.getCategoryData(this.state.pageNo, this.state.cat_per_page);
    this.getSubCategoryData(this.state.pageNo, this.state.cat_per_page);
    this.getChildCategoryData(this.state.pageNo, this.state.cat_per_page);
    await this.getEditPageData();
      // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const req = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorMsg = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (req) {
        case this.getProductApiCallId:
          this.handleGetProductResponse(res, errorMsg);
          break;
        case this.getCoursesApiCallId:
          this.handleGetCoursesListResponse(res, errorMsg);
          break;

        case this.getCategoryApiCallId:
          this.handleGetCategoryResponse(res, errorMsg);
          break;
        case this.addCategoryApiCallId:
          this.handleAddCategoryResponse(res, errorMsg);
          break;
        case this.editCategoryApiCallId:
          this.handleEditCategoryResponse(res, errorMsg);
          break;
        case this.delCategoryApiCallId:
          this.handleDelCategoryResponse(res, errorMsg);
          break;
        case this.exportCategoryApiCallId:
          this.handleExportCategoryResponse(res, errorMsg);
          break;

        
        case this.getSubCategoryApiCallId:
          this.handleGetSubCategoryResponse(res, errorMsg);
          break;
        case this.addSubCategoryApiCallId:
          this.handleAddSubCategoryResponse(res, errorMsg);
          break;
        case this.editSubCategoryApiCallId:
            this.handleEditSubCategoryResponse(res, errorMsg);
            break;
        case this.delSubCategoryApiCallId:
            this.handleDelSubCategoryResponse(res, errorMsg);
            break;
        case this.exportSubCategoryApiCallId:
            this.handleExportSubCategoryResponse(res, errorMsg);
            break;

        case this.getChildCategoryApiCallId:
          this.handleGetChildCategoryResponse(res, errorMsg);
          break;
        case this.addChildCategoryApiCallId:
          this.handleAddChildCategoryResponse(res, errorMsg);
          break;
        case this.editChildCategoryApiCallId:
            this.handleEditChildCategoryResponse(res, errorMsg);
            break;
        case this.delChildCategoryApiCallId:
            this.handleDelChildCategoryResponse(res, errorMsg);
            break;
        case this.exportChildCategoryApiCallId:
            this.handleExportChildCategoryResponse(res, errorMsg);
            break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCoursesApiCallId: any;

  getCategoryApiCallId: any;
  addCategoryApiCallId: any;
  delCategoryApiCallId: any;
  exportCategoryApiCallId: any;
  editCategoryApiCallId: any;

  getSubCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  editSubCategoryApiCallId: any;
  delSubCategoryApiCallId: any;
  exportSubCategoryApiCallId: any;

  getChildCategoryApiCallId: any;
  addChildCategoryApiCallId: any;
  editChildCategoryApiCallId: any;
  delChildCategoryApiCallId: any;
  exportChildCategoryApiCallId: any;

  getUserToken = async () => {
    const token = await getStorageData('loginToken');
    this.setState({ userToken: token });
  };

  getApiHeader = () => {
    if (this.state.userToken) {
      this.setState({
        apiHeader: {
          "Content-Type": configJSON.apiContentType,
          token: this.state.userToken,
        },
      });
    } else {
      this.setState({ apiHeader: {} });
    }
  };

  getEditPageData = async () => {
    const editPageDataString:any = await window.localStorage.getItem("editData");
    const editPageData = JSON.parse(editPageDataString);
    if(!editPageData) return;
    if(editPageData){
      this.setState({
        editPageData: [editPageData.edit, editPageData.rowData]
      })
    }
  };

  getCatStatus = () => {
    let status;
    if(this.state.courseStatus){
      status = "true"
    } else {
      status = "false"
    }
    return status;
  }

  handleGetProductResponse = (res: any, errorMsg: any) => {
    if (res && !res.errors && res.data) {
      this.setState({ arrayHolder: res.data });
      runEngine.debugLog("arrayHolder", this.state.arrayHolder);
    } else {
      console.log(errorMsg);
    }
  };

  handleGetCoursesListResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      this.setState({
        courses: [...this.state.courses, ...res?.data],
        loading: false,
      });
    } else {
      alert(errorMsg);
    }
  };

  handleGetCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      this.setState({
        tableLoader: false,
        categoryData: res?.data,
        isChecked: Array(this.state.categoryData ? this.state.categoryData.length : 0).fill(false)
      });
    } else {
      this.setState({
        rowDataError: errorMsg.message
      });
    }
  };

  handleAddCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      window.alert("Course category added successfully!");
    } else {
      alert(errorMsg);
    }
  };

  handleEditCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      alert("Category updated successfully");
    } else {
      alert(errorMsg);
    }
  };

  handleDelCategoryResponse = (res: any, errorMsg: any) => {
    let _res = res?.meta?.message;
    if (_res) {
      alert(_res);
      this.getCategoryData(this.state.pageNo, this.state.cat_per_page);
    } else {
      alert(errorMsg);
    }
  };

  handleExportCategoryResponse = (res: any, errorMsg: any) => {
    if (res) {
      alert("Exporting course categories data");
    } else {
      alert(errorMsg);
    }
  };


  handleGetSubCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      this.setState({
        tableLoader: false,
        subCategoryData: res?.data,
        isChecked: Array(this.state.subCategoryData ? this.state.subCategoryData.length : 0).fill(false)
      });
    } else {
        this.setState({
          rowDataError: errorMsg.message
        });
    }
  };

  handleAddSubCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      alert("Sub category added successfully!");
    } else {
      alert(errorMsg);
    }
  };

  handleEditSubCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      alert("Category updated successfully");
    } else {
      alert(errorMsg);
    }
  };

  handleDelSubCategoryResponse = (res: any, errorMsg: any) => {
    let _res = res?.meta?.message;
    if (_res) {
      alert(_res);
      this.getCategoryData(this.state.pageNo, this.state.cat_per_page);
    } else {
      alert(errorMsg);
    }
  };

  handleExportSubCategoryResponse = (res: any, errorMsg: any) => {
    if (res) {
      alert("Exporting course categories data");
    } else {
      alert(errorMsg);
    }
  };

  handleGetChildCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      this.setState({
        tableLoader: false,
        childCategoryData: res?.data,
        isChecked: Array(this.state.childCategoryData ? this.state.childCategoryData.length : 0).fill(false)
      });
    } else {
        this.setState({
          rowDataError: errorMsg.message
        });
    }
  };

  handleAddChildCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      alert("Course category added successfully!");
    } else {
      alert(errorMsg);
    }
  };

  handleEditChildCategoryResponse = (res: any, errorMsg: any) => {
    if (res?.data) {
      alert("Category updated successfully");
    } else {
      alert(errorMsg);
    }
  };

  handleDelChildCategoryResponse = (res: any, errorMsg: any) => {
    let _res = res?.meta?.message;
    if (_res) {
      alert(_res);
      this.getCategoryData(this.state.pageNo, this.state.cat_per_page);
    } else {
      alert(errorMsg);
    }
  };

  handleExportChildCategoryResponse = (res: any, errorMsg: any) => {
    if (res) {
      alert("Exporting course categories data");
    } else {
      alert(errorMsg);
    }
  };

  getCoursesData = (pageNo: number, per_page: number) => {
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.coursesListApiEndpoint + `?page=${pageNo}&per_page=${per_page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.apiHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategoryData = (pageNo: number, per_page: number) => {
    this.setState({ tableLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAdminApiEndpoint +
        `?page=${pageNo}&per_page=${per_page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.apiHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addCategory = () => {

    const headers = new Headers();
    headers.append("token", this.state.userToken);

    const formdata = new FormData();
    formdata.append("course_category[name]", this.state.categoryName);
    formdata.append("course_category[icon_cat]", this.state.icon_cat, this.state.icon_cat_name);
    formdata.append("course_category[web_icon]", this.state.web_icon, this.state.web_icon_name);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAdminApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editCategory = (id:any) => {

    const header = new Headers();
    header.append("token", this.state.userToken);

    const formData = new FormData();
    formData.append("course_category[name]", this.state.categoryName);
    formData.append("course_category[icon_cat]", this.state.icon_cat, this.state.icon_cat_name);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAdminApiEndpoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  delCategory = (id: string | number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.delCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAdminApiEndpoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.apiHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  exportCategory = () => {
    const exportCatHeader = {
      header: configJSON.exportCategoryContentType,
      token: this.state.userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.exportCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAdminApiEndpoint + "/export_csv"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(exportCatHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubCategoryData = (pageNo: number, per_page: number) => {
    this.setState({ tableLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryEndpoint + `?page=${pageNo}&per_page=${per_page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.apiHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addSubCategory = () => {
    let status = this.getCatStatus();
    const subCatheader = new Headers();
    subCatheader.append("token", this.state.userToken);

    const subCatBody = new FormData();
    subCatBody.append("course_sub_category[name]", this.state.subCategoryName);
    subCatBody.append("course_sub_category[status]", status);
    subCatBody.append("course_sub_category[web_icon]", this.state.web_icon, this.state.web_icon_name);
    subCatBody.append("course_sub_category[mobile_icon]", this.state.mobile_icon, this.state.mobile_icon_name);
    subCatBody.append("course_sub_category[icon_sub_cat]", this.state.icon_cat, this.state.icon_cat_name);
    subCatBody.append("course_sub_category[course_category]", "TestCat");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addSubCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addSubCatEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      subCatheader
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      subCatBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editSubCategory = (id: any) => {
    let editSubCatStatus = this.getCatStatus();

    const editSubCatHeader = new Headers();
    editSubCatHeader.append("token", this.state.userToken);

    const editSubCatbody = new FormData();
    editSubCatbody.append("course_sub_category[name]", this.state.subCategoryName);
    editSubCatbody.append("course_sub_category[status]", editSubCatStatus);
    editSubCatbody.append("course_sub_category[web_icon]", this.state.web_icon, this.state.web_icon_name);
    editSubCatbody.append("course_sub_category[mobile_icon]", this.state.mobile_icon, this.state.mobile_icon_name);
    editSubCatbody.append("course_sub_category[icon_sub_cat]", this.state.icon_cat, this.state.icon_cat_name);
    editSubCatbody.append("course_sub_category[course_category]", "TestCat");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editSubCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addSubCatEndpoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      editSubCatHeader
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      editSubCatbody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getChildCategoryData = (pageNo: number, per_page: number) => {
    this.setState({ tableLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChildCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.childCategoryEndpoint + `?page=${pageNo}&per_page=${per_page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.apiHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addChildCategory = () => {
    let addChildCatStatus = this.getCatStatus();
    const addChildCatHeader = new Headers();
    addChildCatHeader.append("token", this.state.userToken);

    const addChildCatBody = new FormData();
    addChildCatBody.append("course_child_category[course_category_id]", "90");
    addChildCatBody.append("course_child_category[course_sub_category_id]", "91");
    addChildCatBody.append("course_child_category[name]", this.state.childCategoryName);
    addChildCatBody.append("course_child_category[color]", this.state.childCategoryColor);
    addChildCatBody.append("course_child_category[status]", addChildCatStatus);
    addChildCatBody.append("course_child_category[web_icon]", this.state.web_icon, this.state.web_icon_name);
    addChildCatBody.append("course_child_category[mobile_icon]", this.state.mobile_icon, this.state.mobile_icon_name);
    addChildCatBody.append("course_child_category[course_sub_category]", "Sample");
    addChildCatBody.append("course_child_category[course_category]", "Sample");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addChildCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addChildCategoryEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      addChildCatHeader
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      addChildCatBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editChildCategory = (id: any) => {
    let editChildCatStatus= this.getCatStatus();
    
    const editChildCatHeader = new Headers();
    editChildCatHeader.append("token", this.state.userToken);

    const editChildCatBody = new FormData();
    editChildCatBody.append("course_child_category[course_category_id]", "90");
    editChildCatBody.append("course_child_category[course_sub_category_id]", "91");
    editChildCatBody.append("course_child_category[name]", this.state.childCategoryName);
    editChildCatBody.append("course_child_category[color]", this.state.childCategoryColor);
    editChildCatBody.append("course_child_category[status]", editChildCatStatus);
    editChildCatBody.append("course_child_category[web_icon]", this.state.web_icon, this.state.web_icon_name);
    editChildCatBody.append("course_child_category[mobile_icon]", this.state.mobile_icon, this.state.mobile_icon_name);
    editChildCatBody.append("course_child_category[course_sub_category]", "Sample");
    editChildCatBody.append("course_child_category[course_category]", "Sample");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editChildCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editChildCategoryEndpoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      editChildCatHeader
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      editChildCatBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleShowMore = () => {
    this.setState({ pageNo: this.state.pageNo + 1 }, () => {
      this.getCoursesData(this.state.pageNo, this.state.per_page);
    });
  };

  handleTabClick = (index: number) => {
    this.setState({ activeTab: index });
  };

  handleMouseEnter = (index: number) => {
    this.setState({ hoveredIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredIndex: -1 });
  };

  handleTabView = () => {
    this.setState({ listView: false, tabView: true });
  };

  handleListView = () => {
    this.setState({ tabView: false, listView: true });
  };

  handleMenu = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  handleMenuItemClick = (path: string) => {
    this.props.navigation.navigate(path);
  };

  handleBack = (path:string) => {
    window.localStorage.removeItem("editData");
    this.props.navigation.navigate(path);
  };

  handleTableNext = (tableFunction: any) => {
    this.setState({ pageNo: this.state.pageNo+1 }, () => {
      tableFunction(this.state.pageNo, this.state.cat_per_page);
    });
  };

  handleTablePrevious = (tableFunction: any) => {
    this.setState({ pageNo: this.state.pageNo-1 }, () => {
      tableFunction(this.state.pageNo, this.state.cat_per_page);
    });
  };

  handleTableNumberClick = (pageNo: number, tableFunction: any) => {
    this.setState({ pageNo: pageNo }, () => {
      tableFunction(this.state.pageNo, this.state.cat_per_page);
    });
  };

  goAddScreen = (path: string) => {
    this.props.navigation.navigate(path);
  };

  handleEditReset = () => {
      if(this.state.row){
      this.setState({
        categoryName: this.state.row.attributes?.name,
        icon_cat: null,
        web_icon: null,
        courseStatus: this.state.row.attributes?.status,
      })
    }
  };

  handleAddReset = () => {
    this.setState({
      categoryName: "",
      icon_cat: null,
      web_icon: null,
      courseStatus: false,
    })
  };

  handleAppIconChange = (e: any) => {
    if(!e || !e.target) return;
    this.setState({
      icon_cat_name: e.target.files[0].name,
      icon_cat: e.target.files[0],
      mobile_icon: e.target.files[0],
      mobile_icon_name: e.target.files[0].name,
    });
  };

  handleWebIconChange = (e: any) => {
    if(!e || !e.target) return;
    this.setState({
      web_icon_name: e.target.files[0].name,
      web_icon: e.target.files[0],
    });
  };

  handleCatNameChange = (e: any) => {
    if(!e || !e.target) return;
    this.setState({
      categoryName: e.target.value,
    }) 
  };

  handleSubCatNameChange = (e: any) => {
    if(!e || !e.target) return;
    this.setState({
      subCategoryName: e.target.value,
    }) 
  };

  handleChildCatNameChange = (e: any) => {
    if(!e || !e.target) return;
    this.setState({
      subCategoryName: e.target.value,
    }) 
  };

  handleModalOpen = (row: any) => {
    this.setState({
      modalOpen: true,
      row: row,
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      row: null,
    });
  };

  handleHeaderCheckboxChange = (data: any) => {
    this.setState((prevState) => ({
      isHeaderChecked: !prevState.isHeaderChecked,
      isChecked: Array(data.length).fill(!prevState.isHeaderChecked),
    }));
  };

  handleRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];

      return {
        isChecked,
        isHeaderChecked: isChecked.every((value) => value),
      };
    });
  };

  handleEdit = async (row:any, path: string) => {
    const editPageData = {
      edit: true,
      rowData: row,
    } 
    window.localStorage.setItem("editData", JSON.stringify(editPageData));
    this.props.navigation.navigate(path);
  }
  // Customizable Area End
}

import React from "react";
import {
    Grid,Popover,Typography,Box, Divider,styled,ListItem,ListItemText,List
} from "@material-ui/core";
import {forwardArrow, OvalBlue} from "./assets";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LaunchIcon from '@material-ui/icons/Launch';
import { withStyles, Theme, createStyles ,createTheme, ThemeProvider } from '@material-ui/core/styles';

import Pagination from '@material-ui/lab/Pagination';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FooterWeb from "../../../components/src/Footer.web";
interface CourseAttributes {
    course_thumbnail: string;
    course_child_category: {
      color: string;
      name: string;
    };
    is_wishlist: string;
    course_name: string;
    instructor_name: string;
    first_year_price: number;
    second_year_price: number;
    is_purchased: boolean;
    short_description?: string;
    language_name?: string;
    total_content?: string;
    lectures?: string;
    all_course_class_duration?: string;
  }
  
  const ButtonContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px"
  });
  
  const ResetButton = styled(Button)({
    border: "1px solid RGB(63,100,116)",
    textTransform: "inherit",
    marginRight: "30px",
    padding: "8px 50px",
    width: "195px",
    height: "52px",
    borderRadius: "0px",
    color: "#1F6575",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    "@media (max-width:830px)": {
      padding: "6px 30px",
      fontSize: "11px"
    }
  });
  
  const ApplyButton = styled(Button)({
    padding: "8px 50px",
    borderRadius: "0px",
    textTransform: "inherit",
    height: "52px",
    width: "195px",
    color: "#fff",
    fontFamily: "Inter",
    backgroundColor: "#ffa500",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  
    "&:hover": {
      backgroundColor: "#ffa500"
    },
    "@media (max-width:830px)": {
      padding: "6px 30px",
      fontSize: "11px"
    }
  });

const RatingWrapper = styled(Paper)({
    width: '550px',
    height: '617px',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent'
    },
    '& .filterOptionContainer':{
      overflowY: 'scroll', 
      height: 500,
      '&::-webkit-scrollbar': {
        width: 0,
        background: 'transparent'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#578D92',
        borderRadius: '4px',
      },
    },
    padding: 24,
    '& .gridContainerMain': {
        "& .MuiGrid-grid-lg-6":{
            padding: 20
        }
    },
    '& .applyFilter': {
        textAlign: "center",
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: -0.3255172073841095,
        color: '#000000ff',
        },
    '& .filterOption' : {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: 0.08525115996599197,
        color: '#000000ff',
        borderRadius: 8,
        cursor: 'pointer'
    },
    '& .filterButton': {
      width: 180,
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: 0.08525115996599197,
        color: '#000000ff',
        display: "flex", 
        alignItems: "center",
        textTransform: "none",
        justifyContent: 'space-between',
        gap:8,
        '& .buttonImg': {
            borderRadius: 20,
            width: 30,
            height: 30
        }
    },
    '& .divider': {
        height: '100%', 
        width: 2, 
        marginLeft: 5
    },
    '& .filterlistContainer': {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    '& .filterListItem' : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        cursor: 'pointer',
        padding: 8,
        border: "1px solid",
        borderRadius: "24px",
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 20,
        letterSpacing: 0.08525115996599197,
        color: '#305c67ff',
        margin: "8px 0px",
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        "& .MuiTypography-body1":{
          textOverflow: 'ellipsis',
        overflow: 'hidden',
          fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 20,
        letterSpacing: 0.08525115996599197,
        color: '#305c67ff',
        textAlign: "center" as const,
        }
    },
    '& .active': {
        backgroundColor: '#ffa500',
        color: 'white',
        "& .MuiTypography-body1":{
          color: 'white !important',
        }
      },
    '& .starIcon': {
        marginRight: 8,
        color: '#000',
      },
      '& .activeStarIcon': {
        marginRight: 8,
        color: 'white',
      },
      '& .sidebar': {
        width: '200px',
        borderRight: '1px solid #ccc',
        padding: '10px',
      },
      '& .filterOptions': {
        padding: 0,
        margin: 0,
      },
      '& .filterOptionss': {
        padding: '5px 0',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
        '&.active': {
          color: '#fff',
          backgroundColor: '#007BFF',
        },
      },
      '& .ratingsButton': {
        backgroundColor: '#FFC107',
        color: '#000',
        marginTop: '10px',
        '&:hover': {
          backgroundColor: '#FFA000',
        },
      },

})
const theme = createTheme({
    palette: {
        primary: {
            main: "#366A76",
            contrastText: "#fff",
        },
    },
});

import CategoriessubcategoriesController, {
    Props,
    configJSON
} from "./CategoriessubcategoriesController";
import { board, board2, headerImage2, lecturesIcon } from "../../catalogue/src/assets";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { TbLayoutList } from "react-icons/tb";
import { MdOutlineWindow } from "react-icons/md";
import { IoFilterOutline, IoLanguageOutline } from "react-icons/io5";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { FaCircleCheck, FaCirclePlay } from "react-icons/fa6";
import { FaCircle, FaHeart, FaStar } from "react-icons/fa";
import { PiVideoFill } from "react-icons/pi";
import { HiMiniClock } from "react-icons/hi2";
import CourseCardWeb from "../../../components/src/CourseCard.web";
import { getStorageData } from "framework/src/Utilities";
import HeaderWeb from "../../../components/src/Header.web";

export default class Categoriessubcategories extends CategoriessubcategoriesController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() {
      let token = await getStorageData("loginToken");
      let courseListType = await getStorageData("courseListType")
      this.setState({ token: token ,courseListType, allCourseText: courseListType ? courseListType : "All Courses" }, () => {
        this.getCoursesData(this.state.pageNo, this.state.per_page);
        this.getInstructorData()
      });
    }

    renderHeader = () => {
        return (
          <Grid container className="header" style={{zIndex: 1}}>
            <Grid
              container
              item
              xs={12}
              md={7}
              className="headerLeft"
            >
              <h1 className="h1">{configJSON.bannerHeadingText}</h1>
            </Grid>
            <Grid item xs={12} md={5} lg={6} className="image">
              <img
                src={headerImage2}
                alt="student-on-a-desk-illustration"
                className="img headerImage"
              />
            </Grid>
          </Grid>
        );
      };
      renderHeading = () => {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
          <Grid container className="heading">
            <Grid item xs={12} sm={7} className="headingTitle">
            {this.state.breadcrumb.map((crumb: string) => (
                <>
                  <p style={{ color: "#858585" }}>
                    {crumb}
                  </p>
                  <IoIosArrowForward className="arrowRight" style={{ color: "#858585" }} />
                </>
              ))}
              <p
                style={{
                  color:
                    "#FF8256"
                }}
                data-test-id="CourseText"
              >
                {this.state.allCourseText}
              </p>
            </Grid>
    
            <Grid item xs={12} sm={5} className="headingIcons" style={{ justifyContent: "center", gap: 20}}>
              <div data-test-id="filterIcon" aria-describedby={id} style={{ backgroundColor: this.state.anchorEl ? "#00BE10" : "#EEEFEF" }} className="iconWrapper" onClick={this.handleClick}>
                <IoFilterOutline 
                className="filter" 
                color={this.state.anchorEl ? "#FFF" : "#000"}
                />
              </div>
                    <Popover
                        id={id}
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                    >
                <RatingWrapper>
                  <Box>
                    <Typography data-test-id="applyFilter" className="applyFilter" gutterBottom>{configJSON.applyFilterText}</Typography>
                    <Divider />
                    <Grid container className="gridContainerMain">
                      <Grid item xs={5} lg={6}>
                        <div>
                          {Object.keys(this.state.filterOptions).map((tab) => (
                            <ListItem
                            data-test-id="filterOption"
                              key={tab}
                              className={`${'filterOption'} ${this.isConditionalRending(this.state.activeFilterTab === tab , 'filterButton active' , '')}`}
                              onClick={() => this.handleTabClick(tab)}
                            >
                              <Typography variant="subtitle1" className={`${this.isConditionalRending(this.state.activeFilterTab === tab , 'filterButton' , "filterOption")}`}>{tab}</Typography>
                              {this.isConditionalRending(this.state.activeFilterTab === tab ,
                                <img
                                  src={forwardArrow}
                                  alt="arrow"
                                  className="buttonImg"
                                /> , null)}
                            </ListItem>
                          ))}
                        </div>
                      </Grid>
                      <Grid item xs={1} lg={1} md={1} sm={1}>
                        <Divider className="divider" />
                      </Grid>
                      <Grid item xs={5} lg={5} className="filterOptionContainer">
                        <div className="filterlistContainer">
                          <List className={'filterOptions'}>
                            {this.isConditionalRending(this.state.filterLoading , (
                              Array.from(new Array(5)).map((_, index) => (
                                <ListItem key={index}>
                                  <Skeleton variant="rect" width="100%" height={40} />
                                </ListItem>
                              ))
                            ) , this.state.filterOptions[this.state.activeFilterTab].length > 0 && this.state.filterOptions[this.state.activeFilterTab].map((option: any) => (
                              <ListItem
                              data-test-id="filterListItem"
                                key={option}
                                className={`filterListItem ${this.state.filters[this.getFilterKey(this.state.activeFilterTab)] == option?.id ? ' active' : ''
                                  }`}
                                onClick={() => this.handleOptionClicks(option?.id, option?.attributes?.name)}
                              >
                                {this.isConditionalRending(this.state.activeFilterTab == 'Ratings' , (
                                  <Box display="flex" alignItems="center"
                                  >
                                    <FaStar
                                      className={
                                        this.state.filters[this.getFilterKey(this.state.activeFilterTab)] == option?.attributes?.name ? "activeStarIcon" : "starIcon"
                                      }
                                      style={{
                                        marginRight: theme.spacing(1)
                                      }}
                                    />
                                    &nbsp;
                                    {option?.attributes?.name}
                                  </Box>
                                ) , (
                                  <ListItemText primary={this.getListItemText(option)} />
                                ))}
                              </ListItem>
                            )))}
                          </List>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <ButtonContainer>
                    <ResetButton
                      data-test-id="resetButtonId"
                      onClick={this.handleResetFilter}
                    >
                      {configJSON.resetText}
                    </ResetButton>
                    <ApplyButton
                      onClick={this.handleCourseFilterData}
                      data-test-id="applyBtn"
                      disabled={!Object.values(this.state.filters).some(value => value !== '')}
                    >
                      {configJSON.applyText}
                    </ApplyButton>
                  </ButtonContainer>
                </RatingWrapper>
                    </Popover>
              <div className="view">
                <div
                  style={{
                    backgroundColor: this.state.tabView ? "#00BE10" : "EEEFEF",
                  }}
                  className="iconWrapper"
                  test-id="tabView"
                  onClick={this.handleTabView}
                >
                  <MdOutlineWindow
                    className="window"
                    color={this.state.tabView ? "#FFF" : "#000"}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: this.state.listView ? "#00BE10" : "EEEFEF",
                  }}
                  className="iconWrapper"
                  data-test-id="listIcon"
                  onClick={this.handleListView}
                >
                  <TbLayoutList
                    className="listIcon"
                    color={this.state.listView ? "#FFF" : "#000"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        );
      };

      renderShowMore = (data: number) => {
        return (
          <>
            {!this.state.courseLastPage && !this.state.isSearchResult && !this.isAnyFilterSelected(this.state.filters) && (
              <div data-test-id="show-more" className="showMore" onClick={this.handleShowMore}>
                <IoIosArrowDown size={25} color="#1220C1" />
                <p>{configJSON.ShowMoreText}</p>
              </div>
            )}
          </>
        );
      };


      renderSkeleton = () => {
        return (
          <Grid container style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: theme.spacing(2),
          }}>
            {[1, 2, 3, 4].map((_, index) => (
              <Grid item xs={12} md={4} lg={3} key={index.toString()} style={{padding: theme.spacing(2)}}>
                <Skeleton variant="rect" height={200} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))}
          </Grid>
        );
      };
    render() {
        return (
            <>
            <HeaderWeb id={""} navigation={this.props.navigation} />
            <ThemeProvider theme={theme}>
            <div className="container" style={{ position: 'relative' }}>
              {!this.state.loading && !this.state.isSearchResult && this.state.courses.length !== 0 ? (
                <img src={OvalBlue} style={{
                  ...webStyle.blueImage, 
                  ...(this.state.courses.length >= 8 ? {
                    width: '859px',
                    height: '859px'
                  } :
                  {width: "500px", height: 500})
                }}
                 alt="Oval Blue"
                />
              ) : null}

            {this.renderHeader()}
            <div className="contentWrapper" style={{marginTop: 50}}>
          {this.renderHeading()}
          <div className="content" style={{minHeight: "50vh"}}>
            {this.state.loading ? (
              this.renderSkeleton()
            ) : (
              <>
                  <CourseCardWeb
                      courses={this.state.courses}
                      id={""}
                      navigation={this.props.navigation}
                      gridView={this.state.tabView}
                      listView={this.state.listView}
                    />
              {this.renderShowMore(this.state.courses.length)}</>
            )}
            {!this.state.loading && !this.state.isSearchResult && this.state.courses.length === 0 && (
              <Box display={"flex"} justifyContent={'center'} alignItems={'center'}>
                <Typography variant="h2" style={{ fontFamily: 'Inter'}}>{this.state.courseNotFoundMsg}</Typography>
              </Box>
            )}
            {this.state.isSearchResult && this.state.serachCourseNotFountMsg !== "" && (
              <Box display={"flex"} justifyContent={'center'} alignItems={'center'}>
              <Typography variant="h2" style={{ fontFamily: 'Inter'}}>{this.state.courseNotFoundMsg}</Typography>
            </Box>
            )}
          </div>
          </div>
                 </div>
                 <Box>
                  <FooterWeb id={""} navigation={undefined} />
                 </Box>
            </ThemeProvider>
            </>
        );
    }
}

const webStyle = {
    searchAddBtn: {

    },
    paginationViewAll: {
        display: "flex",
        justifyContent: "flex-end"
    },
    addExportBtn: {
        display: "flex",
        columnGap: "12px"
    },
    addBtn: {
        height: '32px',
        textTransform: 'none',
    },
    searchAddBtnMain: {
        display: "flex",
        marginBottom: "20px",
        justifyContent: "space-between"
    },
    blueImage :{
      position: 'absolute' as const,
      right: 0,
      bottom: 60,
      opacity: 0.8,
      transform: 'rotate(180deg)',
      transition: 'transform 0.5s ease',
      zIndex: -2
    }
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Color } from "@material-ui/lab/Alert";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { validationsLoop } from "../../user-profile-basic/src/FooterController.web";
const inputRegex = /^(?!^\d*$)(?!^[\W_]*$)(?=.*[A-Za-z])[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>_]{1,100}$/;

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface NotificationsListResponse extends ErrorResponse {
  data: NotificationsData[];
  meta: Meta;
}

interface ShowNotificationResponse extends ErrorResponse {
  data: NotificationsData;
  meta: Meta;
}

export interface Meta {
  page: string;
  message: string;
  total_push_notification_template: number;
}

export interface NotificationsData {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  created_at: Date;
  updated_at: Date;
  push_notification_template_type: string;
  headers: string;
  functions: string;
  contents: string;
  status: boolean;
  notificationId: string;
}

interface ErrorResponse {
  errors: Array<{ token: string }>;
}

interface ResponseSubmit extends ErrorResponse {
  data: NotificationsData | DataError;
  meta: {
    message: string;
  };
}

export interface DataError {
  headers: string[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notificationsList: NotificationsData[];
  isLoading: boolean;
  notificationId: string;
  token: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  headers: string;
  headersError: string;
  contents: string;
  contentsError: string;
  push_notification_template_type: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PushnotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationsApiCallId: string = "";
  updateNotificationsApiCallId: string = "";
  showNotificationApiCallId: string = "";
  toggleStatusApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      notificationsList: [],
      isLoading: true,
      token: "",
      headers: "",
      headersError: "",
      contents: "",
      contentsError: "",
      push_notification_template_type: "",
      notificationId: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (propsData) {
        this.handleShowNotification(propsData.pushNotificationId);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ token: token });
    this.handleGetNotifications();
  };

  handleApiRequest(apiCallData: APIPayloadType) {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  }

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson:
      | ShowNotificationResponse
      | NotificationsListResponse
      | ErrorResponse
      | ResponseSubmit
  ) => {
    switch (apiRequestCallId) {
      case this.getNotificationsApiCallId:
        this.handleGetNotificationsResponse(
          responseJson as NotificationsListResponse
        );
        break;
      case this.showNotificationApiCallId:
        this.handleShowNotificationResponse(
          responseJson as ShowNotificationResponse
        );
        break;
      case this.updateNotificationsApiCallId:
        this.handleSubmitPushNotificationResponse(
          responseJson as ResponseSubmit
        );
        break;
      case this.toggleStatusApiCallId:
        this.handleToggleStatusResponse(responseJson as ResponseSubmit);
        break;
    }
  };

  handleGetNotifications = () => {
    this.setState({ isLoading: true });
    this.getNotificationsApiCallId = this.handleApiRequest({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getNotificationsAPIMethod,
      endPoint: configJSON.pushNotificationsEndpoint
    });
  };

  handleGetNotificationsResponse = (response: NotificationsListResponse) => {
    if (
      response.errors &&
      [configJSON.tokenExpiredMessage, configJSON.tokenInvalidMessage].includes(
        response.errors[0].token
      )
    ) {
      this.navigationLoginScreen();
    } else if (response && response.data) {
      this.setState({
        isLoading: false,
        notificationsList: response.data
      });
    } else {
      this.setState({
        isLoading: false,
        notificationsList: []
      });
    }
  };

  handleShowNotification = (notificationId: string) => {
    this.setState({ isLoading: true });
    this.showNotificationApiCallId = this.handleApiRequest({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getNotificationsAPIMethod,
      endPoint: configJSON.pushNotificationsEndpoint + `/${notificationId}`
    });
  };

  handleShowNotificationResponse = (response: ShowNotificationResponse) => {
    if (
      response.errors &&
      [configJSON.tokenExpiredMessage, configJSON.tokenInvalidMessage].includes(
        response.errors[0].token
      )
    ) {
      this.navigationLoginScreen();
    } else if (response && response.data) {
      const { data } = response,
        { attributes, id } = data,
        { contents, headers, push_notification_template_type } = attributes;
      this.setState({
        headers,
        contents,
        push_notification_template_type,
        notificationId: id,
        isLoading: false
      });
    } else {
      this.navigationPushNotifications();
    }
  };

  handleInputChange({ target }: { target: { value: string; name: string } }) {
    let { name, value } = target,
      error = "";
    if (["headers", "contents"].includes(name)) {
      const inputName = name === "headers" ? "heading" : "content";
      error = !inputRegex.test(value)
        ? `Please enter a valid ${inputName}`
        : "";
    }
    const stateValues: unknown = { [name]: value, [`${name}Error`]: error };
    this.setState(stateValues as Pick<S, keyof S>);
  }

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  checkFormValidations(updatedValues: Record<string, unknown>): boolean {
    const validationFields = [
      {
        field: "headers",
        errorKey: "headersError",
        errorMessage: "Headings is Required",
        regex: inputRegex
      },
      {
        field: "contents",
        errorKey: "contentsError",
        errorMessage: "Contents is Required",
        regex: inputRegex
      }
    ];

    return validationsLoop(validationFields, updatedValues, value =>
      this.setState(value)
    );
  }

  handleSubmitPushNotification() {
    this.setState({ isLoading: true });
    const { contents, headers, notificationId } = this.state;
    const formValues = {
      headers,
      contents
    };
    if (!this.checkFormValidations(formValues)) {
      const data = {
        data: {
          headers,
          contents
        }
      };

      this.updateNotificationsApiCallId = this.handleApiRequest({
        method: configJSON.putApiMethodType,
        endPoint: configJSON.pushNotificationsEndpoint + `/${notificationId}`,
        token: this.state.token,
        contentType: configJSON.validationApiContentType,
        body: data
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  isDataError(data: unknown): data is DataError {
    return (data as DataError).headers !== undefined;
  }

  handleSubmitPushNotificationResponse(response: ResponseSubmit) {
    if (
      response.errors &&
      [configJSON.tokenExpiredMessage, configJSON.tokenInvalidMessage].includes(
        response.errors[0].token
      )
    ) {
      this.navigationLoginScreen();
    } else if (
      response.meta &&
      response.meta.message === configJSON.successSubmitMessage
    ) {
      this.navigationPushNotifications();
    } else if (this.isDataError(response.data)) {
      const data = response.data;
      this.setState({
        isAlert: true,
        alertMsg: "Header name " + data.headers[0],
        alertType: "error",
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleToggleStatusResponse = (responseJson: ResponseSubmit) => {
    this.setState({ isLoading: false });
    if (responseJson.meta.message === configJSON.successSubmitMessage) {
      this.setState({
        isAlert: true,
        alertMsg: "Push Notification Template status changed successfully",
        alertType: "success"
      });
      this.handleGetNotifications();
    }
  };

  handleToggleStatus(id: string, status: boolean, index: number) {
    let clone = [...this.state.notificationsList];
    clone[index].attributes.status = !status;

    this.setState({
      notificationsList: clone,
      isLoading: true
    });

    this.toggleStatusApiCallId = this.handleApiRequest({
      method: configJSON.putApiMethodType,
      endPoint: `${configJSON.pushNotificationsEndpoint}/${id}`,
      body: { data: { status: !status } },
      contentType: configJSON.validationApiContentType,
      token: this.state.token
    });
  }

  handleAddReset = () => {
    this.setState({
      contents: "",
      contentsError: "",
      headers: "",
      headersError: ""
    });
  };

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  navigationEditPushNotifications = (pushNotificationId: string | number) => {
    const editpushNotificationsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAddPushNotificationsMessage)
    );
    editpushNotificationsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    editpushNotificationsScreenMsg.addData(
      getName(MessageEnum.NavigationIdMessage),
      {
        pushNotificationId: pushNotificationId
      }
    );
    this.send(editpushNotificationsScreenMsg);
  };

  navigationPushNotifications = () => {
    const pushNotificationsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationPushNotificationsMessage)
    );
    pushNotificationsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(pushNotificationsScreenMsg);
  };
  // Customizable Area End
}

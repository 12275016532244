Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";

exports.getUserInfo = "bx_block_profile/profiles/user_profile";
exports.getTotalOverview = "bx_block_dashboard/dashboards/total_overview";
exports.getOverview = "bx_block_dashboard/dashboards/get_overview?type=";
exports.getTotalOrderChart = "bx_block_dashboard/dashboards/total_orders_chart?type=";
exports.getTotalRevenuesChart = "bx_block_dashboard/dashboards/total_revenues_chart?type=";
exports.getTotalCustomerChart = "bx_block_dashboard/dashboards/total_customer_chart?type=";
exports.getTotalActiveChart = "bx_block_dashboard/dashboards/total_active_user_chart?type=";
// Customizable Area End
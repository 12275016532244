import React from "react";
// Customizable Area Start
import "./categoryAdmin.css";
import "./table.css";
import AdminLayout from "./AdminLayout.web";
import { Modal } from "@material-ui/core";
import Table from "./Table.web";
import EditCourseComp from "./EditCourseComp.web";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

export default class CategoryAdmin extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomRows = (row: any) => {
    return (
      <>
        <td>
          <img
            src={row.attributes.icon_cat}
            alt="App Icon"
            width="30"
            height="30"
          />
        </td>
        <td>
          <img
            src={row.attributes.web_icon}
            alt="Web Icon"
            width="30"
            height="30"
          />
        </td>
        <td>{row.attributes.name}</td>
        <td>550</td>
        <td>22000</td>
      </>
    );
  };

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <AdminLayout
        navigation={undefined}
        id={""}
        title={"Category"}
        display={"flex"}
        handleExport={this.exportCategory}
        addPath={"AddCategory"}
      >
        <Table
          tableData={this.state.categoryData}
          navigation={undefined}
          id={""}
          tableHeaders={[
            "App Icon",
            "Web Icon",
            "Category Name",
            "Total Courses",
            "Total Students",
          ]}
          editPath={"AddCategory"}
          customRows={this.renderCustomRows}
          tableFunction={this.getCategoryData}
          deleteCategory={this.delCategory}
          editCategory={this.handleEdit}
        />
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          className="modal"
        >
          <EditCourseComp
            navigation={undefined}
            id={""}
            buttonText="Update"
            bodyStyle={"bodyStyle"}
            topStyle={"topStyle"}
            bodyContainerStyle={"bodyContainerStyle"}
            modalHeading="Add Category"
            topItemStyle={"topItemStyle"}
            addFunction={this.addCategory}
          />
        </Modal>
      </AdminLayout>
    );
  }
}

// Customizable Area Start
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Snackbar,
  FormControl
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FaChevronDown } from "react-icons/fa";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import {
  TwoColBox,
  InputBox,
  StyledSelect
} from "../../../components/src/GenericInputs";
export const configJSON = require("./config");
// Customizable Area End

import AdminStateListController, {
  Props
} from "./AdminStateListController.web";

export default class AddAdminState extends AdminStateListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  addStateNotificationAlert = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        data-test-id="alertTestId"
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.onCloseAlert}
      >
        <Alert data-test-id="errorAlertId" severity={this.state.alertType}>
          {this.state.alertMsg}
        </Alert>
      </Snackbar>
    );
  };

  renderCountrySelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">
          {configJSON.profileCountryText}
        </Typography>
        <FormControl variant="outlined" fullWidth className="box__input">
          <StyledSelect
            id="demo-customized-select-native"
            disableUnderline
            IconComponent={FaChevronDown}
            data-test-id="countrySelectId"
            onChange={this.handleCountrySelect}
            value={this.state.selectCountry}
          >
            <option aria-label="None" value="" disabled />
            {this.state.countryData &&
              this.state.countryData.map(country => {
                return (
                  <option
                    data-test-id="countryCodeListId"
                    key={country.lable}
                    value={country.value}
                  >
                    {country.lable}
                  </option>
                );
              })}
          </StyledSelect>
        </FormControl>
      </Box>
    );
  };

  renderInputStateView = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>{this.renderCountrySelect()}</TwoColBox>

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">
                {configJSON.nameText}
              </Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  variant="outlined"
                  fullWidth
                  value={this.state.stateName}
                  onChange={this.handleStateName}
                  data-test-id="nameInputId"
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <ButtonBox>
            {this.state.isUpdate ? (
              <Button
                variant="contained"
                className="reset_btn"
                onClick={this.navigationStateListScreen}
              >
                {configJSON.cancelText}
              </Button>
            ) : (
              <Button
                variant="contained"
                className="reset_btn"
                onClick={this.handleFieldReset}
              >
                {configJSON.resetText}
              </Button>
            )}
            {this.state.isUpdate ? (
              <Button
                onClick={this.handleUpdateState}
                data-test-id="updateBtnId"
                variant="contained"
                className="create_btn"
              >
                {configJSON.updateStateText}
              </Button>
            ) : (
              <Button
                data-test-id="addRecordId"
                onClick={this.handlePostState}
                variant="contained"
                className="create_btn"
              >
                {configJSON.createStateText}
              </Button>
            )}
          </ButtonBox>
        </Box>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.loader}
        data-test-id="mainLayoutEvent"
        backData={this.navigationStateListScreen}
        title={this.state.isUpdate ? "Edit State" : "Add State"}
        isEdit
        isAdd
      >
        {this.addStateNotificationAlert()}
        <main className="table_content" style={{ background: "white" }}>
          {this.renderInputStateView()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "25px 0",
  alignItems: "center",
  "& .create_btn": {
    backgroundColor: "#3f6474",
    borderRadius: "0",
    padding: "10px 58px",
    textTransform: "capitalize",
    color: "#fff",
    fontSize: "16px",
    boxShadow: "none",
    margin: "0px 10px",
    width: "195px",
    fontFamily: "Inter",
    height: "52px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal"
  },
  "& .reset_btn": {
    backgroundColor: "#fff",
    borderRadius: "0",
    textTransform: "capitalize",
    border: "1px solid #3F6474",
    fontSize: "16px",
    padding: "10px 60px",
    width: "195px",
    color: "#3f6474",
    margin: "0px 10px",
    height: "52px",
    fontFamily: "Inter",
    fontStyle: "normal",
    boxShadow: "none",
    lineHeight: "24px",
    fontWeight: 700
  },
  "@media (max-width:460px)": {
    "& .create_btn": {
      height: "40px",
      padding: "5px 48px"
    },
    "& .reset_btn": {
      height: "40px",
      padding: "5px 50px"
    }
  },
  "@media (max-width:400px)": {
    flexDirection: "column",
    "& .create_btn": { width: "100%" },
    "& .reset_btn": { marginBottom: "10px", width: "100%" }
  }
});
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { Parser } from "json2csv";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface BaseResponseType {
  meta: Meta;
  errors: ErrorType[];
  error: string;
}

export interface ErrorType {
  token: string;
}

export interface ReportsListResponseType extends BaseResponseType {
  data: ReportsListData[];
}

export interface ReportsResponseType extends BaseResponseType {
  message: string[][];
}

export interface ReportsListData {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  report_name: string;
  created_at: string;
  created_on: string;
  report_file: string;
}

export interface Meta {
  message: string | unknown[];
  page: number;
  total_report: number;
}

export interface InvalidResponseType {
  errors: Array<string>;
}

type UnknownKeysObject = {
  [key: string]: string;
};

export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportsList: ReportsListData[];
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  perPage: number;
  startDate: string;
  endDate: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DailyReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDailyReportListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reportsList: [],
      currentPageCount: 1,
      pageCount: 0,
      startDate: "",
      endDate: "",
      userToken: "",
      isLoading: false,
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      perPage: elementsPerPage
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    this.handleGetReportsList(this.state.currentPageCount, this.state.perPage);
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };

  reportsApiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: ReportsListResponseType | ReportsResponseType
  ) => {
    if (apiRequestCallId === this.getDailyReportListApiCallId) {
      this.handleGetReportsListResponse(
        responseJson as ReportsListResponseType
      );
    }
  };

  handleDownloadReport = async (reportUrl: string) => {
    this.setState({ isLoading: true });
    this.downloadReportCSV(reportUrl);
  };

  downloadReportCSV = (fileUrl: string) => {
    const url = new global.URL(fileUrl);
    const a = document.createElement("a");
    a.href = url.toString();
    a.download = "daily_report_data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ isLoading: false });
  };

  handleGetReportsListResponse = (responseJson: ReportsListResponseType) => {
    if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      Object.keys(responseJson.errors[0])[0] === "token")
     {
      this.navigationLoginScreen();
    } else if (responseJson.data) {
      this.setState({
        reportsList: responseJson.data,
        pageCount: Math.ceil(
          responseJson.meta.total_report / this.state.perPage
        ),
        isLoading: false
      });
    } else if (responseJson.error) {
      this.setState({
        alertMsg: responseJson.error,
        alertType: "error",
        isAlert: true,
        isLoading: false
      });
    }
  };

  handleGetReportsList = (currentPage: number, perPage: number) => {
    this.setState({ isLoading: true });
    this.getDailyReportListApiCallId = this.reportsApiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${
        configJSON.getDailyReports
      }?page=${currentPage}&per_page=${perPage}`,
      type: configJSON.exampleApiContentType
    });
  };

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        currentPageCount: Number(value)
      },
      () => {
        this.handleGetReportsList(
          this.state.currentPageCount,
          this.state.perPage
        );
      }
    );
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}

import React from "react";

// Customizable Area Start
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { Box, Typography, styled, Modal } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
const drawerWidth = 250;

// Customizable Area End

import TestimonialsController, {
  Props,
  TestimonialData
} from "./TestimonialsController.web";

export default class Testimonials extends TestimonialsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  renderStars = (count: number) => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(<span key={i}>&#9733;</span>);
    }
    return stars;
  };

  renderTableBody = (item: TestimonialData, index: number) => {
    return (
      <>
        <tr>
          <td style={{ textAlign: "left" }}>{item.id}</td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.user_name.full_name}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.user_name.full_phone_number}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.user_name.email}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.rating
              ? this.renderStars(item.attributes.rating)
              : "----"}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.comment.length > 50
              ? `${item.attributes.comment.slice(0, 50)}...`
              : item.attributes.comment || "----"}
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivide} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box className="th_box" style={{ justifyContent: "start" }}>
                  <Typography className="title_th">ID</Typography>
                </Box>
              </th>
              {["Name", "Phone", "Email", "Rating", "Reviews"].map(
                (header, index) => (
                  <th key={index.toString()}>
                    <Box className="th_box" style={{ justifyContent: "start" }}>
                      <Typography className="title_th">{header}</Typography>
                    </Box>
                  </th>
                )
              )}
            </tr>
          </thead>
          <span style={webStyles.lineDivide} />
          <TableBody>
            {this.state.testimonialsList.map((item, index) =>
              this.renderTableBody(item, index)
            )}
          </TableBody>
        </TableStyle>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.totalPages}
            shape="rounded"
            boundaryCount={1}
            page={this.state.pageNo}
            siblingCount={0}
            onChange={this.handlePageChange}
          />
        </PaginationBox>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        isLoading={this.state.isLoading}
        title={"Testimonials"}
        isStudent={false}
        isEdit
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
          dataTestId="oncloseAlert"
        />
        <main className="table_data">{this.renderTable()}</main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PaginationBox = styled(Box)({
  "& .viewall_txt": {
    color: "#206575",
    fontSize: "0.75rem",
    cursor: "pointer"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff"
  }
});

const webStyles = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  categoryImgName: {
    display: "inline-flex",
    gap: "1rem",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  checkboxMain: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  lineDivide: {
    height: "20px",
    display: "inline-block"
  },
  tableDivide: {
    height: "10px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
// Customizable Area End

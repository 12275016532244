import React from "react";

// Customizable Area Start
import { Box, Button, styled, FormControl } from "@material-ui/core";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import {
  OneColBox,
  MultiLineTextField,
  InputBox,
} from "../../../components/src/GenericInputs";
export const configJSON = require("./config");
// Customizable Area End

import FaqController, { Props } from "./FaqController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

export default class AddFaq extends FaqController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderForm = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <OneColBox>
            <Box className="box__label">Topic Name</Box>
            <FormControl variant="outlined" fullWidth className="box__input">
              <InputBox
                data-test-id="nameTestId"
                id="outlined-basic"
                variant="outlined"
                name="name"
                fullWidth
                value={this.state.name}
                onChange={(e) => {
                  this.handleInputChange(e);
                }}
              />
              <span style={webStyles.errorText}>{this.state.nameError}</span>
            </FormControl>
          </OneColBox>
          <OneColBox>
            <Box className="box__label">Topic Description</Box>
            <FormControl variant="outlined" fullWidth className="box__input">
              <MultiLineTextField
                variant="outlined"
                fullWidth
                multiline
                data-test-id="descriptionTestId"
                name="description"
                onChange={(event) => {
                  this.handleInputChange(event);
                }}
                maxRows={3}
                minRows={3}
                value={this.state.description}
              />
              <span style={webStyles.errorText}>
                {this.state.descriptionError}
              </span>
            </FormControl>
          </OneColBox>
          <ButtonBox>
            <Button
              variant="contained"
              className="reset_btn"
              onClick={this.navigationFaq}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleUpdateFaq}
              data-test-id="updateButtonTestId"
              variant="contained"
              disabled={this.state.loader}
              className="create_btn"
            >
              Create FAQ
            </Button>
          </ButtonBox>
        </Box>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.loader}
        data-test-id="mainLayoutEvent"
        backData={this.navigationFaq}
        title={this.state.isUpdate ? "Edit FAQ" : "Add FAQ"}
        isEdit
        isAdd
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          isOpen={this.state.isAlert}
          msg={this.state.alertMsg}
          onClose={this.onCloseAlert}
          dataTestId="alertTestId"
        />
        <main className="table_content" style={{ background: "white" }}>
          {this.renderForm()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "25px 0",
  alignItems: "center",
  "& .create_btn": {
    backgroundColor: "#3f6474",
    borderRadius: "0",
    padding: "10px 58px",
    textTransform: "capitalize",
    color: "#fff",
    fontSize: "16px",
    boxShadow: "none",
    margin: "0px 10px",
    width: "195px",
    fontFamily: "Inter",
    height: "52px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal",
  },
  "& .reset_btn": {
    backgroundColor: "#fff",
    borderRadius: "0",
    textTransform: "capitalize",
    border: "1px solid #3F6474",
    fontSize: "16px",
    padding: "10px 60px",
    width: "195px",
    color: "#3f6474",
    margin: "0px 10px",
    height: "52px",
    fontFamily: "Inter",
    fontStyle: "normal",
    boxShadow: "none",
    lineHeight: "24px",
    fontWeight: 700,
  },
  "@media (max-width:460px)": {
    "& .create_btn": {
      height: "40px",
      padding: "5px 48px",
    },
    "& .reset_btn": {
      height: "40px",
      padding: "5px 50px",
    },
  },
  "@media (max-width:400px)": {
    flexDirection: "column",
    "& .create_btn": { width: "100%" },
    "& .reset_btn": { marginBottom: "10px", width: "100%" },
  },
});
// Customizable Area End

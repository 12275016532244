import React from "react";

// Customizable Area Start
import { Box, Button, styled, FormControl } from "@material-ui/core";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import {
  OneColBox,
  MultiLineTextField
} from "../../../components/src/GenericInputs";
export const configJSON = require("./config");
import RteEditor from "../../../components/src/RteEditor.web";
// Customizable Area End

import AddHowItWorkController, { Props } from "./AddHowItWorkController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

export default class AddHowItWork extends AddHowItWorkController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderForm = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <OneColBox>
            <Box className="box__label">Description</Box>
            <FormControl
              variant="outlined"
              className="box__input"
              fullWidth
              style={{ color: "#192252" }}
            >
              {!this.state.loader && (
                <RteEditor
                  onChange={(e) => this.handleDescriptionChange(e)}
                  markup={this.state.description}
                  testId="rte"
                />
              )}
            </FormControl>
          </OneColBox>
          <ButtonBox>
            <Button
              variant="contained"
              className="reset_btn"
              onClick={this.navigationHowItWorks}
            >
              {configJSON.cancelText}
            </Button>
            <Button
              onClick={this.handleUpdateHowItWorks}
              data-test-id="updateButtonTestId"
              variant="contained"
              className="create_btn"
            >
              {configJSON.updateText}
            </Button>
          </ButtonBox>
        </Box>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.loader}
        data-test-id="mainLayoutEvent"
        backData={this.navigationHowItWorks}
        title={this.state.isUpdate ? "Edit How It Works" : "Add How It Works"}
        isEdit
        isAdd
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          isOpen={this.state.isAlert}
          msg={this.state.alertMsg}
          onClose={this.onCloseAlert}
          dataTestId="alertTestId"
        />
        <main className="table_content" style={{ background: "white" }}>
          {this.renderForm()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "25px 0",
  alignItems: "center",
  "& .create_btn": {
    textWrap: 'nowrap',
    backgroundColor: "#3f6474",
    borderRadius: "0",
    padding: "10px 58px",
    textTransform: "capitalize",
    color: "#fff",
    fontSize: "16px",
    boxShadow: "none",
    margin: "0px 10px",
    width: "195px",
    fontFamily: "Inter",
    height: "52px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal"
  },
  "& .reset_btn": {
    backgroundColor: "#fff",
    borderRadius: "0",
    textTransform: "capitalize",
    border: "1px solid #3F6474",
    fontSize: "16px",
    padding: "10px 60px",
    width: "195px",
    color: "#3f6474",
    margin: "0px 10px",
    height: "52px",
    fontFamily: "Inter",
    fontStyle: "normal",
    boxShadow: "none",
    lineHeight: "24px",
    fontWeight: 700
  },
  "@media (max-width:460px)": {
    "& .create_btn": {
      height: "40px",
      padding: "5px 48px"
    },
    "& .reset_btn": {
      height: "40px",
      padding: "5px 50px"
    }
  },
  "@media (max-width:400px)": {
    flexDirection: "column",
    "& .create_btn": { width: "100%" },
    "& .reset_btn": { marginBottom: "10px", width: "100%" }
  }
});
// Customizable Area End

import React from "react";
// Customizable Area Start
import "./categoryAdmin.css";
import "./editCourseComp.css";
import AdminLayout from "./AdminLayout.web";
import EditCourseComp from "./EditCourseComp.web";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

export default class AddCategory extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
  
    return (
      //Merge Engine DefaultContainer
      <AdminLayout
        navigation={undefined}
        id={""}
        headingText="Add"
        title={"Category"}
        display={"none"}
        backPath={"CategoryAdmin"}
      >
        <EditCourseComp
          navigation={undefined}
          id={""}
          title={"Category"}
          addResetFunction={this.handleAddReset}
          editResetFunction={this.handleEditReset}
          row={this.state.row}
          editActive={this.state.edit}
        />
      </AdminLayout>
    );
  }
}

// Customizable Area Start
// Customizable Area End

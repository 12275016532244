import React from "react";

// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import { FiDownload } from "react-icons/fi";
const drawerWidth = 250;
const closeDrawer = 0;

import DailyReportsController, {
  ReportsListData,
  Props
} from "./DailyReportsController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

// Customizable Area End

export default class DailyReports extends DailyReportsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: ReportsListData) => {
    return (
      <>
        <tr>
          <td style={{ textAlign: "left" }}>{item.id}</td>
          <td style={{ textAlign: "left" }}>{item.attributes.report_name}</td>
          <td style={{ textAlign: "left" }}>{item.attributes.created_on}</td>
          <td
            style={{ borderRadius: "0px 10px 10px 0px", textAlign: "center" }}
          >
            <Box className="desc_edit_icon_box">
              <FiDownload
                data-test-id={"downloadIconTestId"}
                className="desc_edit_icon"
                size={20}
                onClick={() =>
                  this.handleDownloadReport(item.attributes.report_file)
                }
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th style={{ width: 50 }}>
                <Box className="th_box" style={{ justifyContent: "left" }}>
                  <Typography className="title_th">S.no</Typography>
                </Box>
              </th>
              <th>
                <Box className="th_box" style={{ justifyContent: "left" }}>
                  <Typography className="title_th">Report Name</Typography>
                </Box>
              </th>
              <th>
                <Box className="th_box" style={{ justifyContent: "left" }}>
                  <Typography className="title_th">Created On</Typography>
                </Box>
              </th>
              <th style={{ width: 100 }}>
                <Box className="th_box" style={{ justifyContent: "center" }}>
                  <Typography className="title_th">Action</Typography>
                </Box>
              </th>
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody>
            {this.state.reportsList.map(item => this.renderTableBody(item))}
          </TableBody>
        </TableStyle>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.pageCount}
            shape="rounded"
            boundaryCount={1}
            page={this.state.currentPageCount}
            siblingCount={0}
            onChange={this.onPageChange}
          />
        </PaginationBox>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.isLoading}
        disableSearch
        title="Daily Reports"
        data-test-id="mainLayoutEvent"
        newUI
      >
        <NotificationAlertMessage
          dataTestId="notificationModalTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.oncloseAlert}
          isOpen={this.state.isAlert}
        />
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  hideDrawer: {
    width: `calc(100% - ${closeDrawer}px)`
  },
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "8px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tableActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  currentPage: {
    backgroundColor: "#176876",
    color: "white",
    borderRadius: "5px",
    padding: "0px 5px"
  },
  modalContent: {
    width: "90%",
    maxWidth: "700px",
    backgroundColor: "#fff",
    borderRadius: "5px"
  },
  tableFooter: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    marginRight: "40px"
  },
  pagination: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  pageWrapper: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tableBodyMsg: {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh",
    width: "100%",
    marginTop: "50px",
    border: "1px solid red"
  }
};

const PaginationBox = styled(Box)({
  "& .viewall_txt": {
    color: "#206575",
    fontSize: "14px",
    cursor: "pointer"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff"
  }
});
// Customizable Area End

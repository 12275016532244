import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { customAlert, getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { ChangeEvent } from "react";
import { ResponseErrors, defaultStateValues } from "./AdminStateListController.web";

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    apiBody?: object;
    body?: object;
    token?: string;
    type?: string;
}

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

export interface InvalidResponseType {
    errors: Array<string>;
}

interface StateData {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    profile_country_id: number;
}

interface DistrictAttributes {
    id: number;
    name: string;
    state: StateData;
    created_on: string;
    updated_on: string;
}

export interface DistrictData {
    id: string;
    type: string;
    attributes: DistrictAttributes;
}

interface MetaData {
    message: string;
    page: string;
    total_district: number;
}

interface ApiResponse {
    data: DistrictData[];
    meta: MetaData;
    error?: string;
}

interface ErrorResponse {
    data: {
        attributes: { name: string };
        name: string[];
    };
}

interface EditDistrictAttributes {
    id: number;
    name: string;
    state: StateData;
    created_on: string;
    updated_on: string;
}

interface EditDistrictData {
    id: string;
    type: string;
    attributes: EditDistrictAttributes;
}

interface Meta {
    message: string;
}

interface EditDistrictDetails {
    data: EditDistrictData;
    meta: Meta;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userToken: string;
    loader: boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    isDrawer: boolean;
    takeAction: string;
    anchorEl: HTMLButtonElement | null;
    currentPageCount: number;
    perPage: number;
    adminDistrictListData: ApiResponse;
    pageCount: number;
    districtId: string | number;
    openDeleteModal: boolean;
    isUpdate: boolean;
    stateData: ApiResponse;
    stateId: string | number;
    districtName: string;
    currentSortState: { order: string; field: string };
    columnSort: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminDistrictController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    endPointApiGetDistrict: string = "";
    deleteDistrictApiCallId: string = "";
    getStatelistApiCallId: string = "";
    postAddDistrictApiCallId: string = "";
    showDistrictApiCallId: string = "";
    updateDistrictApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationIdMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            ...defaultStateValues,
            adminDistrictListData: {
                data: [],
                meta: {
                    message: "",
                    page: "",
                    total_district: 0
                }
            },
            pageCount: 0,
            districtId: "",
            openDeleteModal: false,
            isUpdate: false,
            stateData: {
                data: [],
                meta: {
                    message: "",
                    page: "",
                    total_district: 0
                }
            },
            stateId: "",
            districtName: "",
            currentSortState: { order: "desc", field: "id_sort" },
            columnSort: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isDistrictsValidResponse(responseJson)) {
                if(!responseJson.errors) {
                    this.apiSuccessCallBacks(apiRequestCallId, responseJson);
                } else {
                    this.parseErrors(responseJson)
                }
            };
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
            if (propsData) {
                this.handleShowDistrict(propsData.districtId)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.getUserToken()
    }

    getUserToken = async () => {
        const token = await getStorageData('loginToken');
        this.setState({ userToken: token });
        await this.getDistrictListDataFunction(this.state.currentPageCount, this.state.perPage, this.state.columnSort);
        await this.getStateListData();
    };

    adminStateListApiCall = (apiCallData: APIPayloadType) => {
        let { endPoint, body, method, contentType, type } = apiCallData;
        const header = {
            "Content-Type": contentType,
            "token": this.state.userToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? body : JSON.stringify(body)
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    isDistrictsValidResponse = (responseJson: ValidResponseType) => {
        return responseJson;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ApiResponse & Array<StateData> & ErrorResponse & EditDistrictDetails) => {
        if (apiRequestCallId === this.endPointApiGetDistrict) {
            this.handleGetDistrictsListResponse(responseJson);
        }
        if (apiRequestCallId === this.deleteDistrictApiCallId) {
            this.handleDeleteDistrictResponse(responseJson);
        }
        if (apiRequestCallId === this.getStatelistApiCallId) {
            this.handleGetStateListResponse(responseJson);
        }
        if (apiRequestCallId === this.postAddDistrictApiCallId) {
            this.handlePostDistrictResponse(responseJson);
        }
        if (apiRequestCallId === this.showDistrictApiCallId) {
            this.handleShowAdminDistrictResponse(responseJson);
        }
        if (apiRequestCallId === this.updateDistrictApiCallId) {
            this.handleUpdateAdminDistrictResponse(responseJson);
        }
    };
    
    parseErrors = async (responseJson: ResponseErrors) => {
        if (Object.keys(responseJson.errors[0])[0] === "token") {
          this.navigationLoginScreen();
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      };

    handleUpdateAdminDistrictResponse = async (response: ErrorResponse) => {
        const { data } = response;

        if (data?.attributes?.name.length > 0) {
            const alertMsg = configJSON.districtUpdateSuccessMsg;
            const alertType: Color = configJSON.districtSuccessMsg;

            this.handleFieldReset();
            await this.getDistrictListDataFunction(this.state.currentPageCount, this.state.perPage, this.state.columnSort);

            this.setState({
                isAlert: true,
                alertMsg,
                alertType
            }, () => {
                this.navigationDistrictListScreen();
            });
        } else if (data?.name[0] !== configJSON.postStateErrorMsg) {
            const errorMsg = data?.name[0] || configJSON.deleteErrorMsg;
            const errorType = configJSON.districtErrorMsg;

            const newState = {
                loader: false,
                isAlert: true,
                alertMsg: errorMsg,
                alertType: errorType,
            };
            this.setState(newState);
        }
    };

    handleShowAdminDistrictResponse = async (response: EditDistrictDetails) => {
        const { data } = response;
        const attributes = data?.attributes || {};

        this.setState({
            isUpdate: true,
            loader: false,
            stateId: attributes.state?.id || this.state.stateId,
            districtName: attributes.name || this.state.districtName,
            districtId: attributes.id
        });
    };

    handlePostDistrictResponse = async (response: ErrorResponse) => {
        const { data } = response;

        if (data?.attributes?.name.length > 0) {
            this.handleFieldReset();
            this.navigationDistrictListScreen();
        } else if (data?.name[0] !== configJSON.postStateErrorMsg) {
            const errorMsg = data?.name[0] || configJSON.deleteErrorMsg;
            const errorType = configJSON.districtErrorMsg;

            const newState = {
                loader: false,
                isAlert: true,
                alertMsg: errorMsg,
                alertType: errorType,
            };
            this.setState(newState);
        }
    };

    handleSort(field: string) {
        const sortParams: Record<string, string> = {
            id: "id_sort",
            'District Name': "name_sort",
            State: "state_name_sort"
        };
        let fieldSort = "";
        const sortOrder = this.state.currentSortState.order === "asc" ? "desc" : "asc";

        if (sortParams.hasOwnProperty(field)) {
            fieldSort = `&${sortParams[field]}=${sortOrder}`;
        }
        this.toggleSortState(field, fieldSort);
        this.getDistrictListDataFunction(this.state.currentPageCount, this.state.perPage, fieldSort);
    }

    toggleSortState = (field: string, columnSort: string) => {
        this.setState({
            columnSort,
            currentSortState: {
                field,
                order: this.state.currentSortState.order === "asc" ? "desc" : "asc"
            }
        });
    };

    handleGetStateListResponse = async (response: ApiResponse) => {
        this.setState({
            stateData: response,
            loader: false,
        });
    };

    handleDeleteDistrictResponse = (response: { error?: string }) => {
        const successMsg = configJSON.districtDeleteSuccessMsg;
        const successType = configJSON.districtSuccessMsg;
        const errorMsg = response.error || configJSON.deleteErrorMsg;
        const errorType = configJSON.districtErrorMsg;

        if (response.error) {
            this.setState({
                loader: false,
                isAlert: true,
                alertMsg: errorMsg,
                alertType: errorType,
                openDeleteModal: false
            });
        } else {
            this.setState({
                loader: false,
                isAlert: true,
                alertMsg: successMsg,
                alertType: successType,
                openDeleteModal: false,
            }, () => this.getDistrictListDataFunction(this.state.currentPageCount, this.state.perPage, this.state.columnSort));
        }
    };

    handleGetDistrictsListResponse = (response: ApiResponse) => {
        const pageCount = Math.ceil(response.meta.total_district / this.state.perPage);
        this.setState({
            adminDistrictListData: response,
            loader: false,
            pageCount: pageCount
        });
    };

    handleStateSelectChange = (stateId: string) => {
        this.setState({
            stateId: stateId
        });
    };

    handleDistrictName = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;

        if (inputValue === '' || this.validateDistrictInput(inputValue)) {
            this.setState({
                districtName: inputValue,
                isAlert: false,
            });
        } else {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.validationErrorMsg,
                alertType: configJSON.errorMsg
            });
        }

    };

    validateDistrictInput(input: string) {
        const regex = /^(?!\s*$)(?!^[^a-zA-Z]*$)(?!^[\W_]*$).{1,30}$/;
        return regex.test(input);
    }

    handleCloseDeleteModal = () => {
        this.setState({
            openDeleteModal: false,
            districtId: ""
        });
    };

    handleOpenModal = (deletId: number | string) => {
        this.setState({
            openDeleteModal: true,
            districtId: deletId
        });
    };

    handleFieldReset = () => {
        this.setState({ stateId: "", districtName: "" });
    };

    onCloseAlert = () => {
        this.setState({ isAlert: false });
    };

    onDistrictsPageChange = (event: ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ currentPageCount: newPageCount }, () => {
            this.getDistrictListDataFunction(newPageCount, this.state.perPage, this.state.columnSort);
        });
    };

    getBlankField = (districtName: string, stateId: number) => {
        if (!stateId) return configJSON.stateText;
        if (!districtName) return configJSON.districtNameText;
        return null;
    };

    handleUpdateDistrict = async () => {
        const { districtName, stateId } = this.state;
        const blankField = this.getBlankField(districtName, Number(stateId));

        if (blankField) {
            this.setState({
                isAlert: true,
                alertMsg: `${blankField}${configJSON.errorMsgDistrictBlank}`,
                alertType: configJSON.districtErrorMsg
            });
        } else {
            let body = {
                name: this.state.districtName.trim(),
                state_id: Number(this.state.stateId)
            }
            const endPoint = `${configJSON.endPointApiGetDistrict}${this.state.districtId}`;
            this.updateDistrictApiCallId = this.adminStateListApiCall({
                contentType: configJSON.contentTypeApiUpdateUser,
                method: configJSON.apiUpdateUserType,
                endPoint,
                body: body
            });
        };
    };

    handlePostDistrict = async () => {
        const { districtName, stateId } = this.state;
        const blankField = this.getBlankField(districtName, Number(stateId));

        if (blankField) {
            this.setState({
                isAlert: true,
                alertMsg: `${blankField}${configJSON.errorMsgDistrictBlank}`,
                alertType: configJSON.districtErrorMsg
            });
        } else {
            let body = {
                name: this.state.districtName.trim(),
                state_id: Number(this.state.stateId)
            }
            this.postAddDistrictApiCallId = this.adminStateListApiCall({
                contentType: configJSON.contenttypeApiValidateMobileNo,
                method: configJSON.callTypeApiValidateMobileNo,
                endPoint: configJSON.endPointApiPostDistrict,
                body: body
            });
        };
    };

    handleShowDistrict = async (selectedDistrictId: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.endPointApiGetDistrict}${selectedDistrictId}`;
        this.showDistrictApiCallId = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    getStateListData = async () => {
        this.setState({ loader: true });
        const endPoint = configJSON.endPointApiGetStateList;
        this.getStatelistApiCallId = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    getDistrictListDataFunction = async (pageNo: number, perPage: number, sorting: string) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.endPointApiGetDistrict}?page=${pageNo}&per_page=${perPage}${sorting||""}`;
        this.endPointApiGetDistrict = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    handleDeleteDistrict = async () => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.deleteDistrictAPIEndPoint}/${this.state.districtId}`;
        this.deleteDistrictApiCallId = this.adminStateListApiCall({
            contentType: configJSON.statesApiContentType,
            method: configJSON.httpDeleteType,
            endPoint
        });
    };

    navigationAddDistrictScreen = () => {
        const addDistrictScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAddAdminDistrictMessage)
        );
        addDistrictScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(addDistrictScreenMsg);
    };

    navigationDistrictListScreen = () => {
        const DistrictListScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAdminDistrictListMessage)
        );
        DistrictListScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(DistrictListScreenMsg);
    };

    navigationEditDistrictScreen = (selectedDistrictId: string | number) => {
        const editDistrictScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAddAdminDistrictMessage)
        );
        editDistrictScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        editDistrictScreenMsg.addData(getName(MessageEnum.NavigationIdMessage), { districtId: selectedDistrictId });
        this.send(editDistrictScreenMsg);
    };
    
    navigationLoginScreen = () => {
        alert(configJSON.sessionExpireMsg)
        const messageLogin: Message = new Message(
          getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messageLogin.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(messageLogin);
      };
    // Customizable Area End
}
import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button,
    Snackbar,
    Modal,
    TextField,
    NativeSelect,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Tooltip,
    IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import InfoIcon from '@material-ui/icons/Info';
import { FaChevronDown } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IoIosArrowDown } from "react-icons/io";
import { Pagination } from "@material-ui/lab";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { RxCross2 } from "react-icons/rx";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
const drawerWidth = 250;
const closeDrawer = 0;
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";
import moment from "moment";
// Customizable Area End

import AdminCourseClassesController, {
    Props,
    configJSON,
} from "./AdminCourseClassesController.web";
import { webStyles } from "./AdminCourses.web";

export default class AdminCourseClasses extends AdminCourseClassesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderTableBodyCourseClasses = (item: any, index: any) => {
        return (
            <>
                <tr>
                    <td style={{ width: 50 }}>
                        <Box className="desc_checkbox">
                            <input
                                data-test-id={"rowCheckboxTestId"}
                                type="checkbox"
                                checked={this.state.isChecked[index]}
                                onChange={() => this.handleCourseClassesRowCheckboxChange(index)}
                                style={courseClassesDrawerStyle.input}
                            />
                        </Box>
                    </td>
                    <td className="tr_desc" style={{ textAlign: "left", width: 50 }}>{item.id}</td>
                    <td style={{ textAlign: "left", width: 250 }}>{item.attributes.course_chapter.name?.length > 26 ? `${item.attributes.course_chapter.name.slice(0, 26)}...` : item.attributes.course_chapter.name || "----"}</td>
                    <td style={{ textAlign: "left" }}>{item.attributes.title?.length > 26 ? `${item.attributes.title.slice(0, 26)}...` : item.attributes.title || "----"}</td>
                    <td style={{ textAlign: "left" }} data-test-id="create_date">{moment(item.attributes.created_at).format("DD/MM/YY")}</td>
                    <td style={{ width: 50 }}>
                        {<CourseClassesCheckStatusBtn className="switch">
                            <input type="checkbox" data-test-id={'statusCheckTestId'} onChange={() => this.handleCourseclassStatus(item.id)} checked={item.attributes.status} />
                            <span className="slider round"></span>
                        </CourseClassesCheckStatusBtn>}
                    </td>
                    <td style={{ width: 50 }}>
                        <Box className="desc_edit_icon_box">
                            <PiPen
                                data-test-id={'editIconTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={20}
                                onClick={() => this.handleShowCourseClasses(item.id)}
                            />
                            <FaRegTrashAlt
                                data-test-id={'deleteIconeTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={17}
                                onClick={() => this.handleOpenDeleteCourseClassesModal(item.id)}
                            />
                        </Box>
                    </td>
                </tr>
                <Box className="table_divider" style={courseClassesDrawerStyle.drawerTableDivider} />
            </>
        )
    }

    renderTableCourseClasses = () => {
        const isCurrentSort = (name: string) =>
            this.state.currentSortState.field === name &&
            this.state.currentSortState.order === "asc";
        return (
            <Box>
                <TableStyle>
                    <thead>
                        <tr>
                            <th>
                                <Box
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        data-test-id="headerCheckboxTestId"
                                        style={courseClassesDrawerStyle.input}
                                        checked={this.state.isHeaderChecked}
                                        onChange={this.handleCourseClassesHeaderCheckboxChange}
                                    />
                                </Box>
                            </th>
                            <th>  <Box className="th_box" style={{justifyContent: "flex-start"}}>
                                <Typography className="title_th">S.No</Typography>
                                <IoIosArrowDown
                                    className={`filter_icon ${isCurrentSort("id") ? "active" : ""}`}
                                    onClick={() => this.handleSort("id")}
                                    data-test-id="sort_id"
                                    style={{
                                        transform: isCurrentSort("id") ? "none" : "rotate(180deg)"
                                    }}
                                />
                            </Box></th>
                            {[
                                "Course Chapters",
                                "Title","Created Date"
                            ].map((header, index) => {
                                let renderIcon = false;
                                if (["Course Chapters"].includes(header)) {
                                    renderIcon = true;
                                }
                                return renderTableRecord(
                                    () => isCurrentSort(header),
                                    header,
                                    renderIcon,
                                    index,
                                    () => this.handleSort(header),
                                    "sort_name",
                                    "left"
                                );
                            })
                            }
                          
                            <th>
                                <Box className="th_box" style={{justifyContent: "center"}}>
                                    <Typography className="title_th">Status</Typography>
                                    <IoIosArrowDown
                                        className={`filter_icon ${isCurrentSort("status") ? "active" : ""}`}
                                        onClick={() => this.handleSort("status")}
                                        data-test-id="sort_status"
                                        style={{
                                            transform: isCurrentSort("status") ? "none" : "rotate(180deg)"
                                        }}
                                    />
                                </Box>
                            </th>
                            <th>
                                <Box className="th_box" style={{justifyContent: "center"}}>
                                    <Typography className="title_th">Action</Typography>
                                </Box>
                            </th>
                        </tr>
                    </thead>
                    <span style={courseClassesDrawerStyle.drawerDividerLine}></span>
                    <TableBody>
                        {this.state.adminCourseClassesData?.data?.map((item: any, index: any) => this.renderTableBodyCourseClasses(item, index))}
                    </TableBody>
                </TableStyle>
                <CourseClassesPaginationBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <Pagination
                        data-test-id="pageChangebutton"
                        count={this.state.pageCount}
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.currentPageCount}
                        siblingCount={0}
                        onChange={this.onCourseClassesPageChange}
                    />
                </CourseClassesPaginationBox>
            </Box>
        )
    }

    notificationAlertMessageCourseClasses = () => {
        return (
            <Snackbar
                autoHideDuration={3000}
                open={this.state.isAlert}
                onClose={this.onCloseAlert}
                anchorOrigin={{ horizontal: 'center', vertical: "top" }}
            >
                <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
            </Snackbar>
        )
    }

    renderDeleteInstituteModal = () => {
        return (
            <>
                <CourseClassesDeleteModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="InstituteDeleteModalId"
                    open={this.state.openDeleteCourseClassesModal}
                    onClose={this.handleCloseDeleteCourseClassesModal}
                >
                    <CourseClassesModalView>
                        <CourseClassesDeleteText component={"p"}>
                            Do you want to delete this course class?
                        </CourseClassesDeleteText>
                        <CourseClassesModalButton>
                            <CourseClassesDeleteButton data-test-id="deleteBtnTestId"
                                onClick={() => this.handleDeleteCourseClasses(this.state.deleteCourseClassesId)}
                            >Delete</CourseClassesDeleteButton>
                            <CourseClassesCancelButton data-test-id="cancelBtnTestId"
                                onClick={() => this.handleCloseDeleteCourseClassesModal()}
                            >Cancel</CourseClassesCancelButton>
                        </CourseClassesModalButton>
                    </CourseClassesModalView>
                </CourseClassesDeleteModal>
            </>
        )
    }

    renderAddCourseClassesModal = () => {
        const { selectedOption, videoUrl, videoFileName } = this.state;
        const videoFileText = !videoFileName
            ? "Upload video"
            : videoFileName;
        return (
            <>
                <AddCourseClassesModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="AddInstituteModalId"
                    open={this.state.openAddCourseClassesModal}
                    onClose={this.handleCloseCourseClassModal}
                >
                    <AddCourseClassesModalView>
                        {this.notificationAlertMessageCourseClasses()}
                        <AddCourseClassesModalBox>
                            <AddCourseClassesHeadingText >{this.state.isUpdate ? configJSON.editCourseClasses : configJSON.addCourseClasses}</AddCourseClassesHeadingText>
                            <RxCross2 onClick={() => this.handleCloseCourseClassModal()} style={{ height: "25px", width: "25px" }} />
                        </AddCourseClassesModalBox>
                        <CourseClassesFieldBox>
                            {this.renderChapterNameSelect()}
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Title</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseChapterInputBox
                                        variant="outlined" fullWidth
                                        data-test-id="titleInputTestId"
                                        onChange={this.handleTitle}
                                        value={this.state.title}
                                    />
                                    <span style={webStyles.errorText}>
                                        {this.state.titleError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">{configJSON.details}</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseClassDetailsInputBox
                                        variant="outlined" fullWidth
                                        minRows={3}
                                        maxRows={3}
                                        multiline
                                        data-test-id="detailsInputTestId"
                                        onChange={this.handleDetail}
                                        value={this.state.detail}
                                    />
                                    <span style={webStyles.errorText}>
                                        {this.state.detailError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            {this.renderRadioButton()}
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt"></Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {selectedOption === 'url' && (
                                        <CourseChapterInputBox
                                            type="text"
                                            variant="outlined"
                                            value={videoUrl}
                                            onChange={this.handleUrlChange}
                                            fullWidth
                                        />
                                    )}
                                    {selectedOption === "upload_video" && (
                                        <>
                                            <Box className="file_upload_box">
                                                <Typography className="app_icon_txt">
                                                    {configJSON.uplaodVideoText}
                                                </Typography>
                                                <input
                                                    data-test-id="uploadLearningMaterialId"
                                                    type="file"
                                                    accept="video/*"
                                                    name="learningMaterialFileUpload"
                                                    id="learningMaterialInput"
                                                    style={{ display: "none" }}
                                                    disabled={this.state.fileIsUploading}
                                                    onChange={this.handleFileChange}
                                                    multiple={this.state.isUpdate ? false : true}
                                                />
                                                <video
                                                    ref={this.state.videoRef}
                                                    style={{ display: "none" }}
                                                />
                                                <canvas
                                                    ref={this.state.canvasRef}
                                                    style={{ display: "none" }}
                                                />
                                                <label
                                                    htmlFor="learningMaterialInput"
                                                    className="lable_txt"
                                                >
                                                    {configJSON.browserText}
                                                </label>
                                            </Box>
                                            {this.state.fileIsUploading ? (
                                                <CircularProgress size={32} style={{ margin: "20px auto" }} color="inherit" />
                                            ) : (
                                            this.state.videoFileThumbnail  && ( 
                                                    this.state.videoFileThumbnail.map(((item:string, index: number) => (
                                                        <Box style={{ margin: "10px 0", display: "flex", gap: 10, alignItems: "center", width: "100%" }}>
                                                        <div style={{ height: "100%" }}>
                                                            <img
                                                                src={item}
                                                                alt="thumbnail preview"
                                                                width={60}
                                                                height={33.75}
                                                                style={{ borderRadius: 5, aspectRatio: "16/9", objectFit: "contain" }}
                                                            />
                                                        </div>
                                                        <div style={{ height: "100%" }}>
                                                            <strong style={{ margin: 0, fontSize: "0.75rem" }}>{this.state.videoFileName[index]}</strong>
                                                            <p style={{ color: "#969696", margin: "3px 0", fontSize: "0.75rem" }}>{this.state.videoFileSize}</p>
                                                        </div>
                                                        <div style={{ marginLeft: "auto" }}>
                                                            <Button type="button" variant="text" color="secondary" data-test-id="fileRemoveTestId" onClick={() => this.handleRemoveFile(index)}>REMOVE</Button>
                                                        </div>
                                                    </Box>
                                                )
                                            ))))}
                                        </>
                                    )}
                                    <span style={webStyles.errorText}>
                                        {this.state.videoUrlFileError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Duration (Minutes)</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseChapterInputBox
                                        variant="outlined" fullWidth
                                        data-test-id="durationsInputTestId"
                                        onChange={this.handleDuration}
                                        value={this.state.duration}
                                    />
                                    <span style={webStyles.errorText}>
                                        {this.state.durationError}
                                    </span>
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Status</Typography>
                                <CourseChapterheckBodButton className="switch">
                                    <input type="checkbox"
                                        data-test-id="checkboxId"
                                        onChange={this.handleCourseClassStatusCheck} checked={this.state.courseClassStatus}
                                    />
                                    <span className="slider round"></span>
                                </CourseChapterheckBodButton>
                            </CourseClassesFieldControlBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">Featured</Typography>
                                <CourseChapterheckBodButton className="switch">
                                    <input type="checkbox"
                                        data-test-id="checkboxId"
                                        onChange={this.handleFeaturedCheck} checked={this.state.featured}
                                    />
                                    <span className="slider round"></span>
                                </CourseChapterheckBodButton>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <CourseChapterButtonBox>
                            {this.state.isUpdate ?
                                <Button variant="contained" className="reset_btn"
                                    onClick={() => this.handleCloseCourseClassModal()}
                                >
                                    Cancel
                                </Button> :
                                <Button variant="contained" className="reset_btn"
                                    onClick={this.handleFeildReset}
                                >
                                    Reset
                                </Button>}
                            {this.state.isUpdate ?
                                <Button
                                    data-test-id="updateButtonTestId"
                                    onClick={this.handleEditCourseClass}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    {this.state.loader ? <CircularProgress size={20} color="inherit" /> : "Update"}
                                </Button> :
                                <Button
                                    data-test-id="addClassesButtonTestId"
                                    onClick={this.handlePostCourseClasses}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    {this.state.loader ? <CircularProgress size={20} color="inherit" />:  "Add Classes"}
                                </Button>}
                        </CourseChapterButtonBox>
                    </AddCourseClassesModalView>
                </AddCourseClassesModal>
            </>
        )
    }

    renderChapterNameSelect = () => {
        return (
            <CourseClassesFieldControlBox>
                <Typography className="input_txt">Chapter Name</Typography>
                <div
                    style={{
                        display: "flex",
                        width: "calc(100% - 160px)",
                        flexWrap: "wrap",
                    }}
                >
                    <SelectBox
                        id="demo-customized-select-native"
                        disableUnderline
                        IconComponent={FaChevronDown}
                        data-test-id="courseChapterSelectTestId"
                        onChange={(event) => this.handleCourseChapterSelectChange(event.target.value)}
                        value={this.state.courseChapterId}
                    >
                        <option aria-label="None" value="" />
                        {this.state.courseChapterListData.map((course: {
                            attributes: { name: string }; id: string,
                        }) => {
                            return (
                                <option key={course.id} value={course.id}>
                                    {course.attributes.name}
                                </option>
                            );
                        })}
                    </SelectBox>
                    <span style={webStyles.errorText}>
                        {this.state.courseChapterIdError}
                    </span>
                </div>
            </CourseClassesFieldControlBox>
        )
    }

    renderRadioButton = () => {
        const { selectedOption } = this.state;
        return (
            <CourseClassesFieldControlBox>
                <Typography className="input_txt">Type</Typography>
                <FormControl component="fieldset">
                    <RadioGroup
                        data-test-id="videoOption"
                        aria-label="videoOption"
                        name="videoOption"
                        value={selectedOption}
                        onChange={this.handleChange}
                    >
                        <FormControlLabel value="url" control={<Radio />} label="URL" />
                        <FormControlLabel value="upload_video" control={<Radio />} label="Upload Video" />
                        {this.state.isUpdate && (
                            <Tooltip title={configJSON.editMultiVideoUploadMsg} arrow>
                                <IconButton>
                                    <InfoIcon style={{ fontSize: "1rem" }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </RadioGroup>
                    <span style={webStyles.errorText}>
                        {this.state.selectedOptionError}
                    </span>
                </FormControl>
            </CourseClassesFieldControlBox>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainLayout
                data-test-id="mainLayoutEvent"
                handleSearch={(e: any) => this.handleCourseClassesSearch(e)}
                isLoading={this.state.loader}
                title={"Course Classes"}
                actionButtons={[
                {
                    icon: <AddCircleOutlineIcon />,
                    iconType: "jsxElement",
                    onClick: () => this.handleOpenAddCourseClassModal(),
                    text: "Create Course Classes",
                    testId: "addCourseClassButtonId"
                }
                ]}
                isStudent={false}
            >
                {this.notificationAlertMessageCourseClasses()}
                {this.renderDeleteInstituteModal()}
                {this.renderAddCourseClassesModal()}
                <main className="table_content">
                    {this.renderTableCourseClasses()}
                </main>
            </MainLayout>  
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const courseClassesDrawerStyle = {
    drawerTableDivider: { height: "8px" },
    hideChildCatDrawer: { width: `calc(100% - ${closeDrawer}px)` },
    showChildCatDrawer: { width: `calc(100% - ${drawerWidth}px)` },
    input: {
        height: "20px",
        width: "20px",
        display: "flex",
        border: "3px solid black",
        background: "none",
        alignItems: "center",
        justifyContent: "center",
    },
    drawerDividerLine: {
        height: "20px",
        display: "inline-block",
    },
};

const CourseClassesPaginationBox = styled(Box)({
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#206575",
        color: "#fff",
    },
    "& .viewall_txt": {
        color: "#206575",
        fontSize: "14px",
        cursor: "pointer",
    }
});

const CourseClassesCheckStatusBtn = styled("label")({
    "& .slider": {
        position: "absolute",
        right: 0,
        cursor: "pointer",
        left: 0,
        top: 0,
        bottom: "-1px",
        WebkitTransition: ".4s",
        backgroundColor: "#ccc",
        transition: ".4s",
        borderRadius: "20px"
    },
    "&.switch": {
        display: "inline-block",
        position: "relative",
        width: "36px",
        marginTop: "5px",
        height: "19px",
        "& input": {
            height: 0,
            opacity: 0,
            width: 0,
        },
    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        content: '""',
        position: "absolute",
        height: "18px",
        left: "1px",
        width: "18px",
        bottom: "1px",
        WebkitTransition: ".4s",
        backgroundColor: "white",
        borderRadius: "50%",
        transition: ".4s"
    },
    "& input:checked + .slider:before": { left: "-10px" }
});

const CourseClassesModalView = styled(Box)({
    height: "120px",
    width: "340px",
    borderRadius: '5px',
    padding: "10px 50px",
    outline: "none",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column"
});

const CourseClassesDeleteText = styled(Box)({
    width: "100%",
    height: "37px",
    fontWeight: 600,
    fontSize: "16px",
});

const CourseClassesCancelButton = styled("button")({
    width: "145px",
    height: "40px",
    color: "#206575",
    fontSize: "16px",
    border: "1px solid #206575",
    backgroundColor: "white"
});

const CourseClassesDeleteButton = styled("button")({
    width: "145px",
    border: "0",
    height: "45px",
    color: "white",
    backgroundColor: "#DA1831",
    fontSize: "16px",
    fontWeight: 500
});

const CourseClassesModalButton = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const CourseClassesDeleteModal = styled(Modal)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
});

const AddCourseClassesModal = styled(Modal)({
    height: "100%",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    display: "flex"
});

const AddCourseClassesModalView = styled(Box)({
    height: "580px",
    padding: "10px 30px",
    borderRadius: '18px',
    width: "651px",
    backgroundColor: '#ffffff',
    outline: "none",
    flexDirection: "column",
    margin: "20px",
    display: "flex",
    boxShadow: "0px 0px 3px px transparent",
    overflowY: 'auto'
});

const CourseClassesFieldControlBox = styled(Box)({
    marginRight: '93px',
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "8px 0",
    display: "flex",
    "& .input_txt": {
        width: "160px",
        fontStyle: "normal",
        fontFamily: "Inter",
        textTransform: "capitalize",
        fontSize: "16px",
        fontWeight: 400,
        color: "#192252",
        lineHeight: "24px"
    },
    "& .file_upload_box": {
        borderColor: "#D4D4D4",
        color: "#fff",
        height: "44px",
        justifyContent: "space-between",
        borderRadius: "4px",
        border: "1px solid black",
        display: "flex",
        width: "490px",
        alignItems: "center",
        "& .lable_txt": {
            alignItems: "center",
            display: "flex",
            backgroundColor: "#3F6474",
            justifyContent: "center",
            fontSize: "12px",
            marginRight: "2px",
            borderRadius: "4px",
            height: "90%",
            padding: "0px 30px",
        },
        "& .app_icon_txt": {
            wordSpacing: "-2px",
            marginLeft: "10px",
            fontSize: "13px",
            color: "#A9A9A9",
        },
    },
    "@media (max-width:875px)": {
        alignItems: "start",
        flexDirection: "column",
        "& .input_txt": {
            marginBottom: "8px",
            width: "100%",
        }
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#3f6474"
    },
    "& .MuiFormGroup-root": {
        flexDirection: "row"
    }
});

const CourseChapterInputBox = styled(TextField)({
    width: "490px",
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
    "& .MuiOutlinedInput-input": { padding: "0px 14px" },
    "& .MuiInputBase-input": { height: "44px" },
});

const CourseClassesFieldBox = styled(Box)({
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    "@media (max-width:1330px)": {
        alignItems: "start",
        flexDirection: "column",
    }
});

const CourseChapterheckBodButton = styled("label")({
    "& .slider": {
        bottom: "-1px",
        position: "absolute",
        top: 0,
        borderRadius: "20px",
        backgroundColor: "#ccc",
        transition: ".4s",
        cursor: "pointer",
        right: 0,
        left: 0,
        WebkitTransition: ".4s",
    },
    "& input:checked + .slider:before": { left: "-10px" },
    "&.switch": {
        position: "relative",
        width: "36px",
        marginTop: "5px",
        "& input": {
            height: 0,
            opacity: 0,
            width: 0,
        },
        display: "inline-block",
        height: "19px",
    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        height: "18px",
        WebkitTransition: ".4s",
        content: '""',
        position: "absolute",
        bottom: "1px",
        left: "1px",
        backgroundColor: "white",
        borderRadius: "50%",
        transition: ".4s",
        width: "18px",
    },
});

const CourseChapterButtonBox = styled(Box)({
    display: "flex",
    width: "100%",
    margin: "25px 0",
    justifyContent: "center",
    alignItems: "center",
    "& .create_btn": {
        color: "#fff",
        fontSize: "14px",
        backgroundColor: "#3f6474",
        borderRadius: "0",
        boxShadow: "none",
        margin: "0px 10px",
        fontWeight: 700,
        fontFamily: "Inter",
        textTransform: "capitalize",
        fontStyle: "normal",
        lineHeight: "22px",
        height: "40px",
        width: "179px",
    },
    "& .reset_btn": {
        border: "1px solid #3F6474",
        width: "108px",
        padding: "10px 60px",
        fontSize: "14px",
        color: "#3f6474",
        backgroundColor: "#fff",
        borderRadius: "0",
        textTransform: "capitalize",
        fontFamily: "Inter",
        boxShadow: "none",
        height: "40px",
        margin: "0px 10px",
        lineHeight: "22px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "@media (max-width:460px)": {
        "& .create_btn": {
            padding: '5px 48px',
            height: '40px',
        },
        "& .reset_btn": {
            padding: '5px 50px',
            height: '40px',
        },
    },
    "@media (max-width:400px)": {
        "& .create_btn": { width: '100%' },
        "& .reset_btn": { marginBottom: '10px', width: '100%' },
        flexDirection: 'column',
    },
});

const AddCourseClassesModalBox = styled(Box)({
    paddingTop: 10,
    justifyContent: "space-between",
    paddingBottom: 10,
    width: "100%",
    display: "flex",
});

const AddCourseClassesHeadingText = styled(Typography)({
    fontSize: "18px",
    fontFamily: "Inter",
    letterSpacing: "-0.12px",
    color: "#000",
    fontStyle: "normal",
    lineHeight: "26px",
    fontWeight: 700,
});

const SelectBox = styled(NativeSelect)({
    "&.MuiInputBase-root": {
        border: "1px solid lightgray",
        width: "490px",
        borderRadius: "4px",
    },
    "& .MuiNativeSelect-icon": {
        top: "calc(50% - 7px);",
        right: "6px",
    },
    "& .MuiInputBase-input": { padding: "10px 0px 10px 8px" }
});

const CourseClassDetailsInputBox = styled(TextField)({
    "& .MuiOutlinedInput-input": { padding: "0px" },
    width: "490px",
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
    "& .MuiInputBase-input": { height: "86px" },
});
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface BannerListResponseType {
  data: BannerListData[];
}

export interface BannerResponseType {
  data: BannerListData;
}

export interface BannerListData {
  id: number;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  banner_title: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  banner_image: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  index: number;
  imgData: BannerListData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarouselDisplayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getWebCenterBannerApiCallId: string = "";
  swiper: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      index: 0,
      imgData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.getWebCenterBannerApiCallId) {
        if (responseJson.data as BannerListResponseType) {
          this.setState({ imgData: responseJson.data })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  thumbnailControl = (index: number) => {
    let currentIndex = this.state.index;
    if (currentIndex !== index) {
      let resultSlide = 0;
      let countSlides = this.state.imgData.length;

      if (index > currentIndex && index !== countSlides) {
        resultSlide = index - currentIndex;
      } else if (index > currentIndex && index === countSlides) {
        resultSlide = currentIndex + 1;
      } else if (index < currentIndex && index !== 0) {
        resultSlide = (currentIndex - index) * -1;
      } else if (index < currentIndex && index === 0) {
        resultSlide = currentIndex * -1;
      }
      this.swiper.scrollBy(resultSlide, true);
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getWebCenterBannerData()
  }

  getWebCenterBannerData() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWebCenterBannerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWebTopBannerApiEndPoint + '?banner_type=footer_banner'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangeCarousel = (index:number) => this.setState({ index })

  handleThumbnailClick(index: number) {
    this.setState({ index });
  }
  // Customizable Area End
}

import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { initialValues } from "./OrderManagementController.web";
import { apiService } from "./RazorpayTransactionsController.web";

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface PaymentListResponseType {
  data: Payment[];
  meta: Meta;
  errors: any[];
}

export interface Payment {
  account_id: number;
  full_name: string;
  email: string;
  full_phone_number: string;
  transaction_id: string;
  course_name: string;
  created_at: string;
  transation_status: TransationStatus;
  order_id: number;
  address: string;
  total_price: number;
}
export enum TransationStatus {
  Pending = "Pending",
  Success = "success",
}

export interface Meta {
  page: string;
  total_payment_count: number;
}

export interface InvalidResponseType {
  errors: Array<string>;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  paymentsList: Payment[];
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isUpdate: boolean;
  perPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPaymentManagementListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ...initialValues,
      paymentsList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };

  async componentDidMount() {
    // Customizable Area Start
    await this.getUserToken();
    await this.getListDataFunction(1, this.state.perPage);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (!responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: PaymentListResponseType
  ) => {
    if (apiRequestCallId === this.getPaymentManagementListApiCallId) {
      this.handleGetListDataResponse(responseJson);
    }
  };

  handleGetListDataResponse = async (response: PaymentListResponseType) => {
    const { meta } = response;
    if (response && response?.errors) {
      if (response?.errors[0]?.token === configJSON.tokenExpiredMessage) {
        this.navigationLoginScreen();
      }
    } else {
      this.setState({
        paymentsList: response.data,
        pageCount: Math.ceil(meta.total_payment_count / this.state.perPage),
        isLoading: false,
      });
    }
  };

  getListDataFunction = async (pageNo: number, per_page: number) => {
    this.setState({ isLoading: true });
    this.getPaymentManagementListApiCallId = apiService({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      token: this.state.userToken,
      endPoint: `${configJSON.getPaymentManagementApiEndpoint}?page=${pageNo}&per_page=${per_page}`,
    });
  };

  onPageChange = (event: any, value: any) => {
    this.setState(
      {
        currentPageCount: Number(value),
      },
      () => {
        this.getListDataFunction(this.state.currentPageCount, this.state.perPage);
      }
    );
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const facebookLogo = require("../assets/logo-facebook.png");
export const twitterLogo = require("../assets/logo-twitter.png");
export const instagramLogo = require("../assets/logo-instagram.png");
export const gradient = require("../assets/gradient.png");
export const bgTitle = require("../assets/bg_title.png");
export const headerTitle = require("../assets/header_title.png");
export const mail = require("../assets/mail.png");
export const wishlist = require("../assets/wishlist.png");
export const chseLogo = require("../assets/chse_logo_rocket.png");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

const sortParams: Record<string, string> = {
    ["S.No"]: "id_sort",
    ["Institute Name"]: "name_sort",
    Status: "status_sort",
};

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    apiBody?: object;
    body?: object;
    token?: string;
    type?: string;
}

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

export interface AdminInstitutesResponseType {
    errors: { token: string }[];
    data: Array<InstitutesDataType>;
    meta: {
        total_institute: number;
        page: string;
        message: string;
    };
}

export interface InstitutesDataType {
    id: string;
    type: string;
    attributes: {
        name: string;
        address: string;
        district_id: number;
        state_id: number;
        status: boolean;
        state_name: string;
        institute_logo: string;
    };
}

export interface InvalidResponseType {
    errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    adminInstitutesData: AdminInstitutesResponseType;
    isDrawer: boolean;
    setfullWidth: boolean;
    currentPageCount: number;
    pageCount: number;
    userToken: string;
    loader: boolean;
    openDeleteInstituteModal: boolean;
    deleteInstituteId: string | number;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    isUpdate: boolean;
    perPage: number;
    searchedInstituteText: string;
    isHeaderChecked: boolean;
    isChecked: boolean[];
    openAddInstituteModal: boolean;
    instituteName: string;
    instituteAddress: string;
    instituteLogo: unknown;
    instituteLogoName: string;
    instituteLogoError: string;
    instituteLogoThumbnail: string;
    instituteStatus: any;
    stateListData: any[];
    stateId: string;
    districtId: string;
    instituteId: string | number;
    districtListData: any[];
    currentSortState: { order: string; field: string };
    columnSort: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminInstitutesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAdminInstitutesListApiCallId: string = "";
    deleteInstituteApiCallId: string = "";
    getStateListApiCallId: string = "";
    postAddInstituteApiCallId: string = "";
    showInstitutesApiCallId: string = "";
    editInstituteApiCallId: string = "";
    statusUpdateApicallID: string = "";
    getDistrictListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationIdMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            adminInstitutesData: {
                data: [],
                meta: {
                    page: "",
                    total_institute: 0,
                    message: ""
                },
                errors: [{ token: "" }]
            },
            isDrawer: true,
            setfullWidth: true,
            currentPageCount: 1,
            pageCount: 0,
            userToken: "",
            loader: true,
            openDeleteInstituteModal: false,
            deleteInstituteId: "",
            isAlert: false,
            alertMsg: "",
            alertType: "success",
            isUpdate: false,
            perPage: elementsPerPage,
            searchedInstituteText: "",
            isHeaderChecked: false,
            isChecked: [],
            openAddInstituteModal: false,
            instituteName: "",
            instituteAddress: "",
            instituteLogo: null,
            instituteLogoName: "",
            instituteLogoError: "",
            instituteLogoThumbnail: "",
            instituteStatus: true,
            stateListData: [],
            stateId: "",
            instituteId: "",
            districtListData: [],
            districtId: "",
            currentSortState: { order: "desc", field: "id_sort" },
            columnSort: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isInstituteValidResponse(responseJson)) {
                if(!responseJson.errors) {
                    this.apiSuccessCallBacks(apiRequestCallId, responseJson);
                } else {
                    this.parseErrors(responseJson);
                    this.setState({ loader: false})
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.getUserToken();
        await this.getScreenData();
    }

    async getScreenData() {
        await this.handleGetStatelist();
        await this.getInstitutesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedInstituteText, this.state.columnSort);
    }

    getUserToken = async () => {
        const token = await getStorageData('loginToken');
        this.setState({ userToken: token });
    };

    adminInstitutesApiCall = (data: APIPayloadType) => {
        let { contentType, method, endPoint, body, type } = data;
        const header = {
            "Content-Type": contentType,
            "token": this.state.userToken
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    isInstituteValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson);
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AdminInstitutesResponseType & { data: any[] } & { error?: string }) => {
        if (apiRequestCallId === this.getAdminInstitutesListApiCallId) {
            this.handleGetInstitutesCategoryListResponse(responseJson);
        }
        if (apiRequestCallId === this.deleteInstituteApiCallId) {
            this.handleDeleteInstituteListResponse(responseJson);
        }
        if (apiRequestCallId === this.getStateListApiCallId) {
            this.handleStateListResponse(responseJson);
        }
        if (apiRequestCallId === this.getDistrictListApiCallId) {
            this.handleDistrictListResponse(responseJson);
        }
        if (apiRequestCallId === this.postAddInstituteApiCallId) {
            this.handleAddInstituteResponse(responseJson);
        }
        if (apiRequestCallId === this.showInstitutesApiCallId) {
            this.handleShowAdminInstituteResponse(responseJson);
        }
        if (apiRequestCallId === this.editInstituteApiCallId) {
            this.handleEditAdminChildCategoryListResponse(responseJson);
        }
        if (apiRequestCallId === this.statusUpdateApicallID) {
            this.handleAdminInstituteStatusResponse(responseJson)
        }
    };

    handleDistrictListResponse = (response: { data: unknown[] }) => {
        this.setState({
            districtListData: response.data,
            loader: false
        });
    };

    handleAdminInstituteStatusResponse = async (responseJson: object) => {
        await this.getInstitutesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedInstituteText, this.state.columnSort)
        this.setState({ isAlert: true, alertMsg: "Institute status is updated successfully." })
    };

    handleEditAdminChildCategoryListResponse = (responseJson: AdminInstitutesResponseType) => {
        let alertMsg, alertType: any;
        if (responseJson.errors) {
            alertMsg = "Institute logo should be less than 150KB";
            alertType = "error";
        } else {
            alertMsg = "Institute Edited Successfully";
            alertType = "success";
            this.handleFeildReset();
            this.getInstitutesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedInstituteText, this.state.columnSort)
            this.handleCloseAddInstituteModal();
        }
        this.setState({ isAlert: true, alertMsg, alertType, loader: false });
    };

    handleShowAdminInstituteResponse = (response: any) => {
        const { attributes } = response.data;
        this.setState({
            isUpdate: true,
            instituteName: attributes.name,
            instituteAddress: attributes.address,
            instituteLogoName: attributes.institute_logo,
            instituteLogoThumbnail: attributes.institute_logo,
            instituteStatus: attributes.status,
            stateId: attributes.state_details.id,
            loader: false,
            instituteId: response.data.id,
            districtId: attributes.district_details.id
        }, () => {
            this.handleGetDistrictlist(this.state.stateId)
            this.handleOpenAddInstituteModal();
        });
    };

    handleAddInstituteResponse = (response: any) => {
        const { data } = response;
        let alertMsg, alertType: any;
        if (data.institute_logo) {
            alertMsg = "Institute logo should be less than 150KB";
            alertType = "error";
        } else {
            alertMsg = "Institute Created Successfully";
            alertType = "success";
            this.handleFeildReset();
            this.getInstitutesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedInstituteText, this.state.columnSort)
            this.handleCloseAddInstituteModal();
        }
        this.setState({ isAlert: true, alertMsg, alertType, loader: false });
    };

    handleFeildReset = () => {
        this.setState({
            instituteName: "",
            instituteAddress: "",
            instituteLogo: null,
            instituteLogoName: "",
            instituteLogoError: "",
            instituteLogoThumbnail: "",
            instituteStatus: true,
            stateId: "",
            isUpdate: false,
            districtId: ""
        });
    };

    handleStateListResponse = (response: any) => {
        this.setState({
            stateListData: response,
            loader: false
        });
    };

    handleGetInstitutesCategoryListResponse = async (response: AdminInstitutesResponseType) => {
        if (!response) {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.recordNotFoundMessage,
                alertType: "info",
                loader: false
            });
        } else if (response && response.meta && response.meta.message === configJSON.recordNotFoundMessage) {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.recordNotFoundMessage,
                alertType: "info",
                loader: false,
                pageCount: 0,
                adminInstitutesData: {                
                    data: [],
                    meta: {
                        page: "",
                        total_institute: 0,
                        message: ""
                    },
                    errors: [{ token: "" }]}
            });
        } else if (response && response?.errors) {
            if (response?.errors[0]?.token == configJSON.tokenExpiredMessage) {
                this.navigationLoginScreen()
            }
        } else {
            const pageCount = Math.ceil(response.meta.total_institute / this.state.perPage);
            this.setState({
                adminInstitutesData: response,
                loader: false,
                pageCount: pageCount
            });
        }
    };

    handleDeleteInstituteListResponse = (response: { error?: string }) => {
        if (response.error) {
            this.setState({
                openDeleteInstituteModal: false,
                deleteInstituteId: "",
                loader: false,
                isAlert: true,
                alertMsg: response.error,
                alertType: configJSON.alertTypeError
            });
        } else {
            this.setState({
                openDeleteInstituteModal: false,
                deleteInstituteId: "",
                loader: false,
                isAlert: true,
                alertMsg: configJSON.instituteDeleteMsg,
                alertType: configJSON.alertTypeSuccess
            }, () => this.getInstitutesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedInstituteText, this.state.columnSort));
        }
    };    

    handleShowInstitutes = async (id: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getAllInstitutesAPIEndPoint}/${id}`;
        this.showInstitutesApiCallId = this.adminInstitutesApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleDeleteInstitute = async (id: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getAllInstitutesAPIEndPoint}/${id}`;
        this.deleteInstituteApiCallId = this.adminInstitutesApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpDeleteType,
            endPoint
        });
    };

    getInstitutesDataFunction = async (pageNo: number, per_page: number, searchedInstituteString: string, sorting: string) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getAllInstitutesAPIEndPoint}/search?page=${pageNo}&per_page=${per_page}&search_params=${searchedInstituteString || ""}${sorting || ""}`;
        this.getAdminInstitutesListApiCallId = this.adminInstitutesApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleSort(field: string) {
        let fieldSort = "";
        const sortOrder =
        this.state.currentSortState.order === "asc" ? "desc" : "asc";

        if (sortParams.hasOwnProperty(field)) {
            fieldSort = `&${sortParams[field]}=${sortOrder}`;
        }
        this.toggleSortState(field, fieldSort);
        this.getInstitutesDataFunction(
            this.state.currentPageCount,
            this.state.perPage,
            this.state.searchedInstituteText,
            fieldSort
        );
    }

    toggleSortState = (field: string, columnSort: string) => {
        this.setState({
        columnSort,
        currentSortState: {
            field,
            order: this.state.currentSortState.order === "asc" ? "desc" : "asc",
        },
        });
    };

    handleGetStatelist = async () => {
        this.setState({ loader: true });
        const endPoint = configJSON.getStateListAPIEndPoint;
        this.getStateListApiCallId = this.adminInstitutesApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleGetDistrictlist = async (districtId: string) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getDistrictListAPIEndPoint}${districtId}`;
        this.getDistrictListApiCallId = this.adminInstitutesApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleInstitutesSearch = (e: { target: { value: string } }) => {
        const searchedInstituteText = e.target.value;
        this.setState({ searchedInstituteText, currentPageCount: 1 }, () => {
            this.getInstitutesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedInstituteText, this.state.columnSort);
        });
    };

    handlePostInstitute = async () => {
        this.setState({ loader: true });
        const { instituteName, instituteAddress, instituteLogo, instituteStatus, stateId, districtId } = this.state;
        const blankField = this.getBlankField(instituteName, instituteAddress, instituteLogo as string, stateId, districtId);
        if (blankField) {
            this.setState({
                isAlert: true,
                alertMsg: `${blankField} cannot be blank`,
                alertType: "error",
                loader: false
            });
        } else {
            const formdata = new FormData();
            formdata.append("institute[name]", instituteName.trim());
            formdata.append("institute[state_id]", stateId);
            formdata.append("institute[institute_logo]", instituteLogo as string);
            formdata.append("institute[address]", instituteAddress.trim());
            formdata.append("institute[district_id]", districtId);
            formdata.append("institute[status]", instituteStatus);
            this.postAddInstituteApiCallId = this.adminInstitutesApiCall({
                method: configJSON.httpPostType,
                endPoint: configJSON.getAllInstitutesAPIEndPoint,
                body: formdata,
                type: "formdata"
            });
        }
    };

    handleEditInstitute = async () => {
        this.setState({ loader: true });
        const { instituteName, instituteAddress, instituteLogo, instituteStatus, stateId, instituteId, districtId } = this.state;
        if (!instituteName.trim()) {
            this.setState({ isAlert: true, alertMsg: "Institute name cannot be blank", alertType: "info", loader: false });
            return;
        }
        if (!instituteAddress.trim()) {
            this.setState({ isAlert: true, alertMsg: "Institute address cannot be blank", alertType: "info", loader: false });
            return;
        }
        const formdata = new FormData();
        formdata.append("institute[name]", instituteName.trim());
        formdata.append("institute[state_id]", stateId);
        formdata.append("institute[address]", instituteAddress.trim());
        formdata.append("institute[district_id]", districtId);
        formdata.append("institute[status]", instituteStatus);
        if (instituteLogo) {
            formdata.append("institute[institute_logo]", instituteLogo as string);
        }
        this.editInstituteApiCallId = this.adminInstitutesApiCall({
            method: configJSON.httpPutType,
            endPoint: `${configJSON.getAllInstitutesAPIEndPoint}/${instituteId}`,
            body: formdata,
            type: "formdata"
        });
    };

    getBlankField = (instituteName: string, instituteAddress: string, instituteLogo: string, stateId: string, districtId: string) => {
        if (!instituteName) return "Institute Name";
        if (!instituteAddress) return "Institute Address";
        if (!stateId) return "State";
        if (!districtId) return "District";
        if (!instituteLogo) return "Institute Logo";
        return null;
    }

    onCloseAlert = () => {
        this.setState({ isAlert: false });
    };

    toggleDrawer = () => {
        this.setState(prevState => ({
            isDrawer: !prevState.isDrawer,
            setfullWidth: !prevState.setfullWidth,
        }));
    };

    onInstitutesPageChange = (event: any, value: any) => {
        const newPageCount = Number(value);
        this.setState({ currentPageCount: newPageCount }, () => {
            this.getInstitutesDataFunction(newPageCount, this.state.perPage, this.state.searchedInstituteText, this.state.columnSort);
        });
    };

    handleCloseDeleteInstituteModal = () => {
        this.setState({
            openDeleteInstituteModal: false,
            deleteInstituteId: ""
        });
    };

    handleOpenDeleteInstituteModal = (id: number) => {
        this.setState({
            openDeleteInstituteModal: true,
            deleteInstituteId: id
        });
        console.log("click")
    };

    handleInstituteName = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;

        if (inputValue === '' || this.validateInput(inputValue)) {
            this.setState({
                instituteName: inputValue,
                isAlert: false,
            });
        } else {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.validatinMsgName,
                alertType: configJSON.msgErrorAlert
            });
        }
    };

    validateInput(input: string) {
        const regex = /^(?!\s*$)(?!^[^a-zA-Z]*$)(?!^[\W_]*$).{1,30}$/;
        return regex.test(input);
    }

    validateAddressInput(input: string) {
        const regex = /^(?!\s*$)(?!^[^a-zA-Z]*$)(?!^[\W_]*$).{1,300}$/;
        return regex.test(input);
    }

    handleInstituteAddress = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;

        if (inputValue === '' || this.validateAddressInput(inputValue)) {
            this.setState({
                instituteAddress: inputValue,
                isAlert: false,
            });
        } else {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.validationMsgAddress,
                alertType: configJSON.msgErrorAlert
            });
        }
    };

    handleInstituteLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target?.files;

        if (files && files.length > 0) {
            const file = files[0];
            const fileName = file.name;
            const fileSize = file.size;

            const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
            if (!allowedFormats.includes(file.type)) {
                this.setState({
                    instituteLogoError:
                        "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
                    instituteLogo: null,
                    instituteLogoName: "",
                    instituteLogoThumbnail: ""
                });
            } else if (fileSize > 2 * 1024 * 1024) {
                this.setState({
                    instituteLogoError:
                        "File size exceeds 2MB. Please choose a smaller file.",
                    instituteLogo: null,
                    instituteLogoName: "",
                    instituteLogoThumbnail: "",
                });
            } else {
                const imageUrl = URL.createObjectURL(file);
                this.setState({
                    instituteLogo: file,
                    instituteLogoName: fileName,
                    instituteLogoThumbnail: imageUrl,
                    instituteLogoError: ""
                });
            }
        } else {
            this.setState({
                instituteLogo: null,
                instituteLogoName: "",
                instituteLogoThumbnail: "",
                instituteLogoError:
                    "No file is selected",
            });
        }
    };

    handleDeleteFile = () => {
        this.setState({
            instituteLogo: null,
            instituteLogoName: "",
            instituteLogoThumbnail: "",
            instituteLogoError:
                "",
        });
    };

    handleInstituteStatusCheck = (event: { target: { checked: boolean } }) => {
        this.setState({
            instituteStatus: event.target.checked
        });
    };

    handleStateSelectChange = (id: string) => {
        this.setState({
            stateId: id
        }, () => this.handleGetDistrictlist(this.state.stateId));
    };

    handleDistrictSelectChange = (districtId: string) => {
        this.setState({
            districtId: districtId
        });
    };

    handleCloseAddInstituteModal = () => {
        this.setState({
            openAddInstituteModal: false,
        }, () => this.handleFeildReset());
    };

    handleOpenAddInstituteModal = () => {
        this.setState({
            openAddInstituteModal: true,
        });
    };

    handleInstitutesHeaderCheckboxChange = () => {
        this.setState(prevState => {
            const isHeaderChecked = !prevState.isHeaderChecked;
            const isChecked = Array(prevState.adminInstitutesData.data.length).fill(isHeaderChecked);

            return { isHeaderChecked, isChecked };
        });
    };

    handleInstitutesRowCheckboxChange = (index: number) => {
        this.setState(prevState => {
            const isChecked = [...prevState.isChecked];
            isChecked[index] = !isChecked[index];
            const isHeaderChecked = isChecked.every(value => value);

            return { isChecked, isHeaderChecked };
        });
    };

    handleInstituteStatus = async (instituteId: string) => {
        const updatedNotifications = this.state.adminInstitutesData.data.map(notification =>
            notification.id === instituteId ? {
                ...notification,
                attributes: {
                    ...notification.attributes,
                    status: !notification.attributes.status
                }
            } : notification
        );
        this.setState({
            adminInstitutesData: {
                data: updatedNotifications,
                errors: [{ token: "" }],
                meta: {
                    page: "",
                    total_institute: 0,
                    message: ""
                }
            }
        });

        const clickedData: InstitutesDataType | undefined = updatedNotifications.find(notification => notification.id === instituteId);
        const Value: boolean | undefined = clickedData?.attributes.status;

        if (typeof Value === 'boolean' || Value === undefined) {
            const stringValue = Value ? 'true' : 'false';
            const formdata = new FormData();
            formdata.append("institute[status]", stringValue);

            this.statusUpdateApicallID = this.adminInstitutesApiCall({
                method: configJSON.httpPutType,
                endPoint: `${configJSON.getAllInstitutesAPIEndPoint}/${instituteId}`,
                body: formdata,
                type: "formdata"
            });
        }
    };
    parseErrors = async (responseJson: {
        errors: {
          token: string;
        }[];
      }) => {
        if (Object.keys(responseJson.errors[0])[0] === "token") {
          this.navigationLoginScreen();
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      };
    
    navigationLoginScreen = () => {
        alert(configJSON.sessionExpireMsg)
        const messageLogin: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messageLogin);
    };
    // Customizable Area End
}
import React from "react";
// Customizable Area Start
import {
    withStyles
} from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";
import { Group3, blueCircle, heartFill, image_star, next, prev } from "./assets";



export class CoursesSlider extends CatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    nextHandle = () => {
        if (this.state.currentPage) {
            this.setState({ currentPage: this.state.currentPage + 1 });
        }
    };

    prevHandle = () => {
        if (this.state.currentPage > 0) {
            this.setState({ currentPage: this.state.currentPage - 1 })
        }
    };

    // Customizable Area End

    render() {
        const { classes } = this.props;

        let images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

        let startIndex = this.state.currentPage * 4;
        let endIndex = Math.min(startIndex + 4, images.length);



        return (
            //Merge Engine DefaultContainer

            <div>
                <div style={{ maxWidth: '90%', margin: '0px auto' }}>
                    <div style={{
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <h2 className={classes.recommonded}>Recommended Courses</h2>
                        <button className={classes.stylebutton}> VIEW ALL <ArrowRightAltIcon /></button>
                    </div>
                </div>
                <div style={{ maxWidth: '90%', margin: '0px auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <div>
                            <img src={prev} onClick={this.prevHandle} />
                        </div>
                    </div>
                    <div style={{ minWidth: '90%', minHeight: '520px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        {images.slice(startIndex, endIndex).map((image, index) => (
                            <div className={classes.parentDiv}>
                                <div>
                                    <div>
                                        <img src={Group3} style={{ width: '100%', marginBottom: '20px' }} />
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px auto' }}>
                                        <div style={{ borderBottom: '2px solid #0E599D', paddingBottom: '10px' }}>
                                            <img src={blueCircle} style={{ marginRight: '10px' }} />
                                            Mathematics
                                        </div>

                                        <div>
                                            <span style={{ borderRight: '1px solid gray', paddingRight: '10px' }}><img src={image_star} style={{ marginRight: '10px' }} /> <b>4.5</b> </span>
                                            <span className={classes.ratings}>6K Students <img src={heartFill} style={{ marginLeft: '20px' }} /> </span>
                                        </div>
                                    </div>

                                    <h2 className={classes.cartHeading}>Lorem ipsum dolor sit amet eiu smod, consectetur</h2>
                                    <p className={classes.instructure}>Instructor: Vivek Sir</p>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                                        <div><span style={{ fontStyle: '30px' }}>&#8377;</span> <span className={classes.priceText}>499.00/-</span></div>
                                        <div>
                                            <button className={classes.AddToCart}>+ Add to Cart </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}



                    </div>
                    <div>
                        <div>
                            <img src={next} onClick={this.nextHandle} />
                        </div>
                    </div>
                </div>
            </div>



            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start


export const styles: any = (theme: any) => ({
    parentDiv: {
        padding: '12px',
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        maxWidth: '292px',
        borderRadius: '15px'
    },
    AddToCart: {
        border: 'none',
        width: '132px',
        height: '38px',
        flexShrink: '0',
        borderRadius: '7px',
        background: "linear-gradient(133deg, #F93A3A -26.6%, #A81FE1 118.85%)",
    },
    priceText: {
        color: "#00BE10",
        fontSize: "31.921px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal"
    },
    cartHeading: {
        color: "#212121",
        fontSize: "25.868px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        letterSpacing: "-0.3px"
    },
    instructure: {
        color: "#FFF",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        letterSpacing: "-0.014px",
        background: "#0E599D",
        padding: '2px',
        height: '30px'
    },
    ratings: {
        color: "#939393",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        marginLeft: '10px',

    },
    stylebutton: {
        background: 'white',
        color: "#1220C1",
        border: "2px solid #1721CD",
        fontSize: "14px",
        fontFamily: "ProximaNova",
        borderRadius: "300px",
        width: "152px",
        height: "48px",
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },
    recommonded: {
        color: "#212121",
        fontFamily: "ProximaNova",
        fontSize: "39.633px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "61px", /* 153.912% */
        letterSpacing: "-0.049px"

    }

});



export default withStyles(styles)(CoursesSlider);
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export interface APIPayloadType {
    contentType?: string;
    method: string;
    end_Point: string;
    apiBody?: object;
    body?: object;
    token?: string;
    type?: string;
}
export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}
export interface AdminLanguageResponseType {
    data: Array<LangDataType>;
    meta: {
        message: string;
        total_language: number;
        page: string;
    };
    errors : { token: string }[];
}
export interface LangDataType {
    id: string;
    type: string;
    attributes: {
        id: number;
        status: boolean,
        language_name: string;
        sample_text: string;
    }
}
export interface InvalidResponseType1 {
    errors: Array<string>;
}
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    adminLangData: AdminLanguageResponseType;
    isDrawer: boolean;
    setfullWidth: boolean;
    currentPageCount: number;
    pageCount: number;
    userToken: string;
    loader: boolean;
    sample_text: string;
    openDeleteModal: boolean;
    openEditModal: boolean;
    openCreateModal: boolean;
    deleteId: string | number;
    editId: string | number;
    editedlangname: any;
    createId: string | number;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    isUpdate: boolean;
    perPage: number;
    status: any;
    searchedText: string;
    isHeaderChecked: boolean;
    isChecked: boolean[];
    language_name: string;
    currentSortState: { order: string; field: string };
    columnSort: string;
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class AdminLanguageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAdminLanguageListApiCallId: string = "";
    deletelangApiCallId: string = "";
    updateApiCallID: string = "";
    statusUpdateApicallID: string = "";
    createApiCallId: string = "";
    editAPiCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationIdMessage),
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            adminLangData: {
                data: [],
                meta: {
                    page: "",
                    total_language: 0,
                    message: ""
                },
                errors: [{token: ""}]
            },
            isDrawer: true,
            setfullWidth: true,
            currentPageCount: 1,
            pageCount: 0,
            userToken: "",
            loader: true,
            openDeleteModal: false,
            openEditModal: false,
            editedlangname: "",
            openCreateModal: false,
            language_name: '',
            editId: "",
            createId: "",
            deleteId: "",
            isAlert: false,
            alertMsg: "",
            isChecked: [],
            alertType: "success",
            isUpdate: false,
            isHeaderChecked: false,
            perPage: elementsPerPage,
            status: true,
            searchedText: "",
            sample_text: '',
            currentSortState: { order: "desc", field: "id_sort" },
            columnSort: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const langResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(!langResponseJson.errors) {
            this.apiSuccessCallBacks(apiRequestCallId, langResponseJson);
            if (apiRequestCallId === this.createApiCallId) {

                this.handleCreateLangListResponse(langResponseJson)
            }
            if (apiRequestCallId === this.updateApiCallID) {

                this.handleEditAdminLangListResponse(langResponseJson)
            }
            if (apiRequestCallId === this.statusUpdateApicallID) {
                await this.getLanguageList(this.state.currentPageCount, this.state.perPage, this.state.searchedText, this.state.columnSort)
               this.setState({isAlert:true, alertMsg:"Language status is updated successfully."})

            }
        } else {
            this.parseErrors(langResponseJson)
        }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        await this.getUserToken()
        await this.getLanguageList(this.state.currentPageCount, this.state.perPage, this.state.searchedText, this.state.columnSort)
    }
    parseErrors = async (responseJson: {
        errors: {
          token: string;
        }[];
      }) => {
        if (Object.keys(responseJson.errors[0])[0] === "token") {
          this.navigationLoginScreen();
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      };
    handleLanguageName = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;

        if (inputValue === '' || this.validateInput(inputValue)) {
            this.setState({
                language_name: inputValue,
                isAlert: false,
            });
        } else {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.validatinMsgName,
                alertType: configJSON.msgErrorAlert
            });
        }
    };

    validateInput(input: string) {
        const regex = /^(?!\s*$)(?!^[^a-zA-Z]*$)(?!^[\W_]*$).{1,30}$/;
        return regex.test(input);
    }

    validateSampleInput(input: string) {
        const regex = /^(?!\s*$)(?!^[^a-zA-Z]*$)(?!^[\W_]*$).{1,300}$/;
        return regex.test(input);
    }

    handleSampleText = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;

        if (inputValue === '' || this.validateSampleInput(inputValue)) {
            this.setState({
                sample_text: inputValue,
                isAlert: false,
            });
        } else {
            this.setState({
                isAlert: true,
                alertMsg: configJSON.validationMsgAddress,
                alertType: configJSON.msgErrorAlert
            });
        }
    };

    getUserToken = async () => {
        const token = await getStorageData('loginToken');
        this.setState({ userToken: token });
    };

    GenaralApiCall = (data: APIPayloadType) => {
        let { contentType, method, end_Point, body, type } = data;
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            "Content-Type": contentType,
            "Accept": "*/*",
            "token": this.state.userToken
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            end_Point
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;

    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson);
    };

    onPageChange = (event: any, value: any) => {
        this.setState({
            currentPageCount: Number(value)
        }, () => {
            this.getLanguageList(this.state.currentPageCount, this.state.perPage, this.state.searchedText, this.state.columnSort)
        });
    };
    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AdminLanguageResponseType & { error?: string }) => {
        if (apiRequestCallId === this.getAdminLanguageListApiCallId) {

            this.handleGetLanguageListResponse(responseJson);
        }
        if (apiRequestCallId === this.deletelangApiCallId) {
            this.handleDeleteAdminLangListResponse(responseJson);
        }
    };
    getLanguageList = async (pageNo: number, per_page: number, searchedString: string, sorting: string) => {
        this.setState({ loader: true })
        this.getAdminLanguageListApiCallId = this.GenaralApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpGetType,
            end_Point: `${configJSON.SearchLanguageAPIEndPoint}/search?page=${pageNo}&per_page=${per_page}&search_params=${searchedString || ""}${sorting || ""}`
        });
    };

    handleSort(field: string) {
        const sortParams: Record<string, string> = {
            ["S.No"]: "id_sort",
            Language: "language_name_sort",
            Status: "status_sort",
        };
        let fieldSort = "";
        const sortOrder =
        this.state.currentSortState.order === "asc" ? "desc" : "asc";

        if (sortParams.hasOwnProperty(field)) {
            fieldSort = `&${sortParams[field]}=${sortOrder}`;
        }
        this.toggleSortState(field, fieldSort);
        this.getLanguageList(
            this.state.currentPageCount,
            this.state.perPage,
            this.state.searchedText,
            fieldSort
        );
    }

    toggleSortState = (field: string, columnSort: string) => {
        this.setState({
        columnSort,
        currentSortState: {
            field,
            order: this.state.currentSortState.order === "asc" ? "desc" : "asc",
        },
        });
    };

    handleRowCheckboxChange = (index: number) => {
        this.setState((prevState) => {
            const isChecked = [...prevState.isChecked];
            isChecked[index] = !isChecked[index];
            const isHeaderChecked = isChecked.every((value) => value);
            return {
                isChecked,
                isHeaderChecked,
            };
        });
    };

    handleGetLanguageListResponse = (response: AdminLanguageResponseType) => {
        const { meta } = response;

        if (meta?.message === configJSON.recordNotFoundMessage) {
            this.setState({
                isAlert: true,
                alertMsg: "Record not found.",
                alertType: "info",
                loader: false,
                pageCount: 0,
                adminLangData: {
                    data: [],
                    meta: {
                        page: "",
                        total_language: 0,
                        message: ""
                    },
                    errors: [{token: ""}]
                }
            });
        } else if (response && response?.errors) {
            if (response?.errors[0]?.token == configJSON.tokenExpiredMessage) {
                this.navigationLoginScreen()
            }
        } else {
            const pageCount = Math.ceil(meta.total_language / this.state.perPage);
            this.setState({
                adminLangData: response,
                loader: false,
                pageCount: pageCount
            });
        }
    };

    handleDeleteAdminLangListResponse = (response: { error?: string }) => {
        if (response.error) {
            this.setState({
                openDeleteModal: false,
                deleteId: "",
                loader: false,
                isAlert: true,
                alertMsg: response.error,
                alertType: configJSON.msgErrorAlert
            });
        } else {
            this.setState({
                openDeleteModal: false,
                deleteId: "",
                loader: false,
                isAlert: true,
                alertMsg: configJSON.alertMsg,
                alertType: configJSON.msgSuccessAlert
            }, () => {
                this.getLanguageList(this.state.currentPageCount, this.state.perPage, this.state.searchedText, this.state.columnSort);
            });
        }
    };

    handleEditAdminLangListResponse = (response: AdminLanguageResponseType) => {
        this.setState({
            openEditModal: false,
            editId: "",
            loader: false,
            isAlert: true,
            alertMsg: "Language was successfully updated.",
            alertType: "success"
        }, () => {
            this.getLanguageList(1, this.state.perPage, "", this.state.columnSort);
            this.handleFeildReset();
        });
    };

    handleCreateLangListResponse = (response: AdminLanguageResponseType) => {
        this.setState({
            openCreateModal: false,
            createId: "",
            loader: true,
            isAlert: true,
            alertMsg: "Language was successfully created.",
            alertType: "success"
        }, () => {
            this.getLanguageList(1, this.state.perPage, "", this.state.columnSort);
            this.handleFeildReset();
        });
    };

    handleStatusApiCall = async (id: any) => {
        const updatedNotifications = this.state.adminLangData.data.map(notification =>
            notification.id === id ? {
                ...notification,
                attributes: {
                    ...notification.attributes,
                    status: !notification.attributes.status
                }
            } : notification
        );
    this.setState({adminLangData:{
                data: updatedNotifications,
                meta:
                    this.state.adminLangData.meta,
        errors: [{token: ""}]

    }})
    const clickedData :any = updatedNotifications.find(notification => notification.id === id);
        const Value = clickedData.attributes.status;


        const header = {
            token: this.state.userToken,
            "Content-Type": "application/json",
        };
        const data = {
            change_status: Value
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.statusUpdateApicallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),

            `${configJSON.statusUpdateAPIEndPoint}/${id}`

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleDeleteApiCall = async (id: string | number) => {
        this.setState({ loader: true })
        this.deletelangApiCallId = this.GenaralApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpDeleteType,
            end_Point: `${configJSON.langDeleteAPIEndPoint}/${id}`
        });
    };
    handleLanguageChange = (e: any) => {

        this.setState({

            language_name: e.target?.value
        })
    }
    handleSampleTextChange = (e: any) => {
        this.setState({ sample_text: e.target?.value })
    }
    handleStatusChange = (e: any) => {
        this.setState({ status: !this.state?.status })

    }

    handleUpdateApiCall = async (id: any) => {
        if (!this.state.language_name.trim()) {
            this.setState({ isAlert: true, alertMsg: "Language name cannot be blank", alertType: "info" });
            return;
        }
        if (!this.state.sample_text.trim()) {
            this.setState({ isAlert: true, alertMsg: "Sample text cannot be blank", alertType: "info" });
            return;
        }
        this.updateApiCallID = this.GenaralApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpPutType,
            end_Point: `${configJSON.langEditAPIEndPoint}/${id}`,
            type:"",
            body: {

                language_name: this.state.language_name.trim(),
                sample_text: this.state.sample_text.trim(),
                status: this.state.status

            }

        });
    };


    handleUpdateStatusApiCall = async (id: any) => {

        this.updateApiCallID = this.GenaralApiCall({
            contentType: configJSON.categoryApiContentType,
            method: configJSON.httpPutType,
            end_Point: `${configJSON.langEditAPIEndPoint}/${id}`,
            body: {
                status: this.state.status

            }

        });
    };




    handleLanguage = (value: string) => {

        this.setState({ language_name: value })

    }

    handleStatus = (value: boolean) => {
        this.setState({ status: value })

    }


    handleCreateApiCall = async () => {

        if (!this.state.language_name.trim()) {
            this.setState({ isAlert: true, alertMsg: "Language name cannot be blank", alertType: "info" });
            return;
        }
        if (!this.state.sample_text.trim()) {
            this.setState({ isAlert: true, alertMsg: "Sample text cannot be blank", alertType: "info" });
            return;
        }

        const header = {
            token: this.state.userToken,

            "Content-Type": "application/json",
        };
        const data = {
            language_name: this.state.language_name.trim(),
            sample_text: this.state.sample_text.trim(),
            status: this.state.status
        }



        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),

            configJSON.langCreateAPIEndPoint,

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleCheckboxChange = () => {
        this.setState((previousState) => {
            const isHeaderChecked = !previousState.isHeaderChecked;
            const isChecked = Array(this.state.adminLangData.data.length).fill(isHeaderChecked);
            return {
                isHeaderChecked,
                isChecked,
            };
        });
    };
    handleLanguageSearch = (e: { target: { value: string } }) => {
        let searchVal = e.target.value;
        this.setState({ searchedText: searchVal, currentPageCount: 1 }, () => {
            this.getLanguageList(this.state.currentPageCount, this.state.perPage, this.state.searchedText, this.state.columnSort);
        })
    };
    oncloseAlert = () => {
        this.setState({
            isAlert: false
        });
    };
    handleCloseDeleteModal = () => {
        this.setState({
            openDeleteModal: false,
            deleteId: ""
        });
    };
    handleFeildReset = () => {
        this.setState({
            language_name: '',
            sample_text: '',
            status: true
        });
    };

    handleCloseEditCourseModal = () => {
        this.setState({
            openEditModal: false,
        }, () => this.handleFeildReset());
    };
    handleCloseCreateCourseModal = () => {
        this.setState({
            openCreateModal: false,
        }, () => this.handleFeildReset());
    };
    handleCourseStatusCheck = (event: { target: { checked: boolean } }) => {
        this.setState({
            status: event.target.checked
        });
    };

    handleCloseEditModal = () => {
        this.setState({
            openEditModal: false,
            editId: ""
        }, () => this.handleFeildReset());
    };

    handleCloseCreateModal = () => {
        this.setState({
            openCreateModal: false,
            createId: ""
        });
    };
    handleOpenDeleteModal = (id: number) => {
        this.setState({
            openDeleteModal: true,
            deleteId: id
        });
    };
    handleOpenEditModal = (data: any) => {
        this.setState({
            openEditModal: true,
            editId: data.id,
            language_name: data.attributes.language_name,
            sample_text: data.attributes.sample_text,
            status: data.attributes.status
        });

    };
    handleCreateModal = () => {
        this.setState({
            openCreateModal: true,

        });
    };

    navigationLoginScreen = () => {
        alert(configJSON.sessionExpireMsg)
        const messageLogin: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messageLogin);
    };
    // Customizable Area End
}


import React from "react";
// Customizable Area Start
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import "./categoryAdmin.css";
import "./table.css";
import { logo, thumbnail } from "./assets";
import { FiUser } from "react-icons/fi";
import {
  IoMdAdd,
  IoIosArrowDown,
  IoMdBook,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { PiBell, PiArrowSquareOutLight } from "react-icons/pi";
import { FaCircle } from "react-icons/fa6";
import { LuSearch } from "react-icons/lu";
import { GoHome } from "react-icons/go";
import { IoMenuSharp } from "react-icons/io5";
import { GrBundle } from "react-icons/gr";
import { BsGlobe } from "react-icons/bs";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

export default class AdminLayout extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  menuItems = [
    {
      name: "Home",
      link: "admin/home",
      icon: <GoHome />,
    },
    {
      name: "User",
      link: "admin/user",
      icon: <FiUser />,
    },
    {
      name: "Main menu",
      link: "admin/menu",
      icon: <IoMenuSharp />,
    },
    {
      name: "Course",
      link: "admin/course",
      icon: <IoMdBook />,
    },
    {
      name: "Bundle Course",
      link: "admin/bundle_course",
      icon: <GrBundle />,
    },
    {
      name: "Institute",
      link: "admin/institute",
      icon: <GoHome />,
    },
    {
      name: "Language",
      link: "admin/language",
      icon: <BsGlobe />,
    },
  ];

  renderMenu = (handleMenu: any) => {
    return (
      <Drawer
        className={"drawer"}
        variant="permanent"
        classes={{
          paper: "drawerPaper",
        }}
        anchor="left"
      >
        <div className="drawerLogo" onClick={handleMenu}>
          <img src={logo} alt="CHSE-logo" className="logo" />
          <p style={{ textAlign: "center" }}>CHSE Express</p>
        </div>
        <List className="list">
          {this.menuItems.map((item: any, index: number) => (
            <div
              test-id="menuItemClick"
              key={index}
              onClick={() => this.handleMenuItemClick(item.link)}
            >
              <ListItem style={{ cursor: "pointer" }} className="listItem">
                <ListItemIcon className="listIcon">{item.icon}</ListItemIcon>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="listTextWrapper"
                >
                  <ListItemText className="listText" primary={item.name} />
                  {(index === 1 || index === 2) && <IoIosArrowDown />}
                </div>
              </ListItem>
            </div>
          ))}
        </List>
      </Drawer>
    );
  };
  // Customizable Area End

  render() {
    const title = this.props.title?.toLowerCase();
    return (
      //Merge Engine DefaultContainer
      <div className="root">
        <AppBar
          position="fixed"
          className="appBar"
          color="transparent"
          style={{
            borderBottom:
              this.props.display == "none" ? "2px solid black" : "none",
          }}
        >
          <div className="layoutToolbar">
            <div onClick={this.handleMenu} className="menu">
              <div className="logoWrapper">
                <img src={logo} alt="CHSE-Express-logo" className="logo" />
              </div>
              <p style={{ textAlign: "center" }}>CHSE Express</p>
            </div>
            <div className="adminHeader">
              <div className="commonAdminHeader">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {this.props.display == "none" && (
                    <a
                      href="/category_admin"
                      style={{
                        background: "none",
                        border: "none",
                        textDecoration: "none",
                      }}
                      test-id="backIconClick"
                      onClick={()=> this.handleBack(this.props.backPath)}
                    >
                      <IoIosArrowBack size={30} color="black" />
                    </a>
                  )}
                  {title == "students" ||
                  title == "intsructors" ||
                  title == "admin" ? (
                    <h3>
                      Users&nbsp;
                      <span>
                        <IoIosArrowForward size={30} />
                        &nbsp;
                      </span>{" "}
                      {this.props.title}
                    </h3>
                  ) : (
                    <>
                    {this.props.title && <h3>{this.props.headingText} {this.props.title}</h3>}
                    {this.props.title && this.state.edit && <h3>"Edit {this.props.title}</h3>}
                    {this.props.title && !this.state.edit && <h3>"Add {this.props.title}</h3>}
                    </>
                  )}
                  {/* {title && edit && <h3>Edit {this.props.title}</h3>}? */}
                </div>
                <div className="profileInfo">
                  <div className="bell">
                    <PiBell size={30} />
                    <FaCircle size={10} color="FF0000" className="bellCircle" />
                  </div>
                  <div className="avatar">
                    <img
                      src={thumbnail}
                      alt="user-photo"
                      className="avatarImg"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: this.props.display == "flex" ? "flex" : "none",
                }}
                className="addHeader"
              >
                <div className="adminSearch">
                  <input
                    placeholder="Search"
                    className="adminInput"
                    onChange={this.props.handleSearch}
                    value={this.props.searchValue}
                  />
                  <LuSearch />
                </div>

                <div className="adminSearch">
                  <p>Search with Date</p>
                  <div>
                  <input
                    type="date"
                    className="adminInput"
                    onChange={this.props.handleFromDate}
                    value={this.props.fromDate}
                  />
                  &nbsp;
                  <span style={{ fontWeight: 600, fontSize: ".8rem" }}>to</span>
                  <input
                    type="date"
                    className="adminInput"
                    onChange={this.props.handleToDate}
                    value={this.props.toDate}
                  />
                  </div>
                </div>

                <div className="actions">
                  <button className="action" onClick={this.props.handleExport}>
                    <PiArrowSquareOutLight />
                    <p>Export</p>
                  </button>

                  <button
                    test-id="addPath"
                    onClick={() => this.goAddScreen(this.props.addPath)}
                    className="action"
                    style={{ width: this.props.addButtonWidth }}
                  >
                    <IoMdAdd color={"#fff"} />
                    <p>Add {this.props.title}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AppBar>
        <div
          className={this.state.openMenu ? "menuWrapper" : "menuWrapperClose"}
        >
          {this.renderMenu(this.handleMenu)}
        </div>

        <Toolbar className="contentToolbar" />
        <main
          className={
            this.props.display == "flex"
              ? "pageContent marginFlex"
              : "pageContent marginNone"
          }
        >
          {this.props.children}
        </main>
      </div>
    );
  }
}

// Customizable Area Start
// Customizable Area End

import React from "react";
// Customizable Area Start
import "./categoryAdmin.css";
import "./editCourseComp.css";
import { IoIosClose } from "react-icons/io";
// Customizable Area End"

import CatalogueController, { Props } from "./CatalogueController";

export default class EditCourseComp extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <div className={this.props.bodyStyle}>
        <div className={`bodyContainer ${this.props.bodyContainerStyle}`}>
          {this.props.modalHeading && (
            <div className="modalHeading">
              <h3>{this.props.modalHeading}</h3>
              <IoIosClose size={35} />
            </div>
          )}

          {this.props.childCategory && (<div style={{borderBottom: '0.5px solid #bdbcbc50' }} className={`top top2 ${this.props.topStyle}`}>
            <div className={`topItem topItem3 ${this.props.topItemStyle}`}>
              <p className="addCatTitle">{this.props.childCategory}</p>
              <div className="addCatInputWrapper">
                <input test-id="childCategoryName" className="addCatInput" value={this.state.childCategoryName} onChange={(e) => this.handleCatNameChange(e)} />
              </div>
            </div>
          </div>)}

          {(this.props.childCategory || this.props.subCategory) && (<div style={{borderBottom: '0.5px solid #bdbcbc50' }} className={`top top2 ${this.props.topStyle}`}>
            <div className={`topItem topItem3 ${this.props.topItemStyle}`}>
              <p className="addCatTitle">{this.props.subCategory}</p>
              <div className="addCatInputWrapper">
                <input test-id="subCategoryName" className="addCatInput" value={this.state.subCategoryName} onChange={(e) => this.handleSubCatNameChange(e)} />
              </div>
            </div>
          </div>)}

          <div style={{borderBottom: '0.5px solid #bdbcbc50' }} className={`top top2 ${this.props.topStyle}`}>
            <div className={`topItem topItem3 ${this.props.topItemStyle}`}>
                <p className="addCatTitle">Category</p>
                <div className="addCatInputWrapper">
                  <input test-id="categoryName" className="addCatInput" value={this.state.categoryName} onChange={(e) => this.handleCatNameChange(e)} />
                </div>
              </div>
          </div>
            

          <div style={{borderBottom: '0.5px solid #bdbcbc50' }} className={`top top1 ${this.props.topStyle}`}>
            <div className={`topItem ${this.props.topItemStyle}`}>
              <p className="addCatTitle">Web Icon</p>
              <div className="addCatInputWrapper">
                <input test-id="webIcon" className="addCatInput" value={this.state.web_icon_name} />
                <input test-id="iconUpload" type="file" accept="image/*" onChange={(e) => this.handleWebIconChange(e)}  />
                <button test-id="uploadButton" className="addCatButton">Browse</button>
              </div>
            </div>

            <div className={`topItem ${this.props.topItemStyle}`}>
              <p className="addCatTitle">App Icon</p>
              <div className="addCatInputWrapper">
                <input test-id="appIcon" placeholder="Upload Square Image Icon" className="addCatInput" value={this.state.icon_cat_name} />
                <input test-id="iconUpload2" type="file" accept="image/*" onChange={(e) => this.handleAppIconChange(e)}  />
                <button test-id="uploadButton" className="addCatButton">Browse</button>
              </div>
            </div>
          </div>

      
          <div style={{borderBottom: '0.5px solid #bdbcbc50' }} className={`top top1 ${this.props.topStyle}`}>
            {this.props.childCategory && (
              <div className={`topItem topItem4 ${this.props.topItemStyle}`}>
                <p className="addCatTitle">Select Custom Color</p>
                <div className="addCatInputWrapper addCatInputWrapper4">
                  <input test-id="color" type="color" value={this.state.childCategoryColor} onChange={(e) => this.setState({childCategoryColor : e?.target?.value})}  />
              </div>
            </div>
            )}

            <div className={`topItem topItem4 ${this.props.topItemStyle}`}>
              <p className="addCatTitle">Status</p>
              <div className="addCatInputWrapper addCatInputWrapper4">
              <label className="switch">
                <input test-id="status" type="checkbox" checked={this.props.row ? this.props.row.attributes?.status : this.state.courseStatus} onChange={(e) => this.setState({courseStatus: e?.target?.checked})} />
                <span className="slider round"></span>
              </label>
            </div>
            </div>
          </div>
        </div>

        <div className="addCatbuttonContainer">
          <button type="reset"  test-id="resetButton" className="reset" onClick={this.handleAddReset}>Reset</button>
          {this.props.title == "Category" && <span test-id="addCatButton" className="create" onClick={() => this.addCategory()}>{this.state.edit ? "Update": "Create"}</span>}
          {this.props.subCategory == "Sub Category" && <span test-id="addSubCatButton" className="create" onClick={() => this.addSubCategory()}>{this.state.edit ? "Update": "Create"}</span>}
          {this.props.childCategory == "Child Category" && <span test-id="editCatButton" className="create" onClick={this.props.editFunction}>{this.state.edit ? "Update": "Create"}</span>}
          {this.props.childCategory == "Child Category" && <span test-id="addMoreChildCat" className="create addMore" style={{width: "300px"}} onClick={() => this.addChildCategory()}>+ Add More Child Categories</span>}
        </div>
      </div>
    );
  }
}

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  Chip,
  createTheme,
  ThemeProvider,
  responsiveFontSizes
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import { IoFilterOutline } from "react-icons/io5";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MdOutlineWindow } from "react-icons/md";
import { TbLayoutList } from "react-icons/tb";
import IconButton from "@material-ui/core/IconButton";
import MyCourseController, { Props } from "./MyCourseController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { study } from "./assets";
import FooterWeb from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import MyCourseCardWeb from "../../../components/src/MyCourseCard.web";

const webStylesLocal: Record<string, CSSProperties> = {
  leftRightLable: {
    marginBottom: "10px"
  },
  instructorLang: {
    color: "#FFF",
    fontSize: "9px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.011px"
  },
  instructorLangName: {
    color: "#FFF",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.017px"
  },
  chapterLectureTimeHead: {
    display: "flex",
    columnGap: "7.5px"
  },
  chapterLectureTimeText: {
    color: "#FFF",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "normal",
    marginLeft: 3
  },
  aboutCourseRight: {
    rowGap: "10px"
  },
  aboutCourseLeftRight: {
    display: "flex",
    gap: "10px",
    color: "white",
    padding: "0 10px 0 10px",
    justifyContent: "space-between"
  },
  fullDesc: {
    margin: "12px 0",
    padding: 0,
    color: " #FFF",
    fontSize: "13px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.151px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    overflow: "hidden"
  },
  courseShortDescHover: {
    color: "#FFF",
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px",
    fontWeight: 600,
    marginTop: "9px",
    marginBottom: "9px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    overflow: "hidden"
  },
  aboutCourse2: {
    marginTop: "10px"
  },
  courseName: {
    border: "1px solid white",
    background: "white",
    color: "#1220C1",
    width: "max-content",
    borderRadius: "26.85px",
    padding: "5px 7px",
    height: "min-content",
    fontSize: "12px"
  },
  courseNameTop: {
    display: "flex",
    justifyContent: "space-between"
  },
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(23%, 1fr))",
    gap: "10px",
    width: "100%",
    padding: "10px 12px",
    boxShadow: "0px 1px 8px 0px #00000025",
    boxSizing: "border-box"
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "10px 12px",
    boxShadow: "0px 1px 8px 0px #00000025",
    boxSizing: "border-box"
  },
  bundleCourseMainLayout: {
    width: "100%",
    margin: "0 auto",
    marginBottom: 30
  },
  addToCart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    boxSizing: "border-box",
    margin: "auto"
  },
  addToCartList: {
    display: "flex",
    width: "20%",
    padding: "16px",
    boxSizing: "border-box",
    margin: "auto",
    alignItems: "end",
    justifyContent: "end"
  },
  instructorNameMain: {
    width: "100%",
    padding: 7,
    background: "#0E599D",
    marginBottom: "6px",
    display: "flex",
    justifyContent: "flex-start"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    marginTop: "14.3px",
    justifyContent: "space-between",
    width: "100%"
  },
  aboutCourseList: {
    display: "inline-flex",
    gap: "25px",
    width: "100%",
    alignItems: "center"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center",
    padding: "0 12px",
    boxSizing: "border-box"
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid lightgrey",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  headerContainer: {
    display: "flex",
    color: "white",
    position: "relative",
    width: "100%"
  },
  headerImage: {
    maxWidth: 300,
    position: "absolute",
    top: "-15px",
    right: 0
  },
  cashDisplay: {
    display: "inline-flex",
    color: "#2a9e6d",
    fontSize: "24px",
    gap: "0.2rem",
    borderRadius: "10px",
    padding: "1rem 1.5rem",
    background: "#f6f6f6",
    alignItems: "center"
  }
};

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

export default class MyCourse extends MyCourseController {
  constructor(props: Props) {
    super(props);
    this.closemodal = this.closemodal.bind(this);
  }

  closemodal() {
    this.oncloseAlert();
  }

  renderList() {
    const countCourses = () => {
      return this.state.coursesList.reduce((acc, item) => {
        return acc + item.attributes.course_order.length;
      }, 0);
    };
    return (
      <div style={webStylesLocal.bundleCourseMainLayout}>
        <Box sx={webStylesLocal.adminLayout}>
          <div style={webStylesLocal.headerContainer}>
            <div style={{ maxWidth: "800px", marginRight: "auto" }}>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 20
                }}
              >
                <h1>My Courses</h1>
                <Chip
                  style={{ color: "white", background: "#FCB910" }}
                  label={`${countCourses()} ITEMS`}
                  data-test-id="itemsCounter"
                />
              </div>
              <p>
                Save and curate your desired courses, and easily plan your
                learning journey and access to your favorite educational
                resources with just a click.
              </p>
            </div>
            <div style={webStylesLocal.headerImage}>
              <img
                src={study}
                alt=""
                width={300}
                height={80}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "30px"
            }}
          >
            <div className="searchWrapper">
              <Paper component="form" style={styles.root}>
                <IconButton
                  style={styles.iconButton}
                  aria-label="menu"
                  data-test-id="submitSearchTestId"
                  onClick={() => this.getCourses()}
                >
                  <SearchIcon fontSize="inherit" />
                </IconButton>
                <InputBase
                  style={styles.input}
                  value={this.state.searchText}
                  onChange={e => this.handleSearchChange(e)}
                  data-test-id="searchInputTestId"
                  placeholder="Search..."
                  type="text"
                  inputProps={{ "aria-label": "search", placeholder: "black" }}
                />
              </Paper>
            </div>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "flex-end",
                gap: "10px"
              }}
            >
              <IconButton
                style={{
                  backgroundColor: "#EEEFEF"
                }}
              >
                <IoFilterOutline color={"#000"} fontSize={"1.5rem"} />
              </IconButton>
              <IconButton
                style={{
                  backgroundColor:
                    this.state.listView === "card" ? "#00BE10" : "#EEEFEF"
                }}
                data-test-id="tabView"
                onClick={() => this.changeDisplayListing("card")}
              >
                <MdOutlineWindow
                  fontSize={"1.5rem"}
                  color={this.state.listView === "card" ? "#FFF" : "#000"}
                />
              </IconButton>
              <IconButton
                style={{
                  backgroundColor:
                    this.state.listView === "list" ? "#00BE10" : "#EEEFEF"
                }}
                data-test-id="listIcon"
                onClick={() => this.changeDisplayListing("list")}
              >
                <TbLayoutList
                  fontSize={"1.5rem"}
                  color={this.state.listView === "list" ? "#FFF" : "#000"}
                />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              width: "100%"
              //   background: `url(${bg})`,
              //   backgroundPosition: "center",
              //   backgroundSize: "cover",
              //   backgroundRepeat: "no-repeat"
            }}
          >
            <div
              style={
                this.state.listView === "card"
                  ? webStylesLocal.card
                  : webStylesLocal.list
              }
            >
              {this.state.coursesList.map((item, index) => (
                <React.Fragment key={item.id}>
                  {item.attributes.course_order.map(value =>
                    this.state.listView === "card" ? (
                      <MyCourseCardWeb
                        index={index}
                        isList={false}
                        item={value}
                      />
                    ) : (
                      <MyCourseCardWeb
                        index={index}
                        isList={true}
                        item={value}
                      />
                    )
                  )}
                </React.Fragment>
              ))}
            </div>
            <div style={{ display: "flex", padding: "1rem" }}>
              {this.state.isLoading && (
                <CircularProgress
                  style={{ margin: "auto", color: "#2a9e6d" }}
                />
              )}
            </div>
          </div>
        </Box>
      </div>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        <main
          style={{
            overflowX: "scroll"
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(60,116,129,1) 200px, rgba(255,255,255,1) 200px)",
              padding: "25px 0"
            }}
          >
            {this.renderList()}
            <FooterWeb id="footer" navigation={this.props.navigation} />
          </div>
        </main>
      </ThemeProvider>
    );
  }
}

const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "30px",
    margin: "auto",
    border: "1px solid #FFA235",
    background: "white",
    boxSizing: "border-box",
    boxShadow: "unset",
    height: 44,
    flexDirection: "row-reverse"
  } as Record<string, string | number>,
  input: {
    flex: 1,
    marginRight: 4,
    marginLeft: 16,
    color: "black",
    width: "90%"
  },
  iconButton: {
    padding: 10,
    background: "linear-gradient(180deg,#fff54b,#f4c952)",
    fontSize: "0.55rem",
    margin: 3
  }
};

// Customizable Area End

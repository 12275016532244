import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface TestimonialsResponseList {
  data: TestimonialData[];
  meta: Meta;
  errors: any[];
}

export interface TestimonialData {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  rating: number;
  comment: string;
  user_name: UserName;
  course_details: CourseDetails;
}

export interface CourseDetails {
  id: number;
  course_name: string;
}

export interface UserName {
  id: number;
  full_phone_number: string;
  email: string;
  full_name: string;
}

export interface Meta {
  message: string;
  page: string;
  total_testimonial: number;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  pageNo: number;
  perPage: number;
  totalPages: number;
  searchText: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  testimonialsList: TestimonialData[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TestimonialsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestimonialsListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      isLoading: false,
      userToken: "",
      pageNo: 1,
      perPage: elementsPerPage,
      totalPages: 1,
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      testimonialsList: [],
      searchText: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getTestimonialsList();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getTestimonialsListApiCallId:
          this.handleGetTestimonialsResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  handleGetTestimonialsResponse(resJson: TestimonialsResponseList) {
    if (
      resJson.errors &&
      resJson.errors.length > 0 
    ) {
      this.navigationLoginScreen();
    } else {
      let count = resJson.meta.total_testimonial / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        testimonialsList: resJson.data,
        totalPages: count,
        isLoading: false
      });
    }
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getTestimonialsList();
    });
  };

  getTestimonialsList() {
    this.setState({ isLoading: true });
    this.getTestimonialsListApiCallId = this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint:
        configJSON.testimonialsListApiEndpoint +
        `?page=${this.state.pageNo}&per_page=${this.state.perPage}`,
      token: this.state.userToken
    });
  }

  apiCall(data: APIPayload) {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)     
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import { PiPen } from "react-icons/pi";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { FaRegTrashAlt } from "react-icons/fa";
import { Pagination } from "@material-ui/lab";
import FaqController, { Props, FaqData } from "./FaqController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import {
  DeleteModal,
  ModalView,
  CancelButton,
  ModalButton,
  DeleteButton,
  DeleteText,
} from "../../couponcodegenerator/src/Vouchercodegenerator.web";
// Customizable Area End

export default class Faq extends FaqController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDeleteFaqModal = () => {
    return (
      <DeleteModal
        style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
        data-test-id="deleteModalTestId"
        open={this.state.openDeleteModal}
        onClose={this.handleCloseDeleteModal}
      >
        <ModalView>
          <DeleteText component={"p"}>
            Do you want to delete this Faq?
          </DeleteText>
          <ModalButton>
            <DeleteButton
              data-test-id="deleteBtnTestId"
              onClick={() => this.handleDeleteFaq(this.state.deleteId)}
            >
              Delete
            </DeleteButton>
            <CancelButton
              data-test-id="cancelDeleteModal"
              onClick={() => this.handleCloseDeleteModal()}
            >
              Cancel
            </CancelButton>
          </ModalButton>
        </ModalView>
      </DeleteModal>
    );
  };

  renderTableBody = (item: FaqData) => {
    return (
      <>
        <tr>
          <td style={{ textAlign: "center" }}>{item.id}</td>
          <td style={{ textAlign: "left", width: "500px", lineBreak: "auto" }}>
            {item.attributes.topic_name}
          </td>
          <td style={{ textAlign: "left", width: "500px", lineBreak: "auto" }}>
            {item.attributes.topic_description}
          </td>
          <td
            style={{ borderRadius: "0px 10px 10px 0px", textAlign: "center" }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.navigationEditFaq(item.id)}
                size={20}
                color="black"
              />
              <FaRegTrashAlt
                data-test-id={"deleteIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.handleOpenModal(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              {["ID", "Topic Name", "Topic Description", "Action"].map(
                (header, index) => {
                  return (
                    <th>
                      <Box
                        className="th_box"
                        style={
                          index >= 1 && index < 3
                            ? { justifyContent: "left" }
                            : { justifyContent: "center" }
                        }
                      >
                        <Typography className="title_th">{header}</Typography>
                      </Box>
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody>
            {this.state.faqList &&
              this.state.faqList.length > 0 &&
              this.state.faqList.map((faq) => this.renderTableBody(faq))}
          </TableBody>
        </TableStyle>
        {this.renderPagination()}
      </Box>
    );
  };

  renderPagination = () => {
    return (
      <Box style={webStyles.paginationViewAll}>
        <StyledPagination
          count={this.state.totalPages}
          page={this.state.pageNo}
          onChange={this.handlePageChange}
          shape="rounded"
          boundaryCount={1}
          siblingCount={0}
          data-test-id="paginationTestId"
          classes={{ ul: "custom-pagination" }}
        />
      </Box>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.loader}
        newUI
        title="FAQ's"
        disableSearch
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.handleAddFaqNavigation(),
            text: "Add FAQ",
            testId: "addFaqButtonId",
          },
        ]}
        data-test-id="mainLayoutEvent"
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          isOpen={this.state.isAlert}
          msg={this.state.alertMsg}
          onClose={this.onCloseAlert}
          dataTestId="alertTestId"
        />
        {this.renderDeleteFaqModal()}
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
  dividerLine: {
    height: "20px",
    display: "inline-block",
  },
  tableDivider: {
    height: "8px",
  },
  iconSize: {
    fontSize: "13px",
  },
  paginationViewAll: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    marginBottom: "20px",
    marginRight: "20px",
  },
};
const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    ul: {
      "& .Mui-selected": {
        backgroundColor: "#176876",
        color: "white",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      display: "none", // Hide ellipsis
    },
  })
)(Pagination);

// Customizable Area End

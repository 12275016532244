import React from "react";
// Customizable Area Start
import {
  AppBar,
  Box,
  Typography,
  styled,
  Button,
  Modal
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { Pagination } from "@material-ui/lab";
import {
  CheckBtn,
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import moment from "moment";
const drawerWidth = 250;
const closeDrawer = 0;

// Customizable Area End

import VouchercodegeneratorController, {
  Props
  // configJSON,
} from "./VouchercodegeneratorController";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { GenerateColumnTitles } from "./FlatDiscount.web";

export default class Vouchercodegenerator extends VouchercodegeneratorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.onSort = this.onSort.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  onSort(field: string) {
    this.handleSort(field);
  }

  checkboxTd = (props: { index: number }) => {
    return (
      <Box className="desc_checkbox">
        <input
          data-test-id="rowCheckbox"
          type="checkbox"
          checked={this.state.isChecked[props.index]}
          onChange={() => this.handleRowCheckboxChange(props.index)}
          style={webStyles.input}
        />
      </Box>
    );
  };

  renderTableBody = (item: any, index: any) => {
    const expiryDate = moment(item.attributes["Expiry Date"]).format(
      "DD, MMM YYYY"
    );
    return (
      <>
        <tr>
          <td
            data-test-id="checkboxContainerTestId"
            style={{ borderRadius: "10px 0px 0px 10px" }}
          >
            {this.checkboxTd(index)}
          </td>
          <td>{item.id}</td>
          <td>{item.attributes.voucher_name}</td>
          <td>{item.attributes.voucher_code}</td>
          <td style={{ textTransform: "capitalize" }}>
            {item.attributes.discount_type}
          </td>
          <td>
            {item.attributes.discount_type === "amount" && "₹"}
            {item.attributes.discount}
            {item.attributes.discount_type !== "amount" && "%"}
          </td>
          <td>{item.attributes["Applicable Purchase"]}</td>
          <td>{expiryDate}</td>
          <td>
            <CheckBtn className="switch">
              <input
                type="checkbox"
                data-test-id="toggleStatus"
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
                checked={item.attributes.status}
              />
              <span className="slider round" />
            </CheckBtn>
          </td>
          <td style={{ borderRadius: "0px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleEditPropsVoucher(item.id)}
              />
              <FaRegTrashAlt
                data-test-id={"deleteIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={17}
                onClick={() => this.handleOpenModal(item.id)}
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivide} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box style={webStyles.checkboxMain}>
                  <input
                    type="checkbox"
                    test-id="headerCheckbox"
                    checked={this.state.isHeaderChecked}
                    onChange={this.handleHeaderCheckboxChange}
                    style={webStyles.input}
                  />
                </Box>
              </th>
              <GenerateColumnTitles
                columns={[
                  "S.No",
                  "Vouchers",
                  "Voucher Code",
                  "Discount Type",
                  "Discount",
                  "Applicable Purchase",
                  "Expiry Date",
                  "Status",
                  "Action"
                ]}
                onSort={this.onSort}
                data-test-id="sorterTestId"
                currentSortState={this.state.currentSortState}
              />
            </tr>
          </thead>
          <span style={webStyles.lineDivide} />
          <TableBody>
            {this.state.vouchersList.map((item: any, index: any) =>
              this.renderTableBody(item, index)
            )}
          </TableBody>
        </TableStyle>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.totalPages}
            shape="rounded"
            boundaryCount={1}
            page={this.state.pageNo}
            siblingCount={0}
            onChange={this.handlePageChange}
          />
        </PaginationBox>
      </Box>
    );
  };

  renderDeleteChildCategoryModal = () => {
    return (
      <DeleteModal
        style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
        data-test-id="ChildCatDeleteModalId"
        open={this.state.openDeleteModal}
        onClose={this.handleCloseDeleteModal}
      >
        <ModalView>
          <DeleteText component={"p"}>
            Do you want to delete this Voucher?
          </DeleteText>
          <ModalButton>
            <DeleteButton
              data-test-id="deleteBtnTestId"
              onClick={() => this.handleDeleteVoucher(this.state.deleteId)}
            >
              Delete
            </DeleteButton>
            <CancelButton
              data-test-id="cancelDeleteModal"
              onClick={() => this.handleCloseDeleteModal()}
            >
              Cancel
            </CancelButton>
          </ModalButton>
        </ModalView>
      </DeleteModal>
    );
  };

  // Customizable Area End

  render() {
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleVoucherSearch(e)}
        isLoading={this.state.isLoading}
        title={"Voucher Code"}
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.addVoucherNavigate(),
            text: "Add Voucher Code",
            testId: "addVoucherTestId"
          }
        ]}
        isStudent={false}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        {this.renderDeleteChildCategoryModal()}
        <main className="table_data">{this.renderTable()}</main>
      </MainLayout>
    );
  }
}

// Customizable Area Start
export const NavBar = styled(AppBar)({
  backgroundColor: "#fff",
  marginLeft: "250px",

  "&.MuiPaper-elevation4": { boxShadow: "none" },
  "& .nav_main": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "35px 0 20px 21px",
    justifyContent: "space-between"
  },
  "& .table_main": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflow: "scroll",
    height: "100%",
    "@media (max-width:960px)": {
      padding: "11px",
      overflowX: "scroll"
    }
  },
  "& .nav_txt_box": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#000",
    marginBottom: "15px",
    "& .drower_menu": {
      display: "none",
      height: "36px",
      width: "36px",
      color: "black"
    },
    "& .main_heading": {
      fontSize: "1.375rem",
      fontWeight: 600
    },
    "& .avtar_box": {
      display: "flex",
      justifyContent: "space-between",
      width: "90px",
      alignItems: "center"
    }
  },
  "& .search_main": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .inputSearch": {
      width: "328px",
      height: "48px",
      borderRadius: "2px",
      "& .icon_color": { color: "#b2b2b2" },
      "&.Mui-focused fieldset": {
        borderColor: "#206575"
      },
      "& input::placeholder": {
        color: "#979797 !important",
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "24px"
      }
    },
    "& .btn_box": {
      display: "flex",
      justifyContent: "space-between",
      "& .btns": {
        backgroundColor: "#206575",
        padding: "9px 22px",
        marginLeft: "10px",
        textTransform: "capitalize",
        fontSize: "0.75rem",
        color: "#fff",
        boxShadow: "unset",
        borderRadius: "0",
        "& .MuiButton-iconSizeMedium > :first-child": {
          fontSize: "0.75rem"
        },
        "@media (max-width:970px)": {
          marginLeft: "0",
          marginTop: "20px",
          marginRight: "10px",
          padding: "6px 12px"
        }
      }
    }
  }
});

export const PaginationBox = styled(Box)({
  "& .viewall_txt": {
    color: "#206575",
    fontSize: "0.75rem",
    cursor: "pointer"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff"
  }
});

export const webStyles = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`,
    height: "100svh"
  },
  modalTitleHeadText: {
    color: "var(--Basic-Black, #000)",
    fontFamily: "Inter",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px"
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  addCourseTextModalBtn: {
    width: "180px",
    background: "#1F6575",
    color: "white"
  },
  categoryImgName: {
    display: "flex",
    justifyContent: "center"
  },
  checkboxMain: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  bundleCourseText: {
    display: "flex",
    marginLeft: "40px",
    fontSize: "1.375rem",
    color: "black"
  },
  deleteBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    border: " none",
    color: " white",
    fontSize: "0.875rem",
    fontWeight: 600,
    backgroundColor: "#b91111",
    marginTop: "20px"
  },
  cancelBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    color: "black",
    fontSize: "0.875rem",
    fontWeight: 600,
    background: "white",
    border: "1px solid grey",
    marginTop: "20px"
  },
  lineDivide: {
    height: "20px",
    display: "inline-block"
  },
  tableDivide: {
    height: "10px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
export const StyledTable = styled("table")({
  overflow: "scroll",
  width: "100%",
  marginTop: "10px",
  borderTop: "2px solid black",
  borderSpacing: "0",
  position: "relative",
  minWidth: "670px",
  color: "black",
  "& thead": {
    width: "100%",
    borderCollapse: "initial",
    padding: "10px 5px",
    height: "50px"
  },
  "& th": {
    borderBottom: "2px solid black",
    fontWeight: "600",
    "& .headMain": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .titleHead": {
        fontSize: "0.625rem",
        fontWeight: 600,
        marginLeft: "1px"
      }
    }
  },
  "& td": {
    "& .checkboxDesc": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center"
    },
    "& .iconDscBox": {
      textAlign: "left",
      marginLeft: "1px",
      "& .desc_sort_icon": {
        padding: "3.2px",
        height: "20.5px",
        width: "20.3px",
        backgroundColor: "lightgray"
      }
    },
    "& .imgDesc": {
      width: "41px",
      height: "41px"
    },
    "& .editDeleteIconMain": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "& .editDeleteIcon": {
        margin: "0 5.5px",
        cursor: "pointer"
      }
    }
  }
});

export const DeleteModal = styled(Modal)({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const ModalView = styled(Box)({
  height: "120px",
  width: "340px",
  borderRadius: "5px",
  padding: "10px 50px",
  outline: "none",
  backgroundColor: "#ffffff",
  display: "flex",
  margin: "20px",
  boxShadow: "0px 0px 3px px transparent",
  flexDirection: "column"
});

export const ModalViewDuplicated = styled(Box)({
  width: "500px",
  borderRadius: "5px",
  padding: "10px 50px",
  outline: "none",
  backgroundColor: "#ffffff",
  display: "flex",
  margin: "20px",
  boxShadow: "0px 0px 3px px transparent",
  flexDirection: "column"
});

export const ModalButton = styled(Box)({
  justifyContent: "space-between",
  display: "flex"
});

export const CancelButton = styled("button")({
  height: "40px",
  width: "145px",
  color: "#206575",
  border: "1px solid #206575",
  fontSize: "0.875rem",
  backgroundColor: "white"
});

export const DeleteButton = styled("button")({
  height: "45px",
  width: "145px",
  border: "0",
  backgroundColor: "#DA1831",
  fontSize: "0.875rem",
  color: "white",
  fontWeight: 500
});

export const DeleteText = styled(Box)({
  height: "37px",
  width: "100%",
  fontSize: "0.875rem",
  fontWeight: 600
});

export const DuplicatedModal = ({
  isModalOpen,
  handleCloseModal,
  message
}: {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  message: string;
}) => {
  return (
    <>
      <DeleteModal
        style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
        data-test-id="deleteModalId"
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <ModalViewDuplicated>
          <div style={webStyles.modalTitle}>
            <div style={webStyles.modalTitleHeadText} />
            <ClearIcon data-test-id="clearIcon" onClick={handleCloseModal} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px"
            }}
          >
            <Typography style={{ color: "#FF3D3D", fontWeight: "bold" }}>
              OOPS!
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              The generated {message} appears to be duplicated. Kindly review
              and verify its uniqueness.
            </Typography>
          </div>
          <ModalButton style={{ marginBottom: "13px" }}>
            <Button
              data-test-id="modalCloseTestId"
              style={{ ...webStyles.addCourseTextModalBtn, margin: "auto" }}
              onClick={handleCloseModal}
            >
              Okay
            </Button>
          </ModalButton>
        </ModalViewDuplicated>
      </DeleteModal>
    </>
  );
};

// Customizable Area End

import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { FiDownload } from "react-icons/fi";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import { PiPen } from "react-icons/pi";
// Customizable Area End

import TermsAndConditionsController, {
  Props,
  TermsAndConditionsData
} from "./TermsAndConditionsController";

export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: TermsAndConditionsData) => {
    return (
      <>
        <tr>
          <td style={{ textAlign: "left", width: 25 }}>{item.id}</td>
          <td style={{ textAlign: "left", width: "500px", lineBreak: "auto" }}>
            {item.attributes.description}
          </td>
          <td style={{ textAlign: "left", width: 100 }}>
            {item.attributes.created_on}
          </td>
          <td
            style={{
              borderRadius: "0px 10px 10px 0px",
              textAlign: "center",
              width: 25
            }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.navigationEditTermsAndConditions(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              {["S. No", "Description", "Created At", "Action"].map(
                (header, index) => {
                  return (
                    <th>
                      <Box
                        className="th_box"
                        style={
                          index === 3
                            ? { justifyContent: "center" }
                            : { justifyContent: "left" }
                        }
                      >
                        <Typography className="title_th">{header}</Typography>
                      </Box>
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody>
            {this.state.termsAndConditions &&
              this.renderTableBody(this.state.termsAndConditions)}
          </TableBody>
        </TableStyle>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.loader}
        newUI
        isAdd
        title="Terms & Conditions"
        data-test-id="mainLayoutEvent"
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          isOpen={this.state.isAlert}
          msg={this.state.alertMsg}
          onClose={this.onCloseAlert}
          dataTestId="alertTestId"
        />
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "8px"
  },
  iconSize: {
    fontSize: "13px"
  }
};
// Customizable Area End

import React from "react";

// Customizable Area Start
import { Box, Typography, Snackbar, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { IoIosArrowDown } from "react-icons/io";
import { Pagination } from "@material-ui/lab";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
export const configJSON = require("./config");
// Customizable Area End

import AdminStateListController, {
  Props,
  StateListData
} from "./AdminStateListController.web";
import {
  DeleteModalStyle,
  PaginationBox,
  renderTableRecord,
  webStyles
} from "./AdminDistrictList.web";

export default class AdminStateList extends AdminStateListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStateTableBody = (item: StateListData) => {
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input type="checkbox" style={webStyles.input} />
            </Box>
          </td>
          <td className="tr_desc" style={{ textAlign: "left" }}>
            {item.id}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.name || "----"}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.created_on || "----"}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.updated_on || "----"}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.profile_country.name || "----"}
          </td>
          <td
            style={{ borderRadius: "0px 10px 10px 0px", textAlign: "center" }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id="editButtonId"
                className="desc_edit_icon"
                onClick={() =>
                  this.navigationEditStateScreen(item.attributes.id)
                }
                size={20}
                color="black"
              />
              <FaRegTrashAlt
                data-test-id="deleteIconeId"
                className="desc_edit_icon"
                onClick={() => this.handleOpenModal(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  leftAlignedCols = (isCurrentSort: (name: string) => boolean) => {
    return (
      <>
        {[
          configJSON.stateNameText,
          configJSON.createdAtText,
          configJSON.updatedAtText,
          configJSON.profileCountryText
        ].map((header, index) => {
          let renderIcon = false;
          if ([configJSON.sNoText, configJSON.stateNameText].includes(header)) {
            renderIcon = true;
          }
          return renderTableRecord(
            () => isCurrentSort("name"),
            header,
            renderIcon,
            index,
            () => this.handleSort("name"),
            "sort_name"
          );
        })}
      </>
    );
  };

  renderStateColumns = (isCurrentSort: (name: string) => boolean) => {
    return (
      <>
        <th>
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center"
            }}
          >
            <input type="checkbox" style={webStyles.input} />
          </Box>
        </th>
        <th>
          <Box className="th_box" style={{ justifyContent: "left" }}>
            <Typography className="title_th">{configJSON.sNoText}</Typography>
            <IoIosArrowDown
              className={`filter_icon ${isCurrentSort("id") ? "active" : ""}`}
              onClick={() => this.handleSort("id")}
              data-test-id="sort_id"
              style={{
                transform: isCurrentSort("id") ? "none" : "rotate(180deg)"
              }}
            />
          </Box>
        </th>
        {this.leftAlignedCols(isCurrentSort)}
        <th>
          <Box className="th_box" style={{ justifyContent: "center" }}>
            <Typography className="title_th">
              {configJSON.actionText}
            </Typography>
          </Box>
        </th>
      </>
    );
  };

  renderStateTable = () => {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>{this.renderStateColumns(isCurrentSort)}</tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody data-test-id="stateListId">
            {this.state.adminStateListData.data.map(item =>
              this.renderStateTableBody(item)
            )}
          </TableBody>
        </TableStyle>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="paginationListId"
            count={this.state.pageCount}
            shape="rounded"
            boundaryCount={1}
            page={this.state.currentPageCount}
            siblingCount={0}
            onChange={this.onStatesPageChange}
          />
        </PaginationBox>
      </Box>
    );
  };

  renderDeleteStateModal = () => {
    return (
      <DeleteModalStyle
        style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
        open={this.state.openDeleteModal}
        onClose={this.handleCloseDeleteModal}
      >
        <Box className="modalComponent">
          <Box className="deleteTitleText" component={"p"}>
            {configJSON.deleteStateModalText}
          </Box>
          <Box className="buttonBox">
            <Button
              data-test-id="deleteButtonId"
              className="delButton"
              onClick={this.handleDeleteState}
            >
              {configJSON.deleteText}
            </Button>
            <Button className="canButton" onClick={this.handleCloseDeleteModal}>
              {configJSON.cancelDistrictText}
            </Button>
          </Box>
        </Box>
      </DeleteModalStyle>
    );
  };

  notificationAlertState = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.onCloseAlert}
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        data-test-id="addButtonId"
        isLoading={this.state.loader}
        title="State"
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.navigationAddStateScreen(),
            text: "Add State",
            testId: "backButtonId"
          }
        ]}
        disableSearch
      >
        {this.notificationAlertState()}
        {this.renderDeleteStateModal()}
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderStateTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End

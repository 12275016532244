// Customizable Area Start
export const star = require("../assets/star.png");
export const study = require("../assets/study.png");
export const playIcon = require("../assets/playIcon.png");
export const bg = require("../assets/bg.jpg");
export const verticalLine = require("../assets/verticalLine.png");
export const lecture = require("../assets/lecture.png");
export const time = require("../assets/time.png");
export const chapter = require("../assets/chapter.png");
export const dashedLine = require("../assets/dashedLine.png");
// Customizable Area End

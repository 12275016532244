import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import moment from "moment";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import { parseErrors } from "./FlatDiscountController.web";
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ApiResponse {
  data: DataApiResponse;
  meta: { message: string };
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface DataApiResponse {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  coupon_code: string;
  coupon_name: string;
  discount_type: string;
  discount: string;
  expiry_date: string;
  status: boolean;
  coupon_code_course: CouponCodeCourse[];
  created_at: string;
  updated_at: string;
  "Applicable Purchase": string;
}

export interface CouponCodeCourse {
  id: number;
  coupon_code_id: number;
  course_id: number;
  courseable_type: string;
  created_at: Date;
  updated_at: Date;
}

export interface AssociatedCoursesType {
  csv_data: CSVDatum[];
}

export interface CSVDatum {
  id: number;
  type: string;
  name: string;
  created_at: Date;
}

interface S {
  // Customizable Area Start
  isChecked: boolean[];
  isHeaderChecked: boolean;
  userToken: any;
  pageNo: number;
  perPage: number;
  totalPages: number;
  isLoading: boolean;
  openDeleteModal: boolean;
  deleteId: string | number;
  searchText: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isModalOpen: boolean;
  couponFormData: CouponFormData;
  couponFormDataInitial?: CouponFormData;
  isUpdate: boolean;
  discountTypeList: any[];
  couponsList: any[];
  couponDataId: any;
  associatedCourses: CSVDatum[];
  currentSortState: { order: string; field: string };
  columnSort: string;
  // Customizable Area End
}

interface CouponFormData {
  coupon_name: string;
  coupon_code: string;
  discount_type: string;
  discount: string | number;
  expiry_date: string;
  applicable_on: string | number;
  course_id: number[];
  course_id_values: (string | number)[][];
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export const initialValues: any = {
  isChecked: [],
  isHeaderChecked: false,
  isLoading: false,
  userToken: "",
  pageNo: 1,
  perPage: elementsPerPage,
  totalPages: 1,
  openDeleteModal: false,
  deleteId: "",
  searchText: "",
  isUpdate: false,
  isAlert: false,
  alertMsg: "",
  alertType: "success",
  isModalOpen: false,
};

export default class CouponcodegeneratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCouponsApiCallId: string = "";
  getAssociatedCoursesApiCallId: string = "";
  deleteCouponAPICallId: string = "";
  addCouponApiCallId: string = "";
  editCouponApiCallId: string = "";
  showCouponApiCallId: string = "";
  toggleCouponStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
    ];

    this.state = {
      ...initialValues,
      couponFormData: {
        coupon_name: "",
        coupon_code: "",
        discount_type: "amount",
        discount: "",
        expiry_date: "",
        applicable_on: "",
        course_id: [],
        course_id_values: [],
      },
      couponFormDataInitial: undefined,
      couponsList: [],
      discountTypeList: [
        { id: "amount", name: "Amount" },
        { id: "percentage", name: "Percentage" },
      ],
      associatedCourses: [],
      couponDataId: [],
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getCouponsDataList(this.state.columnSort);
    this.handleGetAssociatedCourses();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCouponsApiCallId:
            this.handleGetCouponsResponse(responseJson);
            break;
          case this.deleteCouponAPICallId:
            this.deleteCouponApiResponse(responseJson);
            break;
          case this.editCouponApiCallId:
            this.handleAddCouponApiResponse(responseJson);
            break;
          case this.addCouponApiCallId:
            this.handleAddCouponApiResponse(responseJson);
            break;
          case this.showCouponApiCallId:
            this.handleGetCouponListResponse(responseJson);
            break;
          case this.toggleCouponStatusApiCallId:
            this.handleToggleOptionsResponse(responseJson);
            break;
          case this.getAssociatedCoursesApiCallId:
            this.handleGetAssociatedCoursesResponse(responseJson);
            break;
        }
      } else {
        parseErrors(responseJson, this.parseApiErrorResponse, this.send, this.props);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let couponData = message.getData(
        getName(MessageEnum.NavigationIdMessage)
      );
      if (couponData) {
        this.handleShowCoupon(couponData.ScreenTest);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleShowCoupon = (id: string | number) => {
    this.setState({ isLoading: true });
    this.showCouponApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeCouponGet,
      endPoint: configJSON.couponWebApiEndpoint + `/${id}`,
    });
  };

  handleGetCouponListResponse(resJson: ApiResponse) {
    const { data } = resJson;
    const { attributes } = data;

    const values: CouponFormData = {
      discount: attributes.discount,
      discount_type: attributes.discount_type,
      expiry_date: moment(attributes.expiry_date).format("YYYY-MM-DD"),
      coupon_code: attributes.coupon_code,
      coupon_name: attributes.coupon_name,
      applicable_on: attributes["Applicable Purchase"],
      course_id: attributes.coupon_code_course.map((val) => val.course_id),
      course_id_values: attributes.coupon_code_course.map((value) => [
        value.course_id,
        value.courseable_type,
      ]),
    };

    this.setState({
      couponDataId: data.id,
      isUpdate: true,
      couponFormData: values,
      couponFormDataInitial: values,
      isLoading: false,
    });
  }

  handleSort(field: string) {
    const sortParams: Record<string, string> = {
      "S.No": "id_sort",
      Coupons: "name_sort",
      Status: "status_sort",
      "Expiry Date": "expiry_date_sort"
    };
    let columnSort = "";
    const sortOrder = this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      columnSort = `&${sortParams[field]}=${sortOrder}`;
    }
    this.toggleSortState(field, columnSort);
    this.getCouponsDataList(columnSort);
  }

  toggleSortState = (field: string, columnSort: string) => {
    this.setState({
      columnSort,
      currentSortState: {
        field,
        order: this.state.currentSortState.order === "asc" ? "desc" : "asc"
      }
    });
  };

  handleResetData() {
    this.setState({
      couponFormData: {
        discount: "",
        discount_type: "",
        expiry_date: "",
        coupon_code: "",
        coupon_name: "",
        applicable_on: "",
        course_id: [],
        course_id_values: [],
      },
    });
  }

  oncloseAlert = () => {
    this.setState({
      isAlert: false,
    });
  };

  handleGetCouponsResponse(resJson: any) {
    if (resJson.meta?.coupons === "Record not found") {
      this.setState({
        isAlert: true,
        alertMsg: resJson.meta.coupons,
        alertType: "info",
        isLoading: false,
        totalPages: 0,
        couponsList: [],
        isChecked: [],
      });
    } else if (
      resJson?.errors &&
      resJson.errors.length > 0 &&
      resJson.errors[0]?.token == configJSON.tokenExpiredMessage
    ) {
      this.couponNavigate();
    } else {
      let count = resJson.meta.total_coupons / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        couponsList: resJson.data,
        isChecked: Array(
          this.state.couponsList ? this.state.couponsList.length : 0
        ).fill(false),
        totalPages: count,
        isLoading: false,
      });
    }
  }

  handleGetAssociatedCoursesResponse(resJson: AssociatedCoursesType) {
    if (resJson.csv_data) {
      this.setState({
        associatedCourses: resJson.csv_data,
      });
    }
    this.setState({ isLoading: false });
  }

  handleAddCouponApiResponse(responseJson: any) {
    if (
      responseJson &&
      responseJson.data &&
      responseJson.data.type === "error"
    ) {
      const object = Object.keys(
        responseJson.data.attributes.errors as any[]
      )[0];
      const objectMsg = Object.values(
        responseJson.data.attributes.errors as any[]
      )[0][0];
      const stateFinal: any =
        objectMsg === "has already been taken"
          ? { isModalOpen: true, isLoading: false, alertMsg: object }
          : {
              isAlert: true,
              alertMsg: object + " " + objectMsg || "Error during petition",
              alertType: "error",
              isLoading: false,
            };
      this.setState(stateFinal);
    } else {
      this.couponNavigate();
    }
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  handleGoBack() {
    this.couponNavigate();
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getCouponsDataList(this.state.columnSort);
    });
  };
  handleOpenModal = (id: string) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id,
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: "",
    });
  };
  handleEditPropsCoupon = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddCouponcodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };
  deleteCouponApiResponse(responseJson: any) {
    this.setState({ openDeleteModal: false, isLoading: false }, () =>
      this.getCouponsDataList(this.state.columnSort)
    );
  }
  handleHeaderCheckboxChange = () => {
    this.setState((prevState) => ({
      isHeaderChecked: !prevState.isHeaderChecked,
      isChecked: Array(this.state.couponsList.length).fill(
        !prevState.isHeaderChecked
      ),
    }));
  };
  handleRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      return {
        isChecked,
        isHeaderChecked: isChecked.every((value) => value),
      };
    });
  };

  handleDeleteCoupon(id: string | number) {
    this.setState({ isLoading: true });
    this.deleteCouponAPICallId = this.apiCall({
      method: configJSON.deleteCouponAPiMethod,
      endPoint: configJSON.couponWebApiEndpoint + `/${id}`,
    });
  }
  handleCouponsSearch(e: any) {
    let searchVal = e.target.value;
    this.setState({ searchText: searchVal, pageNo: 1 }, () => {
      this.getCouponsDataList(this.state.columnSort);
    });
  }
  getCouponsDataList(sorting?:string) {
    const searchParamsString = this.state.searchText
      ? `&search_params=${this.state.searchText}&${sorting}`
      : `${sorting}`;
    this.setState({ isLoading: true });
    this.getCouponsApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeCouponGet,
      contentType: configJSON.validationApiContentType,
      endPoint:
        configJSON.couponWebSearchApiEndpoint +
        `?page=${this.state.pageNo}&per_page=${this.state.perPage}${searchParamsString}`,
    });
  }
  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token: this.state.userToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleCouponInputChange(e: any, type?: "number" | "date") {
    let { name, value } = e.target;
    if (type === "number") {
      if (!/^(?=.{0,10}$)[0-9]*\.?[0-9]*$/.test(value)) return;
    }
    this.setState({
      couponFormData: {
        ...this.state.couponFormData,
        [name]: value,
      },
    });
  }

  onCourseInfoClick() {
    this.setState({
      alertMsg: 
        "If any associated course is not selected, this coupon will be applicable for all the existing courses.",
      isAlert: true,
      alertType: "info"
    })
  }

  getCouponsList(courses: number[]) {
    return courses.map((course) => [
      course,
      this.state.associatedCourses.find((val) => val.id === course)?.type || "",
    ]);
  }

  handleAddCoupon() {
    this.setState({ isLoading: true });
    const {
      discount,
      discount_type,
      expiry_date,
      applicable_on,
      coupon_code,
      coupon_name,
      course_id,
    } = this.state.couponFormData;

    if (
      !coupon_name ||
      !coupon_code ||
      !discount ||
      !applicable_on ||
      !expiry_date
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Please fill all mandatory fields.",
        alertType: "error",
        isLoading: false,
      });
      return;
    }

    let couponsList: (string | number)[][] = [];
    if (course_id && course_id.length > 0) {
      couponsList = this.getCouponsList(course_id);
    }

    const body = {
      data: {
        coupon_name,
        coupon_code,
        discount_type,
        discount,
        expiry_date: moment(expiry_date).format("DD-MM-YYYY"),
        applicable_on,
        course_id: couponsList,
      },
    };

    this.addCouponApiCallId = this.apiCall({
      method: configJSON.createCouponAPiMethod,
      endPoint: configJSON.couponWebApiEndpoint,
      body,
      contentType: configJSON.validationApiContentType,
    });
  }

  handleEditCoupon() {
    this.setState({ isLoading: true });
    let {
      discount,
      discount_type,
      expiry_date,
      applicable_on,
      coupon_code,
      coupon_name,
      course_id,
    } = this.state.couponFormData;
    const { couponDataId } = this.state;
    const initialValues: any = this.state.couponFormDataInitial;

    if (
      !coupon_name ||
      !coupon_code ||
      !discount ||
      !expiry_date ||
      !applicable_on ||
      !initialValues
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Please fill all mandatory fields.",
        alertType: "error",
        isLoading: false,
      });
      return;
    }

    let formValues: any = {
      coupon_name,
      coupon_code,
      discount_type,
      discount,
      expiry_date: moment(expiry_date).format("DD-MM-YYYY"),
      applicable_on,
      course_id,
    };

    let body: any = {
      data: {},
    };

    Object.keys(formValues).forEach((key) => {
      if (formValues[key] !== initialValues[key]) {
        body.data[key] = formValues[key];
      }
    });

    if (course_id && course_id.length > 0) {
      const couponList = this.getCouponsList(course_id);
      body.data.course_id = couponList;
    }

    this.editCouponApiCallId = this.apiCall({
      method: configJSON.putCouponApiMethod,
      endPoint: configJSON.couponWebApiEndpoint + `/${couponDataId}`,
      body,
      contentType: configJSON.validationApiContentType,
    });
  }

  addCouponNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddCouponcodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  couponNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationCouponcodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleToggleOptionsResponse = (responseJson: any) => {
    this.getCouponsDataList(this.state.columnSort);
    this.setState({
      isAlert: true,
      alertMsg:
        responseJson?.meta?.message || "Coupon status change successfully",
      alertType: "success",
      isLoading: false,
    });
  };

  handleToggleStatus = (id: string, status: boolean, index: number) => {
    let clone = [...this.state.couponsList];
    clone[index].attributes.status = !status;

    this.setState({
      couponsList: clone,
    });

    this.setState({ isLoading: true });
    this.toggleCouponStatusApiCallId = this.apiCall({
      body: { data: { status: !status } },
      contentType: configJSON.validationApiContentType,
      method: configJSON.putCouponApiMethod,
      endPoint: configJSON.couponWebApiEndpoint + `/${id}`,
    });
  };

  handleGetAssociatedCourses = () => {
    this.setState({ isLoading: true });
    this.getAssociatedCoursesApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeCouponGet,
      endPoint: configJSON.associatedCoursesApiEndpoint,
    });
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { initialValues } from "./CouponcodegeneratorController.web";
import { parseErrors } from "./FlatDiscountController.web";
const fetch = require("node-fetch");
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const integerRegex = /^(?=.{0,10}$)\d*$/,
  decimalRegex = /^(?=.{0,10}$)\d*\.?\d*$/;

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token: string;
  type?: string;
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface Response {
  data: { id: string; type: string; attributes: Attributes };
  meta: { message: string };
}

export interface Attributes {
  id: number;
  voucher_code: string;
  voucher_name: string;
  discount_type: string;
  discount: number;
  expiry_date: string;
  status: boolean;
  minimum_purchase: number;
  created_at: Date;
  updated_at: Date;
  no_of_time_purchased_voucher_code: number;
  "Creation Date": string;
  "Expiry Date": string;
  "List Expiry Date": string;
  "Applicable Purchase": string;
}

interface S {
  // Customizable Area Start
  isChecked: boolean[];
  isLoading: boolean;
  isHeaderChecked: boolean;
  userToken: any;
  pageNo: number;
  perPage: number;
  totalPages: number;
  openDeleteModal: boolean;
  deleteId: string | number;
  searchText: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isModalOpen: boolean;
  voucherFormDataInitial?: FormValues;
  voucherFormData: FormValues;
  isUpdate: boolean;
  discountTypeList: any[];
  vouchersList: any[];
  voucherDataId: any;
  currentSortState: { order: string; field: string };
  columnSort: string;
  // Customizable Area End
}

export interface FormValues {
  voucher_name: string;
  voucher_code: string;
  discount_type: string;
  discount: string | number;
  expiry_date: string;
  creation_date: string;
  minimum_purchase: string | number;
  no_of_time_purchased_voucher_code: string | number;
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class VouchercodegeneratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVouchersApiCallId: string = "";
  deleteVoucherAPICallId: string = "";
  addVoucherApiCallId: string = "";
  editVoucherApiCallId: string = "";
  showVoucherApiCallId: string = "";
  toggleVoucherStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
    ];

    this.state = {
      ...initialValues,
      voucherFormData: {
        voucher_name: "",
        voucher_code: "",
        discount_type: "amount",
        discount: "",
        expiry_date: "",
        minimum_purchase: "",
        creation_date: new Date().toJSON().slice(0, 10),
        no_of_time_purchased_voucher_code: "",
      },
      voucherFormDataInitial: undefined,
      vouchersList: [],
      discountTypeList: [
        { id: "amount", name: "Amount" },
        { id: "percentage", name: "Percentage" },
      ],
      voucherDataId: [],
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: "",
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getVouchersDataList(this.state.columnSort);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getVouchersApiCallId:
            this.handleGetVouchersResponse(responseJson);
            break;
          case this.deleteVoucherAPICallId:
            this.deleteVoucherApiResponse(responseJson);
            break;
          case this.editVoucherApiCallId:
            this.handleAddVoucherApiResponse(responseJson);
            break;
          case this.addVoucherApiCallId:
            this.handleAddVoucherApiResponse(responseJson);
            break;
          case this.showVoucherApiCallId:
            this.handleGetVoucherListResponse(responseJson);
            break;
          case this.toggleVoucherStatusApiCallId:
            this.handleToggleOptionsResponse(responseJson);
            break;
        }
      } else {
        parseErrors(responseJson, this.parseApiErrorResponse, this.send, this.props);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let bundleCourseData = message.getData(
        getName(MessageEnum.NavigationIdMessage)
      );
      if (bundleCourseData) {
        this.handleShowVoucher(bundleCourseData.ScreenTest);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleShowVoucher = (id: string | number) => {
    this.setState({ isLoading: true });
    this.showVoucherApiCallId = apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeCouponGet,
      endPoint: configJSON.voucherGetApiEndpoint + id,
      token: this.state.userToken,
    });
  };

  handleGetVoucherListResponse(resJson: Response) {
    const { data } = resJson;
    const { attributes } = data;

    const values = {
      discount: attributes.discount,
      discount_type: attributes.discount_type,
      expiry_date: attributes["Expiry Date"].replace(/\s/g, ""),
      minimum_purchase: attributes.minimum_purchase,
      voucher_code: attributes.voucher_code,
      voucher_name: attributes.voucher_name,
      creation_date: new Date(attributes["Creation Date"].replace(/\s/g, ""))
        .toJSON()
        .slice(0, 10),
      no_of_time_purchased_voucher_code:
        attributes.no_of_time_purchased_voucher_code,
    };

    this.setState({
      voucherDataId: data.id,
      isUpdate: true,
      voucherFormData: values,
      voucherFormDataInitial: values,
      isLoading: false,
    });
  }

  handleSort(field: string) {
    const sortParams: Record<string, string> = {
      "S.No": "id_sort",
      Vouchers: "name_sort",
      Status: "status_sort",
      "Expiry Date": "expiry_date_sort",
    };
    let fieldSort = "";
    const sortOrder =
      this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      fieldSort = `&${sortParams[field]}=${sortOrder}`;
    }
    this.toggleSortState(field, fieldSort);
    this.getVouchersDataList(fieldSort);
  }

  toggleSortState = (field: string, columnSort: string) => {
    this.setState({
      columnSort,
      currentSortState: {
        field,
        order: this.state.currentSortState.order === "asc" ? "desc" : "asc",
      },
    });
  };

  handleResetData() {
    this.setState({
      voucherFormData: {
        discount: "",
        discount_type: "amount",
        expiry_date: "",
        minimum_purchase: "",
        voucher_code: "",
        voucher_name: "",
        creation_date: new Date().toJSON().slice(0, 10),
        no_of_time_purchased_voucher_code: "",
      },
    });
  }

  oncloseAlert = () => {
    this.setState({
      isAlert: false,
    });
  };

  handleGetVouchersResponse(resJson: any) {
    if (resJson.error === "Record not found.") {
      this.setState({
        isAlert: true,
        alertMsg: resJson.error,
        alertType: "info",
        isLoading: false,
        totalPages: 0,
        vouchersList: [],
        isChecked: [],
      });
    } else if (
      resJson?.errors &&
      resJson.errors.length > 0 &&
      resJson.errors[0]?.token == configJSON.tokenExpiredMessage
    ) {
      this.voucherNavigate();
    } else {
      let count = resJson.meta.total_vouchers / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        vouchersList: resJson.data,
        isChecked: Array(
          this.state.vouchersList ? this.state.vouchersList.length : 0
        ).fill(false),
        totalPages: count,
        isLoading: false,
      });
    }
  }

  handleAddVoucherApiResponse(responseJson: any) {
    if (responseJson && responseJson.error) {
      const object = Object.keys(responseJson.error)[0];
      const objectMsg = Object.values(responseJson.error as any[])[0][0];
      const stateFinal: any =
        objectMsg === "has already been taken"
          ? {
              isModalOpen: true,
              isLoading: false,
              alertMsg:
                object === "voucher_code" ? "voucher code" : "voucher name",
            }
          : {
              isAlert: true,
              alertMsg: object + " " + objectMsg || "Error during petition",
              alertType: "error",
              isLoading: false,
            };
      this.setState(stateFinal);
    } else {
      this.voucherNavigate();
    }
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  handleGoBack() {
    this.voucherNavigate();
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getVouchersDataList(this.state.columnSort);
    });
  };
  handleOpenModal = (id: number) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id,
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: "",
    });
  };
  handleEditPropsVoucher = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddVouchercodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };
  deleteVoucherApiResponse(responseJson: any) {
    this.setState({ openDeleteModal: false, isLoading: false }, () =>
      this.getVouchersDataList(this.state.columnSort)
    );
  }
  handleHeaderCheckboxChange = () => {
    this.setState((prevState) => ({
      isHeaderChecked: !prevState.isHeaderChecked,
      isChecked: Array(this.state.vouchersList.length).fill(
        !prevState.isHeaderChecked
      ),
    }));
  };
  handleRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      return {
        isChecked,
        isHeaderChecked: isChecked.every((value) => value),
      };
    });
  };

  handleDeleteVoucher(id: any) {
    this.setState({ isLoading: true });
    this.deleteVoucherAPICallId = apiCall({
      method: configJSON.deleteCouponAPiMethod,
      endPoint: configJSON.vouchersListApiEndpoint + `/${id}`,
      token: this.state.userToken,
    });
  }
  handleVoucherSearch(e: any) {
    let searchVal = e.target.value;
    this.setState({ searchText: searchVal, pageNo: 1 }, () => {
      this.getVouchersDataList(this.state.columnSort);
    });
  }
  getVouchersDataList(field?: string) {
    const searchParamsString = this.state.searchText
      ? `&search_params=${this.state.searchText}&${field}`
      : `${field}`;
    this.setState({ isLoading: true });
    this.getVouchersApiCallId = apiCall({
      method: configJSON.apiMethodTypeCouponGet,
      endPoint:
        configJSON.vouchersListApiEndpoint +
        `?page=${this.state.pageNo}&per_page=${this.state.perPage}${searchParamsString}`,
      token: this.state.userToken,
    });
  }

  handleCourseInputChange(e: any, isNumber = false, isInteger = false) {
    let { name, value } = e.target;
    if (isNumber) {
      const regex = isInteger ? integerRegex : decimalRegex;
      if (!regex.test(value)) {
        return;
      }
    }
    this.setState({
      voucherFormData: {
        ...this.state.voucherFormData,
        [name]: value,
      },
    });
  }
  handleAddVoucher() {
    this.setState({ isLoading: true });
    const {
      discount,
      discount_type,
      expiry_date,
      minimum_purchase,
      voucher_code,
      voucher_name,
      no_of_time_purchased_voucher_code,
    } = this.state.voucherFormData;

    if (
      !discount ||
      !expiry_date ||
      !minimum_purchase ||
      !voucher_code ||
      !voucher_name ||
      !no_of_time_purchased_voucher_code
    ) {
      this.setState({
        isAlert: true,
        alertMsg: `Please fill in all fields`,
        alertType: "error",
        isLoading: false,
      });
      return;
    }

    const header = new fetch.Headers();
    header.append("token", this.state.userToken);

    const body = {
      data: {
        voucher_name,
        voucher_code,
        discount_type,
        discount,
        expiry_date,
        minimum_purchase,
        no_of_time_purchased_voucher_code,
      },
    };

    this.addVoucherApiCallId = apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.createCouponAPiMethod,
      endPoint: configJSON.vouchersListApiEndpoint,
      body,
      token: this.state.userToken,
    });
  }

  handleEditVoucher() {
    this.setState({ isLoading: true });
    const {
      discount,
      expiry_date,
      voucher_code,
      voucher_name,
      minimum_purchase,
      no_of_time_purchased_voucher_code,
    } = this.state.voucherFormData;
    const initialValues = this.state.voucherFormDataInitial;

    if (
      !discount ||
      !voucher_name ||
      !voucher_code ||
      !expiry_date ||
      !minimum_purchase ||
      !initialValues ||
      !no_of_time_purchased_voucher_code
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Please fill in all fields",
        alertType: "error",
        isLoading: false,
      });
      return;
    }

    const header = new fetch.Headers();
    header.append("token", this.state.userToken);

    const body = removeDuplicatesForSubmit<FormValues>(
      this.state.voucherFormData,
      initialValues
    );

    this.editVoucherApiCallId = apiCall({
      body,
      contentType: configJSON.validationApiContentType,
      method: configJSON.putCouponApiMethod,
      endPoint: configJSON.voucherGetApiEndpoint + this.state.voucherDataId,
      token: this.state.userToken,
    });
  }

  addVoucherNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddVouchercodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  voucherNavigate() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationVouchercodegeneratorMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleToggleOptionsResponse = (responseJson: any) => {
    this.getVouchersDataList(this.state.columnSort);
    this.setState({
      isAlert: true,
      alertMsg:
        responseJson?.meta?.message || "Voucher status change successfully",
      alertType: "success",
      isLoading: false,
    });
  };

  handleToggleStatus = (id: string, status: boolean, index: number) => {
    let clone = [...this.state.vouchersList];
    clone[index].attributes.status = !status;

    this.setState({
      vouchersList: clone,
    });

    this.setState({ isLoading: true });
    this.toggleVoucherStatusApiCallId = apiCall({
      body: { data: { status: !status } },
      contentType: configJSON.validationApiContentType,
      method: configJSON.putCouponApiMethod,
      endPoint: configJSON.voucherGetApiEndpoint + id,
      token: this.state.userToken,
    });
  };

  // Customizable Area End
}
// Customizable Area Start
type FormValuesType = {
  [key: string]: any;
};

export const removeDuplicatesForSubmit = <T extends FormValuesType>(
  formValues: T,
  initialValues: T
) => {
  const body: any = {
    data: {
      ...formValues,
    },
  };

  Object.keys(formValues).forEach((key) => {
    if (formValues[key] !== initialValues[key]) {
      body.data[key] = formValues[key];
    }
  });

  return body;
};

export const apiCall = (data: APIPayload) => {
  let { contentType, method, endPoint, body, type, token } = data;
  let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  const header = {
    ...(contentType ? { "Content-Type": contentType } : {}),
    token,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      !type ? JSON.stringify(body) : body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
// Customizable Area End

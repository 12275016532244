import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Snackbar,
  FormControl
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import { TwoColBox, InputBox } from "../../../components/src/GenericInputs";
import MainLayout from "../../../components/src/MainLayout.web";

// Customizable Area End

import AchievementsController, { Props } from "./AchievementsController.web";

export default class Achievements extends AchievementsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }
  renderInputView = () => {
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Total Followers</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="totalFollowersId"
                  variant="outlined"
                  fullWidth
                  name="totalFollowers"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.totalFollowers}
                />
                <span style={webStyles.errorText}>
                  {this.state.totalFollowersError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Certified Teachers</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="certifiedTeachersId"
                  variant="outlined"
                  fullWidth
                  name="certifiedTeachers"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.certifiedTeachers}
                />
                <span style={webStyles.errorText}>
                  {this.state.certifiedTeachersError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Online Courses</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="onlineCoursesId"
                  variant="outlined"
                  fullWidth
                  name="onlineCourses"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.onlineCourses}
                />
                <span style={webStyles.errorText}>
                  {this.state.onlineCoursesError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Students Enrolled</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="studentsEnrolledId"
                  variant="outlined"
                  fullWidth
                  name="studentEnrolled"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.studentEnrolled}
                />
                <span style={webStyles.errorText}>
                  {this.state.studentEnrolledError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <ButtonContainer>
            <ResetButton data-test-id="cancelButtonId">Cancel</ResetButton>

            <CreateButton
              data-test-id="submitButtonId"
              onClick={() => this.handleSubmitAchievement()}
            >
              Update
            </CreateButton>
          </ButtonContainer>
        </Box>
      </div>
    );
  };

  notificationAlert = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.oncloseAlert}
        data-test-id="alertTestId"
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.isLoading}
        data-test-id="mainLayoutEvent"
        title={"Our Achievements"}
        isEdit
        isAdd
      >
        <main
          className="table_content"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderInputView()}
        </main>
        {this.notificationAlert()}
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px"
});

export const ResetButton = styled(Button)({
  border: "1px solid RGB(63,100,116)",
  textTransform: "inherit",
  marginRight: "30px",
  padding: "8px 50px",
  width: "195px",
  height: "52px",
  borderRadius: "0px",
  color: "#1F6575",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  "@media (max-width:830px)": {
    padding: "6px 30px",
    fontSize: "11px"
  }
});

export const CreateButton = styled(Button)({
  padding: "8px 50px",
  borderRadius: "0px",
  textTransform: "inherit",
  height: "52px",
  width: "195px",
  color: "#fff",
  fontFamily: "Inter",
  backgroundColor: "RGB(63,100,116)",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",

  "&:hover": {
    backgroundColor: "RGB(63,100,116)"
  },
  "@media (max-width:830px)": {
    padding: "6px 30px",
    fontSize: "11px"
  }
});

// Customizable Area End

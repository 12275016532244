import React from "react";

// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
const drawerWidth = 250;
const closeDrawer = 0;
const columnHeaders = [
  { label: "ID", width: 50 },
  { label: "Account", width: 70 },
  { label: "Total Price", width: 50 },
  { label: "Payment", width: 50 },
  { label: "Razorpay Order", width: 50 },
  { label: "Payment Status", width: 50 },
  { label: "Created At", width: 90 },
  { label: "Updated At", width: 60 },
  { label: "Discount", width: 90 },
];

import RazorpayTransactionsController, {
  Props,
  IRazorpayTransactions,
} from "./RazorpayTransactionsController.web";
// Customizable Area End

export default class RazorpayTransactions extends RazorpayTransactionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: IRazorpayTransactions) => {
    return (
      <>
        <tr>
          <td
            className="tr_desc"
            style={{
              textAlign: "left",
              borderRadius: "10px 0px 0px 10px",
            }}
          >
            {item.order_id}
          </td>
          <td style={{ textAlign: "left" }}>{item.full_name}</td>
          <td style={{ textAlign: "left" }}>{item.total_price}</td>
          <td style={{ textAlign: "left" }}>{item.transaction_id}</td>
          <td style={{ textAlign: "left" }}>{item.razorpay_order_id}</td>
          <td style={{ textAlign: "left" }}>{item.payment_status}</td>
          <td style={{ textAlign: "left" }}>{item.created_at}</td>
          <td style={{ textAlign: "left" }}>{item.updated_at}</td>
          <td style={{ textAlign: "left" }}>{item.coupon_code}</td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <div style={{ overflow: "auto" }}>
          <TableStyle>
            <thead>
              <tr>
                {columnHeaders.map((header, index) => {
                  return (
                    <th key={index.toString()}>
                      <Box
                        className="th_box"
                        style={{
                          justifyContent: "left",
                          width: header.width,
                          textAlign:
                            header.label !== "Action" ? "left" : "center",
                        }}
                      >
                        <Typography className="title_th">
                          {header.label}
                        </Typography>
                      </Box>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <span style={webStyles.dividerLine} />
            <TableBody>
              {this.state.transactionsList.map((item) =>
                this.renderTableBody(item)
              )}
            </TableBody>
          </TableStyle>
          {this.renderPagination()}
        </div>
      </Box>
    );
  };

  renderPagination() {
    return (
      <PaginationBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Pagination
          data-test-id="pageChangebutton"
          count={this.state.pageCount}
          shape="rounded"
          boundaryCount={1}
          page={this.state.currentPageCount}
          siblingCount={0}
          onChange={this.onPageChange}
        />
      </PaginationBox>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.isLoading}
        newUI
        isAdd
        title="Razorpay Transactions"
        data-test-id="mainLayoutEvent"
      >
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  hideDrawer: {
    width: `calc(100% - ${closeDrawer}px)`,
  },
  dividerLine: {
    height: "20px",
    display: "inline-block",
  },
  tableDivider: {
    height: "8px",
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  currentPage: {
    backgroundColor: "#176876",
    color: "white",
    borderRadius: "5px",
    padding: "0px 5px",
  },
  modalContent: {
    width: "90%",
    maxWidth: "700px",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  tableFooter: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    marginRight: "40px",
  },
  pagination: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pageWrapper: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableBodyMsg: {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh",
    width: "100%",
    marginTop: "50px",
    border: "1px solid red",
  },
};

export const PaginationBox = styled(Box)({
  "& .viewall_txt": {
    color: "#206575",
    fontSize: "14px",
    cursor: "pointer",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff",
  },
});
// Customizable Area End

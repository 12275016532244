import React from "react";
// Customizable Area Start
import {
    withStyles
} from "@material-ui/core";
import { googlePlay, building, logoLeft, logoRight, mail, tele, you, ins, face } from "./assets";
import AddIcon from '@material-ui/icons/Add';
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";



export class Footer extends CatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            //Merge Engine DefaultContainer
            <div style={{ backgroundColor: '#ECECEC' }}>
                <div style={{ maxWidth: '90%', margin: '0px auto', padding: '40px 0px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '50px 0px', width: '100%' }}>
                        <div style={{ display: 'flex', width: '42%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <img src={logoLeft} style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                                <img src={logoRight} style={{ width: '160px', height: '100px' }} />
                            </div>
                            <div><img src={googlePlay} /></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <img src={face} style={{ marginRight: '10px' }} />
                                <img src={ins} style={{ marginRight: '10px' }} />
                                <img src={you} />
                            </div>
                        </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <h4 className={classes.navigationHeadings}>Contact</h4>
                            <div>
                                <p className={classes.navItem} style={{ maxWidth: '300px', maxHeight: '150px' }}>
                                    <span style={{ marginRight: '10px' }}><img src={building} /></span>
                                    Lorem ipsum dolor
                                    sit amet, consectetur
                                    adipiscing elit, sed
                                    do eiusmod
                                </p>

                            </div>
                            <p className={classes.navItem}>
                                <span style={{ marginRight: '10px' }}><img src={tele} /></span>
                                +91 - 1234 - 6766546</p>
                            <p className={classes.navItem}>
                                <span style={{ marginRight: '10px' }}><img src={mail} /></span>
                                email@chseexpress.com</p>
                        </div>
                        <div>
                            <h4 className={classes.navigationHeadings}>Support</h4>
                            <p className={classes.navItem}>Frequently Asked Questions</p>
                            <p className={classes.navItem}>Help Center</p>
                            <p className={classes.navItem}>Refund Policy</p>
                            <p className={classes.navItem}>Customer Support</p>
                        </div>
                        <div>
                            <h4 className={classes.navigationHeadings}>Information</h4>
                            <p className={classes.navItem}>Courses</p>
                            <p className={classes.navItem}>Write to Us</p>
                            <p className={classes.navItem}>Careers</p>
                            <p className={classes.navItem}>Privacy</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <h4 className={classes.navigationHeadings}>Subscribe</h4>
                            <div style={{ position: 'relative' }}>
                                <input type="email" className={classes.subscribeInputs} placeholder="Enter email address" />
                                <button className={classes.subscribeButtons}><AddIcon style={{ fontSize:'8px', marginRight:'8px', fontWeight:'normal', color:'white'}}/>Subscribe Now</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start


export const styles: any = (theme: any) => ({
    footerSection: {
        flex: '1 0 21%', // This makes sure the width of each section is roughly 1/4 of the container
        margin: '0 2% 20px',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '8px'
    },
    subscribeInputs: {
        outline: 'none',
        border: 'none',
        width: '415px',
        height: '58px',
        flexShrink: '0',
        borderRadius: '16px',
        background: '#FFF',
        boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.09)',
        "& ::placeholder": {
            color: '#818181',
            fontSize: '15.633px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            letterSpacing: '-0.019px',
            paddingLeft: '5px'
        }
    },
    subscribeButtons: {
        width: '132px',
        height: '38px',
        flexShrink: '0',
        borderRadius: '7px',
        background: "linear-gradient(133deg, #F93A3A -26.6%, #A81FE1 118.85%)",
        position: 'absolute',
        top: '10px',
        right: '10px',
        border: 'none',        
    },
    navigationHeadings: {
        color: '#212121',
        fontSize: "25.633px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "61px", /* 237.974% */
        letterSpacing: "-0.031px"
    },
    navItem: {
        color: "#212121",
        fontSize: "22.633px",
        fontStyle: " normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "-0.028px"
    }

});



export default withStyles(styles)(Footer);
// Customizable Area End

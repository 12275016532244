Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BaselineReporting";
exports.labelBodyText = "BaselineReporting Body";
exports.line = "Line"
exports.bar = "Bar"
exports.pie = "Pie"
exports.region = "Region"
exports.label = "label"
exports.value = "value"
exports.select = "Select"
exports.today = "today"
exports.week = "week"
exports.month = "month"


exports.btnExampleTitle = "CLICK ME";
exports.baselineApi = "bx_block_baselinereporting/baseline_reportings/total_sos_count"

exports.getDailyReports = "bx_block_baselinereporting/reports";
exports.getUserReports = "account_block/generate_user_report";
exports.getCourseReports = "bx_block_profile/courses/generate_course_report";
exports.getPaymentReports = "bx_block_cfrazorpayintegration64/generate_payment_report";
exports.getOrderReports = "bx_block_cfrazorpayintegration64/generate_order_report";
exports.getTopCourseUserReports = "bx_block_profile/courses/top_course_user_metrics";
exports.getTrafficSourcesReports = "bx_block_cfrazorpayintegration64/traffic_sources_metrics_report";
exports.tokenExpiredMessage = "Token has Expired";
exports.sessionExpireMsg = "Session is expired. Please login again.";
// Customizable Area End
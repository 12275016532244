import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Chip,
  withStyles,
  Theme,
  createStyles,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";
import { Close as CloseIcon, Check as CheckIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { MdCurrencyRupee } from "react-icons/md";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  PiClipboardTextLight,
  PiGraduationCap,
  PiBookOpenText,
  PiUser,
  PiLockKeyFill
} from "react-icons/pi";
import { BsDot } from "react-icons/bs";
import { FaCirclePlay } from "react-icons/fa6";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import CourseDetailController, {
  CourseDetailsAttributes,
  Props,
  configJSON
} from "./CourseDetailController.web";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BiChalkboard } from "react-icons/bi";
import { IoLanguageSharp } from "react-icons/io5";
import { FiShare2 } from "react-icons/fi";
import { FaRegClock, FaStar } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import Footer from "../../../components/src/Footer.web";
import {
  BundleCoursesAttributes,
  CustomDialog,
  LaunchedCourses,
  LaunchedCoursesAttributes,
  getNewLaunchCoursesWeb,
  CardType,
  webStyle
} from "../../landingpage/src/LandingPage.web";
import {
  courseDetailBg,
  facebookIcon,
  telegramICon,
  whatsappIcon,
  instagramIcon,
  clockIcon,
  backArrowPage,
  forwardArrowPage
} from "./assets";
import HeaderWeb from "../../../components/src/Header.web";

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

const parserOptions: HTMLReactParserOptions = {
  replace: domNode => {
    if (domNode.type.toString() === "script") {
      return <></>;
    }
  }
};

const obj = [
  {
    label: "Instructor",
    icon: <PiUser fontSize={"inherit"} />,
    valueName: "instructor_name"
  },
  {
    label: "Chapters",
    icon: <PiBookOpenText fontSize={"inherit"} />,
    valueName: "total_content"
  },
  {
    label: "Lecture",
    icon: <BiChalkboard fontSize={"inherit"} />,
    valueName: "course_class_count"
  },
  {
    label: "Duration",
    icon: <FaRegClock fontSize={"inherit"} />,
    valueName: "all_course_class_duration"
  },
  {
    label: "Enrolled",
    icon: <PiGraduationCap fontSize={"inherit"} />,
    valueName: "student_count"
  },
  {
    label: "Languages",
    icon: <IoLanguageSharp fontSize={"inherit"} />,
    valueName: "language_name"
  }
];

// Customizable Area End

export default class CourseDetail extends CourseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  showSkeleton(amount = 4) {
    return (
      <>
        {Array(amount)
          .fill(null)
          .map(() => (
            <Skeleton variant="rect" className="card" height={378} />
          ))}
      </>
    );
  }

  stringToHTML(htmlString: string) {
    return (
      <div
        className="description-wrapper"
        style={{
          margin: "16px 0",
          padding: 0,
          WebkitLineClamp: 3,
          height: 60,
          display: "-webkit-box",
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitBoxOrient: "vertical"
        }}
      >
        {parse(htmlString, parserOptions)}
      </div>
    );
  }

  stringToBoolean(value: string): boolean {
    return value.toLowerCase() === "true";
  }

  handleCoursesPagination = <T, undefined>(
    array: T[],
    pageNumber: number,
    itemsPerPage: number
  ) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  };

  showCoursePrice(value: LaunchedCoursesAttributes | BundleCoursesAttributes) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          columnGap: "10px"
        }}
      >
        <div
          style={{
            display: "inline-flex",
            columnGap: "10px",
            alignItems: "center"
          }}
          className="course-price"
        >
          <MdCurrencyRupee color="inherit" fontSize="inherit" />
          <span>{Math.round(value.first_year_price)}/-</span>
        </div>
        {value.second_year_price && (
          <div
            style={{
              display: "inline-flex",
              columnGap: "10px",
              alignItems: "center"
            }}
            className="course-price-discount"
          >
            <MdCurrencyRupee color="inherit" fontSize="inherit" />
            <span>{Math.round(value.second_year_price)}/-</span>
          </div>
        )}
      </div>
    );
  }

  renderScreen = () => {
    const value = this.state.courseDetail as CourseDetailsAttributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart;
    return (
      <>
        <div style={{ ...coursesStyles.column, ...coursesStyles.leftColumn }}>
          <div style={coursesStyles.header}>
            <h1
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {value.course_name}
            </h1>
            {this.stringToHTML(value.full_description)}
            <div style={coursesStyles.smallDescription}>
              <Chip
                label={
                  value.course_child_category &&
                  value.course_child_category.name
                }
                style={{ background: "#ff5c01", color: "white" }}
              />
              <div>
                Created by{" "}
                <strong style={{ color: "#fcb910" }}>CHSE Express</strong>
              </div>
              <div>
                <FaStar color="#FFD735" />{" "}
                <span>{value.average_rating.Average_rating}</span>
              </div>
              <div
                style={{ display: "inline-flex", gap: 5, alignItems: "center" }}
              >
                <img src={clockIcon} height={18} width={18} />{" "}
                <span>
                  {this.convertToHoursAndMinutes(
                    value.all_course_class_duration,
                    true
                  )}{" "}
                  hours
                </span>
              </div>
            </div>
          </div>
          <StyledTabs
            value={this.state.currentTab}
            onChange={(e, value) =>
              this.setState({
                currentTab: value
              })
            }
            data-test-id="tabsTestId"
            aria-label="styled tabs example"
          >
            <StyledTab label="OVERVIEW" />
            <StyledTab label="COURSE" />
            <StyledTab label="INSTRUCTOR" />
            <StyledTab label="REVIEW" />
          </StyledTabs>
          {this.renderTab(value)}
        </div>
        <div
          style={{
            ...coursesStyles.column,
            ...coursesStyles.rightColumn
          }}
        >
          <div style={coursesStyles.rightColumnCard}>
            <div style={{ position: "relative" }}>
              <img
                src={value.course_thumbnail}
                style={{
                  borderRadius: "10.61px",
                  height: "142px",
                  width: "100%"
                }}
                alt="course thumbnail"
                height={"142px"}
                width={"auto"}
              />
              <FaCirclePlay
                style={{
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%",
                  fontSize: 32,
                  color: "white"
                }}
                width={39}
                height={39}
              />
            </div>
            <div
              style={{ display: "inline-flex", gap: 10, alignItems: "center" }}
            >
              <div style={{ width: "50%", fontSize: "1.75rem" }}>
                <MdCurrencyRupee fontSize={"inherit"} />{" "}
                <strong style={{ color: "#00be10" }}>
                  {Math.round(value.first_year_price)}/-
                </strong>
              </div>
              {value.second_year_price && (
                <div
                  style={{
                    width: "50%",
                    fontSize: "1.5rem",
                    color: "#BDBCBC"
                  }}
                >
                  <div
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationColor: "grey"
                    }}
                  >
                    <MdCurrencyRupee fontSize={"inherit"} />{" "}
                    <span>{Math.round(value.second_year_price)}/-</span>
                  </div>
                </div>
              )}
            </div>
            <div style={{ ...coursesStyles.column, gap: "10px" }}>
              <Button
                variant="contained"
                style={coursesStyles.containedButton}
                fullWidth
                data-test-id="buyNowTestId"
                onClick={() =>
                  this.handleOpenCourseValidityPopup(
                    isPurchasedOrCart,
                    {
                      attributes: this.state.courseDetail,
                      type: "course",
                      id: +this.state.courseId
                    },
                    "trendingCourses",
                    +this.state.courseId
                  )
                }
              >
                {value.is_purchased ? "PURCHASED" : "BUY NOW"}
              </Button>
              <Button
                variant="outlined"
                data-test-id="addToCartTestID"
                style={coursesStyles.outlinedButton}
                fullWidth
                onClick={() =>
                  this.handleOpenCourseValidityPopup(
                    isPurchasedOrCart,
                    {
                      attributes: this.state.courseDetail,
                      type: "course",
                      id: +this.state.courseId
                    },
                    "trendingCourses",
                    +this.state.courseId
                  )
                }
              >
                {value.is_cart || value.is_purchased
                  ? "ADDED"
                  : "+ ADD TO CART"}
              </Button>
            </div>
            {this.courseDetailList(value)}
          </div>
          {this.state.relatedCourses && this.state.relatedCourses.length ? (
            <div style={coursesStyles.rightColumnCard}>
              <h3 style={{ margin: 0 }}>Related Courses</h3>
              {this.state.relatedCourses.map(item => (
                <Link
                  to={`/course/${item.id}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    boxShadow: "0px 2px 6px 0px #00000046",
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    borderRadius: 10.61,
                    padding: 12,
                    boxSizing: "border-box"
                  }}
                >
                  <img
                    src={item.attributes.course_thumbnail}
                    style={{
                      borderRadius: "10.61px",
                      height: "142px",
                      width: "100%"
                    }}
                    alt="course thumbnail"
                    height={"142px"}
                    width={"auto"}
                  />
                  <h5
                    style={{
                      margin: 0,
                      fontSize: 20,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {item.attributes.course_name}
                  </h5>
                  <span style={{ fontSize: 16 }}>
                    {item.attributes.course_class_count} chapters <BsDot />{" "}
                    {item.attributes.total_content} lectures
                  </span>
                </Link>
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  renderScreenLoading = () => {
    return (
      <>
        <div style={{ ...coursesStyles.column, ...coursesStyles.leftColumn }}>
          <div style={coursesStyles.header}>
            <h1
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              <Skeleton variant="text" />
            </h1>
            <Skeleton variant="rect" width={"100%"} height={100} />
            <Skeleton variant="text" />
          </div>
          <Skeleton variant="rect" width={"100%"} height={350} />
        </div>
        <div
          style={{
            ...coursesStyles.column,
            ...coursesStyles.rightColumn
          }}
        >
          <div style={coursesStyles.rightColumnCard}>
            <Skeleton variant="rect" width={"100%"} height={400} />
          </div>
        </div>
      </>
    );
  };

  renderTab(value: CourseDetailsAttributes) {
    switch (this.state.currentTab) {
      case 0: // Overview
        return this.renderOverview(value);
      case 1: // Course
        return this.renderCourse(value);
      case 2: // Instructor
        break;
      case 3: // Review
        break;
    }
  }

  renderCourse(value: CourseDetailsAttributes) {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h2 style={{ ...coursesStyles.titleText, marginBottom: 0 }}>
          Course Contents
        </h2>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 12
          }}
        >
          <span style={{ fontSize: 18 }}>
            {value.total_content} Chapters <BsDot /> {value.course_class_count}{" "}
            Lectures
            <BsDot />{" "}
            {this.convertToHoursAndMinutes(
              value.all_course_class_duration
            )}{" "}
            Total Long
          </span>
          <Button
            endIcon={<ArrowForwardIcon htmlColor="#1721CD" />}
            variant="outlined"
            data-test-id="expandAllAccordions"
            style={coursesStyles.courseViewAll}
            onClick={() =>
              this.handleExpandAllAccordions(
                value.specfifc_content_duration.map(e => e.id)
              )
            }
          >
            <span style={coursesStyles.viewAllText}>EXPAND ALL</span>
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          {value.specfifc_content_duration.map(v => {
            const isExpanded = !!this.state.openedAccordions.find(
              x => x === v.id
            );
            return (
              <Accordion
                expanded={isExpanded}
                data-test-id="accordionTestId"
                onChange={() => this.handleAccordionChange(v.id)}
              >
                <AccordionSummary
                  expandIcon={
                    isExpanded ? (
                      <RemoveIcon htmlColor="white" />
                    ) : (
                      <AddIcon htmlColor="white" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  key={v.id}
                >
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold"
                    }}
                  >
                    {v.name}
                  </span>
                  <span style={{ marginLeft: "auto", marginRight: 30 }}>
                    {v.class_count} Classes
                  </span>
                  <span>{this.convertToTimeFormat(v.duration_count)} Mins</span>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "flex", flexDirection: "column", gap: 12 }}
                >
                  <div
                    style={{ width: "100%", display: "inline-flex", gap: 10 }}
                  >
                    <img
                      src={v.course_chapter_thumbnail}
                      style={{ borderRadius: 10 }}
                      width={"30%"}
                      height={"auto"}
                    />
                    <p style={{ margin: 0 }}>{v.details}</p>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    {v.classes_data.map((val, i) => (
                      <span
                        style={{
                          padding: "10px 5px",
                          fontSize: 20,
                          borderBottom:
                            v.classes_data.length === i
                              ? ""
                              : "1px solid black",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            gap: 10,
                            alignItems: "center"
                          }}
                        >
                          <PiLockKeyFill fontSize={"inherit"} color="inherit" />
                          <span>{val.title}</span>
                        </span>
                        <span>
                          {this.convertToTimeFormat(parseFloat(val.duration))}{" "}
                          MINS
                        </span>
                      </span>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    );
  }

  renderOverview(value: CourseDetailsAttributes) {
    return (
      <>
        <h2 style={coursesStyles.titleText}>Course Overview</h2>
        <div
          style={{
            ...coursesStyles.column,
            ...coursesStyles.rightColumnCard
          }}
        >
          <div>
            <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                boxSizing: "border-box",
                padding: 15,
                borderRadius: 12,
                background: "white",
                boxShadow: "0px 2px 6px 0px #00000046"
              }}
            >
              <img
                src={value.course_thumbnail}
                style={{
                  borderRadius: "10.61px",
                  height: "100px",
                  width: "30%"
                }}
                alt="course thumbnail"
                height={"142px"}
                width={"auto"}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "45%"
                }}
              >
                <div style={coursesStyles.aboutCourse}>
                  <div
                    className="course-cat-name"
                    style={{
                      borderColor: value.course_child_category.color,
                      paddingBottom: 0,
                      maxWidth: "unset"
                    }}
                  >
                    <CircleIcon
                      fontSize="inherit"
                      htmlColor={value.course_child_category.color}
                    />
                    <span className="course-cat-name-text">
                      <strong>
                        {value.course_child_category &&
                          value.course_child_category.name}
                      </strong>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      columnGap: "10.21px",
                      alignItems: "center",
                      width: "45%"
                    }}
                  >
                    <FaStar fontSize={"inherit"} color="#FFD735" />
                    <span
                      className="student-count-rating"
                      style={{
                        display: "inline-flex",
                        gap: 5,
                        alignItems: "center"
                      }}
                    >
                      <strong style={{ color: "black" }}>
                        {value.average_rating.Average_rating}
                      </strong>{" "}
                      | {value.student_count} Students
                    </span>
                  </div>
                </div>
                <div>
                  <strong>{value.course_name}</strong>
                </div>
              </div>
              <div style={{ width: "25%" }}>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    fontSize: "1.75rem",
                    marginTop: 30
                  }}
                >
                  <MdCurrencyRupee fontSize={"inherit"} />{" "}
                  <strong style={{ color: "#00be10" }}>
                    {Math.round(value.first_year_price)}/-
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <h3 style={{ color: "#858585" }}>Instructors</h3>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr"
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    height: 60,
                    gap: 20
                  }}
                >
                  <div>
                    <img
                      src={value.instructor_details.profile_icon}
                      height={40}
                      width={40}
                      style={{ width: 40, height: 40 }}
                    />
                  </div>
                  <div>
                    <h3 style={{ margin: 0 }}>{value.instructor_name}</h3>
                    <h5 style={{ margin: 0, fontWeight: "normal" }}>
                      {value.instructor_details.leacture_in}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderView = () => {
    const trendingCoursesSort = this.handleCoursesPagination<
      LaunchedCourses,
      undefined
    >(this.state.trendingCourses, this.state.pageNo, 4);
    return (
      <div style={coursesStyles.mainLayout}>
        <Box sx={coursesStyles.screenLayout}>
          {!this.state.isLoading &&
            this.state.courseDetail &&
            this.renderScreen()}
          {this.state.isLoading && this.renderScreenLoading()}
        </Box>
        <div style={{ margin: "35px 0" }}>
          <div style={{ ...webStyle.launcedCourseHead, marginBottom: "33px" }}>
            <div style={webStyle.courseTitle}>Trending Courses</div>
            <Button
              endIcon={<ArrowForwardIcon htmlColor="#1721CD" />}
              variant="outlined"
              data-test-id="goTrendingCourseList"
              style={webStyle.courseViewAll}
              onClick={() => this.handleViewCourseList()}
            >
              <span style={webStyle.viewAllText}>VIEW ALL</span>
            </Button>
          </div>
          <div style={webStyle.launchedCoursesMain}>
            <IconButton
              data-test-id="backTrendingCourse"
              onClick={() => this.handleTrendingCoursePage("prev")}
              style={{
                display: this.state.pageNo === 1 ? "none" : ""
              }}
              className="change-page-button"
              disabled={this.disabledBackButtons()}
            >
              <img src={backArrowPage} width={48.56} height={48.56} />
            </IconButton>
            <div className="launchTrendCardMain">
              {trendingCoursesSort && trendingCoursesSort.length > 0
                ? trendingCoursesSort.map(
                    (item: LaunchedCourses, index: number) => (
                      <React.Fragment key={item.id}>
                        {getNewLaunchCoursesWeb(
                          ({
                            ...item,
                            isAdd: true
                          } as unknown) as LaunchedCourses,
                          index,
                          CardType.trendingCourses,
                          this.stringToBoolean,
                          this.showCoursePrice,
                          this.handleWishlistStatus,
                          this.handleOpenCourseValidityPopup
                        )}
                      </React.Fragment>
                    )
                  )
                : this.showSkeleton()}
            </div>
            <IconButton
              data-test-id="nextTrendingCourse"
              onClick={() => this.handleTrendingCoursePage("next")}
              className="change-page-button"
              style={{
                ...webStyle.forwardIcon,
                display:
                  this.state.pageNo + 1 === this.state.lastPage ? "none" : ""
              }}
              disabled={this.disabledNextButtons()}
            >
              <img src={forwardArrowPage} width={48.56} height={48.56} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  renderItemValidityDialog = () => {
    const {
      courseValidityDetails,
      selectedValidity,
      isCourseValidityOpen
    } = this.state;
    const courseDescription =
      courseValidityDetails.attributes.short_description;
    const course_name = courseValidityDetails.attributes.course_name;
    return (
      <CustomDialog
        className={"dialogContainer"}
        open={isCourseValidityOpen}
        onClose={this.onClose}
        style={{ padding: 24 }}
      >
        <DialogTitle disableTypography className={"dialogTitleRoot"}>
          <Typography
            data-test-id="courseValidityTitle"
            className={"dialogTitle"}
          >
            {configJSON.courseValidityTitle}
          </Typography>
          <IconButton className={"closeButton"} onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"dialogContent"}>
          <Typography variant="subtitle1" className={"subTitleCourseValidity"}>
            {course_name}: {courseDescription}
          </Typography>
          <div className={"buttonGroup"}>
            <Button
              data-test-id="1_year"
              variant="outlined"
              color="primary"
              className={`${"button"} ${
                selectedValidity === 1 ? "selected" : ""
              }`}
              onClick={() => this.handleSelectValidity(1)}
            >
              <span className={"buttonLabel"}>
                {selectedValidity === 1 && (
                  <CheckIcon className={"checkIcon"} />
                )}
                1 Year Validity
              </span>
            </Button>
            {courseValidityDetails.attributes.second_year_price ? (
              <Button
                data-test-id="2_year"
                variant="outlined"
                color="primary"
                className={`${"button"} ${
                  selectedValidity === 2 ? "selected" : ""
                }`}
                onClick={() => this.handleSelectValidity(2)}
              >
                <span className={"buttonLabel"}>
                  2 Year Validity
                  {selectedValidity === 2 && (
                    <CheckIcon className={"checkIcon"} />
                  )}
                </span>
              </Button>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", padding: "16px 24px" }}
        >
          <Button className={"cancelButton"} onClick={this.onClose}>
            Cancel
          </Button>
          <Button
            data-test-id="applyButton"
            variant="contained"
            className={"applyButton"}
            onClick={() =>
              this.handleChartStatus(
                courseValidityDetails.hasOwnProperty("isAdd"),
                courseValidityDetails.id,
                courseValidityDetails.type,
                selectedValidity
              )
            }
          >
            Apply
          </Button>
        </DialogActions>
      </CustomDialog>
    );
  };

  courseDetailList(value: CourseDetailsAttributes) {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {obj.map(item => (
            <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #BEBEBE",
                padding: "5px 0"
              }}
            >
              <div style={{ display: "inline-flex", width: "50%", gap: "6px" }}>
                {item.icon}
                <strong>{item.label}</strong>
              </div>
              <div style={{ width: "50%" }}>
                {item.valueName === "all_course_class_duration"
                  ? this.convertToHoursAndMinutes(value[
                      (item.valueName as unknown) as keyof CourseDetailsAttributes
                    ] as number)
                  : value[
                      (item.valueName as unknown) as keyof CourseDetailsAttributes
                    ]}
              </div>
            </div>
          ))}
          <div
            style={{
              display: "inline-flex",
              gap: 10,
              width: "100%",
              alignItems: "center",
              borderBottom: "1px solid #BEBEBE",
              padding: "5px 0"
            }}
          >
            <div style={{ display: "inline-flex", width: "50%", gap: "6px" }}>
              <FiShare2 fontSize={"inherit"} />
              <strong>Share on</strong>
            </div>
            <div style={{ width: "50%", display: "inline-flex", gap: 5 }}>
              <img
                src={facebookIcon}
                width={20}
                height={20}
                alt="facebook logo"
                data-test-id="facebookNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://www.facebook.com/")
                }
              />
              <img
                src={instagramIcon}
                width={20}
                height={20}
                alt="instagram logo"
                data-test-id="instagramNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://www.instagram.com/")
                }
              />
              <img
                src={whatsappIcon}
                width={20}
                height={20}
                alt="whatsapp logo"
                data-test-id="whatsappNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://web.whatsapp.com/")
                }
              />
              <img
                src={telegramICon}
                width={20}
                height={20}
                alt="telegram logo"
                data-test-id="telegramNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://web.telegram.org/")
                }
              />
            </div>
          </div>
        </div>
        <Paper
          style={{
            marginTop: "10px",
            padding: "4px 5px",
            display: "flex",
            alignItems: "center",
            position: "relative",
            boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.14)",
            borderRadius: 10,
            background: "#eee"
          }}
        >
          <InputBase
            style={{
              flex: 1,
              marginLeft: "10px",
              marginRight: "90px"
            }}
            readOnly
            value={window.location.href}
          />
          <Button
            onClick={() => this.copyTextToClipboard(window.location.href)}
            data-test-id="copyToClipboardTestId"
            style={{
              borderRadius: 7,
              background: "linear-gradient(133.48deg, #3cc9e1, #03859b)",
              height: 32,
              position: "absolute",
              right: 5,
              fontSize: 10,
              color: "white",
              display: "inline-flex"
            }}
            startIcon={<PiClipboardTextLight fontSize="inherit" />}
            variant="contained"
          >
            COPY
          </Button>
        </Paper>
      </>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          dataTestId="alertTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={undefined} />
        {this.state.isCourseValidityOpen && this.renderItemValidityDialog()}
        <main
          style={{
            overflowX: "scroll",
            maxWidth: "1920px",
            width: "100%"
          }}
        >
          <div
            style={{
              background: `linear-gradient(180deg, rgba(60,116,129,1) 207px, rgba(255,255,255,1) 207px), url(${courseDetailBg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "darken",
              padding: "25px 12px"
            }}
          >
            {this.renderView()}
            <Footer id="" navigation={this.props.navigation} />
          </div>
        </main>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "20px !important",
    boxShadow: "none",
    margin: "16px 0"
  }
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    flexDirection: "row-reverse",
    color: "white",
    background: "linear-gradient(310.05deg, #439EAD 7.85%, #206575 99.2%)",
    gap: 20,
    borderRadius: 20,
    transition: "0.3s ease",
    "&:hover": {
      filter: "brightness(1.2)"
    },
    "&$expanded": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  expanded: {}
})(MuiAccordionSummary);

enum CourseStylesProperties {
  smallDescription = "smallDescription",
  screenLayout = "screenLayout",
  mainLayout = "mainLayout",
  leftColumn = "leftColumn",
  rightColumn = "rightColumn",
  rightColumnCard = "rightColumnCard",
  column = "column",
  header = "header",
  outlinedButton = "outlinedButton",
  containedButton = "containedButton",
  aboutCourse = "aboutCourse",
  courseViewAll = "courseViewAll",
  viewAllText = "viewAllText",
  titleText = "titleText"
}
const coursesStyles: Record<CourseStylesProperties, React.CSSProperties> = {
  titleText: {
    color: "#858585",
    fontWeight: 700,
    fontSize: 24
  },
  viewAllText: {
    color: "#1220C1",
    fontFamily: "Inter",
    fontSize: "15.868px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px"
  },
  courseViewAll: {
    display: "flex",
    columnGap: "10px",
    borderRadius: "31.826px",
    border: "1.8px solid #1721CD",
    background: "#FFF"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    justifyContent: "space-between",
    width: "100%"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    color: "white"
  },
  screenLayout: {
    display: "inline-flex",
    gap: "20px",
    width: "100%"
  },
  mainLayout: {
    width: "100%",
    margin: "0 auto"
  },
  column: { display: "flex", flexDirection: "column" },
  leftColumn: {
    width: "70%",
    gap: "19px"
  },
  rightColumn: {
    width: "30%",
    gap: "30px"
  },
  rightColumnCard: {
    boxSizing: "border-box",
    padding: 15,
    borderRadius: 12,
    background: "white",
    boxShadow: "0px 1px 8px 0px #00000025",
    width: "100%",
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  },
  smallDescription: {
    display: "inline-flex",
    gap: 30,
    alignItems: "center"
  },
  outlinedButton: {
    background: "white",
    color: "#FD7101",
    borderRadius: "32px",
    border: "2px solid #FD7101"
  },
  containedButton: {
    background: "linear-gradient(133.48deg, #FF5C01 -26.6%, #FFA302 118.85%)",
    color: "white",
    borderRadius: "32px"
  }
};

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  fixed: {
    height: "58px"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      borderRadius: "12px",
      width: "100%",
      backgroundColor: "#ffd735"
    }
  }
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      background: "#e3e3e3",
      color: "#212121",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      borderRadius: "0px 0px 12px 12px",
      "&.Mui-selected": {
        background: "linear-gradient(180deg, #ffed65, #fcb910)"
      },
      "&:focus": {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

// Customizable Area End

import React from "react";
// Customizable Area Start
import {
    withStyles
} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { related1, related2 } from "./assets";



let courseData = [
    {
        id: 1,
        image: related1,
        title: 'Solid State Conduct',
        chapter: '68 Chapter . 34 Lecture'
    },
    {
        id: 2,
        image: related2,
        title: 'Solid State Conduct',
        chapter: '68 Chapter . 34 Lecture ',
    },
];

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";


export class RelatedCourse extends CatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const { classes } = this.props;


        return (
            //Merge Engine DefaultContainer
            <div style={{ background: 'white', borderRadius: '23px', paddingTop: '10px', paddingBottom:'10px', marginLeft: '10px', boxShadow: "0px 1px 8px 5px rgba(0, 0, 0, 0.07)"}
}>
                <Typography variant='h6' style={{ fontSize: '26px', margin: '10px 0px', paddingLeft: '18px' }} className={classes.typograph}> Related Courses</Typography>
                <div className={classes.Mdiv}>
                    {courseData.map((course) => (
                        <div className={classes.Cardstyle} key={course.id}>
                            <CardMedia
                                component="img"
                                alt="img"
                                height="180px"
                                className={classes.ImageStyle}
                                image={course.image}
                            />
                            <CardContent>
                                <Typography variant="h6" component="h2" className={classes.subTitle}>
                                    {course.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p" className={classes.bodyText}>
                                    {course.chapter}
                                </Typography>
                            </CardContent>
                        </div>
                    ))}
                </div>
            </div >
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
    Mdiv: {
        marginTop: '10px',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        alignItems: 'center',
    },
    Cardstyle: {
        width: '90%',
        margin: '8px',
        padding: '5px',
        borderRadius: '10px',
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
    },
    ImageStyle: {
        borderRadius: '10px',
    },
    typograph: {
        color: '#212121',
        fontFamily: 'ProximaNova',
        fontSize: '27.633px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '-0.034px'
    },
    subTitle: {
        color: '#212121',
        fontFamily: 'ProximaNova',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '-0.029px'
    },
    bodyText: {
        color: '#212121',
        fontFamily: 'ProximaNova',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '-0.022px'
    }
});


export default withStyles(styles)(RelatedCourse);
// Customizable Area End

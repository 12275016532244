import React, { Component } from "react";
import RichTextEditor, { EditorValue } from "react-rte";

interface RteTestProps {
  markup: string;
  testId:string;
  onChange?: (htmlString: string) => void;
}


interface RteTestState {
  value: EditorValue;
  externalUpdate: boolean;
}

export default class RteEditor extends Component<RteTestProps, RteTestState> {
  static prop(arg0: string): any {
      throw new Error("Method not implemented.");
  }
  value: EditorValue;

  constructor(props: RteTestProps) {
    super(props);
    this.value = RichTextEditor.createValueFromString(this.props.markup, "html");
    this.state = {
      value: RichTextEditor.createValueFromString(this.props.markup, "html"),
      externalUpdate: false,
    };
  }

  componentDidUpdate(prevProps: RteTestProps) {
    if (prevProps.markup !== this.props.markup && !this.state.externalUpdate) {
      this.setState({
        value: RichTextEditor.createValueFromString(this.props.markup, "html"),
        externalUpdate: true,
      });
    }
  }
  onChange = (value: EditorValue) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  };

  render() {
    return <RichTextEditor value={this.state.value} data-test-id={this.props.testId} onChange={this.onChange} />;
  }
}

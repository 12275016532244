import React from "react";

// Customizable Area Start
import { Box, FormControl, Button } from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
import Loader from "../../../components/src/Loader.web";
const drawerWidth = 250;
const closeDrawer = 0;
// Customizable Area End

import VoucherController, { Props } from "./VouchercodegeneratorController";
import MainLayout from "../../../components/src/MainLayout.web";
import { webStyles } from "./AddCouponcodegenerator.web";
import { DuplicatedModal } from "./Vouchercodegenerator.web";
import {
  DateInput,
  InputBox,
  OneColBox,
  StyledSelect,
  TwoColBox
} from "../../../components/src/GenericInputs";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

export default class AddVoucher extends VoucherController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddVoucherForm() {
    const { voucherFormData } = this.state;
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Voucher Name</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="voucherNameTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="voucher_name"
                  fullWidth
                  value={voucherFormData.voucher_name}
                  onChange={e => {
                    this.handleCourseInputChange(e);
                  }}
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Voucher Code</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="voucherCodeTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="voucher_code"
                  fullWidth
                  value={voucherFormData.voucher_code}
                  onChange={e => {
                    this.handleCourseInputChange(e);
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Discount Type</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <StyledSelect
                  fullWidth={true}
                  onChange={e => {
                    this.handleCourseInputChange(e);
                  }}
                  disableUnderline
                  name="discount_type"
                  value={voucherFormData.discount_type}
                  data-test-id="discountTypeTestId"
                  IconComponent={IoIosArrowDown}
                >
                  {this.state.discountTypeList &&
                    this.state.discountTypeList.length > 0 &&
                    this.state.discountTypeList.map((item: any) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                </StyledSelect>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">
                Discount{" "}
                {this.state.voucherFormData.discount_type === "amount"
                  ? "(₹)"
                  : "(%)"}
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="discountTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="discount"
                  fullWidth
                  value={voucherFormData.discount}
                  onChange={e => {
                    this.handleCourseInputChange(e, true);
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Creation Date</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <DateInput
                  id="datetime-local"
                  name="creation_date"
                  value={this.state.voucherFormData.creation_date}
                  data-test-id="creationDateTestId"
                  onChange={e => {
                    this.handleCourseInputChange(e);
                  }}
                  // DISABLED UNTIL CONFIRMATION
                  disabled
                  inputProps={{
                    inputProps: {
                      max:
                        this.state.voucherFormData.expiry_date &&
                        new Date(this.state.voucherFormData.expiry_date)
                          .toJSON()
                          .slice(0, 10)
                    }
                  }}
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Expiry Date</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <DateInput
                  id="datetime-local"
                  name="expiry_date"
                  value={this.state.voucherFormData.expiry_date}
                  data-test-id="expiryDateTestId"
                  onChange={e => {
                    this.handleCourseInputChange(e);
                  }}
                  inputProps={{
                    inputProps: {
                      min: this.state.voucherFormData.creation_date
                        ? new Date(this.state.voucherFormData.creation_date)
                            .toJSON()
                            .slice(0, 10)
                        : new Date().toJSON().slice(0, 10)
                    }
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Applicable Purchase (₹)</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="minimunPurchaseTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="minimum_purchase"
                  fullWidth
                  value={voucherFormData.minimum_purchase}
                  onChange={e => {
                    this.handleCourseInputChange(e, true);
                  }}
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Voucher code Usage Limit</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="purchaseLimitTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="no_of_time_purchased_voucher_code"
                  fullWidth
                  value={voucherFormData.no_of_time_purchased_voucher_code}
                  onChange={e => {
                    this.handleCourseInputChange(e, true, true);
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              columnGap: "10px",
              marginBottom: "20px"
            }}
          >
            {this.state.isUpdate ? (
              <>
                <Button
                  style={webStyles.resetBtn}
                  variant="outlined"
                  data-test-id="cancelButtonTestId"
                  onClick={() => this.handleGoBack()}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="updateButtonTestId"
                  style={webStyles.createBtn}
                  disabled={this.state.isLoading}
                  onClick={() => this.handleEditVoucher()}
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={webStyles.resetBtn}
                  data-test-id="resetButtonTestId"
                  variant="outlined"
                  onClick={() => this.handleResetData()}
                >
                  Reset
                </Button>
                <Button
                  data-test-id="createButtonTestId"
                  style={webStyles.createBtn}
                  disabled={this.state.isLoading}
                  onClick={() => this.handleAddVoucher()}
                >
                  Create
                </Button>
              </>
            )}
          </div>
        </Box>
      </div>
    );
  }

  closemodal() {
    this.oncloseAlert();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <MainLayout
          isAdd={true}
          backData={() => this.handleGoBack()}
          title={"Add Voucher Code"}
        >
          <Loader loading={this.state.isLoading} />
          <NotificationAlertMessage
            dataTestId="onclosealert"
            alertType={this.state.alertType}
            msg={this.state.alertMsg}
            onClose={this.closemodal}
            isOpen={this.state.isAlert}
          />
          <DuplicatedModal
            handleCloseModal={() =>
              this.setState({ isModalOpen: false, alertMsg: "" })
            }
            isModalOpen={this.state.isModalOpen}
            message={this.state.alertMsg}
          />
          <main className="table_content">{this.renderAddVoucherForm()}</main>
        </MainLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStylesLocal = {};
// Customizable Area End

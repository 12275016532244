import React from "react";
// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import { IoIosArrowUp } from "react-icons/io";
import FlatDiscountControllerWeb, {
  Attributes,
  Props as PropsWeb,
  configJSON
} from "./FlatDiscountController.web";
import {
  DeleteModal,
  ModalView,
  CancelButton,
  ModalButton,
  DeleteButton,
  DeleteText,
  PaginationBox,
  webStyles
} from "./Vouchercodegenerator.web";
import {
  CheckBtn,
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";

const drawerWidth = 250;
const closeDrawer = 0;

// Customizable Area End

export default class FlatDiscount extends FlatDiscountControllerWeb {
  constructor(props: PropsWeb) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.onSort = this.onSort.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  onSort(field: string) {
    this.handleSort(field);
  }

  tableBody = () => {
    return (
      <>
        <thead>
          <tr>
            <th>
              <Box style={webStyles.checkboxMain}>
                <input
                  type="checkbox"
                  test-id="headerCheckbox"
                  checked={this.state.isHeaderChecked}
                  onChange={this.handleHeaderCheckboxChange}
                  style={webStyles.input}
                />
              </Box>
            </th>
            {[
              "S.No",
              "Flat Discount",
              "Discount Code",
              "Discount Type",
              "Discount",
              "Applicable purchase",
              "Expiry Date",
              "Status",
              "Action"
            ].map((header, index) => {
              const hasSorting = [
                "S.No",
                "Flat Discount",
                "Expiry Date",
                "Status"
              ].includes(header);
              const isActive = this.state.currentSortState.field === header;
              const isAscending =
                isActive && this.state.currentSortState.order === "asc";
              return (
                <th key={index.toString()}>
                  <Box className="th_box" style={{ justifyContent: "center" }}>
                    <Typography className="title_th">{header}</Typography>
                    {hasSorting && (
                      <IoIosArrowUp
                        className={`filter_icon ${isAscending ? "active" : ""}`}
                        data-test-id={`sorter_${header}`}
                        style={{
                          transform: isAscending ? "none" : "rotate(180deg)"
                        }}
                        onClick={() => this.onSort(header)}
                      />
                    )}
                  </Box>
                </th>
              );
            })}
          </tr>
        </thead>
        <span style={webStyles.lineDivide} />
        <TableBody>
          {this.state.discountsList.map((item, index: any) =>
            this.renderTableBody(item, index)
          )}
        </TableBody>
      </>
    );
  };

  renderCouponsTable = () => {
    return (
      <Box>
        <TableStyle>{this.tableBody()}</TableStyle>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.totalPages}
            shape="rounded"
            boundaryCount={1}
            page={this.state.pageNo}
            siblingCount={0}
            onChange={this.handlePageChange}
          />
        </PaginationBox>
      </Box>
    );
  };

  renderTableBody = (
    item: { attributes: Attributes; id: string },
    index: any
  ) => {
    const expiryDate = moment(item.attributes.expiry_date).format(
      "DD, MMM YYYY"
    );
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                data-test-id="rowCheckbox"
                type="checkbox"
                checked={this.state.isChecked[index]}
                onChange={() => this.handleRowCheckboxChange(index)}
                style={webStyles.input}
              />
            </Box>
          </td>
          <td>{item.id}</td>
          <td>{item.attributes.discount_name}</td>
          <td>{item.attributes.discount_code}</td>
          <td style={{ textTransform: "capitalize" }}>
            {item.attributes.discount_type}
          </td>
          <td>
            {item.attributes.discount_type === "amount" && "₹"}
            {item.attributes.discount}
            {item.attributes.discount_type !== "amount" && "%"}
          </td>
          <td>{item.attributes.applicable_purchase}</td>
          <td>{expiryDate}</td>
          <td>
            <CheckBtn className="switch">
              <input
                type="checkbox"
                data-test-id="toggleStatus"
                checked={item.attributes.status}
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
              />
              <span className="slider round" />
            </CheckBtn>
          </td>
          <td style={{ borderRadius: "0px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleEditPropsDiscount(item.id)}
              />
              <FaRegTrashAlt
                data-test-id={"deleteIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={17}
                onClick={() => this.handleOpenModal(item.id)}
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivide} />
      </>
    );
  };

  renderDeleteCouponModal = () => {
    return (
      <>
        <DeleteModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="deleteModalTestId"
          open={this.state.openDeleteModal}
          onClose={this.handleCloseDeleteModal}
        >
          <ModalView>
            <DeleteText component={"p"}>
              {configJSON.confirmDeleteMessage}
            </DeleteText>
            <ModalButton>
              <DeleteButton
                data-test-id="deleteBtnTestId"
                onClick={() => this.handleDeleteDiscount(this.state.deleteId)}
              >
                {configJSON.deleteText}
              </DeleteButton>
              <CancelButton
                data-test-id="cancelDeleteModal"
                onClick={() => this.handleCloseDeleteModal()}
              >
                Cancel
              </CancelButton>
            </ModalButton>
          </ModalView>
        </DeleteModal>
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleDiscountSearch(e)}
        isLoading={this.state.isLoading}
        title={"Flat Discount"}
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.addDiscountNavigate(),
            text: "Add Flat Discount",
            testId: "addDiscountTestId"
          }
        ]}
        isStudent={false}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        {this.renderDeleteCouponModal()}
        <main className="table_data">{this.renderCouponsTable()}</main>
      </MainLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const GenerateColumnTitles = ({
  columns,
  onSort,
  currentSortState
}: {
  columns: string[];
  onSort?: (name: string) => void;
  currentSortState: { field: string; order: string };
}) => {
  return (
    <>
      {columns.map((header, index) => {
        const hasSorting = [
          "S.No",
          "Coupons",
          "Vouchers",
          "Expiry Date",
          "Status"
        ].includes(header);
        const isActive = currentSortState.field === header;
        const isAscending = isActive && currentSortState.order === "asc";
        return (
          <th key={index.toString()}>
            <Box className="th_box" style={{ justifyContent: "center" }}>
              <Typography className="title_th">{header}</Typography>
              {hasSorting && (
                <IoIosArrowUp
                  data-test-id= "filter_icon"
                  className={`filter_icon ${isAscending ? "active" : ""}`}
                  style={{ transform: isAscending ? "none" : "rotate(180deg)" }}
                  onClick={() => onSort && onSort(header)}
                />
              )}
            </Box>
          </th>
        );
      })}
    </>
  );
};
// Customizable Area End

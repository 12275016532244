import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import "./CourseList.css";
import "./Courses.css"; 
import { headerImage2, lecturesIcon, board, board2 } from "./assets";
import { IoIosSearch, IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { IoFilterOutline, IoLanguageOutline } from "react-icons/io5";
import { MdOutlineWindow } from "react-icons/md";
import { TbLayoutList } from "react-icons/tb";
import { LiaRupeeSignSolid } from "react-icons/lia";
import {
  FaCirclePlay,
  FaCircle,
  FaStar,
  FaHeart,
  FaCircleCheck,
} from "react-icons/fa6";
import { PiVideoFill } from "react-icons/pi";
import { HiMiniClock } from "react-icons/hi2";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class CourseList extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return (
      <Grid container className="header">
        <Grid
          container
          item
          xs={12}
          md={7}
          className="headerLeft"
        >
          <h1 className="h1">The entires CHSE syllabus at your fingertips</h1>
          <div className="searchBox">
            <div className="selectWrapper">
              <select className="select">
                {this.state.categories.map((category: any, index: number) => (
                  <option
                    key={index.toString()}
                    value={category.value}
                    className="option"
                  >
                    {category.name}
                  </option>
                ))}
              </select>
              <IoIosArrowDown size={20} />
            </div>
            <div className="searchWrapper">
              <input placeholder="Search with keywords..." className="input" />
              <div className="searchIcon">
                <IoIosSearch className="icon" />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5} lg={6} className="image">
          <img
            src={headerImage2}
            alt="student-on-a-desk-illustration"
            className="img headerImage"
          />
        </Grid>
      </Grid>
    );
  };

  renderTabMenu = () => {
    return (
      <div className="tabMenu">
        {this.state.tabs.map((tab: any, index: number) => (
          <div
            key={index.toString()}
            className="tabItemWrapper"
            onClick={() => this.handleTabClick(index)}
          >
            <div
              className={
                this.state.activeTab === index ? "tabItemActive" : "tabItem"
              }
            >
              <p className="tabText">{tab.name}</p>
            </div>
            <div
              className={
                this.state.activeTab === index ? "borderActive" : "border"
              }
            ></div>
          </div>
        ))}
      </div>
    );
  };

  renderHeading = () => {
    return (
      <Grid container className="heading">
        <Grid item xs={12} sm={7} className="headingTitle">
          <p
            style={{
              color:
                this.state.tabs[this.state.activeTab].name == "All Courses"
                  ? "#FF8256"
                  : "#858585",
            }}
          >
            All Courses
          </p>
          {this.state.tabs[this.state.activeTab].name !== "All Courses" && (
            <>
              <IoIosArrowForward className="arrowRight" />
              <p style={{ color: "#FF8256" }}>
                {this.state.tabs[this.state.activeTab].name}
              </p>
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={5} className="headingIcons">
          <div style={{ backgroundColor: "#DCEAFF" }} className="iconWrapper">
            <IoFilterOutline className="filter" />
          </div>
          <div className="view">
            <div
              style={{
                backgroundColor: this.state.tabView ? "#00BE10" : "EEEFEF",
              }}
              className="iconWrapper"
              test-id="tabView"
              onClick={this.handleTabView}
            >
              <MdOutlineWindow
                className="window"
                color={this.state.tabView ? "#FFF" : "#000"}
              />
            </div>
            <div
              style={{
                backgroundColor: this.state.listView ? "#00BE10" : "EEEFEF",
              }}
              className="iconWrapper"
              onClick={this.handleListView}
            >
              <TbLayoutList
                className="listIcon"
                color={this.state.listView ? "#FFF" : "#000"}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderTabCard = (course: any) => {
    return (
      <div className="cardWrapper">
        <div className="spacingWrapper">
          <div className="imgWrapper">
            <div className="thumbnailImg">
              <img
                src={course?.attributes?.course_thumbnail}
                alt="course-thumbnail-image"
                className="thumbnail"
              />
            </div>
            <FaCirclePlay className="playIcon" color={"white"} />
          </div>
          <div className="courseHeading">
            <div className="courseHeadingLeft">
              <div
                className="courseName"
                style={{
                  borderBottom: `2px solid ${course?.attributes?.course_child_category?.color}`,
                }}
              >
                <FaCircle
                  className="circleIcon"
                  color={course?.attributes?.course_child_category?.color}
                />
                <p>{course?.attributes?.course_child_category?.name}</p>
              </div>
              <div className="courseRatingInfo">
                <FaStar className="starIcon" color={"#ffc108"} size={10} />
                &nbsp;
                <p>
                  4.5 <span>| 6K Students</span>
                </p>
              </div>
            </div>
            <div className="wishlistIconWrapper">
              {course?.attributes?.is_wishlist == "true" ? (
                <FaHeart className="heartIcon" color={"red"} />
              ) : (
                <FaHeart className="heartIcon" color={"#DBDBDB"} />
              )}
            </div>
          </div>
          <div className="courseDesc">
            <p>{course?.attributes?.course_name}</p>
          </div>
        </div>
        <div
          className="instructor"
          style={{
            backgroundColor: course?.attributes?.course_child_category?.color,
          }}
        >
          <p>Instructor: {course?.attributes?.instructor_name}</p>
        </div>
        <div className="spacingWrapper">
          <div className="purchaseInfo">
            <div className="price">
              <LiaRupeeSignSolid className="rupeeIcon" />
              <p>{course?.attributes?.first_year_price}/-</p>
            </div>
            <div
              className={
                course?.attributes?.is_purchased ? "cartPurchased" : "cart"
              }
            >
              {course?.attributes?.is_purchased ? (
                <p>
                  <FaCircleCheck className="check" />
                  &nbsp; Added
                </p>
              ) : (
                <p>+ Add to Cart</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHoveredTabCard = (course: any, index: number) => {
    return (
      <div
        style={{ display: this.state.hoveredIndex === index ? "flex" : "none" }}
        className="cardWrapper2"
      >
        <div className="spacingWrapper2">
          <div className="courseHeadingWrapper">
            <div className="courseHeading2">
              <p>{course?.attributes?.course_child_category?.name}</p>
            </div>

            <div className="wishlistIconWrapperList wishlistIconWrapperList2">
                {course?.attributes?.is_wishlist == "true" ? (
                  <FaHeart
                    className="heartIconList heartIconList2"
                    color={"red"}
                  />
                ) : (
                  <FaHeart
                    className="heartIconList heartIconList2"
                    color={"#4C8491"}
                  />
                )}
              </div>
          </div>

          <div className="courseDesc2">
            <p style={{ color: "white" }}>
              {course?.attributes?.course_name}
            </p>
          </div>

          <div className="shortDesc">
            <p>{course?.attributes?.short_description}</p>
          </div>

          <div className="courseInfo">
            <div className="courseInfoLeft">
              <div className="courseRatingInfo2">
                <FaStar className="starIcon2" color={"#ffc108"} />
                &nbsp;
                <p>
                  4.5 | <span>6K Students</span>
                </p>
              </div>
              <div className="info1">
                <p>INSTRUCTOR</p>
                <p>{course?.attributes?.instructor_name}</p>
              </div>
              <div className="info1">
                <p>LANGUAGE</p>
                <p>{course?.attributes?.language_name}</p>
              </div>
            </div>
            <div className="divider">
              <div className="line"></div>
            </div>
            <div className="courseInfoRight">
              <div className="info">
                <PiVideoFill className="infoIcon" color={"#fff"} />
                &nbsp;
                <p>{course?.attributes?.total_content} Chapters</p>
              </div>
              <div className="info">
                <img className="boardIcon" src={lecturesIcon} alt="a-lecture-board-icon"/>
                &nbsp;
                <p>{course?.attributes?.lectures ? course?.attributes?.lectures : "0"} Lectures</p>
              </div>
              <div className="info">
                <HiMiniClock className="infoIcon" color={"#fff"} />
                &nbsp;
                <p>{course?.attributes?.all_course_class_duration} Minutes</p>
              </div>
            </div>
          </div>

          <div className="purchaseInfo purchaseInfo2">
            <div className="price price2">
              <LiaRupeeSignSolid
                className="rupeeIcon rupeeIcon2"
                color={"#fff"}
              />
              <p>{course?.attributes?.first_year_price}/-</p>
            </div>
            <div
              className={
                course?.attributes?.is_purchased ? "cartPurchased" : "cart cart2"
              }
            >
              {course?.attributes?.is_purchased ? (
                <p>
                  <FaCircleCheck />
                  &nbsp;Added
                </p>
              ) : (
                <p>+ Add to Cart</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderListCard = (course: any) => {
    return (
      <div className="cardWrapperList">
        <div className="imgWrapperList">
          <div className="thumbnailImgList">
            <img
              src={course?.attributes?.course_thumbnail}
              alt="course-thumbnail-image"
              className="thumbnailList"
            />
          </div>
          <FaCirclePlay className="playIcon" color={"white"} />
        </div>
        <div className="listInfoLeft">
          <div className="listContent">
            <div className="courseHeadingList">
              <div className="courseNameList"
                style={{
                  borderBottom: `2px solid ${course?.attributes?.course_child_category?.color}`,
                }}
              >
                <FaCircle
                  className="circleIconList"
                  color={course?.attributes?.course_child_category?.color}
                />
                <p>{course?.attributes?.course_child_category?.name}</p>
              </div>
              <div className="courseRatingInfoList">
                <FaStar className="starIcon starIconList" color={"#ffc108"} />
                <p>
                  4.5 | <span>6K Students</span>
                </p>
              </div>
              <div className="courseRatingInfoList">
                <img
                  src={board}
                  alt="icon-of-a-chalkboard"
                  className="boardIcon"
                />
                &nbsp;
                <p style={{ color: "#4C8491" }}>
                  Instructor: {course?.attributes?.instructor_name}
                </p>
              </div>
            </div>
            <div className="courseDescList">
              <p>{course?.attributes?.course_name}</p>
            </div>
          </div>

          <div className="purchaseInfoList">
            <div className="priceWrapper">
              <div className="priceList">
                <LiaRupeeSignSolid className="rupeeIconList" />
                <p>{course?.attributes?.first_year_price.toFixed(2)}/-</p>
              </div>
              <div className="priceList">
                <LiaRupeeSignSolid
                  className="rupeeIconList"
                  color={"#bdbcbc"}
                />
                <p>{course?.attributes?.second_year_price.toFixed(2)}/-</p>
                <div className="stroke"></div>
              </div>
            </div>
            <div className="cartWrapper">
              <div className="wishlistIconWrapperList">
                {course?.attributes?.is_wishlist == "true" ? (
                  <FaHeart className="heartIconList" color={"red"} />
                ) : (
                  <FaHeart className="heartIconList" color={"DBDBDB"} />
                )}
              </div>
              <div
                className={
                  course?.attributes?.is_purchased
                    ? "cartPurchasedList"
                    : "cartList"
                }
              >
                {course?.attributes?.is_purchased ? (
                  <p>
                    <FaCircleCheck color={"#00BE10"} />
                    &nbsp;Added
                  </p>
                ) : (
                  <p>+ Add to Cart</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHoveredListCard = (course: any, index: number) => {
    return (
      <div
        style={{ display: this.state.hoveredIndex === index ? "flex" : "none" }}
        className="cardWrapperList2 cardWrapperList3"
      >
        <div className="listInfoLeft2">
          <div className="courseHeadingList, courseHeadingList2">
            <div
              style={{ marginRight: "10px" }}
              className="courseHeading2 courseHeading2List"
            >
              <p style={{ color: "#000" }}>{course?.attributes?.course_child_category?.name}</p>
            </div>
            <div className="courseDesc3">
              <p>{course?.attributes?.course_name}</p>
            </div>
          </div>
          <div className="courseDescList, courseDescList2">
            <p style={{ fontWeight: 400 }}>
              {course?.attributes?.short_description}
            </p>
          </div>
          <div className="courseInfo courseInfoList">
            <div className="info infoList">
              <PiVideoFill className="infoIconList" color={"#fff"} />
              &nbsp;
              <p>{course?.attributes?.total_content} Chapters</p>
            </div>
            <div className="info infoList">
              <img src={lecturesIcon} alt="a-lecture-board-icon" className="boardIcon"/>
              &nbsp;
              <p>{course?.attributes?.lectures ? course?.attributes?.lectures : "0"} Lectures</p>
            </div>
            <div className="info infoList">
              <HiMiniClock className="infoIcon infoIconList" color={"#fff"} />
              &nbsp;
              <p>{course?.attributes?.all_course_class_duration} Minutes</p>
            </div>
            <div className="info, infoList">
              <FaStar className="starIcon2" color={"#ffc108"} />
              &nbsp;
              <p>
                4.5 | <span>6K Students</span>
              </p>
            </div>
            <div className="info, infoList">
              <img
                src={board2}
                alt="icon-of-a-chalkboard"
                className="boardIcon boardIcon2"
                style={{ width: "15px", height: "15px" }}
              />
              &nbsp;
              <p>Instructor: {course?.attributes?.instructor_name}</p>
            </div>
            <div className="info infoList">
              <IoLanguageOutline className="infoIcon infoIconList" color={"#fff"} />
              <p>Language:&nbsp;</p>
              <p>{course?.attributes?.language_name}</p>
            </div>
          </div>
        </div>

        <div className="purchaseInfoList purchaseInfoList2">
          <div className="priceWrapper priceWrapperList">
            <div className="priceList priceList2">
              <LiaRupeeSignSolid className="rupeeIconList rupeeIconList2" />
              <p>{course?.attributes?.first_year_price.toFixed(2)}/-</p>
            </div>
            <div className="priceList priceList2">
              <LiaRupeeSignSolid
                className="rupeeIconList rupeeIconList2"
                color={"#bdbcbc"}
              />
              <p>{course?.attributes?.second_year_price.toFixed(2)}/-</p>
              <div style={{ border: "1px solid red" }} className="stroke"></div>
            </div>
          </div>
          <div className="cartWrapper cartWrapperList">
            <div className="wishlistIconWrapperList wishlistIconWrapperList2">
              {course?.attributes?.is_wishlist == "true" ? (
                <FaHeart
                  className="heartIconList heartIconList2"
                  color={"red"}
                />
              ) : (
                <FaHeart
                  className="heartIconList heartIconList2"
                  color={"#4C8491"}
                />
              )}
            </div>
            <div
              className={
                course?.attributes?.is_purchased
                  ? "cartPurchasedList cartPurchasedList2"
                  : "cartList cartList2"
              }
            >
              {course?.attributes?.is_purchased ? (
                <p style={{ color: "#316773" }}>
                  <FaCircleCheck color={"#4C8491"} />
                  &nbsp;Added
                </p>
              ) : (
                <p style={{ color: "#316773" }}>+ Add to Cart</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderShowMore = (data: any) => {
    return (
      <>
        {data >= 10 && (
          <div className="showMore" onClick={this.handleShowMore}>
            <IoIosArrowDown size={25} color="#1220C1" />
            <p>Show More</p>
          </div>
        )}
      </>
    );
  };

  renderCourses = () => {
    return (
      <>
        <Grid
          container
          className="courses"
          style={{ flexDirection: this.state.tabView ? "row" : "column" }}
        >
          {this.state.tabView && (
            <>
              {this.state.courses.map((course: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  key={index.toString()}
                  className="cardContainer"
                  test-id="mouseEnterLeave"
                  onMouseEnter={() => this.handleMouseEnter(index)}
                  onMouseLeave={this.handleMouseLeave}
                >
                  {this.renderTabCard(course)}
                  {this.renderHoveredTabCard(course, index)}
                </Grid>
              ))}
            </>
          )}

          {this.state.listView && (
            <>
              {this.state.courses.map((course: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  key={index.toString()}
                  className="cardContainerList"
                  test-id="mouseEnterLeave"
                  onMouseEnter={() => this.handleMouseEnter(index)}
                  onMouseLeave={this.handleMouseLeave}
                >
                  {this.renderListCard(course)}
                  {this.renderHoveredListCard(course, index)}
                </Grid>
              ))}
            </>
          )}
        </Grid>
        {this.renderShowMore(this.state.courses.length)}
      </>
    );
  };
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <div className="container">
        {this.renderHeader()}
        <div className="contentWrapper">
          {this.renderTabMenu()}
          {this.renderHeading()}
          <div className="content">
            <div className="bgCircle"></div>
            {this.renderCourses()}
          </div>
        </div>
      </div>
    );
  }
}

// Customizable Area Start
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
